import React from "react";
import Add from "./Add";
import AlreadyConfirm from "./AlreadyConfirm";
import ArrowBack from "./ArrowBack";
import ArrowForward from "./ArrowForward";
import ArrowForward200 from "./ArrowForward200";
import Block from "./Block";
import CancelDone from "./CancelDone";
import CancelNone from "./CancelNone";
import Certificate from "./Certificate";
import CheckBoxBlank from "./CheckBoxBlank";
import CheckBoxFill from "./CheckBoxFill";
import ChevronRightBlue from "./ChevronRightBlue";
import Close from "./Close";
import CloseWhite from "./CloseWhite";
import CompanyBuildingIcon from "./CompanyBuildingIcon";
import ConfirmDone from "./ConfirmDone";
import ConfirmNone from "./ConfirmNone";
import Delete from "./Delete";
import EditSquare from "./EditSquare";
import EmployeeIcon from "./EmployeeIcon";
import ExpandLess from "./ExpandLess";
import ExpandMore from "./ExpandMore";
import InfoIcon from "./InfoIcon";
import Leave from "./Leave";
import Logout from "./Logout";
import NewEmployeeIcon from "./NewEmployeeIcon";
import OpenPreview from "./OpenPreview";
import Read from "./Read";
import ReadNone from "./ReadNone";
import Remove from "./Remove";
import RequestResend from "./RequestResend";
import ResignerIcon from "./ResignerIcon";
import Search from "./Search";
import SelfIssue from "./SelfIssue";
import Settings from "./Settings";
import WarningIcon from "./WarningIcon";
import SearchPersonIcon from "./SearchPersonIcon";
import PersonIcon from "./PersonIcon";
import ConsentDocument from "./ConsentDocument";
import SearchDocument from "./SearchDocument";
import Home from "./Home";
import Notification from "./Notification";
import PaginationSingleArrowLeft from "./PaginationSingleArrowLeft";
import PaginationSingleArrowRight from "./PaginationSingleArrowRight";
import PaginationDoubleArrowLeft from "./PaginationDoubleArrowLeft";
import PaginationDoubleArrowRight from "./PaginationDoubleArrowRight";
import RequiredDocument from "./RequiredDocument";

const Icon = ({ name, size, color, marginRight, marginLeft }) => {
  return (
    <div
      style={{
        marginRight: marginRight ? marginRight : "0px",
        marginLeft: marginLeft ? marginLeft : "0px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {name === "Add" && <Add size={size} color={color} />}
      {name === "AlreadyConfirm" && <AlreadyConfirm size={size} color={color} />}
      {name === "ArrowBack" && <ArrowBack size={size} color={color} />}
      {name === "ArrowForward" && <ArrowForward size={size} color={color} />}
      {name === "ArrowForward200" && <ArrowForward200 size={size} color={color} />}
      {name === "Block" && <Block size={size} color={color} />}
      {name === "CancelDone" && <CancelDone size={size} color={color} />}
      {name === "CancelNone" && <CancelNone size={size} color={color} />}
      {name === "Certificate" && <Certificate size={size} color={color} />}
      {name === "CheckBoxBlank" && <CheckBoxBlank size={size} color={color} />}
      {name === "CheckBoxFill" && <CheckBoxFill size={size} color={color} />}
      {name === "ChevronRightBlue" && <ChevronRightBlue size={size} color={color} />}
      {name === "Close" && <Close size={size} color={color} />}
      {name === "CloseWhite" && <CloseWhite size={size} color={color} />}
      {name === "CompanyBuildingIcon" && <CompanyBuildingIcon size={size} color={color} />}
      {name === "ConfirmDone" && <ConfirmDone size={size} color={color} />}
      {name === "ConfirmNone" && <ConfirmNone size={size} color={color} />}
      {name === "Delete" && <Delete size={size} color={color} />}
      {name === "EditSquare" && <EditSquare size={size} color={color} />}
      {name === "EmployeeIcon" && <EmployeeIcon size={size} color={color} />}
      {name === "ExpandLess" && <ExpandLess size={size} color={color} />}
      {name === "ExpandMore" && <ExpandMore size={size} color={color} />}
      {name === "InfoIcon" && <InfoIcon size={size} color={color} />}
      {name === "Leave" && <Leave size={size} color={color} />}
      {name === "Logout" && <Logout size={size} color={color} />}
      {name === "NewEmployeeIcon" && <NewEmployeeIcon size={size} color={color} />}
      {name === "OpenPreview" && <OpenPreview size={size} color={color} />}
      {name === "Read" && <Read size={size} color={color} />}
      {name === "ReadNone" && <ReadNone size={size} color={color} />}
      {name === "Remove" && <Remove size={size} color={color} />}
      {name === "RequestResend" && <RequestResend size={size} color={color} />}
      {name === "ResignerIcon" && <ResignerIcon size={size} color={color} />}
      {name === "Search" && <Search size={size} color={color} />}
      {name === "SelfIssue" && <SelfIssue size={size} color={color} />}
      {name === "Settings" && <Settings size={size} color={color} />}
      {name === "WarningIcon" && <WarningIcon size={size} color={color} />}
      {name === "SearchPersonIcon" && <SearchPersonIcon size={size} color={color} />}
      {name === "PersonIcon" && <PersonIcon size={size} color={color} />}
      {name === "ConsentDocument" && <ConsentDocument size={size} color={color} />}
      {name === "SearchDocument" && <SearchDocument size={size} color={color} />}
      {name === "Home" && <Home size={size} color={color} />}
      {name === "Notification" && <Notification size={size} color={color} />}
      {name === "PaginationSingleArrowLeft" && <PaginationSingleArrowLeft size={size} color={color} />}
      {name === "PaginationSingleArrowRight" && <PaginationSingleArrowRight size={size} color={color} />}
      {name === "PaginationDoubleArrowLeft" && <PaginationDoubleArrowLeft size={size} color={color} />}
      {name === "PaginationDoubleArrowRight" && <PaginationDoubleArrowRight size={size} color={color} />}
      {name === "RequiredDocument" && <RequiredDocument size={size} color={color} />}
    </div>
  );
};

export default Icon;
