const ConsentDocument = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17.475C17.7583 3 17.9958 3.09583 18.1875 3.2875C18.3792 3.47917 18.475 3.71667 18.475 4C18.475 4.28333 18.3792 4.52083 18.1875 4.7125C17.9958 4.90417 17.7583 5 17.475 5H5V19H19V12.5C19 12.2167 19.0958 11.9792 19.2875 11.7875C19.4792 11.5958 19.7167 11.5 20 11.5C20.2833 11.5 20.5208 11.5958 20.7125 11.7875C20.9042 11.9792 21 12.2167 21 12.5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM11.525 14.2L20.025 5.7C20.2083 5.51667 20.4333 5.425 20.7 5.425C20.9667 5.425 21.2 5.51667 21.4 5.7C21.6 5.88333 21.7 6.11667 21.7 6.4C21.7 6.68333 21.6 6.925 21.4 7.125L12.225 16.3C12.025 16.5 11.7917 16.6 11.525 16.6C11.2583 16.6 11.025 16.5 10.825 16.3L6.575 12.05C6.39167 11.8667 6.3 11.6333 6.3 11.35C6.3 11.0667 6.39167 10.8333 6.575 10.65C6.75833 10.4667 6.99167 10.375 7.275 10.375C7.55833 10.375 7.79167 10.4667 7.975 10.65L11.525 14.2Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default ConsentDocument;
