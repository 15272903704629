const PaginationDoubleArrowRight = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.57511 11.9998L5.70011 8.0998C5.51678 7.91647 5.42094 7.6873 5.41261 7.4123C5.40428 7.1373 5.50011 6.8998 5.70011 6.6998C5.88344 6.51647 6.11678 6.4248 6.40011 6.4248C6.68344 6.4248 6.91678 6.51647 7.10011 6.6998L11.7001 11.2998C11.8001 11.3998 11.8709 11.5081 11.9126 11.6248C11.9543 11.7415 11.9751 11.8665 11.9751 11.9998C11.9751 12.1331 11.9543 12.2581 11.9126 12.3748C11.8709 12.4915 11.8001 12.5998 11.7001 12.6998L7.10011 17.2998C6.91678 17.4831 6.68761 17.579 6.41261 17.5873C6.13761 17.5956 5.90011 17.4998 5.70011 17.2998C5.51678 17.1165 5.42511 16.8831 5.42511 16.5998C5.42511 16.3165 5.51678 16.0831 5.70011 15.8998L9.57511 11.9998ZM16.1751 11.9998L12.3001 8.0998C12.1168 7.91647 12.0209 7.6873 12.0126 7.4123C12.0043 7.1373 12.1001 6.8998 12.3001 6.6998C12.4834 6.51647 12.7168 6.4248 13.0001 6.4248C13.2834 6.4248 13.5168 6.51647 13.7001 6.6998L18.3001 11.2998C18.4001 11.3998 18.4709 11.5081 18.5126 11.6248C18.5543 11.7415 18.5751 11.8665 18.5751 11.9998C18.5751 12.1331 18.5543 12.2581 18.5126 12.3748C18.4709 12.4915 18.4001 12.5998 18.3001 12.6998L13.7001 17.2998C13.5168 17.4831 13.2876 17.579 13.0126 17.5873C12.7376 17.5956 12.5001 17.4998 12.3001 17.2998C12.1168 17.1165 12.0251 16.8831 12.0251 16.5998C12.0251 16.3165 12.1168 16.0831 12.3001 15.8998L16.1751 11.9998Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default PaginationDoubleArrowRight;
