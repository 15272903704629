const ArrowForward = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8311 12L7.29841 4.46731C7.09969 4.26859 7.00289 4.03206 7.00801 3.75771C7.01314 3.48334 7.11506 3.24681 7.31378 3.04811C7.5125 2.84939 7.74903 2.75003 8.02338 2.75003C8.29773 2.75003 8.53426 2.84939 8.73298 3.04811L16.3888 10.7193C16.5695 10.9 16.7035 11.1026 16.7907 11.3269C16.8778 11.5513 16.9214 11.7757 16.9214 12C16.9214 12.2244 16.8778 12.4487 16.7907 12.6731C16.7035 12.8974 16.5695 13.1 16.3888 13.2808L8.7176 20.9519C8.51889 21.1506 8.28491 21.2474 8.01568 21.2423C7.74646 21.2372 7.5125 21.1352 7.31378 20.9365C7.11506 20.7378 7.0157 20.5013 7.0157 20.2269C7.0157 19.9526 7.11506 19.716 7.31378 19.5173L14.8311 12Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ArrowForward;
