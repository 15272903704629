import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import { AddDotYYYYMMDD, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import { HideMemberDataKeys } from "../Utils/KeyArray";
import DetailList from "../Components/DetailList";
import NavigationPanel from "../Components/NavigationPanel";

const WB71ResignerSub4 = observer(({ setResigner3, setResigner4, setResigner5, resignerData, setResignerData }) => {
  const [isExpandMore, setIsExpandMore] = useState(false);

  const onClickBack = () => {
    amplitude.track("퇴사 키트 발급3 - 퇴사자 이름/전화번호 등록 페이지 진입");
    setResignerData();
    setResigner4(false);
    setResigner3(true);
  };

  const onClickNext = () => {
    amplitude.track("퇴사 키트 발급5 - 퇴사자 퇴사 예정일 등록 페이지 진입", {
      퇴사자정보: resignerData,
    });
    setResigner4(false);
    setResigner5(true);
  };

  const secondBodyText = (resignerData?.Job || [])
    .slice(1, 5)
    .map((job) => job?.Job)
    .filter((job) => job)
    .join("\n");

  return (
    <div className="AllContent_Default_V2" style={{ marginBottom: "192px" }}>
      <div className="Leave_LeftArea_CT">
        <div className="Leave_Main_Copy">퇴사자의 정보를 확인해주세요.</div>
        <div className="Resigner_BG_ResignerDetail">
          {resignerData.Name ? <DetailList label={"이름"} bodyText={resignerData.Name} /> : null}
          {resignerData.Gender ? <DetailList label={"성별"} bodyText={resignerData.Gender} /> : null}
          {resignerData.Birthday ? <DetailList label={"생년월일"} bodyText={AddDotYYYYMMDD(resignerData.Birthday)} /> : null}
          {resignerData.TelNumber ? <DetailList label={"전화번호"} bodyText={AddHyphenTelNumber(resignerData.TelNumber)} /> : null}
          {resignerData.Email ? <DetailList label={"이메일"} bodyText={resignerData.Email} /> : null}
          {resignerData.Address || resignerData.주소 ? <DetailList label={"주소"} bodyText={resignerData.Address || resignerData.주소} /> : null}
          {resignerData.AcqDate ? <DetailList label={"입사일"} bodyText={AddDotYYYYMMDD(resignerData.AcqDate)} /> : null}
          {resignerData.LoseDate ? <DetailList label={"퇴사일"} bodyText={AddDotYYYYMMDD(resignerData.LoseDate)} /> : null}
          {resignerData.Position ? <DetailList label={"포지션"} bodyText={resignerData.Position} /> : null}
          {resignerData.Department ? <DetailList label={"부서"} bodyText={resignerData.Department} /> : null}
          {resignerData.JobPosition ? <DetailList label={"직위/직급"} bodyText={resignerData.JobPosition} /> : null}
          {resignerData.Job && resignerData.Job.length > 0 ? (
            <>
              {secondBodyText && (
                <>
                  <DetailList
                    label={"직무/업무"}
                    bodyText={resignerData.Job[0].Job}
                    state={"extendable"}
                    isExpandMore={isExpandMore}
                    setIsExpandMore={setIsExpandMore}
                    secondBodyText={secondBodyText}
                  />
                </>
              )}
              {!secondBodyText && resignerData.Job && (
                <>
                  <DetailList label={"직무/업무"} bodyText={resignerData.Job[0].Job} />
                </>
              )}
            </>
          ) : null}
          {Object.keys(resignerData)
            .filter((key) => !HideMemberDataKeys.includes(key))
            .map((key) => {
              const value = resignerData[key];
              const displayValue = typeof value === "object" ? JSON.stringify(value) : value;
              return <DetailList key={key} label={key} bodyText={displayValue} />;
            })}
        </div>
      </div>
      <div className="Leave_Vertical_Divider" />
      <div className="Leave_RightArea_CT">
        <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
        <div className="Leave_PageCount_Copy">1/4</div>
      </div>
    </div>
  );
});

export default WB71ResignerSub4;
