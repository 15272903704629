import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const OfferLetterInfoModal = ({ setOfferLetterInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">입사확약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setOfferLetterInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            채용 과정에서 입사 번복의 문제는 꽤 많이 발생합니다. 채용 과정에서 입사가 확정된 경우, 입사확약서를 작성하면 입사 번복의 문제를 줄일 수
            있습니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            입사확약서를 작성함으로써 회사와 근로자가 확실하게 입사를 하고 채용을 하겠다는 <span style={{ fontWeight: 600 }}>약속</span>을 통해 서로의
            시간과 노력을 지킬 수 있습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferLetterInfoModal;
