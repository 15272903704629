import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { DocumentNameToKorean } from "../Utils/ConvertStringFormat";
import * as amplitude from "@amplitude/analytics-browser";

const WB51ConsentDocumentSub4 = observer(({ setDocument1, setDocument4, selectedDocuments, setSelectedDocuments, sendDocumentArray }) => {
  const onClickConfirm = () => {
    amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
    setDocument4(false);
    setDocument1(true);
    setSelectedDocuments([]);
  };

  return (
    <>
      {selectedDocuments.length > 1 ? (
        <div className="Leave_Guide_Copy" style={{ marginTop: "120px" }}>
          {sendDocumentArray[0].Name} 님 의{" "}
          <span style={{ color: "rgb(55, 105, 252)" }}>{DocumentNameToKorean(sendDocumentArray[0].DocumentName)}</span> 외{" "}
          {sendDocumentArray.length - 1}건의 동의 요청이 발송되었어요!
        </div>
      ) : (
        <div className="Leave_Guide_Copy" style={{ marginTop: "120px" }}>
          {selectedDocuments[0].Name} 님의{" "}
          <span style={{ color: "rgb(55, 105, 252)" }}>{DocumentNameToKorean(selectedDocuments[0].DocumentName)}</span> 동의 요청이 발송되었어요!
        </div>
      )}

      <div className="Leave_Image_CT">
        <img src={require("../Assets/Images/PaperPlane.png")} alt="PaperPlane" width="33%" height="auto" />
      </div>
      <div className="Leave_Sub_Copy" style={{ marginTop: 72, textAlign: "center" }}>
        동의가 완료되면 알림으로 완료 사실을 알리고
        <br />
        문서 조회를 통해 언제든지 확인하고 재발송 할 수 있습니다.
      </div>
      <div className="Leave_TextButton_CT" style={{ justifyContent: "center" }}>
        <div className="Leave_IconTextButton_CT">
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickConfirm}>
            확인
          </button>
          <Icon name={"ChevronRightBlue"} size={32} />
        </div>
      </div>
    </>
  );
});

export default WB51ConsentDocumentSub4;
