import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import { observer } from "mobx-react";
import React from "react";

const PDFInputField = observer(({ name, link }) => {
  const openLink = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <div className="ResignerDetail_Input_CT">
      <div className="ResignerDetail_PDF_CT">
        <div className="ResignerDetail_Input_BG">{name}</div>
        <button
          type="button"
          className="ProfileDetail_UploadButton"
          onClick={() => {
            openLink(link);
          }}
        >
          PDF
        </button>
      </div>
    </div>
  );
});

export default PDFInputField;
