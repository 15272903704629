import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useLocation } from "react-router-dom";
import DetailTitle from "../Components/DetailTitle";
import { observer } from "mobx-react";
import DetailHead from "../Components/DetailHead";

const WBc2InvestorCompanyShareholderReportDetail = observer(() => {
  const location = useLocation();
  const itemData = location.state.item;
  const cacheBustingUrl = `${itemData.Url}?t=${new Date().getTime()}`;

  return (
    <div className="FlexContent_Other_V">
      <DetailTitle title={`${itemData.ReportName}`} />
      <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
        {itemData && (
          <>
            <DetailHead itemData={itemData} labelList={["주주보고서 시기", "주주보고서 작성일"]} />
            <div className="Document_PDF_Contents_CT">
              <div className="PDFDetail_BG">
                <iframe src={cacheBustingUrl} title="This is a unique title" height="1240" style={{ width: "75vw" }}></iframe>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default WBc2InvestorCompanyShareholderReportDetail;
