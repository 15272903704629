import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Pagination from "../Pagination";
import { AddDotYYYYMMDD, ConvertKSTFormatDate, AddHyphenTelNumber } from "../../Utils/ConvertNumberFormat";
import { DocumentNameToKorean } from "../../Utils/ConvertStringFormat";
import CheckBox from "../Atom/CheckBox";

const WidgetList = observer(
  ({
    type,
    fieldList,
    listDataArray,
    onClickListItem,
    itemsPerPage,
    height,
    selectedListItemArray,
    setSelectedListItemArray,
    onClickCheckbox,
    showDetailData,
  }) => {
    const navigate = useNavigate();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const pageCount = Math.ceil(listDataArray.length / itemsPerPage);
    const [currentListDataArray, setCurrentListDataArray] = useState([]);

    useEffect(() => {
      if (currentListDataArray.length === 0 && listDataArray.length > 0) {
        setCurrentListDataArray(listDataArray.slice(0, itemsPerPage));
      }
    }, [currentListDataArray.length, itemsPerPage, listDataArray]);

    useEffect(() => {
      if (isCheckAll && selectedListItemArray.length < listDataArray.length) {
        setIsCheckAll(false);
      } else if (isCheckAll && selectedListItemArray.length === listDataArray.length) {
        setIsCheckAll(true);
      }
    }, [selectedListItemArray, isCheckAll, listDataArray]);

    const handlePageChange = (page) => {
      const start = (page - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setCurrentListDataArray(listDataArray.slice(start, end));
    };

    const onClickDetail = (item) => {
      navigate(`/consentDocumentDetail/${item.ID}`, {
        state: {
          item: item,
        },
      });
    };

    // 필드 매핑
    const fieldMapping = [
      { key: "Name", label: "이름", className: "WidgetList_BodyText", width: "84px", marginRight: "24px", format: (data) => data || "-" },
      { key: "Name", label: "수령인", className: "WidgetList_BodyText", width: "84px", marginRight: "24px", format: (data) => data || "-" },
      { key: "Birthday", label: "생년월일", className: "WidgetList_BodyText", width: "86px", marginRight: "24px", format: AddDotYYYYMMDD },
      { key: "TelNumber", label: "전화번호", className: "WidgetList_BodyText", width: "128px", marginRight: "24px", format: AddHyphenTelNumber },
      { key: "Department", label: "부서", className: "WidgetList_BodyText", marginRight: "24px", format: (data) => data || "-" },
      {
        key: "JobPosition",
        label: "직급/직위",
        className: "WidgetList_BodyText",
        width: "120px",
        marginRight: "24px",
        format: (data) => data || "-",
      },
      {
        key: "WorkPeriod",
        label: "재직기간",
        className: "WidgetList_BodyText",
        width: "192px",
        marginRight: "24px",
        format: (data) => (data?.AcqDate && data?.LoseDate ? `${AddDotYYYYMMDD(data.AcqDate)} ~ ${AddDotYYYYMMDD(data.LoseDate)}` : "-"),
      },
      { key: "DocumentName", label: "문서명", className: "WidgetList_BodyText", marginRight: "24px", format: DocumentNameToKorean },
      {
        key: "DocumentStatus",
        label: "동의 여부",
        className: "WidgetList_BodyText",
        width: "52px",
        marginRight: "24px",
        format: (data) => data || "-",
      },
      {
        key: "ReportYearMonth",
        label: "주주보고서 시기",
        className: "WidgetList_BodyText",
        width: "84px",
        marginRight: "24px",
        format: (date) => {
          const dateString = date?.toString();
          return dateString?.length === 6 ? `${dateString.slice(0, 4)}년 ${dateString.slice(4, 6)}월` : "-";
        },
      },
      {
        key: "CreationDate",
        label: "작성일",
        className: "WidgetList_BodyText",
        width: showDetailData ? "160px" : "74px",
        format: ConvertKSTFormatDate,
      },
      { key: "AcqDate", label: "입사일", className: "WidgetList_BodyText", width: "74px", format: AddDotYYYYMMDD },
      { key: "CreationDate", label: "발급일", className: "WidgetList_BodyText", width: "74px", format: ConvertKSTFormatDate },
    ];

    // 필드 렌더링 함수
    const renderFields = (data) =>
      fieldMapping
        .filter((field) => fieldList.includes(field.label))
        .map((field, index) => (
          <div
            key={field.key}
            className={field.className}
            style={{
              width:
                field.width && showDetailData && field.label !== "작성일"
                  ? field.width
                  : field.width && showDetailData && field.label === "작성일"
                  ? "74px"
                  : !field.width
                  ? "100%"
                  : field.width,
              minWidth:
                field.width && showDetailData && field.label !== "작성일"
                  ? field.width
                  : field.width && showDetailData && field.label === "작성일"
                  ? "74px"
                  : !field.width
                  ? "auto"
                  : field.width,
              maxWidth:
                field.width && showDetailData && field.label !== "작성일"
                  ? field.width
                  : field.width && showDetailData && field.label === "작성일"
                  ? "74px"
                  : !field.width
                  ? "100%"
                  : field.width,
              marginLeft: type === "singleSelect" && index === 0 ? "12px" : "0px",
              marginRight: field.marginRight ? field.marginRight : index === fieldList.length - 1 ? "12px" : "0px",
            }}
          >
            {field.format(data[field.key])}
          </div>
        ));

    return (
      <>
        {type === "singleSelect" && (
          <div className="WidgetList_Container">
            <div className="WidgetList_LabelContainer">
              {fieldMapping
                .filter((field) => fieldList.includes(field.label))
                .map((field, index) => (
                  <div
                    key={field.key}
                    className={"WidgetList_LabelText"}
                    style={{
                      width: field.width ? "auto" : "100%",
                      minWidth: field.width ? field.width : "auto",
                      maxWidth: field.width ? field.width : "auto",
                      marginLeft: index === 0 ? "12px" : "0px",
                      marginRight: field.marginRight ? field.marginRight : index === fieldList.length - 1 ? "12px" : "0px",
                    }}
                  >
                    {field.label}
                  </div>
                ))}
            </div>
            <div className="WidgetList_Divider" />
            <div className="WidgetList_ListContainer" style={{ height: height }}>
              {currentListDataArray.map((item) => (
                <button key={item.ID} className="WidgetList_Button" onClick={() => onClickListItem(item)}>
                  <div className="WidgetList_ContentsContainer">{renderFields(item)}</div>
                  <div className="WidgetList_ContentsDivider" />
                </button>
              ))}
            </div>
            {pageCount > 1 && (
              <div className="WidgetList_Pagination">
                <Pagination totalItems={listDataArray.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
              </div>
            )}
          </div>
        )}
        {type === "singleCheckbox" && (
          <div className="WidgetList_Container">
            <div className="WidgetList_LabelContainer">
              {fieldMapping
                .filter((field) => fieldList.includes(field.label))
                .map((field) => (
                  <div
                    key={field.key}
                    className={"WidgetList_LabelText"}
                    style={{
                      width: field.width ? "auto" : "100%",
                      minWidth: field.width ? field.width : "auto",
                      maxWidth: field.width ? field.width : "auto",
                      marginLeft: type === "singleCheckbox" && field.label === "수령인" ? "40px" : "0px",
                      marginRight: field.marginRight ? field.marginRight : "0px",
                    }}
                  >
                    {field.label}
                  </div>
                ))}
            </div>
            <div className="WidgetList_Divider" />
            <div className="WidgetList_ListContainer" style={{ height: height }}>
              {currentListDataArray.map((item) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <div className="WidgetList_CheckListContainer" key={item.ID} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <CheckBox
                      isCheck={selectedListItemArray.includes(item)}
                      setIsCheck={() => onClickCheckbox(item)}
                      size={"32"}
                      marginRight={"16px"}
                    />

                    {renderFields(item)}
                    {showDetailData && (
                      <button className="WidgetList_DetailDataButton" onClick={() => onClickDetail(item)}>
                        상세 정보
                      </button>
                    )}
                  </div>
                  <div className="WidgetList_ContentsDivider" />
                </div>
              ))}
            </div>
            <div className="WidgetList_Pagination">
              <Pagination totalItems={listDataArray.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
            </div>
          </div>
        )}
        {type === "multiCheckbox" && (
          <div className="WidgetList_Container">
            <div className="WidgetList_LabelContainer">
              <CheckBox
                size={"32"}
                isCheck={isCheckAll}
                marginRight={"16px"}
                setIsCheck={(value) => {
                  setIsCheckAll(value);
                  setSelectedListItemArray(value ? listDataArray.map((item) => item) : []);
                }}
              />
              {fieldMapping
                .filter((field) => fieldList.includes(field.label))
                .map((field) => (
                  <div
                    key={field.key}
                    className={"WidgetList_LabelText"}
                    style={{
                      width: field.width ? "auto" : "100%",
                      minWidth: field.width ? field.width : "auto",
                      maxWidth: field.width ? field.width : "auto",
                      marginLeft: "0px",
                      marginRight: field.marginRight ? field.marginRight : "0px",
                    }}
                  >
                    {field.label}
                  </div>
                ))}
            </div>
            <div className="WidgetList_Divider" />
            <div className="WidgetList_ListContainer" style={{ height: height }}>
              {currentListDataArray.map((item) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <div className="WidgetList_CheckListContainer" key={item.ID} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <CheckBox
                      isCheck={selectedListItemArray.includes(item)}
                      setIsCheck={() => onClickCheckbox(item)}
                      size={"32"}
                      marginRight={"16px"}
                    />

                    {renderFields(item)}
                    {showDetailData && (
                      <button className="WidgetList_DetailDataButton" onClick={() => onClickDetail(item)}>
                        상세 정보
                      </button>
                    )}
                  </div>
                  <div className="WidgetList_ContentsDivider" />
                </div>
              ))}
            </div>
            <div className="WidgetList_Pagination">
              <Pagination totalItems={listDataArray.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
            </div>
          </div>
        )}
      </>
    );
  }
);

export default WidgetList;
