import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";

const PageTitle = observer(({ icon, title, onClick }) => {
  return (
    <div className="PageTitle_CT">
      {onClick ? (
        <button className="PageTitle_Icon_Button" onClick={onClick}>
          <Icon name={icon} size={"32"} color={"#7A7A7A"} />
        </button>
      ) : (
        <div className="PageTitle_Icon">
          <Icon name={icon} size={"32"} color={"#7A7A7A"} />
        </div>
      )}
      <div className="PageTitle_Title">{title}</div>
    </div>
  );
});

export default PageTitle;
