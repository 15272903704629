import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
// import * as amplitude from "@amplitude/analytics-browser";
import { useSearchParams } from "react-router-dom";

const PaymentFail = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;

  const [searchParams] = useSearchParams();

  const onClickPaymentSelect = async () => {
    // amplitude.track("회원가입1 - 약관동의 페이지 진입");
    navigate("/consentDocument");
  };

  return (
    <>
      <div className="FlexContent_Login" style={{ backgroundColor: "rgb(242, 244, 246)" }}>
        <div className="AllContent">
          <>
            <div className="box_section2" style={{ width: "540px" }}>
              <img width="100px" src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png" alt="에러 이미지" />
              <h2>결제를 실패했어요.</h2>

              <div className="PaymentConfirm_Left_CT">
                <div className="PaymentConfirm_Category_CT" style={{ marginTop: "50px" }}>
                  <div className="PaymentConfirm_Label_Copy">에러메시지</div>
                  <div id="message">{`${searchParams.get("message")}`}</div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div className="PaymentConfirm_Label_Copy">에러코드</div>
                  <div id="code">{`${searchParams.get("code")}`}</div>
                </div>
              </div>

              <div>
                <button className="button p-grid-col5" onClick={onClickPaymentSelect}>
                  다시 시도
                </button>
              </div>
            </div>
          </>
        </div>

        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default PaymentFail;
