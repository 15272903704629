const InitialLogo = () => {
  return (
    <svg
      //   width="161"
      //   height="180"
      viewBox="0 0 161 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M161 117.551H106.34V176.593L161 117.551Z" fill="#7EE5C3" />
      <path
        d="M120.401 63.3871H157.15C152.315 23.1048 121.852 0 81.4763 0C35.2984 0 0 32.7823 0 90C0 146.976 34.6939 180 81.4763 180C90.4902 180 98.7944 178.796 106.34 176.593C106.34 173.774 106.34 170.125 106.34 163.325V150.887C106.34 144.75 106.34 150.057 106.34 143.092C106.34 141.85 106.34 140.936 106.34 140.64C99.7997 145.276 91.4395 147.765 81.9599 147.823C55.2443 147.702 36.9907 127.742 36.9907 90C36.9907 53.1048 55.0026 32.2984 82.2016 32.1774C102.631 32.2984 117.5 44.0323 120.401 63.3871Z"
        fill="#3769FC"
      />
    </svg>
  );
};

export default InitialLogo;
