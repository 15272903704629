import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { AddUnitYYYYMMDDHHMMSS, CalculateNextYear } from "../Utils/ConvertNumberFormat";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";

const PaymentConfirm = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [isPaymentConfirm, setIsPaymentConfirm] = useState(false);
  const [approvalTime, setApprovalTime] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const saveData = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const updatePaymentsStatus = (newStatus) => {
    try {
      const userData = LoadComapanyData("userData");
      if (userData) {
        userData.paymentsStatus = newStatus;
        userData.approvalTime = approvalTime;
        userData.cardNumber = cardNumber;
        saveData("userData", userData);
        setIsLoading(false);
        navigate("/consentDocument");
      } else {
        console.error("No userData found in localStorage");
      }
    } catch (error) {
      console.error("Error updating paymentsStatus:", error);
    }
  };

  const onClickPaymentConfirm = async () => {
    // amplitude.track("회원가입1 - 약관동의 페이지 진입");
    setIsLoading(true);
    updatePaymentsStatus(true);
  };

  const userData = LoadComapanyData("userData");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authKey = params.get("authKey");
    const customerKey = params.get("customerKey");
    const secretKey = "live_sk_0RnYX2w532zYkOzEeQdg3NeyqApQ";

    const confirm = async () => {
      if (!responseData) {
        axios
          .post(
            "https://api.tosspayments.com/v1/billing/authorizations/issue",
            {
              authKey,
              customerKey,
            },
            {
              headers: {
                Authorization: "Basic " + btoa(secretKey + ":"),
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            const updatedResponseData = {
              ...response.data,
              authKey: authKey,
              customerKey: customerKey,
            };
            setResponseData(updatedResponseData);
            const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/payments";
            try {
              const response = await axios.post(endPoint, {
                email: userData.companyEmail,
                subscriptionType: "연간",
                companyName: userData.companyName,
                data: updatedResponseData,
              });

              if (response.data.status === "결제 정보 저장 성공") {
                setApprovalTime(response.data.data.approvalTime);
                setCardNumber(response.data.data.cardNumber);
                setIsPaymentConfirm(true);
              } else {
                alert("결제 정보 저장에 실패했습니다. 다시 시도해주세요.");
              }
            } catch (error) {
              console.log("Payments axios Error", error);
            }
          })
          .catch((error) => {
            console.log("Fail confirm axios");
            setError(error);
          });
      }
    };
    if (!responseData) {
      confirm().then(() => {
        console.log("PaymentConfirm - confirm then error : ", error);
      });
    }
  }, [userData.companyEmail, userData.companyName, error, location.search, navigate, responseData, searchParams]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="FlexContent_Login" style={{ backgroundColor: "rgb(242, 244, 246)" }}>
        <div className="AllContent">
          <>
            {isPaymentConfirm ? (
              <div className="box_section2" style={{ width: "540px" }}>
                <img width="100px" src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" alt="Success" />
                <h2>결제를 완료했어요!</h2>

                <div className="PaymentConfirm_Left_CT">
                  {responseData && (
                    <>
                      <div className="PaymentConfirm_Category_CT" style={{ marginTop: "50px" }}>
                        <div className="PaymentConfirm_Label_Copy">카드번호</div>
                        <div id="cardNumber">{cardNumber}</div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <div className="PaymentConfirm_Label_Copy">결제일자</div>

                        <div
                          // id="approvedAt"
                          style={{ whiteSpace: "initial", width: "250px" }}
                        >
                          {AddUnitYYYYMMDDHHMMSS(approvalTime)}
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <div className="PaymentConfirm_Label_Copy">서비스 이용기한</div>

                        <div
                          // id="approvedAt"
                          style={{ whiteSpace: "initial", width: "250px" }}
                        >
                          {`${AddUnitYYYYMMDDHHMMSS(approvalTime)} 부터 ${CalculateNextYear(AddUnitYYYYMMDDHHMMSS(approvalTime))} 23:59:59 까지`}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <button className="button p-grid-col5" onClick={onClickPaymentConfirm}>
                    계속 진행
                  </button>
                </div>
              </div>
            ) : (
              <div className="box_section2" style={{ width: "540px" }}>
                <img width="100px" src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" alt="Success" />
                <h2>결제를 진행중입니다.</h2>

                <div className="PaymentConfirm_Left_CT">
                  <div className="PaymentConfirm_Category_CT" style={{ marginTop: "50px" }}>
                    <div className="PaymentConfirm_Label_Copy">고객키</div>
                    <div id="customerKey">{`${searchParams.get("customerKey")}`}</div>
                  </div>
                  {/* <div style={{ marginTop: "10px" }}>
                  <div className="PaymentConfirm_Label_Copy">주문번호</div>
                  <div id="orderId">{`${searchParams.get("orderId")}`}</div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div className="PaymentConfirm_Label_Copy">결제일자</div>
                  {responseData && (
                    <div
                      // id="approvedAt"
                      style={{ whiteSpace: "initial", width: "250px" }}
                    >
                      {formatDate(responseData.approvedAt)}
                    </div>
                  )}
                </div> */}
                </div>

                {/* <div>
                  <button className="button p-grid-col5" onClick={onClickHome}>
                    홈화면으로
                  </button>
                </div> */}
              </div>
            )}

            {/* <div
              className="box_section"
              style={{ width: "600px", textAlign: "left" }}
            >
              <b>Response Data :</b>
              <div id="response" style={{ whiteSpace: "initial" }}>
                {responseData && (
                  <pre>{JSON.stringify(responseData, null, 4)}</pre>
                )}
              </div>
            </div> */}
          </>
        </div>

        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default PaymentConfirm;
