const CloseWhite = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9996 13.05L6.74961 18.3C6.59961 18.45 6.42461 18.525 6.22461 18.525C6.02461 18.525 5.84961 18.45 5.69961 18.3C5.54961 18.15 5.47461 17.975 5.47461 17.775C5.47461 17.575 5.54961 17.4 5.69961 17.25L10.9496 12L5.69961 6.75001C5.54961 6.60001 5.47461 6.42501 5.47461 6.22501C5.47461 6.02501 5.54961 5.85001 5.69961 5.70001C5.84961 5.55001 6.02461 5.47501 6.22461 5.47501C6.42461 5.47501 6.59961 5.55001 6.74961 5.70001L11.9996 10.95L17.2496 5.70001C17.3996 5.55001 17.5746 5.47501 17.7746 5.47501C17.9746 5.47501 18.1496 5.55001 18.2996 5.70001C18.4496 5.85001 18.5246 6.02501 18.5246 6.22501C18.5246 6.42501 18.4496 6.60001 18.2996 6.75001L13.0496 12L18.2996 17.25C18.4496 17.4 18.5246 17.575 18.5246 17.775C18.5246 17.975 18.4496 18.15 18.2996 18.3C18.1496 18.45 17.9746 18.525 17.7746 18.525C17.5746 18.525 17.3996 18.45 17.2496 18.3L11.9996 13.05Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default CloseWhite;
