import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";
// import WidgetNavigation from "../Components/Organism/WidgetNavigation";
// import WidgetDescription from "../Components/Organism/WidgetDescription";
// import WidgetList from "../Components/Organism/WidgetList";

const WB11Login = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const [companyId, setCompanyId] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);

  const onClickSignin = async () => {
    amplitude.track("회원가입1 - 약관동의 페이지 진입");
    navigate("/signin");
  };

  const handleLoginComplete = async () => {
    setIsLoading(true);

    try {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/member/login";

      const response = await axios.get(endPoint, {
        params: { email: companyId, password: companyPassword },
      });
      if (response.data.status === "비밀번호 일치") {
        const saveData = (key, value) => {
          try {
            localStorage.setItem(key, JSON.stringify(value));
          } catch (error) {}
        };

        const dataToSave = {
          companyEmail: response.data.email,
          companyRegistrationNumber: response.data.companyRegistrationNumber,
          companyName: response.data.companyName,
          seal: response?.data?.seal ? response.data.seal : "",
          approvalTime: response?.data?.approvalTime ? response.data.approvalTime : "",
          cardNumber: response?.data?.cardNumber ? response.data.cardNumber : "",
          paymentsStatus: response?.data?.paymentsStatus ? response.data.paymentsStatus : false,
        };
        amplitude.init("9fa2d56b8be8c76c4e7a38a6bb76da6f", response.data.companyName, {
          defaultTracking: false,
          minIdLength: 1,
        });
        saveData("userData", dataToSave);
        amplitude.track("로그인 성공후 홈 화면(증명서 관리 화면) 진입", {
          이메일: companyId,
          기업기관명: response.data.companyName,
          사업자등록번호: response.data.companyRegistrationNumber,
          직인: response?.data?.seal ? response.data.seal : "직인 미등록",
        });
        setIsLoading(false);
        navigate("/home");
      } else {
        amplitude.track("로그인 실패", {
          입력이메일: companyId,
          입력비밀번호: companyPassword,
        });
        setIsLoading(false);
        alert("아이디 또는 비밀번호가 맞지 않습니다.\n다시 한번 확인해주세요.");
      }
    } catch (error) {
      setIsLoading(false);
      alert("통신이 원활하지 않습니다.\n다시 한번 시도해주세요.");
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="FlexContent_Login">
        <div className="AllContent">
          <div className="Login_Head_Copy">로그인</div>
          <input
            className="Login_Input_BG"
            placeholder="아이디를 입력 해주세요."
            value={companyId}
            style={{ marginBottom: windowWidth > 767 ? "12px" : "2px" }}
            onChange={(e) => setCompanyId(e.target.value)}
            required
          />
          <input
            className="Login_Input_BG"
            placeholder="비밀번호를 입력 해주세요."
            value={companyPassword}
            onChange={(e) => setCompanyPassword(e.target.value)}
            required
            type="password"
          />
          <div className="Login_Button_One_CT">
            <button className="LoginButton_Copy_CT" onClick={handleLoginComplete}>
              <div className="LoginButton_One_BG">
                <div className="LoginButton_Copy">로그인</div>
              </div>
            </button>
          </div>
          <button className="Login_SigninButton" onClick={onClickSignin}>
            <div className="Login_SigninButton_CT">
              <div className="Login_Signin_Copy">기업회원이 아니신가요?</div>
              <div className="Login_SigninButton_Copy">기업회원 가입</div>
              <Icon name={"ChevronRightBlue"} size={32} />
            </div>
          </button>

          {/* <WidgetNavigation previousButtonLabel={"이전"} nextButtonLabel={"다음"} />
          <WidgetDescription
            titleText={"대상자로 부터 전달 받을 서류를 선택해주세요."}
            bodyText={
              "대상자에게 필수로 받아야 하는 서류로, 필요하신 서류를 선택하시면\n대상자에게 해당 서류 전달에 대한 안내 문자 메시지가 전송됩니다."
            }
            entireStepNumber={4}
            currentStepNumber={1}
          /> */}
        </div>
        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default WB11Login;
