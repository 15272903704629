import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import NavigationPanel from "../Components/NavigationPanel";

const WB51ConsentDocumentSub3 = observer(
  ({ setDocument1, setDocument3, setDocument4, selectedDocuments, setSelectedDocuments, setIsLoading, setSendDocumentArray }) => {
    const navigate = useNavigate();
    const onClickBack = () => {
      amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
      setSelectedDocuments([]);
      setDocument3(false);
      setDocument1(true);
    };
    const allDocumentsHaveJoinKit = (documents) => {
      return documents.every((document) => document.hasOwnProperty("JoinKit"));
    };
    const discernJoinKit = allDocumentsHaveJoinKit(selectedDocuments);
    const userData = LoadComapanyData("userData");

    const keysToKeep = ["Name", "DocumentName", "TelNumber"];
    const stockholderNamesString = Array.from(new Set(selectedDocuments.map((employee) => employee.Name))).join(", ");
    const filteredDocuments = selectedDocuments.reduce((acc, document) => {
      if (document.hasOwnProperty("MemberData")) {
        document.MemberData.forEach((member) => {
          const filteredMember = {};
          keysToKeep.forEach((key) => {
            if (member.hasOwnProperty(key)) {
              filteredMember[key] = member[key];
            } else if (key === "DocumentName") {
              filteredMember[key] = document[key];
            }
          });
          acc.push(filteredMember);
        });
      } else {
        const filteredDocument = {};
        keysToKeep.forEach((key) => {
          if (document.hasOwnProperty(key)) {
            filteredDocument[key] = document[key];
          }
        });
        acc.push(filteredDocument);
      }
      return acc;
    }, []);

    const onClickNext = async () => {
      setIsLoading(true);
      try {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/contrack";
        const response = await axios.post(endPoint, {
          email: userData.companyEmail,
          data: ConvertKeysToLowercase(filteredDocuments),
          ...(discernJoinKit ? { joinKit: true } : {}),
        });

        if (response.data.message === "문서 전달에 성공했습니다.") {
          setSendDocumentArray(filteredDocuments);
          amplitude.track("동의 받기3 - 동의 문서 발송 완료");
          setIsLoading(false);
          setDocument3(false);
          amplitude.track("동의 받기4 - 동의 문서 발송 완료 스크린 진입");
          setDocument4(true);
        } else {
          amplitude.track("동의 받기3 - 동의 문서 발송 실패");
          setIsLoading(false);
          alert("동의 문서 발송을 실패했습니다. 다시 시도해주세요.");
          navigate("/consentDocument");
        }
      } catch (error) {
        amplitude.track("동의 받기3 - 동의 문서 발송 통신 실패");
        setIsLoading(false);
        console.log(error);
        alert("동의 문서 발송 통신을 실패했습니다. 다시 시도해주세요.");
        navigate("/consentDocument");
      }
    };

    return (
      <>
        <div className="AllContent_Default_V2">
          <div className="Leave_LeftArea_CT">
            <div className="Leave_Main_Copy">{stockholderNamesString}님 에게는 이렇게 메시지가 전달돼요.</div>
            <div className="Leave_InputField_CT">
              <div className="Leave_Messege_Label">
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">문자메시지</div>
                </div>
              </div>
            </div>
            <div className="Leave_Messege_BG">
              <div className="Leave_Messege_Copy">
                안녕하세요, {userData.companyName} 입니다.
                <br />
                당사에서 귀하의 동의 및 서명이 필요한 문서를 발급하였습니다.
                <br />
                써티드 앱에서 문서를 확인하시고 동의 및 서명을 진행해주세요.
                <br />
                서명하신 문서는 써티드 앱에서 확인이 가능합니다.
                <br />
                감사합니다.
                <br />
                <br />
                {userData.companyName} 드림.
                <br />
                <br />
                -다운로드 링크-
                <br />
                https://certifie.onelink.me/2vIN/landing01
              </div>
            </div>
          </div>
          <div className="Leave_Vertical_Divider" />
          <div className="Leave_RightArea_CT">
            <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"발송"} onClickNext={onClickNext} />
            <div className="Leave_PageCount_Copy">2/3</div>
          </div>
        </div>
      </>
    );
  }
);

export default WB51ConsentDocumentSub3;
