const RequestResend = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        fill={color || "#AAAAAA"}
        d="M800-720H160v480h360v80H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v280h-80v-280Zm-640 0v480-200 3-283Zm320 200 320-200v80L501-453q-10 6-21 6t-21-6L160-640v-80l320 200Zm287 360H640q-17 0-28.5-11.5T600-200q0-17 11.5-28.5T640-240h127l-36-36q-12-12-11.5-28t12.5-28q12-11 28-11.5t28 11.5l104 104q12 12 12 28t-12 28L788-68q-11 11-27.5 11.5T732-68q-11-11-11-28t11-28l35-36Z"
      />
    </svg>
  );
};

export default RequestResend;
