import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import Icon from "../Assets/Icons/Icon";

const WB71ResignerSub1 = observer(({ setResigner1, setResigner2, setResigner3 }) => {
  const onClickNext = () => {
    amplitude.track("퇴사 키트 발급2 - 안내 페이지2 진입");
    setResigner1(false);
    setResigner2(true);
  };

  const onClickStart = () => {
    amplitude.track("퇴사 키트 발급3 - 퇴사자 이름/전화번호 등록 페이지 진입");
    setResigner1(false);
    setResigner3(true);
  };
  return (
    <>
      <div className="Leave_Guide_Copy">
        퇴사자에게 챙겨 줘야 하는 서류는 대체 어디까지 일까요?
        <br />
        일일히 알아보고, 열심히 작성해서 전달한 서류,
        <br />
        퇴사자가 재발급을 원하면 다시 해야 한다구요?
      </div>
      <div className="Leave_Image_CT">
        <img src={require("../Assets/Images/SearchFile.png")} alt="SearchFile" width="33%" height="auto" />
      </div>
      <div className="Leave_TextButton_CT">
        <button className="Leave_TextButton_Copy" type="button" onClick={onClickStart}>
          바로 시작
        </button>
        <div className="Leave_IconTextButton_CT">
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickNext}>
            다음
          </button>
          <Icon name={"ChevronRightBlue"} size={"32"} />
        </div>
      </div>
    </>
  );
});

export default WB71ResignerSub1;
