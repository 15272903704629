import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const DailyContractInfoModal = ({ setDailyContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">일용직 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setDailyContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            일용직 근로계약의 경우 비정규 근로계약에 해당하나, 일일 단위로 근로계약을 체결하는 것이 연속하여{" "}
            <span style={{ fontWeight: 600 }}>2년</span>을 초과할 경우 기간의 정함이 없는 근로계약으로 전환될 수 있습니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            또한 일일 단위로 근로하는 근로계약이 연속하여 1주 단위를 초과할 경우 해당 기간 근로자가{" "}
            <span style={{ fontWeight: 600 }}>주 15시간 이상 </span>
            근로 제공 시 주휴일 및 주휴수당이 적용될 수 있음을 유의해주세요.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 과태료 500만원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyContractInfoModal;
