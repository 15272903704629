import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React from "react";
import Icon from "../../Assets/Icons/Icon";
import BasicText from "./BasicText";

const BasicButton = ({ property, label, onClickButton, buttonHeight, buttonWidth, icon, marginRight, marginLeft, marginTop, marginBottom }) => {
  return (
    <button
      type="button"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width:
          buttonWidth === "1"
            ? "592px"
            : buttonWidth === "1/2"
            ? "290px"
            : buttonWidth === "1/3"
            ? "189px"
            : buttonWidth === "1/4"
            ? "139px"
            : buttonWidth === "96px"
            ? "96px"
            : buttonWidth === "72px"
            ? "72px"
            : buttonWidth === "64px"
            ? "64px"
            : "592px",
        minWidth:
          buttonWidth === "1"
            ? "592px"
            : buttonWidth === "1/2"
            ? "290px"
            : buttonWidth === "1/3"
            ? "189px"
            : buttonWidth === "1/4"
            ? "139px"
            : buttonWidth === "96px"
            ? "96px"
            : buttonWidth === "72px"
            ? "72px"
            : buttonWidth === "64px"
            ? "64px"
            : "592px",
        maxWidth:
          buttonWidth === "1"
            ? "592px"
            : buttonWidth === "1/2"
            ? "290px"
            : buttonWidth === "1/3"
            ? "189px"
            : buttonWidth === "1/4"
            ? "139px"
            : buttonWidth === "96px"
            ? "96px"
            : buttonWidth === "72px"
            ? "72px"
            : buttonWidth === "64px"
            ? "64px"
            : "592px",
        height:
          buttonHeight === "extraSmall"
            ? "32px"
            : buttonHeight === "small"
            ? "36px"
            : buttonHeight === "medium"
            ? "48px"
            : buttonHeight === "large"
            ? "64px"
            : "64px",
        borderStyle:
          property === "solid" || property === "CTA" || property === "disabled" || property === "destroy"
            ? "none"
            : property === "blackLine" || property === "blueLine"
            ? "solid"
            : "none",
        borderWidth: property === "blackLine" || property === "blueLine" ? "1px" : "none",
        borderColor: property === "blackLine" ? "#AAAAAA" : property === "blueLine" ? "#94BAFD" : "none",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "0px",
        marginLeft: marginLeft ? marginLeft : "0px",
        marginRight: marginRight ? marginRight : "0px",
        marginTop: marginTop ? marginTop : "0px",
        marginBottom: marginBottom ? marginBottom : "0px",
        boxSizing: "border-box",
        backgroundColor:
          property === "blackLine" || property === "blueLine"
            ? "#FFFFFF"
            : property === "solid"
            ? "#E7EFFF"
            : property === "CTA"
            ? "#3769fc"
            : property === "disabled"
            ? "#E3E3E3"
            : property === "destroy"
            ? "#FFE9E9"
            : "#3769fc",
      }}
      onClick={onClickButton}
    >
      {icon && (
        <Icon
          name={icon}
          size={20}
          marginRight={"4px"}
          color={
            property === "blackLine"
              ? "#464646"
              : property === "blueLine" || property === "solid"
              ? "#3769FC"
              : property === "CTA"
              ? "#FFFFFF"
              : property === "disabled"
              ? "#AAAAAA"
              : property === "destroy"
              ? "#FF7373"
              : "#464646"
          }
        />
      )}
      <BasicText
        text={label}
        fontSize={
          buttonHeight === "extraSmall" || buttonHeight === "small"
            ? "14px"
            : buttonHeight === "medium"
            ? "16px"
            : buttonHeight === "large"
            ? "24px"
            : "24px"
        }
        fontWeight={"semiBold"}
        fontColor={
          property === "blackLine"
            ? "#464646"
            : property === "blueLine" || property === "solid"
            ? "#3769FC"
            : property === "CTA"
            ? "#FFFFFF"
            : property === "disabled"
            ? "#AAAAAA"
            : property === "destroy"
            ? "#FF7373"
            : "#464646"
        }
      />
    </button>
  );
};

export default BasicButton;
