import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const HomeButton = ({ label, onClick }) => {
  return (
    <>
      <button className="Home_LargeBar_CT" onClick={onClick}>
        <div className="Home_LargeBar_Text_CT">
          <div className="Home_LargeBar_Category_Copy">{label}</div>
          {label === "직원 등록" && (
            <div className="Home_LargeBar_Body_Copy">
              써티드에 처음 방문 하셨다면 <span style={{ color: "rgb(55, 105, 252)" }}>직원 등록</span>부터 시작해보세요.
            </div>
          )}
          {label === "문서 작성" && (
            <div className="Home_LargeBar_Body_Copy">
              써티드를 통해 <span style={{ color: "rgb(55, 105, 252)" }}>다양한 문서</span>를 작성해보세요.
            </div>
          )}
          {label === "동의 받기" && (
            <div className="Home_LargeBar_Body_Copy">
              작성한 문서를 직원에게 전달하여 <span style={{ color: "rgb(55, 105, 252)" }}>동의</span>까지!
            </div>
          )}
          {label === "입사 키트" && (
            <div className="Home_LargeBar_Body_Copy">
              새로 <span style={{ color: "rgb(55, 105, 252)" }}>입사한 직원</span>에게 어떤 서류를 발급해야할지 고민된다면?
            </div>
          )}
        </div>
        <Icon name={"ArrowForward200"} size={"48"} color={"#94bafd"} />
      </button>
      <div className="Home_LargeBar_Divider" />
    </>
  );
};

export default HomeButton;
