export const ExtractValuesToString = (dataArray, key) => {
  if (!dataArray || !Array.isArray(dataArray) || dataArray.length === 0) {
    return null;
  }

  const filteredValues = dataArray
    .filter((item) => item[key] && item[key].trim() !== "") // 해당 키가 존재하고 값이 있는 객체만 포함
    .map((item) => item[key]); // 키의 값을 추출

  if (filteredValues.length === 0) {
    return null;
  }

  return filteredValues.join(", "); // 쉼표로 구분된 문자열로 결합
};
