export const DocumentNameToKorean = (documentName) => {
  const documentNameMap = {
    OfferLetter: "입사확약서",
    ConfidentialityAgreement: "보안유지서약서",
    SalaryContract: "연봉계약서",
    PrivacyConsent: "개인정보 제공 및 수집 이용 동의서",
    FulltimeContract: "정규직 근로계약서",
    TemporaryContract: "계약직(기간제) 근로계약서",
    ParttimeContract: "단시간(아르바이트) 근로계약서",
    DailyContract: "일용직 근로계약서",
    JuvenileContract: "청소년(연소자) 근로계약서",
    ParentalConsentForm: "친권자(후견인) 동의서",
    StockOptionContract: "주식매수선택권 부여계약서(스톡옵션 계약서)",
    StockholdersAgreement: "주주간계약서",
    PartnershipAgreement: "주주간계약서 (동업계약서)",
  };
  return documentNameMap[documentName] || "알 수 없는 문서"; // 매핑되지 않은 경우 기본값 반환
};

export const ConvertKeysToLowercase = (obj) => {
  const isObject = (value) => value && typeof value === "object" && !Array.isArray(value);
  const convertObjectKeys = (input) => {
    const newObj = {};
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = ConvertKeysToLowercase(input[key]);
      }
    }
    return newObj;
  };
  if (Array.isArray(obj)) {
    return obj.map((item) => ConvertKeysToLowercase(item));
  }
  if (isObject(obj)) {
    return convertObjectKeys(obj);
  }
  return obj;
};

export const ConvertArrayKeysToLowercase = (arr) => {
  const newArr = [];

  for (const obj of arr) {
    const newObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = obj[key];
      }
    }
    newArr.push(newObj);
  }
  return newArr;
};
