import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../Assets/Icons/Icon";

const DetailTitle = ({ title, notUseArrowBack, onClick }) => {
  const navigate = useNavigate();

  const MovePage = () => {
    navigate(-1);
  };

  return (
    <div className="ResignerDetail_Title_CT">
      <div className="DetailTitle_Labels_CT">
        {notUseArrowBack ? (
          <div className="DetailTitle_Label_CT">
            <div className="DetailTitle_Label_Underline">
              <div className="DetailTitle_Label_Copy_A">{title}</div>
            </div>
          </div>
        ) : (
          <div className="DetailTitle_Label_CT">
            <button onClick={onClick || MovePage} className="DetailTitle_Label_Underline">
              <div className="DetailTitle_Icon_CT">
                <Icon name={"ArrowBack"} />
              </div>
              <div className="DetailTitle_Label_Copy_A">{title}</div>
            </button>
          </div>
        )}
      </div>
      <div className="DetailTitle_Line" />
    </div>
  );
};

export default DetailTitle;
