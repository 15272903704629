import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";
import PrivacyConsentTable from "../ChartTable/PrivacyConsentTable";

const PrivacyConsent = observer(
  ({
    setPrivacyConsentInfo,
    inputCompanyName,
    setInputCompanyName,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Department.length > 0 &&
        selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate?.length === 8
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 기업/기관명을 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Department.length > 0) {
        errorMessage += errorMessage ? "\n- 입사 예정자 부서를 입력해주세요." : "- 입사 예정자 부서를 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.PrivacyConsent) {
            updatedData.Documents.PrivacyConsent = {};
          }
          updatedData.Documents.PrivacyConsent[nestedField] = value;
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateDepartmentData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Department");
      handleSelectedEmployeeDataChange("Department", value);
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Department, "Department");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);
        const selectedEmployeeDataCopy = { ...selectedEmployeeData };
        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.PrivacyConsent.Birthday = AddDotYYYYMMDD(selectedEmployeeDataCopy?.Documents?.PrivacyConsent?.Birthday);

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/pdf/privacy-consent";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            telNumber: selectedEmployeeDataCopy?.TelNumber,
            name: selectedEmployeeData?.Name,
            data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.PrivacyConsent),
            ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
          });
          if (response.data.message === "개인정보활용동의서 생성 완료") {
            handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
            setTimeout(async () => {
              setIsLoading(false);
              setPrivacyConsentInfo(false);
              alert("개인정보 제공 및 수집 이용 동의서 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setPrivacyConsentInfo(false);
            alert("개인정보 제공 및 수집 이용 동의서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("PrivacyConsent ERROR : ", error);
          setIsLoading(false);
          setPrivacyConsentInfo(false);
          alert("개인정보 제공 및 수집 이용 동의서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">개인정보 제공 및 수집 이용 동의서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setPrivacyConsentInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <div className="ContractInput_Body_Copy">
                  {inputCompanyName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{inputCompanyName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  (이하”회사”) (은)는 「개인정보보호법」에 따라 근로자의 개인정보 수집 · 이용에 대한 동의를 받고 있습니다. 근로자는 아래 ‘가’, ‘나’,
                  ‘다’, ‘라’ 항목의 동의를 거부할 수 있으며 동의하지 않는 경우 동 정보를 기초로 한 회사의 인사제도 운영에 있어 불이익을 받을 수 있음을
                  알려드립니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  가. 개인정보
                </div>
                <PrivacyConsentTable type={"personalInfo"} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "4px",
                  }}
                >
                  <div className="ContractInput_Label_Copy">개인정보의 수집 · 이용에 (□ 동의함 □ 동의하지 않음)</div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "16px" }}>
                  나. 고유식별정보
                </div>
                <PrivacyConsentTable type={"uniqueID"} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "4px",
                  }}
                >
                  <div className="ContractInput_Label_Copy">고유식별정보의 수집 · 이용에 (□ 동의함 □ 동의하지 않음)</div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  다. 민감정보
                </div>
                <PrivacyConsentTable type={"sensitiveInfo"} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "4px",
                  }}
                >
                  <div className="ContractInput_Label_Copy">민감정보의 수집 · 이용에 (□ 동의함 □ 동의하지 않음)</div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  라. 개인정보의 제3자 제공
                </div>
                <PrivacyConsentTable type={"thirdParty"} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "4px",
                  }}
                >
                  <div className="ContractInput_Label_Copy">개인정보의 제3자 제공 및 위탁에 대해 (□ 동의함 □ 동의하지 않음)</div>
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate &&
                    selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.PrivacyConsent.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "24px" }}>
                  부서 : {selectedEmployeeData?.Department || ""}
                </div>
                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    성명 : {selectedEmployeeData?.Name || ""}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="기업/기관명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.PrivacyConsent?.Birthday &&
                          selectedEmployeeData?.Documents?.PrivacyConsent?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.PrivacyConsent?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 부서</div>
                  <div
                    className={
                      (selectedEmployeeData?.Department && selectedEmployeeData?.Department?.length > 0) ||
                      (selectedEmployeeData?.Documents?.PrivacyConsent?.Department &&
                        selectedEmployeeData?.Documents?.PrivacyConsent?.Department?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.PrivacyConsent?.Department || selectedEmployeeData?.Department || ""}
                  onChange={(e) => onChangeStateDepartmentData(e.target.value)}
                  placeholder="부서를 입력해주세요."
                  name="NewEmployeeDepartment"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate &&
                      selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.PrivacyConsent?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput} style={{ height: "48px" }}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PrivacyConsent;
