import React from "react";

const RefundPolicy = () => {
  return (
    <div>
      <div className="Payment_Terms_Label_Copy">제10조 (청약 철회 및 환불 등)</div>
      <div className="Payment_Terms_Body_Copy">
        1. 회원은 결제 후 유료서비스를 사용하지 않은 경우, 결제로부터 7일 이내 (이하 ‘환불가능기간’)에 회사에 결제 취소(청약 철회)를 요청할 수
        있습니다. 환불가능기간이 도과한 이후 청약의 철회 및 환불 처리는 불가합니다.
      </div>
      <div className="Payment_Terms_Body_Copy">
        2. 회원이 환불가능기간 (결제일로부터 7일 이내)이 경과한 후 청약 철회 및 환불을 신청하면 이용요금의 30%를 위약금으로 공제한 후 환불합니다. 단,
        위약금 공제 이후 환불할 금액이 1만원 미만인 경우에는 환불하지 아니합니다.
      </div>
      <div className="Payment_Terms_Body_Copy">
        3. 청약의 철회는 회원이 서비스 내에서 할 수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 회원의 의사표시를
        수령한 후 지체 없이 이러한 사실을 회원에게 회신합니다.
      </div>
      <div className="Payment_Terms_Body_Copy">
        4. 회사는 회원이 청약 철회 신청을 확인 후 환불 금액이 있을 경우, 원칙적으로 회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별
        사업자에게 대금의 청구 정지 내지 취소를 요청하고, 회원이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 회원에게
        전자메일, 서비스 홈페이지로 공지한 경우 및 아래의 각 호의 경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.
      </div>
      <div className="Payment_Terms_Body_Copy" style={{ marginLeft: "12px" }}>
        (1) 신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내
        <br />
        (2) 결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난
        환불의 경우
        <br />
        (3) 회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시 제공하지 않는 경우(현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나,
        타인 명의의 계좌를 제공하는 경우 등)
        <br />
        (4) 해당 회원의 명시적 의사표시가 있는 경우
      </div>
      <div className="Payment_Terms_Body_Copy">
        5. 유료서비스를 이용 중인 회원이 탈퇴하는 경우 유료서비스는 즉시 해지되며 환불은 불가능합니다. 이 경우 회원의 정보와 서비스이용내용은 관련
        법령이 정하는 경우를 제외하고는 약관 및 이 약관에 따라 처리됩니다.
      </div>
    </div>
  );
};

export default RefundPolicy;
