import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import WB61NewEmployeeSub1 from "../SubPages/WB61NewEmployeeSub1";
import WB61NewEmployeeSub2 from "../SubPages/WB61NewEmployeeSub2";
import WB61NewEmployeeSub3 from "../SubPages/WB61NewEmployeeSub3";
import WB61NewEmployeeSub4 from "../SubPages/WB61NewEmployeeSub4";
import WB61NewEmployeeSub5 from "../SubPages/WB61NewEmployeeSub5";
import WB61NewEmployeeSub6 from "../SubPages/WB61NewEmployeeSub6";

const WB61NewEmployee = observer(() => {
  const [newEmployee1, setNewEmployee1] = useState(true);
  const [newEmployee2, setNewEmployee2] = useState(false);
  const [newEmployee3, setNewEmployee3] = useState(false);
  const [newEmployee4, setNewEmployee4] = useState(false);
  const [newEmployee5, setNewEmployee5] = useState(false);
  const [newEmployee6, setNewEmployee6] = useState(false);

  const [resistEmployees, setResistEmployees] = useState([{ Name: "", TelNumber: "", NameDone: false, TelNumberDone: false, ID: uuidv4() }]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);

  const [inputCompanyName, setInputCompanyName] = useState("");
  const [companyRepresentName, setCompanyRepresentName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyTelNumber, setCompanyTelNumber] = useState("");
  const [sealImageSrc, setSealImageSrc] = useState(null);
  const [sealImageSrcDone, setSealImageSrcDone] = useState(null);

  const userData = LoadComapanyData("userData");

  const [offerLetter, setOfferLetter] = useState(false);
  const [offerLetterInfo, setOfferLetterInfo] = useState(false);

  const [confidentialityAgreement, setConfidentialityAgreement] = useState(false);
  const [confidentialityAgreementInfo, setConfidentialityAgreementInfo] = useState(false);

  const [fulltimeContract, setFulltimeContract] = useState(false);
  const [fulltimeContractInfo, setFulltimeContractInfo] = useState(false);

  const [temporaryContract, setTemporaryContract] = useState(false);
  const [temporaryContractInfo, setTemporaryContractInfo] = useState(false);

  const [parttimeContract, setParttimeContract] = useState(false);
  const [parttimeContractInfo, setParttimeContractInfo] = useState(false);

  const [dailyContract, setDailyContract] = useState(false);
  const [dailyContractInfo, setDailyContractInfo] = useState(false);

  const [juvenileContract, setJuvenileContract] = useState(false);
  const [juvenileContractInfo, setJuvenileContractInfo] = useState(false);

  const [parentalAuthorityConsentInfo, setParentalAuthorityConsentInfo] = useState(false);

  const [annualSalaryContract, setAnnualSalaryContract] = useState(false);
  const [annualSalaryContractInfo, setAnnualSalaryContractInfo] = useState(false);

  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [privacyConsentInfo, setPrivacyConsentInfo] = useState(false);

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C" style={{ alignItems: "center", marginTop: "48px" }}>
        {/* <div className="Wait_Notification_Copy">준비중 입니다.</div> */}

        {newEmployee1 ? (
          <WB61NewEmployeeSub1 setNewEmployee1={setNewEmployee1} setNewEmployee2={setNewEmployee2} setNewEmployee3={setNewEmployee3} />
        ) : null}
        {newEmployee2 ? <WB61NewEmployeeSub2 setNewEmployee2={setNewEmployee2} setNewEmployee3={setNewEmployee3} /> : null}
        {newEmployee3 ? (
          <WB61NewEmployeeSub3
            setNewEmployee2={setNewEmployee2}
            setNewEmployee3={setNewEmployee3}
            setNewEmployee4={setNewEmployee4}
            setNewEmployee5={setNewEmployee5}
            resistEmployees={resistEmployees}
            setResistEmployees={setResistEmployees}
            seal={userData.seal}
          />
        ) : null}
        {newEmployee4 ? (
          <WB61NewEmployeeSub4
            setNewEmployee3={setNewEmployee3}
            setNewEmployee4={setNewEmployee4}
            setNewEmployee5={setNewEmployee5}
            seal={userData.seal}
            sealImageSrc={sealImageSrc}
            setSealImageSrc={setSealImageSrc}
            sealImageSrcDone={sealImageSrcDone}
            setSealImageSrcDone={setSealImageSrcDone}
          />
        ) : null}
        {newEmployee5 ? (
          <WB61NewEmployeeSub5
            setNewEmployee3={setNewEmployee3}
            setNewEmployee4={setNewEmployee4}
            setNewEmployee5={setNewEmployee5}
            setNewEmployee6={setNewEmployee6}
            seal={userData.seal}
            offerLetter={offerLetter}
            setOfferLetter={setOfferLetter}
            offerLetterInfo={offerLetterInfo}
            setOfferLetterInfo={setOfferLetterInfo}
            confidentialityAgreement={confidentialityAgreement}
            setConfidentialityAgreement={setConfidentialityAgreement}
            confidentialityAgreementInfo={confidentialityAgreementInfo}
            setConfidentialityAgreementInfo={setConfidentialityAgreementInfo}
            fulltimeContract={fulltimeContract}
            setFulltimeContract={setFulltimeContract}
            fulltimeContractInfo={fulltimeContractInfo}
            setFulltimeContractInfo={setFulltimeContractInfo}
            temporaryContract={temporaryContract}
            setTemporaryContract={setTemporaryContract}
            temporaryContractInfo={temporaryContractInfo}
            setTemporaryContractInfo={setTemporaryContractInfo}
            parttimeContract={parttimeContract}
            setParttimeContract={setParttimeContract}
            parttimeContractInfo={parttimeContractInfo}
            setParttimeContractInfo={setParttimeContractInfo}
            dailyContract={dailyContract}
            setDailyContract={setDailyContract}
            dailyContractInfo={dailyContractInfo}
            setDailyContractInfo={setDailyContractInfo}
            juvenileContract={juvenileContract}
            setJuvenileContract={setJuvenileContract}
            juvenileContractInfo={juvenileContractInfo}
            setJuvenileContractInfo={setJuvenileContractInfo}
            annualSalaryContract={annualSalaryContract}
            setAnnualSalaryContract={setAnnualSalaryContract}
            annualSalaryContractInfo={annualSalaryContractInfo}
            setAnnualSalaryContractInfo={setAnnualSalaryContractInfo}
            privacyConsent={privacyConsent}
            setPrivacyConsent={setPrivacyConsent}
            privacyConsentInfo={privacyConsentInfo}
            setPrivacyConsentInfo={setPrivacyConsentInfo}
            parentalAuthorityConsentInfo={parentalAuthorityConsentInfo}
            setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo}
          />
        ) : null}
        {newEmployee6 ? (
          <WB61NewEmployeeSub6
            setNewEmployee5={setNewEmployee5}
            setNewEmployee6={setNewEmployee6}
            offerLetter={offerLetter}
            offerLetterInfo={offerLetterInfo}
            setOfferLetterInfo={setOfferLetterInfo}
            confidentialityAgreement={confidentialityAgreement}
            confidentialityAgreementInfo={confidentialityAgreementInfo}
            setConfidentialityAgreementInfo={setConfidentialityAgreementInfo}
            fulltimeContract={fulltimeContract}
            fulltimeContractInfo={fulltimeContractInfo}
            setFulltimeContractInfo={setFulltimeContractInfo}
            temporaryContract={temporaryContract}
            temporaryContractInfo={temporaryContractInfo}
            setTemporaryContractInfo={setTemporaryContractInfo}
            parttimeContract={parttimeContract}
            parttimeContractInfo={parttimeContractInfo}
            setParttimeContractInfo={setParttimeContractInfo}
            dailyContract={dailyContract}
            dailyContractInfo={dailyContractInfo}
            setDailyContractInfo={setDailyContractInfo}
            juvenileContract={juvenileContract}
            juvenileContractInfo={juvenileContractInfo}
            setJuvenileContractInfo={setJuvenileContractInfo}
            annualSalaryContract={annualSalaryContract}
            annualSalaryContractInfo={annualSalaryContractInfo}
            setAnnualSalaryContractInfo={setAnnualSalaryContractInfo}
            privacyConsent={privacyConsent}
            privacyConsentInfo={privacyConsentInfo}
            setPrivacyConsentInfo={setPrivacyConsentInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            parentalAuthorityConsentInfo={parentalAuthorityConsentInfo}
            setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo}
            companyTelNumber={companyTelNumber}
            setCompanyTelNumber={setCompanyTelNumber}
            resistEmployees={resistEmployees}
            setResistEmployees={setResistEmployees}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
          />
        ) : null}
      </div>
    </div>
  );
});

export default WB61NewEmployee;
