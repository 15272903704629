import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { AddHyphenTelNumber, AddDotYYYYMMDD } from "../Utils/ConvertNumberFormat";

const ParentalAuthorityConsentTable = ({
  type,
  guardianName,
  guardianBirthday,
  guardianAddress,
  guardianTelNumber,
  guardianRelations,
  employeeName,
  employeeBirthday,
  employeeAddress,
  employeeTelNumber,
  inputCompanyName,
  companyAddress,
  companyRepresentName,
  companyTelNumber,
}) => {
  return (
    <>
      {type === "parent" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "64px" }} rowSpan={"5"}>
                친권자
                <br />
                (후견인)
              </td>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                성명
              </td>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white", textAlign: "left" }}>
                {guardianName || ""}
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                생년월일
              </td>
              <td className="ContractInput_Cell_Full_Body">{AddDotYYYYMMDD(guardianBirthday) || ""}</td>
            </tr>

            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                주소
              </td>
              <td className="ContractInput_Cell_Full_Body">{guardianAddress || ""}</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                연락처
              </td>
              <td className="ContractInput_Cell_Full_Body">{AddHyphenTelNumber(guardianTelNumber) || ""}</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                연소근로자와의 관계
              </td>
              <td className="ContractInput_Cell_Full_Body">{guardianRelations || ""}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {type === "teen" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "64px" }} rowSpan={"5"}>
                연소근로자
              </td>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                성명
              </td>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white", textAlign: "left" }}>
                {employeeName || ""}
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                생년월일
              </td>
              <td className="ContractInput_Cell_Full_Body">{AddDotYYYYMMDD(employeeBirthday) || ""}</td>
            </tr>

            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                주소
              </td>
              <td className="ContractInput_Cell_Full_Body">{employeeAddress || ""}</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                연락처
              </td>
              <td className="ContractInput_Cell_Full_Body">{AddHyphenTelNumber(employeeTelNumber) || ""}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {type === "company" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "64px" }} rowSpan={"5"}>
                사업장 개요
              </td>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                사업장명
              </td>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white", textAlign: "left" }}>
                {inputCompanyName || ""}
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                사업장 주소
              </td>
              <td className="ContractInput_Cell_Full_Body">{companyAddress || ""}</td>
            </tr>

            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                대표자
              </td>
              <td className="ContractInput_Cell_Full_Body">{companyRepresentName || ""}</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                사업장 전화번호
              </td>
              <td className="ContractInput_Cell_Full_Body">{companyTelNumber || ""}</td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default ParentalAuthorityConsentTable;
