import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { AddUnitYYYYMMDD } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";

const WB71ResignerSub7 = observer(
  ({ setResigner6, setResigner7, setResigner8, resignerData, workIncomeSrc, salarySrc, severancePaySrc, sealImageSrc, loseDate, seal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const userData = LoadComapanyData("userData");
    const onClickBack = () => {
      amplitude.track("퇴사 키트 발급6 - 퇴사자 서류 등록 페이지 진입");
      setResigner7(false);
      setResigner6(true);
    };

    const workPeriod = `${AddUnitYYYYMMDD(resignerData.AcqDate)} ~ ${AddUnitYYYYMMDD(loseDate)}`;

    const handleSigninComplete = async (e) => {
      setIsLoading(true);
      e.preventDefault();

      const data = {
        name: resignerData?.Name,
        birthday: resignerData?.Birthday,
        telNumber: resignerData?.TelNumber,
        companyEmail: userData?.companyEmail,
        companyName: userData?.companyName,
        companyRegistrationNumber: userData?.companyRegistrationNumber,
        acqDate: resignerData.AcqDate,
        loseDate: loseDate,
        periodOfEmployment: workPeriod,
        position: resignerData?.Position,
        department: resignerData?.Department,
        jobPosition: resignerData?.JobPosition,
        job: [
          ...(resignerData?.Job[0] ? [{ id: resignerData?.Job[0]?.ID, job: resignerData?.Job[0]?.Job }] : []),
          ...(resignerData?.Job[1] ? [{ id: resignerData?.Job[1]?.ID, job: resignerData?.Job[1]?.Job }] : []),
          ...(resignerData?.Job[2] ? [{ id: resignerData?.Job[2]?.ID, job: resignerData?.Job[2]?.Job }] : []),
          ...(resignerData?.Job[3] ? [{ id: resignerData?.Job[3]?.ID, job: resignerData?.Job[3]?.Job }] : []),
          ...(resignerData?.Job[4] ? [{ id: resignerData?.Job[4]?.ID, job: resignerData?.Job[4]?.Job }] : []),
        ],
      };

      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("earnedIncomeWithholdingTaxReceipt", workIncomeSrc);
      formData.append("payStub", salarySrc);
      formData.append("severancePaySettlementDetails", severancePaySrc);
      formData.append("email", userData?.companyEmail);
      formData.append("name", resignerData?.Name);
      formData.append("telNumber", resignerData?.TelNumber);
      if (seal && !sealImageSrc) {
        formData.append("seal", seal);
      } else if (!seal && sealImageSrc) {
        formData.append("seal", sealImageSrc);
      }
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      try {
        const resistEndPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/resigner";
        const response = await axios.post(resistEndPoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [
            function () {
              return formData;
            },
          ],
        });
        if (response.data.status === "퇴사자 정보 저장 완료, SMS 전달 완료.") {
          const userData = JSON.parse(localStorage.getItem("userData")) || {};
          const newSealValue = response.data.seal;
          userData.seal = newSealValue;
          localStorage.setItem("userData", JSON.stringify(userData));

          setIsLoading(false);
          amplitude.track("퇴사 키트 발급8 - 퇴사 키트 발급 완료 페이지 진입");
          setResigner7(false);
          setResigner8(true);
        } else {
          setIsLoading(false);
          alert("메시지 전송을 실패했습니다. 다시 시도해주세요.");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error during resigner submission:", error);
        alert("퇴사자 처리를 실패했습니다. 한번 더 시도해주세요.");
      }
    };
    return (
      <>
        {isLoading ? <Loading /> : null}

        <form className="AllContent_Default_V2" onSubmit={handleSigninComplete} encType="multipart/form-data" style={{ marginBottom: "192px" }}>
          <div className="Leave_LeftArea_CT">
            <div className="Leave_Main_Copy">{resignerData.Name}님 에게는 이렇게 메시지가 전달돼요.</div>
            <div className="Leave_InputField_CT">
              <div className="Leave_Messege_Label">
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">문자메시지</div>
                </div>
              </div>
            </div>
            <div className="Leave_Messege_BG">
              <div className="Leave_Messege_Copy">
                안녕하세요 {resignerData.Name} 님, {userData.companyName} 입니다.
                <br />
                당사를 떠나시게 되어 아쉽지만, 항상 좋은 추억으로 남기를 기원합니다.
                <br />
                퇴사 시 수령할 서류들이 모두 발급되어 앱 내 다운로드 링크를 통해 확인하실 수 있습니다.
                <br />
                퇴사 이후에도 {resignerData.Name} 님의 행복과 성공을 응원하겠습니다.
                <br />
                감사합니다.
                <br />
                <br />
                {userData.companyName} 드림.
                <br />
                <br />
                -발급 완료 서류 목록-
                <br />
                1.근로소득원천징수영수증
                <br />
                2.급여명세서
                <br />
                3.퇴직금 정산내역
                <br />
                4.경력증명서
                <br />
                <br />
                -다운로드 링크-
                <br />
                https://certifie.onelink.me/2vIN/landing01
              </div>
            </div>
          </div>
          <div className="Leave_Vertical_Divider" />
          <div className="Leave_RightArea_CT">
            <div className="Leave_Navigation_CT">
              <div className="Leave_IconTextButton_CT">
                <Icon name={"ArrowBack"} size={24} color={"#3769FC"} />
                <button className="Leave_TextButton_Copy" type="button" onClick={onClickBack}>
                  이전
                </button>
              </div>
              <div className="Leave_IconTextButton_CT">
                <button className="Leave_TextButton_Copy" type="submit">
                  완료
                </button>
                <Icon name={"ArrowForward"} size={24} color={"#3769FC"} />
              </div>
            </div>
            <div className="Leave_PageCount_Copy">4/4</div>
          </div>
        </form>
      </>
    );
  }
);

export default WB71ResignerSub7;
