import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { AddHyphenTelNumber, AddDotYYYYMMDD } from "../Utils/ConvertNumberFormat";

const ContractDataTable = ({
  inputCompanyName,
  companyRepresentName,
  companyAddress,
  employeeName,
  employeeBirthday,
  employeeAddress,
  employeeTelNumber,
}) => {
  return (
    <table
      className="ContractInput_Chart_BG"
      style={{
        borderSpacing: "0px",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td className="ContractInput_Cell_Label" rowSpan="2">
            기업/ <br />
            기관
          </td>
          <td className="ContractInput_Cell_Label_3">상 호 명</td>
          <td className="ContractInput_Cell_Half_Body">{inputCompanyName}</td>
          <td className="ContractInput_Cell_Label">대표자명</td>
          <td className="ContractInput_Cell_Half_Body">{companyRepresentName}</td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label_2">주 소</td>
          <td className="ContractInput_Cell_Full_Body" colSpan="3">
            {companyAddress}
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label" rowSpan="6">
            입사
            <br />
            예정자
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label_2">성 명</td>
          <td className="ContractInput_Cell_Half_Body">{employeeName}</td>
          <td className="ContractInput_Cell_Label">생년월일</td>
          <td className="ContractInput_Cell_Half_Body">{AddDotYYYYMMDD(employeeBirthday)}</td>
        </tr>

        <tr>
          <td className="ContractInput_Cell_Label_2">주 소</td>
          <td className="ContractInput_Cell_Full_Body" colSpan="3">
            {employeeAddress}
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label">전화번호</td>
          <td className="ContractInput_Cell_Full_Body" colSpan="3">
            {AddHyphenTelNumber(employeeTelNumber)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ContractDataTable;
