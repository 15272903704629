import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import WB71ResignerSub1 from "../SubPages/WB71ResignerSub1";
import WB71ResignerSub2 from "../SubPages/WB71ResignerSub2";
import WB71ResignerSub3 from "../SubPages/WB71ResignerSub3";
import WB71ResignerSub4 from "../SubPages/WB71ResignerSub4";
import WB71ResignerSub5 from "../SubPages/WB71ResignerSub5";
import WB71ResignerSub6 from "../SubPages/WB71ResignerSub6";
import WB71ResignerSub7 from "../SubPages/WB71ResignerSub7";
import WB71ResignerSub8 from "../SubPages/WB71ResignerSub8";
import TabBar from "../Components/TabBar";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WB71Resigner = observer(() => {
  const [resigner1, setResigner1] = useState(true);
  const [resigner2, setResigner2] = useState(false);
  const [resigner3, setResigner3] = useState(false);
  const [resigner4, setResigner4] = useState(false);
  const [resigner5, setResigner5] = useState(false);
  const [resigner6, setResigner6] = useState(false);
  const [resigner7, setResigner7] = useState(false);
  const [resigner8, setResigner8] = useState(false);

  const [resignerData, setResignerData] = useState();

  const userData = LoadComapanyData("userData");

  const [loseDate, setLoseDate] = useState("");

  const [workIncomeSrc, setWorkIncomeSrc] = useState("");
  const [salarySrc, setSalarySrc] = useState("");
  const [severancePaySrc, setSeverancePaySrc] = useState("");

  const [sealImageSrc, setSealImageSrc] = useState(null);
  const [sealImageSrcDone, setSealImageSrcDone] = useState(null);

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
        <TabBar
          tabLabel1={"resigner"}
          tabLink1={"/resigner"}
          tabLabel1Text={"등록"}
          tabLabel1AmplitudeText={"퇴사 키트 발급1 - 안내 페이지1 진입"}
          tabLabel2={"resignerList"}
          tabLink2={"/resignerList"}
          tabLabel2Text={"조회"}
          tabLabel2AmplitudeText={"퇴사 키트 조회 스크린 진입"}
        />
        {resigner1 ? <WB71ResignerSub1 setResigner1={setResigner1} setResigner2={setResigner2} setResigner3={setResigner3} /> : null}
        {resigner2 ? <WB71ResignerSub2 setResigner2={setResigner2} setResigner3={setResigner3} /> : null}
        {resigner3 ? <WB71ResignerSub3 setResigner3={setResigner3} setResigner4={setResigner4} setResignerData={setResignerData} /> : null}
        {resigner4 ? (
          <WB71ResignerSub4
            setResigner3={setResigner3}
            setResigner4={setResigner4}
            setResigner5={setResigner5}
            resignerData={resignerData}
            setResignerData={setResignerData}
          />
        ) : null}
        {resigner5 ? (
          <WB71ResignerSub5
            setResigner4={setResigner4}
            setResigner5={setResigner5}
            setResigner6={setResigner6}
            loseDate={loseDate}
            setLoseDate={setLoseDate}
          />
        ) : null}
        {resigner6 ? (
          <WB71ResignerSub6
            setResigner5={setResigner5}
            setResigner6={setResigner6}
            setResigner7={setResigner7}
            workIncomeSrc={workIncomeSrc}
            setWorkIncomeSrc={setWorkIncomeSrc}
            salarySrc={salarySrc}
            setSalarySrc={setSalarySrc}
            severancePaySrc={severancePaySrc}
            setSeverancePaySrc={setSeverancePaySrc}
            seal={userData.seal}
            sealImageSrc={sealImageSrc}
            setSealImageSrc={setSealImageSrc}
            sealImageSrcDone={sealImageSrcDone}
            setSealImageSrcDone={setSealImageSrcDone}
          />
        ) : null}
        {resigner7 ? (
          <WB71ResignerSub7
            setResigner6={setResigner1}
            setResigner7={setResigner7}
            setResigner8={setResigner8}
            resignerData={resignerData}
            workIncomeSrc={workIncomeSrc}
            salarySrc={salarySrc}
            severancePaySrc={severancePaySrc}
            sealImageSrc={sealImageSrc}
            seal={userData.seal}
            loseDate={loseDate}
          />
        ) : null}
        {resigner8 ? <WB71ResignerSub8 setResigner1={setResigner1} setResigner8={setResigner8} resignerData={resignerData} /> : null}
      </div>
    </div>
  );
});

export default WB71Resigner;
