import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DetailTitle from "../Components/DetailTitle";
import { observer } from "mobx-react";
import { DocumentNameToKorean } from "../Utils/ConvertStringFormat";
import DetailHead from "../Components/DetailHead";
import { ExtractValuesToString } from "../Utils/ExtractValuesToString";

const WB86ReferDocumentDetail = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const itemData = location.state.item;
  const fillterData = location.state.fillter;
  const routeData = location.state.route;
  const cacheBustingUrl = `${itemData.Url}?t=${new Date().getTime()}`;

  const onClickDetailTitle = (certificateData) => {
    if (routeData === "full") {
      navigate(`/referDocumentFullList`, {
        state: {
          item: certificateData,
        },
      });
    } else if (routeData === "unsent") {
      navigate(`/referDocumentUnsentList`, {
        state: {
          item: certificateData,
        },
      });
    } else if (routeData === "wait") {
      navigate(`/referDocumentWaitList`, {
        state: {
          item: certificateData,
        },
      });
    } else if (routeData === "agree") {
      navigate(`/referDocumentAgreeList`, {
        state: {
          item: certificateData,
        },
      });
    }
  };

  return (
    <div className="FlexContent_Other_V">
      <DetailTitle
        title={`${
          ExtractValuesToString(itemData.MemberData, "name") || ExtractValuesToString(itemData.MemberData, "Name") || itemData.Name
        }님의 ${DocumentNameToKorean(itemData.DocumentName)}`}
        onClick={() => onClickDetailTitle(fillterData)}
      />
      <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
        {itemData && (
          <>
            <DetailHead itemData={itemData} labelList={["이름", "생년월일", "전화번호", "동의 여부", "작성일"]} />
            <div className="Document_PDF_Contents_CT">
              <div className="PDFDetail_BG">
                <iframe src={cacheBustingUrl} title="This is a unique title" height="1240" style={{ width: "75vw" }}></iframe>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default WB86ReferDocumentDetail;
