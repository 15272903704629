import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import WB51ConsentDocumentSub1 from "../SubPages/WB51ConsentDocumentSub1";
import WB51ConsentDocumentSub2 from "../SubPages/WB51ConsentDocumentSub2";
import WB51ConsentDocumentSub3 from "../SubPages/WB51ConsentDocumentSub3";
import WB51ConsentDocumentSub4 from "../SubPages/WB51ConsentDocumentSub4";
import Loading from "../Components/Loading";

const WB51ConsentDocument = observer(() => {
  const [document1, setDocument1] = useState(true);
  const [document2, setDocument2] = useState(false);
  const [document3, setDocument3] = useState(false);
  const [document4, setDocument4] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sendDocumentArray, setSendDocumentArray] = useState([]);

  const userData = LoadComapanyData("userData");

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="FlexContent_Other_V">
        <div className="AllContent_Default_C" style={{ alignItems: "center", marginTop: "48px" }}>
          {document1 ? (
            <WB51ConsentDocumentSub1
              setDocument1={setDocument1}
              setDocument2={setDocument2}
              setDocument3={setDocument3}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              setIsLoading={setIsLoading}
              userData={userData}
            />
          ) : null}
          {document2 ? (
            <WB51ConsentDocumentSub2 setDocument1={setDocument1} setDocument2={setDocument2} setSelectedDocuments={setSelectedDocuments} />
          ) : null}
          {document3 ? (
            <WB51ConsentDocumentSub3
              setDocument1={setDocument1}
              setDocument3={setDocument3}
              setDocument4={setDocument4}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              setIsLoading={setIsLoading}
              setSendDocumentArray={setSendDocumentArray}
            />
          ) : null}
          {document4 ? (
            <WB51ConsentDocumentSub4
              setDocument1={setDocument1}
              setDocument4={setDocument4}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              sendDocumentArray={sendDocumentArray}
            />
          ) : null}
        </div>
      </div>
    </>
  );
});

export default WB51ConsentDocument;
