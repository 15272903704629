import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import { HandleSalaryDocumentData } from "../Utils/OnChangeDocumentData";

const SalaryCompositionTable = ({ selectedEmployeeData, documentName, setSelectedEmployeeData, setSelectedEmployees }) => {
  const extractNumbers = (str) => (str ? str.replace(/\D/g, "") : "0");

  const calculateSum = () => {
    const getValue = (field, index) =>
      parseInt(extractNumbers(selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[index]?.[field])) || 0;

    const num1 = getValue("SalaryCompositionTablePaymentAmount", 0);
    const num2 = getValue("SalaryCompositionTablePaymentAmount", 1);
    const num3 = getValue("SalaryCompositionTablePaymentAmount", 2);
    const num4 = getValue("SalaryCompositionTablePaymentAmount", 3);
    const num5 = getValue("SalaryCompositionTablePaymentAmount", 4);

    const sum = num1 + num2 + num3 + num4 + num5;
    return sum.toString();
  };

  return (
    <>
      <table
        className="Email_PDF_Chart_BG"
        style={{
          borderSpacing: "0px",
          borderCollapse: "collapse",
          marginBottom: "8px",

          marginTop: "4px",
        }}
      >
        <tbody>
          <tr>
            <td className="SalaryCompositionTable_Cell_Label">구분</td>
            <td className="SalaryCompositionTable_Cell_Label">월 지급액</td>
            <td className="SalaryCompositionTable_Cell_Label">지급기준</td>
            <td className="SalaryCompositionTable_Cell_Label">비고</td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Title_Required"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[0]?.SalaryCompositionTableTitle || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableTitle1",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="예시) 기본급"
                name="SalaryCompositionTableTitle1"
                required
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Amount_Required"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentAmount1",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="숫자만 입력"
                name="SalaryCompositionTablePaymentAmount1"
                required
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "171px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Standard_Required"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[0]?.SalaryCompositionTablePaymentStandard || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentStandard1",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="예시) 월 209시간 근무 기준"
                name="SalaryCompositionTablePaymentStandard1"
                required
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "64px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Note"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[0]?.SalaryCompositionTableNote || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableNote1",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableNote1"
              />
            </td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Title"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[1]?.SalaryCompositionTableTitle || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableTitle2",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableTitle2"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Amount"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentAmount2",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentAmount2"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "171px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Standard"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[1]?.SalaryCompositionTablePaymentStandard || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentStandard2",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentStandard2"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "64px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Note"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[1]?.SalaryCompositionTableNote || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableNote2",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableNote2"
              />
            </td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Title"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[2]?.SalaryCompositionTableTitle || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableTitle3",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableTitle3"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Amount"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentAmount3",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentAmount3"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "171px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Standard"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[2]?.SalaryCompositionTablePaymentStandard || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentStandard3",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentStandard3"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "64px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Note"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[2]?.SalaryCompositionTableNote || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableNote3",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableNote3"
              />
            </td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Title"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[3]?.SalaryCompositionTableTitle || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableTitle4",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableTitle4"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Amount"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentAmount4",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentAmount4"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "171px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Standard"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[3]?.SalaryCompositionTablePaymentStandard || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentStandard4",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentStandard4"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "64px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Note"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[3]?.SalaryCompositionTableNote || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableNote4",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableNote4"
              />
            </td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Title"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[4]?.SalaryCompositionTableTitle || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableTitle5",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableTitle5"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "72px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Amount"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentAmount5",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentAmount5"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "171px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Standard"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[4]?.SalaryCompositionTablePaymentStandard || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTablePaymentStandard5",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTablePaymentStandard5"
              />
            </td>
            <td className="SalaryCompositionTable_Cell" style={{ width: "64px" }}>
              <input
                className="SalaryCompositionTable_Input_BG_Note"
                value={selectedEmployeeData?.Documents?.[documentName]?.SalaryInformation[4]?.SalaryCompositionTableNote || ""}
                onChange={(e) =>
                  HandleSalaryDocumentData(
                    "Documents",
                    e.target.value,
                    "SalaryCompositionTableNote5",
                    documentName,
                    setSelectedEmployeeData,
                    setSelectedEmployees
                  )
                }
                placeholder="선택 입력"
                name="SalaryCompositionTableNote5"
              />
            </td>
          </tr>
          <tr>
            <td className="SalaryCompositionTable_Cell_Label" style={{ backgroundColor: "white", width: "85px" }}>
              월총액
            </td>
            <td
              className="SalaryCompositionTable_Cell_Label"
              style={{
                backgroundColor: "white",
                textAlign: "right",
                width: "72px",
              }}
            >
              {AddCommaAmountNumber(calculateSum())}원
            </td>
            <td className="SalaryCompositionTable_Cell_Label" colSpan={"2"} style={{ backgroundColor: "white", width: "249px" }}>
              공제 전 금액
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SalaryCompositionTable;
