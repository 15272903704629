import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import Loading from "../Components/Loading";
import Add from "../Assets/Icons/Add";
import Delete from "../Assets/Icons/Delete";
import NavigationPanel from "../Components/NavigationPanel";

const WB61NewEmployeeSub3 = observer(
  ({ setNewEmployee2, setNewEmployee3, setNewEmployee4, setNewEmployee5, resistEmployees, setResistEmployees, seal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState();
    const [companyEmail, setCompanyEmail] = useState();
    const validateEmployee = (employee) => {
      const numberPattern = /^\d+$/;
      return {
        ...employee,
        NameDone: employee.Name.length > 0,
        TelNumberDone: employee.TelNumber.length >= 10 && employee.TelNumber.length <= 11 && numberPattern.test(employee.TelNumber),
      };
    };

    const removeEmployee = (index) => {
      setResistEmployees((prevEmployees) => prevEmployees.filter((_, i) => i !== index));
    };

    const onClickBack = () => {
      amplitude.track("입사 키트 발급2 - 안내 페이지2 진입");
      setNewEmployee3(false);
      setNewEmployee2(true);
    };

    const handleInputChange = (index, key, value) => {
      setResistEmployees((prevEmployees) => {
        const updatedEmployees = prevEmployees.map((employee, i) => (i === index ? validateEmployee({ ...employee, [key]: value }) : employee));
        return updatedEmployees;
      });
    };

    const addEmployee = () => {
      setResistEmployees((prevEmployees) => [...prevEmployees, { Name: "", TelNumber: "", NameDone: false, TelNumberDone: false, ID: uuidv4() }]);
    };

    const onClickNext = () => {
      const allNamesDone = resistEmployees.every((employee) => employee.NameDone);
      const allTelNumbersDone = resistEmployees.every((employee) => employee.TelNumberDone);
      if (allNamesDone && allTelNumbersDone) {
        if (seal.length > 0) {
          const matchingEmployees = findAllMatchingEmployees(resistEmployees, employeeList);
          if (matchingEmployees.length > 0) {
            let alertMessage = "";
            matchingEmployees.forEach((employee, index) => {
              alertMessage += `${employee.Name} 님은 이미 등록된 직원입니다. (${AddHyphenTelNumber(employee.TelNumber)})`;

              // 마지막 객체가 아니면 줄바꿈 추가
              if (index !== matchingEmployees.length - 1) {
                alertMessage += "\n";
              }
            });

            alertMessage += `\n\n입사 키트는 등록된 직원을 대상으로는 발급할 수 없습니다.\n자세한 문의는 help@certifie.io로 전달부탁드립니다.`;

            alert(alertMessage);
          } else {
            amplitude.track("입사 키트 발급3 - 입사자 이름/전화번호 등록 완료", {
              입사자정보: resistEmployees,
            });
            amplitude.track("입사 키트 발급5 - 입사 서류 선택 페이지 진입");
            setNewEmployee3(false);
            setNewEmployee5(true);
          }
        } else {
          amplitude.track("입사 키트 발급3 - 입사자 이름/전화번호 등록 완료", {
            입사자정보: resistEmployees,
          });
          amplitude.track("입사 키트 발급4 - 기업/기관 직인 등록 페이지 진입");
          setNewEmployee3(false);
          setNewEmployee4(true);
        }
      } else {
        const incompleteEmployees = resistEmployees
          .map((employee, index) => {
            const errors = [];
            if (!employee.NameDone) errors.push("이름");
            if (!employee.TelNumberDone) errors.push("전화번호");
            return errors.length > 0 ? `입사자 ${index + 1}: ${errors.join(", ")}` : null;
          })
          .filter(Boolean);

        alert(`다음 정보를 입력해주세요:\n- ${incompleteEmployees.join("\n- ")}`);
      }
    };

    useEffect(() => {
      const userData = LoadComapanyData("userData");
      if (userData) {
        setCompanyEmail(userData.companyEmail);
      } else {
        console.log("No userData found.");
      }
    }, []);

    useEffect(() => {
      if (!companyEmail) return;
      setIsLoading(true);

      const loadEmployeeData = async () => {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/employee";
        try {
          const response = await axios.get(endPoint, {
            params: {
              email: companyEmail,
            },
          });

          if (response.data.status === "직원 정보 조회 성공") {
            const rawEmployeeData = response.data.data.Employee;
            rawEmployeeData.sort((a, b) => {
              return a.Name.localeCompare(b.Name);
            });
            setEmployeeList(rawEmployeeData);
            setIsLoading(false);
          } else {
            alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error : ", error);
          alert("직원 정보 조회 통신을 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      };

      loadEmployeeData();
    }, [companyEmail]);
    const findAllMatchingEmployees = (resistEmployees, employeeList) => {
      let results = [];

      resistEmployees.forEach((resistEmployee) => {
        employeeList.forEach((employee) => {
          if (resistEmployee.Name === employee.Name && resistEmployee.TelNumber === employee.TelNumber) {
            results.push({
              Name: resistEmployee.Name,
              TelNumber: resistEmployee.TelNumber,
            });
          }
        });
      });

      return results;
    };

    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="AllContent_Default_V2">
            <div className="Leave_LeftArea_CT">
              <div className="Leave_Main_Copy">입사자의 이름과 전화번호를 입력해주세요.</div>
              <div className="Leave_Sub_Copy" style={{ marginBottom: "48px" }}>
                입사자 분이 필수 서류를 클릭 한번으로 수령하실 수 있게
                <br />
                문자메시지를 전송해 드립니다.
                <br />
                <span style={{ color: "#AAAAAA" }}>(입사 예정인 직원에 대해서만 진행할 수 있습니다.)</span>
              </div>
              {resistEmployees.map((employee, index) => (
                <div key={index} className="Leave_InputField_CT" style={{ marginTop: "0px" }}>
                  <div className="ProfileDetail_Subject">
                    <div className="Profile_Label_CT">
                      <div className="Profile_Label_Copy">입사자 {index + 1} 이름</div>
                      <div className={employee.NameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                    </div>
                    <input
                      className="Leave_LongInput_BG"
                      value={employee.Name}
                      onChange={(e) => handleInputChange(index, "Name", e.target.value)}
                      placeholder="입사자의 이름을 입력해주세요."
                      name="employeeName"
                      required
                    />
                  </div>
                  <div className="ProfileDetail_Subject">
                    <div className="Profile_Label_CT">
                      <div className="Profile_Label_Copy">입사자 {index + 1} 전화번호</div>
                      <div className={employee.TelNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                    </div>
                    <input
                      className="Leave_LongInput_BG"
                      value={employee.TelNumber}
                      onChange={(e) => handleInputChange(index, "TelNumber", e.target.value)}
                      placeholder="입사자의 전화번호를 입력해주세요. (숫자만 입력해주세요.)"
                      name="telNumber"
                      required
                    />
                  </div>
                  {resistEmployees.length > 1 && (
                    <button
                      className="EmployeeResist_NewLabelButton_BG"
                      type="button"
                      onClick={() => removeEmployee(index)}
                      style={{
                        backgroundColor: "rgb(255, 233, 233)",
                        color: "rgb(255, 115, 115)",
                        fontSize: "20px",
                        marginBottom: "24px",
                      }}
                    >
                      <div style={{ display: "flex", marginRight: "4px" }}>
                        <Delete size={"24"} color={"rgb(255, 115, 115)"} />
                      </div>
                      삭제
                    </button>
                  )}
                  <div className="NewEmployee_Divider"></div>
                </div>
              ))}
              <button
                className="EmployeeResist_NewLabelButton_BG"
                type="button"
                onClick={addEmployee}
                style={{
                  backgroundColor: "rgb(231, 239, 255)",
                  color: "rgb(55, 105, 252)",
                  fontSize: "20px",
                }}
              >
                <div style={{ display: "flex", marginRight: "4px" }}>
                  <Add size={"24"} color={"rgb(55, 105, 252)"} />
                </div>
                입사자 추가
              </button>
            </div>
            <div className="Leave_Vertical_Divider" />
            <div className="Leave_RightArea_CT">
              <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
              <div className="Leave_PageCount_Copy">1/3</div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default WB61NewEmployeeSub3;
