import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import axios from "axios";
import Loading from "../Components/Loading";
import ReferSelectList from "../Components/ReferSelectList";

const WB71ResignerSub3 = observer(({ setResigner3, setResigner4, setResignerData }) => {
  const [employeeData, setEmployeeData] = useState([]);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userData = LoadComapanyData("userData");
    if (userData) {
      setCompanyEmail(userData.companyEmail);
    } else {
      console.log("No userData found.");
    }
  }, []);

  useEffect(() => {
    if (!companyEmail) return;
    setIsLoading(true);
    const loadEmployeeData = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/employee";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: companyEmail,
          },
        });
        if (response.data.status === "직원 정보 조회 성공") {
          const rawEmployeeData = response.data.data.Employee;
          const filteredEmployeeData = rawEmployeeData.filter((employee) => !employee.LoseDate);
          filteredEmployeeData.sort((a, b) => {
            return a.Name.localeCompare(b.Name);
          });
          setEmployeeData(filteredEmployeeData);
          setIsLoading(false);
        } else {
          alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("직원 정보 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    loadEmployeeData();
  }, [companyEmail]);

  const handleItemClick = (resignerData) => {
    amplitude.track("퇴사 키트 발급4 - 퇴사자 정보 확인 페이지 진입");
    setResignerData(resignerData);
    setResigner3(false);
    setResigner4(true);
  };

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C" style={{ marginLeft: "0px", marginBottom: "192px" }}>
        <div className="Wait_CT" style={{ alignItems: "flex-start" }}>
          {isLoading ? (
            <div className="Wait_Lists_CT">
              <Loading />
            </div>
          ) : !isLoading && employeeData.length === 0 ? (
            <div className="Wait_NoneItem_Copy">등록된 직원 정보가 없습니다.</div>
          ) : !isLoading && employeeData.length > 0 ? (
            <>
              <div className="Leave_Main_Copy">퇴사하는 직원을 선택해주세요.</div>
              <div className="Leave_Sub_Copy" style={{ marginBottom: "48px" }}>
                경력 정보가 모두 등록된 직원에게 퇴사키트를 발급할 수 있습니다.
                <br /> <span style={{ color: "#AAAAAA" }}>(경력 정보는 직원 조회의 직원 상세 페이지에서 등록할 수 있습니다.)</span>
              </div>
              <ReferSelectList
                labelList={["이름", "생년월일", "전화번호", "부서", "직급/직위", "입사일"]}
                handleItemClick={handleItemClick}
                listData={employeeData}
              />
            </>
          ) : (
            <div className="Wait_NoneItem_Copy">등록된 직원 정보가 없습니다.</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default WB71ResignerSub3;
