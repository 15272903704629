import { observable, action } from "mobx";

export const companyStore = observable({
  accessToken: "",
  address: "",
  detailAddress: "",
  businessRegistrationNumber: "",
  callNumber: "",
  companyIntroduction: "",
  companyName: "",
  email: "",
  faxNumber: "",
  logo: "",
  seal: "",
  status: "",
  website: "",
  companyRegistrationNumber: "",

  setAccessToken: function (accessToken) {
    this.accessToken = accessToken;
  },
  setAddress: function (address) {
    this.address = address;
  },
  setDetailAddress: function (detailAddress) {
    this.detailAddress = detailAddress;
  },
  setBusinessRegistrationNumber: function (businessRegistrationNumber) {
    this.businessRegistrationNumber = businessRegistrationNumber;
  },
  setCallNumber: function (callNumber) {
    this.callNumber = callNumber;
  },
  setCompanyIntroduction: function (companyIntroduction) {
    this.companyIntroduction = companyIntroduction;
  },
  setCompanyName: function (companyName) {
    this.companyName = companyName;
  },
  setEmail: function (email) {
    this.email = email;
  },
  setFaxNumber: function (faxNumber) {
    this.faxNumber = faxNumber;
  },
  setLogo: function (logo) {
    this.logo = logo;
  },
  setSeal: function (seal) {
    this.seal = seal;
  },
  setStatus: function (status) {
    this.status = status;
  },
  setWebsite: function (website) {
    this.website = website;
  },
  setCompanyRegistrationNumber: function (companyRegistrationNumber) {
    this.companyRegistrationNumber = companyRegistrationNumber;
  },

  resetStore: action(function () {
    this.accessToken = "";
    this.address = "";
    this.detailAddress = "";
    this.businessRegistrationNumber = "";
    this.callNumber = "";
    this.companyIntroduction = "";
    this.companyName = "";
    this.detailAddress = "";
    this.email = "";
    this.faxNumber = "";
    this.logo = "";
    this.seal = "";
    this.statu = "";
    this.website = "";
    this.companyRegistrationNumber = "";
  }),
});
