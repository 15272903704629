import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const NavigationPanel = ({ backButtonText, onClickBack, nextButtonText, onClickNext }) => {
  return (
    <div
      className="Leave_Navigation_CT"
      style={{
        justifyContent: nextButtonText?.length > 0 && !backButtonText ? "flex-end" : "space-between",
      }}
    >
      {backButtonText?.length > 0 && (
        <div className="Leave_IconTextButton_CT">
          <Icon name={"ArrowBack"} size={24} color={"#3769FC"} />
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickBack}>
            {backButtonText}
          </button>
        </div>
      )}
      {nextButtonText?.length > 0 && (
        <div className="Leave_IconTextButton_CT">
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickNext} style={{ marginRight: 8 }}>
            {nextButtonText}
          </button>
          <Icon name={"ArrowForward"} size={24} color={"#3769FC"} />
        </div>
      )}
    </div>
  );
};

export default NavigationPanel;
