const PaginationDoubleArrowLeft = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8248 12.0001L11.6998 15.9001C11.8831 16.0834 11.979 16.3126 11.9873 16.5876C11.9956 16.8626 11.8998 17.1001 11.6998 17.3001C11.5165 17.4834 11.2831 17.5751 10.9998 17.5751C10.7165 17.5751 10.4831 17.4834 10.2998 17.3001L5.6998 12.7001C5.5998 12.6001 5.52897 12.4918 5.4873 12.3751C5.44564 12.2584 5.4248 12.1334 5.4248 12.0001C5.4248 11.8668 5.44564 11.7418 5.4873 11.6251C5.52897 11.5084 5.5998 11.4001 5.6998 11.3001L10.2998 6.70011C10.4831 6.51678 10.7123 6.42094 10.9873 6.41261C11.2623 6.40428 11.4998 6.50011 11.6998 6.70011C11.8831 6.88344 11.9748 7.11678 11.9748 7.40011C11.9748 7.68344 11.8831 7.91678 11.6998 8.10011L7.8248 12.0001ZM14.4248 12.0001L18.2998 15.9001C18.4831 16.0834 18.579 16.3126 18.5873 16.5876C18.5956 16.8626 18.4998 17.1001 18.2998 17.3001C18.1165 17.4834 17.8831 17.5751 17.5998 17.5751C17.3165 17.5751 17.0831 17.4834 16.8998 17.3001L12.2998 12.7001C12.1998 12.6001 12.129 12.4918 12.0873 12.3751C12.0456 12.2584 12.0248 12.1334 12.0248 12.0001C12.0248 11.8668 12.0456 11.7418 12.0873 11.6251C12.129 11.5084 12.1998 11.4001 12.2998 11.3001L16.8998 6.70011C17.0831 6.51678 17.3123 6.42094 17.5873 6.41261C17.8623 6.40428 18.0998 6.50011 18.2998 6.70011C18.4831 6.88344 18.5748 7.11678 18.5748 7.40011C18.5748 7.68344 18.4831 7.91678 18.2998 8.10011L14.4248 12.0001Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default PaginationDoubleArrowLeft;
