const ChevronRightBlue = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89997 11.6333C5.81108 11.5222 5.76386 11.4 5.7583 11.2667C5.75275 11.1333 5.79997 11.0167 5.89997 10.9167L8.8333 7.98333L5.8833 5.03333C5.79441 4.94444 5.75275 4.82499 5.7583 4.67499C5.76386 4.52499 5.81108 4.40555 5.89997 4.31666C6.01108 4.20555 6.13052 4.15277 6.2583 4.15833C6.38608 4.16388 6.49997 4.21666 6.59997 4.31666L9.91664 7.63333C9.97219 7.68888 10.0111 7.74444 10.0333 7.79999C10.0555 7.85555 10.0666 7.91666 10.0666 7.98333C10.0666 8.04999 10.0555 8.1111 10.0333 8.16666C10.0111 8.22221 9.97219 8.27777 9.91664 8.33333L6.61664 11.6333C6.51664 11.7333 6.39997 11.7805 6.26664 11.775C6.1333 11.7694 6.01108 11.7222 5.89997 11.6333Z"
        fill="#3769FC"
      />
    </svg>
  );
};

export default ChevronRightBlue;
