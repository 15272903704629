import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const PrivacyConsentTable = ({ type }) => {
  return (
    <>
      {type === "personalInfo" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                구분
              </td>
              <td className="ContractInput_Cell_Label" style={{ textAlign: "center" }}>
                내용
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                수집하는 개인정보 항목
              </td>
              <td className="ContractInput_Cell_Full_Body">
                개인식별정보(성명, 증명사진), 연락정보(주소, 전화번호, 휴대전화번호, 전자우편주소), 학력사항(재학기간, 학교, 전공, 학점),
                경력사항(근무처, 근무기간, 직위, 업무), 병역사항(군별, 병과, 계급, 제대여부, 복무기간, 미필사유), 가족사항(가족관계, 성명, 연령, 학력,
                직업, 근무처, 거주지), 기타사항(주거환경, 혼인여부, 보훈대상여부, 자격, 면허, 외국어능력, OA활용능력, 신장, 체중, 시력, 혈액형, 특기,
                취미 등)
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 수집 이용 목적
              </td>
              <td className="ContractInput_Cell_Full_Body">
                자격 확인 및 직무 적합여부 판단
                <br />
                인사관리(인사이동, 급여지급 등) 및 4대보험 가입
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 보유 및<br />
                이용기간
              </td>
              <td className="ContractInput_Cell_Full_Body">
                위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상,
                해당 개인정보가 불필요하게 된 것이 확인된 때에 파기
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                동의거부에 따른 불이익
              </td>
              <td className="ContractInput_Cell_Full_Body">인사제도 운영 시 정보부재로 인한 불이익</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {type === "uniqueID" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                구분
              </td>
              <td className="ContractInput_Cell_Label" style={{ textAlign: "center" }}>
                내용
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                수집하는 개인정보 항목
              </td>
              <td className="ContractInput_Cell_Full_Body">주민등록번호, 외국인등록번호</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 수집 이용 목적
              </td>
              <td className="ContractInput_Cell_Full_Body">
                자격 확인 및 직무 적합여부 판단
                <br />
                인사관리(인사이동, 급여지급 등) 및 4대보험 가입
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 보유 및<br />
                이용기간
              </td>
              <td className="ContractInput_Cell_Full_Body">
                위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상,
                해당 개인정보가 불필요하게 된 것이 확인된 때에 파기
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                동의거부에 따른 불이익
              </td>
              <td className="ContractInput_Cell_Full_Body">인사제도 운영 시 정보부재로 인한 불이익</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {type === "sensitiveInfo" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                구분
              </td>
              <td className="ContractInput_Cell_Label" style={{ textAlign: "center" }}>
                내용
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                수집하는 개인정보 항목
              </td>
              <td className="ContractInput_Cell_Full_Body">병력, 신체장애, 국가보훈대상, 범죄경력, 기타 인사관리에 필요한 민감정보</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 수집 이용 목적
              </td>
              <td className="ContractInput_Cell_Full_Body">
                직무 적합여부 판단
                <br />
                우선채용대상자격 확인 및 정부지원금 신청
                <br />
                인사관리(인사이동, 급여지급 등)
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보의 보유 및<br />
                이용기간
              </td>
              <td className="ContractInput_Cell_Full_Body">
                위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상,
                해당 개인정보가 불필요하게 된 것이 확인된 때에 파기
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                동의거부에 따른 불이익
              </td>
              <td className="ContractInput_Cell_Full_Body">인사제도 운영 시 정보부재로 인한 불이익</td>
            </tr>
          </tbody>
        </table>
      ) : null}
      {type === "thirdParty" ? (
        <table
          className="Email_PDF_Chart_BG"
          style={{
            borderSpacing: "0px",
            borderCollapse: "collapse",
            marginBottom: "8px",
            marginTop: "12px",
          }}
        >
          <tbody>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ width: "120px" }}>
                구분
              </td>
              <td className="ContractInput_Cell_Label" style={{ textAlign: "center" }}>
                내용
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                개인정보를 제공받는 자
              </td>
              <td className="ContractInput_Cell_Full_Body">
                국세청(관할 지방세무서) 및 관공서(노동부, 국민연금 관리공단, 건강보험 관리공단 등), 각종 인사관리 및 노무관련 업무위탁업체, 각종
                교육기관
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                제공하는 개인정보 항목
              </td>
              <td className="ContractInput_Cell_Full_Body">
                개인정보(성명, 주민등록번호, 주소, 성별, 직업, 전화번호, 휴대전화번호, 전자우편주소), 원천징수 및 연말정산 신고 등에 필요한 개인정보
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                제공하는 고유식별정보 항목
              </td>
              <td className="ContractInput_Cell_Full_Body">주민등록번호, 외국인등록번호</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                제공받는 자의 개인정보의 수집 이용 목적
              </td>
              <td className="ContractInput_Cell_Full_Body">인사관리 및 4대보험 가입, 연말정산, 외부 교육의 목적</td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                제공받는 자의 개인정보의 보유 및 이용기간
              </td>
              <td className="ContractInput_Cell_Full_Body">
                위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상,
                해당 개인정보가 불필요하게 된 것이 확인된 때에 파기
              </td>
            </tr>
            <tr>
              <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
                동의거부에 따른 불이익
              </td>
              <td className="ContractInput_Cell_Full_Body">인사제도 운영 시 정보부재로 인한 불이익</td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default PrivacyConsentTable;
