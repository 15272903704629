import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Pagination from "./Pagination";
import { DocumentNameToKorean } from "../Utils/ConvertStringFormat";
import { AddDotYYYYMMDD, ConvertKSTFormatDate, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";

const ReferSelectList = observer(({ labelList, handleItemClick, listData }) => {
  const screenWidth = window.innerWidth;
  const itemsPerPage = 10;
  const pageCount = Math.ceil(listData.length / itemsPerPage);
  const [currentListData, setCurrentListData] = useState([]);

  useEffect(() => {
    if (currentListData.length === 0 && listData.length > 0) {
      setCurrentListData(listData.slice(0, itemsPerPage));
    }
  }, [currentListData.length, listData]);

  const handlePageChange = (page) => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentListData(listData.slice(start, end));
  };

  const checkIfLabelExists = (label) => {
    return labelList.includes(label);
  };
  const isDocumentNameIncluded = checkIfLabelExists("문서명");
  const isInvestorCompanyShareholderReportIncluded = checkIfLabelExists("주주보고서 명");
  const isReportYearMonthIncluded = checkIfLabelExists("주주보고서 시기");
  const isNameIncluded = checkIfLabelExists("이름");
  const isRecipientIncluded = checkIfLabelExists("수령인");
  const isBirthdayIncluded = checkIfLabelExists("생년월일");
  const isTelNumberIncluded = checkIfLabelExists("전화번호");
  const isDepartmentIncluded = checkIfLabelExists("부서");
  const isWorkPeriodIncluded = checkIfLabelExists("재직기간");
  const isJobPositionIncluded = checkIfLabelExists("직급/직위");
  const isAcqDateIncluded = checkIfLabelExists("입사일");
  const isCreateDateIncluded = checkIfLabelExists("작성일");
  const isIssueDateIncluded = checkIfLabelExists("발급일");
  const isDocumentStatusIncluded = checkIfLabelExists("동의 여부");

  const formatReportYearMonthDateString = (date) => {
    const dateString = typeof date === "number" ? date.toString() : date;
    if (dateString.length === 6) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      return `${year}년 ${month}월`;
    }
    return date;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <div className="Document_EmployeeList_Label_CT">
        {isNameIncluded ? (
          <div
            className="ReferSelectList_Label_Name_Copy"
            style={{
              marginLeft: "12px",
            }}
          >
            이름
          </div>
        ) : null}
        {isRecipientIncluded ? (
          <div
            className="ReferSelectList_Label_Name_Copy"
            style={{
              marginLeft: "12px",
            }}
          >
            수령인
          </div>
        ) : null}
        {isInvestorCompanyShareholderReportIncluded ? (
          <div
            className="ReferSelectList_Label_InvestorCompanyShareholderReport_Copy"
            style={{
              marginLeft: "12px",
            }}
          >
            주주보고서 명
          </div>
        ) : null}
        {isBirthdayIncluded ? <div className="ReferSelectList_Label_Name_Copy">생년월일</div> : null}
        {isTelNumberIncluded ? <div className="ReferSelectList_Label_TelNumber_Copy">전화번호</div> : null}
        {isDepartmentIncluded ? <div className="ReferSelectList_Label_Department_Copy">부서</div> : null}
        {isJobPositionIncluded ? <div className="ReferSelectList_Label_JobPosition_Copy">직급/직위</div> : null}
        {isWorkPeriodIncluded ? <div className="ReferSelectList_Label_WorkPeriod_Copy">재직기간</div> : null}
        {isDocumentStatusIncluded ? <div className="ReferSelectList_Label_DocumentStatus_Copy">동의 여부</div> : null}
        {isDocumentNameIncluded ? <div className="ReferSelectList_Label_DocumentName_Copy">문서명</div> : null}
        {isAcqDateIncluded ? (
          <div className="ReferSelectList_Label_AcqDate_Copy" style={{ marginRight: "12px" }}>
            입사일
          </div>
        ) : null}
        {isReportYearMonthIncluded ? (
          <div className="ReferSelectList_Label_Name_Copy" style={{ minWidth: "102px", maxWidth: "102px" }}>
            주주보고서 시기
          </div>
        ) : null}
        {isCreateDateIncluded ? (
          <div className="ReferSelectList_Label_AcqDate_Copy" style={{ marginRight: "12px" }}>
            작성일
          </div>
        ) : null}
        {isIssueDateIncluded ? (
          <div className="ReferSelectList_Label_AcqDate_Copy" style={{ marginRight: "12px" }}>
            발급일
          </div>
        ) : null}
      </div>
      <div className="Document_EmployeeList_Divider" style={{ backgroundColor: "rgb(170, 170, 170)" }} />
      <div className="ReferEmployee_Lists_CT" style={{ height: "586px" }}>
        {currentListData.map((certificateData) => (
          <React.Fragment key={certificateData.ID}>
            <button className="ReferSelectList_EmployeeList_Button_BG" onClick={() => handleItemClick(certificateData)}>
              <div className="ReferSelectList_EmployeeList_Contents_CT">
                {isNameIncluded && (
                  <div className="ReferSelectList_Body_Name_Copy" style={{ marginLeft: "12px" }}>
                    {certificateData.MemberData ? (
                      <>
                        {certificateData.MemberData[0].Name || certificateData.MemberData[0].name}
                        <br />외 {certificateData.MemberData.length - 1} 명
                      </>
                    ) : (
                      certificateData.Name
                    )}
                  </div>
                )}
                {isInvestorCompanyShareholderReportIncluded && (
                  <div className="ReferSelectList_Body_InvestorCompanyShareholderReport_Copy" style={{ marginLeft: "12px" }}>
                    {certificateData.ReportName}
                  </div>
                )}
                {screenWidth > 768 && (
                  <>
                    {isBirthdayIncluded && (
                      <div className="ReferSelectList_Body_YYYYMMDDDate_Copy">
                        {certificateData.Birthday ? AddDotYYYYMMDD(certificateData.Birthday) : "-"}
                      </div>
                    )}

                    {isTelNumberIncluded && (
                      <div className="ReferSelectList_Body_TelNumber_Copy">
                        {certificateData.TelNumber && !certificateData.MemberData ? AddHyphenTelNumber(certificateData.TelNumber) : "-"}
                      </div>
                    )}
                    {isDepartmentIncluded && (
                      <div className="ReferSelectList_Body_Department_Copy">{certificateData.Department ? certificateData.Department : "-"}</div>
                    )}
                    {isWorkPeriodIncluded && (
                      <div className="ReferSelectList_Body_WorkPeriod_Copy">
                        {certificateData.AcqDate && certificateData.LoseDate
                          ? `${AddDotYYYYMMDD(certificateData.AcqDate)} ~ ${AddDotYYYYMMDD(certificateData.LoseDate)}`
                          : "-"}
                      </div>
                    )}
                    {isJobPositionIncluded && (
                      <div className="ReferSelectList_Body_JobPosition_Copy">{certificateData.JobPosition ? certificateData.JobPosition : "-"}</div>
                    )}
                    {isDocumentStatusIncluded && (
                      <div className="ReferSelectList_Body_DocumentStatus_Copy">
                        {certificateData.DocumentStatus === "작성"
                          ? "미발송"
                          : certificateData.DocumentStatus === "서명대기"
                          ? "대기"
                          : certificateData.DocumentStatus === "서명완료"
                          ? "완료"
                          : "미표시"}
                      </div>
                    )}
                    {isDocumentNameIncluded && (
                      <div className="ReferSelectList_Body_DocumentName_Copy">
                        {certificateData.DocumentName ? DocumentNameToKorean(certificateData.DocumentName) : "-"}
                      </div>
                    )}
                    {isAcqDateIncluded && (
                      <div className="ReferSelectList_Body_AcqDate_Copy" style={{ marginRight: "12px" }}>
                        {certificateData.AcqDate ? AddDotYYYYMMDD(certificateData.AcqDate) : "-"}
                      </div>
                    )}
                    {isReportYearMonthIncluded && (
                      <div
                        className="ReferSelectList_Body_AcqDate_Copy"
                        style={{ minWidth: "102px", maxWidth: "102px", textAlign: "left", marginRight: "24px" }}
                      >
                        {certificateData.ReportYearMonth ? formatReportYearMonthDateString(certificateData.ReportYearMonth) : "-"}
                      </div>
                    )}
                    {isCreateDateIncluded && (
                      <div className="ReferSelectList_Body_AcqDate_Copy" style={{ marginRight: "12px" }}>
                        {certificateData.CreationDate
                          ? ConvertKSTFormatDate(certificateData.CreationDate)
                          : certificateData.Date
                          ? AddDotYYYYMMDD(certificateData.Date)
                          : "-"}
                      </div>
                    )}
                    {isIssueDateIncluded && (
                      <div className="ReferSelectList_Body_AcqDate_Copy" style={{ marginRight: "12px" }}>
                        {certificateData.CreationDate ? ConvertKSTFormatDate(certificateData.CreationDate) : "-"}
                        {/* {certificateData.createDate ? ConvertKSTFormatDate(certificateData.createDate) : "-"} */}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="Document_EmployeeList_Divider" />
            </button>
          </React.Fragment>
        ))}
      </div>
      {pageCount > 1 ? (
        <div className="Wait_Pagination">
          <Pagination totalItems={listData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
        </div>
      ) : null}
    </div>
  );
});

export default ReferSelectList;
