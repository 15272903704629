import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const ParentalAuthorityConsentInfoModal = ({ setParentalAuthorityConsentInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">친권자(후견인) 동의서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setParentalAuthorityConsentInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            근로기준법은 <span style={{ fontWeight: 600 }}>만 18세 미만</span>인 자를 연소자로 보고 있기 때문에 별도로 보호 규정을 적용하고 있습니다.
            연소자를 채용해야 하는 사업주는 가족관계 증명서와 <span style={{ fontWeight: 600 }}>친권자 동의서</span> 또는{" "}
            <span style={{ fontWeight: 600 }}>후견인 동의서</span>를 사업장에 갖춰야 합니다.
          </div>

          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 과태료 500만원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentalAuthorityConsentInfoModal;
