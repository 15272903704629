import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Select } from "react-dropdown-select";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB01SigninSub4 = observer(
  ({
    businessType,
    setBusinessType,
    businessTypeDone,
    setBusinessTypeDone,
    isVisibleEtc,
    setIsVisibleEtc,
    etcBusinessType,
    setEtcBusinessType,
    employeesNumber,
    setEmployeesNumber,
    employeesNumberDone,
    setEmployeesNumberDone,
    setSignin3,
    setSignin4,
    setSignin5,
  }) => {
    const numberOptions = [
      {
        value: "1~4 명",
        label: "1~4 명",
      },
      {
        value: "5~10 명",
        label: "5~10 명",
      },
      {
        value: "11~20 명",
        label: "11~20 명",
      },
      {
        value: "21~49 명",
        label: "21~49 명",
      },
      {
        value: "50~99 명",
        label: "50~99 명",
      },
      {
        value: "100~299 명",
        label: "100~299 명",
      },
      {
        value: "300 명 이상",
        label: "300 명 이상",
      },
    ];

    const typeOptions = [
      {
        value: "제조업",
        label: "제조업",
      },
      {
        value: "정보통신업 (IT)",
        label: "정보통신업 (IT)",
      },
      {
        value: "F&B, 프랜차이즈",
        label: "F&B, 프랜차이즈",
      },
      {
        value: "도・소매 유통 판매",
        label: "도・소매 유통 판매",
      },
      {
        value: "엔터테인먼트, 여행, 예술",
        label: "엔터테인먼트, 여행, 예술",
      },
      {
        value: "공공 행정",
        label: "공공 행정",
      },
      {
        value: "건설 및 기간 산업",
        label: "건설 및 기간 산업",
      },
      {
        value: "서비스",
        label: "서비스",
      },
      {
        value: "세무, 법무, 노무",
        label: "세무, 법무, 노무",
      },
      {
        value: "의료 보건, 사회 복지",
        label: "의료 보건, 사회 복지",
      },
      {
        value: "금융, 보험, 부동산",
        label: "금융, 보험, 부동산",
      },
      {
        value: "협회 및 단체",
        label: "협회 및 단체",
      },
      {
        value: "교육 및 연구",
        label: "교육 및 연구",
      },
      {
        value: "1차 산업 (농・임・수산업)",
        label: "1차 산업 (농・임・수산업)",
      },
      {
        value: "기타",
        label: "기타",
      },
    ];

    const onClickBack = () => {
      amplitude.track("회원가입3 - 기업/기관 필수 정보 등록 페이지 진입");
      setSignin4(false);
      setSignin3(true);
    };

    const onClickNext = () => {
      if (employeesNumberDone === true && businessTypeDone === true) {
        amplitude.track("회원가입4 - 기업/기관 직원수/업종 정보 등록 완료", {
          직원수: employeesNumber[0].label,
          업종: etcBusinessType.length > 0 ? etcBusinessType : businessType[0].label,
        });
        amplitude.track("회원가입5 - 담당자 정보 등록 페이지 진입");

        setSignin4(false);
        setSignin5(true);
      } else {
        alert("업종, 직원수를 선택해주세요.");
      }
    };

    useEffect(() => {
      if (employeesNumber.length > 0) {
        setEmployeesNumberDone(true);
      } else {
        setEmployeesNumberDone(false);
      }

      if (businessType.length > 0 && businessType[0].label !== "기타") {
        setBusinessTypeDone(true);
      } else if (businessType.length > 0 && businessType[0].label === "기타" && etcBusinessType.length > 0) {
        setBusinessTypeDone(true);
      } else {
        setBusinessTypeDone(false);
      }

      if (businessType[0]?.label && businessType[0]?.label === "기타") {
        setIsVisibleEtc(true);
      } else {
        setIsVisibleEtc(false);
      }
    }, [employeesNumber, businessType, etcBusinessType, setEmployeesNumberDone, setBusinessTypeDone, setIsVisibleEtc]);

    return (
      <>
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">기업/기관의 업종과 직원수를 선택해주세요.</div>
          <div className="Leave_InputField_CT">
            {/* 업종 */}
            <div className="ProfileDetail_Subject" style={{ marginBottom: isVisibleEtc ? 8 : null }}>
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">업종</div>
                <div className={businessTypeDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <Select
                options={typeOptions}
                onChange={(values) => setBusinessType(values)}
                style={{
                  width: 480,
                  height: 44,
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#AAAAAA",
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
              />
            </div>
            {isVisibleEtc ? (
              <input
                className="Signin_LongInput_BG"
                value={etcBusinessType}
                onChange={(e) => setEtcBusinessType(e.target.value)}
                placeholder="업종을 직접 입력해주세요."
                name="etcBusinessType"
                style={{
                  borderColor: "rgb(170, 170, 170)",
                  marginBottom: 24,
                }}
                required
              />
            ) : null}
            {/*직원수 */}
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">직원수</div>
                <div className={employeesNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <Select
                options={numberOptions}
                onChange={(values) => setEmployeesNumber(values)}
                style={{
                  width: 480,
                  height: 44,
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#AAAAAA",
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
              />
            </div>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">4/5</div>
        </div>
      </>
    );
  }
);

export default WB01SigninSub4;
