import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Icon from "../Assets/Icons/Icon";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import HomeButton from "../Components/HomeButton";
// import HomeWidget from "../Components/HomeWidget";
// import { v4 as uuidv4 } from "uuid";

const WB21Home = observer(() => {
  const userData = LoadComapanyData("userData");
  const navigate = useNavigate();

  const onClickEmployee = () => {
    amplitude.track("직원 등록 (엑셀파일)1 - 엑셀 파일 형식 안내 스크린 진입");
    navigate("/addEmployeeFile");
  };

  const onClickIssueDocument = () => {
    amplitude.track("문서 발급1 - 직원 선택 페이지 진입");
    navigate("/issueDocument");
  };

  const onClickConsentDocument = () => {
    amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
    navigate("/consentDocument");
  };

  const onClickNewEmployee = () => {
    amplitude.track("입사 키트 발급1 - 안내 페이지1 진입");
    navigate("/newEmployee");
  };

  // const onClickNotification = () => {
  //   amplitude.track("알림 메뉴 진입");
  //   navigate("/notification");
  // };

  // const testDataArray = [
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  // ];

  return (
    <div className="FlexContent_Other_V" style={{ paddingBottom: "64px" }}>
      <div className="AllContent_Default_C">
        <div className="Home_Head_Copy" style={{ marginTop: "72px" }}>
          안녕하세요, <span style={{ color: "rgb(55, 105, 252)" }}>{userData.companyName}</span> 담당자님.
        </div>
        <div className="Widget_Two_CT">
          {/* <HomeWidget
            onClickMore={onClickNotification}
            onClickBody={onClickNotification}
            title={"알림"}
            dataList={testDataArray}
            bodyTextKey={"bodyText"}
            marginRight={"24px"}
          /> */}
          <div className="Widget_CT" style={{ marginRight: "24px" }}>
            <div className="WidgetList_Title_CT">
              <div className="WidgetList_Title_Copy">알림</div>
              <div className="WidgetList_Button_CT">
                <Icon name={"Add"} size={"24"} color={"#AAAAAA"} />
                <button className="WidgetList_Button_Copy">더보기</button>
              </div>
            </div>
            <div className="WidgetList_Title_Divider" />
            <div style={{ width: "100%", height: "192px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="WidgetList_Title_Copy" style={{ width: "450px", textAlign: "center", color: "rgb(170, 170, 170)", fontWeight: "400" }}>
                준비중 입니다.
              </div>
            </div>
          </div>
          <div className="Widget_CT">
            <div className="WidgetList_Title_CT">
              <div className="WidgetList_Title_Copy">인사 노무 정보</div>
              <div className="WidgetList_Button_CT">
                <Icon name={"Add"} size={"24"} color={"#AAAAAA"} />
                <button className="WidgetList_Button_Copy">더보기</button>
              </div>
            </div>
            <div className="WidgetList_Title_Divider" />
            <div style={{ width: "100%", height: "192px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="WidgetList_Title_Copy" style={{ width: "450px", textAlign: "center", color: "rgb(170, 170, 170)", fontWeight: "400" }}>
                준비중 입니다.
              </div>
            </div>
          </div>
        </div>
        <div className="Home_LargeBar_Divider" />
        <HomeButton label={"직원 등록"} onClick={onClickEmployee} />
        <HomeButton label={"문서 작성"} onClick={onClickIssueDocument} />
        <HomeButton label={"동의 받기"} onClick={onClickConsentDocument} />
        <HomeButton label={"입사 키트"} onClick={onClickNewEmployee} />
      </div>
    </div>
  );
});

export default WB21Home;
