const EditSquare = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 23.7C4.1 23.7 3.75 23.55 3.45 23.25C3.15 22.95 3 22.6 3 22.2V7.20001C3 6.80001 3.15 6.45001 3.45 6.15001C3.75 5.85001 4.1 5.70001 4.5 5.70001H14.625L13.125 7.20001H4.5V22.2H19.5V13.5L21 12V22.2C21 22.6 20.85 22.95 20.55 23.25C20.25 23.55 19.9 23.7 19.5 23.7H4.5ZM16.55 5.90001L17.625 6.95001L10.5 14.05V16.2H12.625L19.775 9.05001L20.825 10.1L13.7 17.25C13.5667 17.3833 13.4042 17.4917 13.2125 17.575C13.0208 17.6583 12.825 17.7 12.625 17.7H9.75C9.53333 17.7 9.35417 17.6292 9.2125 17.4875C9.07083 17.3458 9 17.1667 9 16.95V14.075C9 13.875 9.04167 13.6792 9.125 13.4875C9.20833 13.2958 9.31667 13.1333 9.45 13L16.55 5.90001ZM20.825 10.1L16.55 5.90001L19.05 3.40001C19.3333 3.11667 19.6875 2.97501 20.1125 2.97501C20.5375 2.97501 20.8917 3.12501 21.175 3.42501L23.275 5.55001C23.5583 5.85001 23.7 6.20417 23.7 6.61251C23.7 7.02084 23.55 7.36667 23.25 7.65001L20.825 10.1Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default EditSquare;
