const ExpandMore = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 15.075C11.9001 15.075 11.8085 15.0583 11.7251 15.025C11.6418 14.9917 11.5585 14.9333 11.4751 14.85L6.52515 9.9C6.39182 9.76666 6.32932 9.5875 6.33765 9.3625C6.34598 9.1375 6.41682 8.95833 6.55015 8.825C6.71682 8.65833 6.89598 8.5875 7.08765 8.6125C7.27932 8.6375 7.45015 8.71666 7.60015 8.85L12.0001 13.25L16.4001 8.85C16.5335 8.71666 16.7126 8.64166 16.9376 8.625C17.1627 8.60833 17.3418 8.68333 17.4752 8.85C17.6418 8.98333 17.7126 9.15833 17.6876 9.375C17.6627 9.59166 17.5835 9.775 17.4502 9.925L12.5251 14.85C12.4418 14.9333 12.3585 14.9917 12.2751 15.025C12.1918 15.0583 12.1001 15.075 12.0001 15.075Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default ExpandMore;
