import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as XLSX from "xlsx";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB31AddEmployeeFileSub2 = observer(
  ({ excelData, setExcelData, excelDataSrc, setExcelDataSrc, excelDataInput, setMappedData, setEmployee1, setEmployee2, setEmployee3 }) => {
    const onClickExcelDataUpload = async () => {
      excelDataInput.current.value = null;
      excelDataInput.current.click();
    };
    const onExcelDataUpload = (e) => {
      setExcelDataSrc(e.target.files[0]);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let invalidIndex = true;
        const referenceLength = jsonData[0].length;
        const invalidLength = jsonData.every((array) => array.length === referenceLength);
        for (let i = 1; i < jsonData.length; i++) {
          const element = jsonData[i][0];
          if (element === "" || element === null || element === undefined) {
            invalidIndex = false;
            break;
          }
        }
        if (!invalidLength || !invalidIndex) {
          alert("엑셀 파일 형식을 수정해주세요.");
          excelDataInput.current.value = null;
          setExcelDataSrc("");
          setExcelData();
        } else {
          setExcelData(jsonData);
        }
      };
      reader.readAsArrayBuffer(file);
    };
    const columns = excelData ? excelData[0].map((_, index) => excelData.map((row) => row[index])) : null;
    const newMappedData = excelData
      ? columns.map((column) => {
          return column.slice(1);
        })
      : [];

    const onClickNext = () => {
      if (excelData) {
        amplitude.track("직원 등록 (엑셀파일)3 - 엑셀 데이터 레이블 설정 스크린 진입");
        setExcelDataSrc("");
        setExcelData();
        setMappedData(newMappedData);
        setEmployee2(false);
        setEmployee3(true);
      } else {
        alert("엑셀 파일을 등록해주세요.");
      }
    };
    const onClickBack = () => {
      amplitude.track("직원 등록 (엑셀파일)1 - 엑셀 파일 형식 안내 스크린 진입");
      setEmployee2(false);
      setEmployee1(true);
    };
    return (
      <>
        <div className="AllContent_Default_V2">
          <div className="Leave_LeftArea_CT">
            <div className="Leave_Main_Copy">
              직원 정보가 담긴 엑셀 파일을 <br />
              업로드 해주세요.
            </div>
            <div className="Leave_Sub_Copy">
              .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xls, .xml, .xlam, .xla, .xlw 확장자 파일을 업로드 하실 수 있습니다.
            </div>
            <div className="Profile_Label_CT" style={{ marginTop: "48px" }}>
              <div className="Profile_Label_Copy">엑셀 파일</div>
              <div className={excelData ? "Required_Dot_Blue" : "Required_Dot_Red"} />
            </div>
            <div className="ProfileDetail_UploadButton_CT" style={{ marginBottom: "48px" }}>
              <input className="ProfileDetail_ShortInput_BG " value={excelDataSrc ? excelDataSrc.name : ""} readOnly />
              <input
                accept=".xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xls, .xml, .xlam, .xla, .xlw"
                type="file"
                onChange={(e) => onExcelDataUpload(e)}
                className="ProfileDetail_UploadButton_None"
                ref={excelDataInput}
                required
                max-size="5242880"
                name="license"
              />
              <button type="button" className="ProfileDetail_UploadButton" onClick={onClickExcelDataUpload}>
                파일 업로드
              </button>
            </div>
          </div>
          <div className="EmployeeResist_Vertical_Divider" />
          <div className="Leave_RightArea_CT">
            <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          </div>
        </div>
      </>
    );
  }
);
export default WB31AddEmployeeFileSub2;
