import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { AddDotYYYYMMDD, ConvertISOFormatDate } from "../Utils/ConvertNumberFormat";
import Loading from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import Icon from "../Assets/Icons/Icon";
import CheckListLeft2 from "../Components/CheckListLeft2";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";
import Pagination from "../Components/Pagination";
import { AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import { GetPreviousDay } from "../Utils/GetPreviousDay";
import { Select } from "react-dropdown-select";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WBd1RequestRequiredDocument = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstTab, setFirstTab] = useState(true);
  const [firstTabPage1, setFirstTabPage1] = useState(true);
  const [firstTabPage2, setFirstTabPage2] = useState(false);
  const [firstTabPage3, setFirstTabPage3] = useState(false);
  const [firstTabPage4, setFirstTabPage4] = useState(false);
  const [secondTab, setSecondTab] = useState(false);
  const [secondTabGroupList, setSecondTabGroupList] = useState(true);
  const [secondTabDocumentList, setSecondTabDocumentList] = useState(false);
  const [secondTabDocumentListArray, setSecondTabDocumentListArray] = useState([]);
  const [secondTabDocumentListName, setSecondTabDocumentListName] = useState();
  const [secondTabDocumentDetail, setSecondTabDocumentDetail] = useState(false);
  const [secondTabDocumentItem, setSecondTabDocumentItem] = useState();
  const [certificate, setCertificate] = useState(false);
  const [resume, setResume] = useState(false);
  const [identityCardImage, setIdentityCardImage] = useState(false);
  const [diploma, setDiploma] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addGroupName, setAddGroupName] = useState("");
  const [isVisibleAddGroup, setIsVisibleAddGroup] = useState(false);
  const [isVisibleChangeGroup, setIsVisibleChangeGroup] = useState(false);
  const [isVisibleResendMessege, setIsVisibleResendMessege] = useState(false);
  const [changeGroupName, setChangeGroupName] = useState("");
  const [resistEmployees, setResistEmployees] = useState([{ name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
  const [sendDocument, setSendDocument] = useState([]);
  const inquiryGroupItemsPerPage = 15;
  const requestGroupItemsPerPage = 6;
  const selectedGroupDocumentListItemsPerPage = 13;
  const [groupList, setGroupList] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const inquiryGroupPageCount = Math.ceil(groupList?.length / inquiryGroupItemsPerPage);
  const requestGroupPageCount = Math.ceil(groupList?.length / requestGroupItemsPerPage);
  const selectedGroupDocumentListPageCount = Math.ceil(secondTabDocumentListArray?.length / selectedGroupDocumentListItemsPerPage);
  const [currentInquiryGroupListData, setCurrentInquiryGroupListData] = useState([]);
  const [currentRequestGroupListData, setCurrentRequestGroupListData] = useState([]);
  const [currentSelectedGroupDocumentListData, setCurrentSelectedGroupDocumentListData] = useState([]);
  const userData = LoadComapanyData("userData");

  console.log("secondTabDocumentItem", secondTabDocumentItem);
  useEffect(() => {
    setIsLoading(true);
    const getGroupList = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/group";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: userData.companyEmail,
          },
        });
        if (response.data.status === "그룹 조회 성공") {
          setGroupList(response.data.data);
        } else {
          alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    const getRequestList = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: userData.companyEmail,
          },
        });
        if (response.data.status === "대상자 확인 완료") {
          console.log("response.data.data : ", response.data.data);
          setRequestList(response.data.data);
          setIsLoading(false);
        } else {
          alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    getGroupList();
    getRequestList();
  }, [userData.companyEmail]);

  const getGroupList = async () => {
    const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/group";
    try {
      const response = await axios.get(endPoint, {
        params: {
          email: userData.companyEmail,
        },
      });
      if (response.data.status === "그룹 조회 성공") {
        setGroupList(response.data.data);
      } else {
        alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error : ", error);
      alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
      setIsLoading(false);
    }
  };

  const getRequestList = async () => {
    const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient";
    try {
      const response = await axios.get(endPoint, {
        params: {
          email: userData.companyEmail,
        },
      });
      if (response.data.status === "대상자 확인 완료") {
        setRequestList(response.data.data);
        setIsLoading(false);
      } else {
        alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error : ", error);
      alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
      setIsLoading(false);
    }
  };

  const getRequiredDocuments = () => {
    const documents = [];
    if (certificate) documents.push("- 경력증명서");
    if (resume) documents.push("- 이력서");
    if (identityCardImage) documents.push("- 주민등록증 이미지");
    if (diploma) documents.push("- 졸업증명서");

    return documents.join("\n");
  };

  const validateEmployee = (employee) => {
    const numberPattern = /^\d+$/;
    return {
      ...employee,
      nameDone: employee.name.length > 0,
      telNumberDone: employee.telNumber.length >= 10 && employee.telNumber.length <= 11 && numberPattern.test(employee.telNumber),
    };
  };

  const removeEmployee = (index) => {
    setResistEmployees((prevEmployees) => prevEmployees.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, key, value) => {
    setResistEmployees((prevEmployees) => {
      const updatedEmployees = prevEmployees.map((employee, i) => (i === index ? validateEmployee({ ...employee, [key]: value }) : employee));
      return updatedEmployees;
    });
  };

  const addEmployee = () => {
    setResistEmployees((prevEmployees) => [...prevEmployees, { name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
  };

  const onSelectGroup = (groupData) => {
    if (selectedGroup && selectedGroup.id === groupData.id) {
      setSelectedGroup(null); // Deselect
    } else {
      setSelectedGroup(groupData); // Select
    }
  };

  const groupListOptions = [...new Set(groupList.filter((item) => item.groupName !== secondTabDocumentListName).map((item) => item.groupName))].map(
    (groupName) => ({
      value: groupName,
      label: groupName,
    })
  );

  useEffect(() => {
    setCurrentRequestGroupListData(groupList.slice(0, requestGroupItemsPerPage));
    setCurrentInquiryGroupListData(groupList.slice(0, inquiryGroupItemsPerPage));
  }, [groupList]);

  const handleInquiryGroupListPageChange = (page) => {
    const start = (page - 1) * inquiryGroupItemsPerPage;
    const end = start + inquiryGroupItemsPerPage;
    setCurrentInquiryGroupListData(groupList.slice(start, end));
  };

  const handleRequestGroupListPageChange = (page) => {
    const start = (page - 1) * requestGroupItemsPerPage;
    const end = start + requestGroupItemsPerPage;
    setCurrentRequestGroupListData(groupList.slice(start, end));
  };

  const handleSelectedGroupDocumentListPageChange = (page) => {
    const start = (page - 1) * selectedGroupDocumentListItemsPerPage;
    const end = start + selectedGroupDocumentListItemsPerPage;
    setCurrentSelectedGroupDocumentListData(secondTabDocumentListArray.slice(start, end));
  };

  const tabList = ["전송", "조회"];

  const onClickFirstTab = () => {
    setFirstTab(true);
    setSecondTab(false);
  };

  const onClickSecondTab = () => {
    setFirstTab(false);
    setSecondTab(true);
  };

  const firstTabMoveToPage1 = () => {
    setFirstTabPage1(true);
    setFirstTabPage2(false);
    setFirstTabPage3(false);
    setFirstTabPage4(false);
  };

  const firstTabMoveToPage2 = () => {
    if (!selectedGroup) {
      alert("필수 서류를 분류할 그룹을 선택해주세요.");
    } else {
      setFirstTabPage1(false);
      setFirstTabPage2(true);
      setFirstTabPage3(false);
      setFirstTabPage4(false);
    }
  };

  const firstTabMoveToPage3 = () => {
    const allNamesDone = resistEmployees.every((employee) => employee.nameDone);
    const allTelNumbersDone = resistEmployees.every((employee) => employee.telNumberDone);
    if (allNamesDone && allTelNumbersDone) {
      setFirstTabPage1(false);
      setFirstTabPage2(false);
      setFirstTabPage3(true);
      setFirstTabPage4(false);
    } else {
      const incompleteEmployees = resistEmployees
        .map((employee, index) => {
          const errors = [];
          if (!employee.nameDone) errors.push("이름");
          if (!employee.telNumberDone) errors.push("전화번호");
          return errors.length > 0 ? `입사자 ${index + 1} : ${errors.join(", ")}` : null;
        })
        .filter(Boolean);

      alert(`다음 정보를 입력해주세요:\n- ${incompleteEmployees.join("\n- ")}`);
    }
  };

  const firstTabMoveToPage4 = () => {
    if (certificate || resume || identityCardImage || diploma) {
      setFirstTabPage1(false);
      setFirstTabPage2(false);
      setFirstTabPage3(false);
      setFirstTabPage4(true);
    } else {
      alert("전달 받을 필수 서류를 선택해주세요.");
    }
  };

  const firstTabConfirmSendMessege = async () => {
    setIsLoading(true);

    const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/documents";
    try {
      if (certificate && sendDocument.length < 4) {
        sendDocument.push({ documentType: "certificate" });
      }
      if (resume && sendDocument.length < 4) {
        sendDocument.push({ documentType: "resume" });
      }
      if (identityCardImage && sendDocument.length < 4) {
        sendDocument.push({ documentType: "identityCardImage" });
      }
      if (diploma && sendDocument.length < 4) {
        sendDocument.push({ documentType: "diploma" });
      }

      const recipientsData = {
        groupName: selectedGroup.groupName,
        groupId: selectedGroup.id,
        requestList: resistEmployees.map((employee) => ({
          recipientName: employee.name,
          recipientTelNumber: employee.telNumber,
          documents: sendDocument,
        })),
      };

      const response = await axios.post(endPoint, {
        email: userData.companyEmail,
        companyName: userData.companyName,
        recipientsData: recipientsData,
      });

      if (response.data.status === "필수 서류 요청 성공") {
        alert("필수 서류 요청 메시지를 전송했습니다.");
        setIsLoading(false);
        setCertificate(false);
        setResume(false);
        setIdentityCardImage(false);
        setDiploma(false);
        setSelectedGroup(null);
        setResistEmployees([{ name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
        setSendDocument([]);
        await getGroupList();
        await getRequestList();
        setFirstTabPage1(true);
        setFirstTabPage2(false);
        setFirstTabPage3(false);
        setFirstTabPage4(false);
      } else {
        alert("필수 서류 요청 메시지 전송에 실패하였습니다. 다시 시도해주세요.");
        setSendDocument([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error : ", error);
      alert("필수 서류 요청 메시지 전송 통신을 실패하였습니다. 다시 시도해주세요.");
      setSendDocument([]);
      setIsLoading(false);
    }
  };

  const secondTabConfirmResendMessege = async () => {
    setIsLoading(true);
    const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/sms";
    try {
      const response = await axios.post(endPoint, {
        companyName: userData.companyName,
        recipientsName: secondTabDocumentItem.name,
        recipientTelNumber: secondTabDocumentItem.telNumber,
        documents: secondTabDocumentItem.documents,
      });
      if (response.data.status === "메세지 재전송 성공") {
        setIsVisibleResendMessege(false);
        alert("필수 서류 요청 메시지를 재전송했습니다.");
        setIsLoading(false);
      } else {
        alert("필수 서류 요청 메시지 재전송에 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error : ", error);
      alert("필수 서류 요청 메시지 재전송 통신을 실패하였습니다. 다시 시도해주세요.");
      setIsLoading(false);
    }
  };

  const secondTabSelectGroup = (selectGroup) => {
    setSecondTabDocumentListName(selectGroup.groupName);
    const matchingObject = requestList.find((item) => item.groupName === selectGroup.groupName);
    if (matchingObject?.requestList?.length > 0) {
      setSecondTabDocumentListArray(matchingObject.requestList);
      setCurrentSelectedGroupDocumentListData(matchingObject.requestList.slice(0, selectedGroupDocumentListItemsPerPage));
      setSecondTabGroupList(false);
      setSecondTabDocumentList(true);
    } else {
      setSecondTabGroupList(false);
      setSecondTabDocumentList(true);
    }
  };

  const secondTabReturnToGroupList = () => {
    setSecondTabDocumentListArray([]);
    setSecondTabDocumentListName("");
    setCurrentSelectedGroupDocumentListData([]);
    setSecondTabGroupList(true);
    setSecondTabDocumentList(false);
  };

  const secondTabSelectDocument = (selectDocument) => {
    setSecondTabDocumentItem(selectDocument);
    setSecondTabDocumentList(false);
    setSecondTabDocumentDetail(true);
  };

  const secondTabReturnToDocumentList = async () => {
    setSecondTabDocumentItem();
    setSecondTabDocumentList(true);
    setSecondTabDocumentDetail(false);
  };

  // const hasDocuments = (item, documentType) => {
  //   return item?.documents && Array.isArray(item.documents) && item.documents.some((document) => document.documentType === documentType);
  // };

  const getDocuments = (item, documentType, single = false) => {
    if (item?.documents && Array.isArray(item.documents)) {
      return single
        ? item.documents.find((document) => document.documentType === documentType) // 단일 문서 찾기
        : item.documents.filter((document) => document.documentType === documentType); // 여러 문서 필터링
    }
    return single ? null : [];
  };

  const hasValidDocument = (item, documentType) => {
    // documentType이 맞는 객체를 찾고, documentType 외의 다른 키가 있는지 확인
    return (
      item &&
      Array.isArray(item.documents) &&
      item.documents.some((document) => document.documentType === documentType && Object.keys(document).some((key) => key !== "documentType"))
    );
  };

  const hasCertificateDocument = hasValidDocument(secondTabDocumentItem, "certificate");
  const certificateDocuments = getDocuments(secondTabDocumentItem, "certificate");

  const hasResumeDocument = hasValidDocument(secondTabDocumentItem, "resume");
  const resumeDocument = getDocuments(secondTabDocumentItem, "resume", true);

  const hasDiplomaDocument = hasValidDocument(secondTabDocumentItem, "diploma");
  const diplomaDocuments = getDocuments(secondTabDocumentItem, "diploma");

  const hasIdentityCardImageDocument = hasValidDocument(secondTabDocumentItem, "identityCardImage");
  const identityCardImageDocument = getDocuments(secondTabDocumentItem, "identityCardImage", true);

  const hasDocumentsArray = (documents) => {
    // documents가 배열인지 확인
    if (!Array.isArray(documents)) return false;

    // 하나라도 documentType 키만 존재하는 객체가 있으면 false 반환
    return documents.every(
      (document) =>
        Object.keys(document).length > 1 || // documentType 외 추가 키가 있는지 확인
        Object.keys(document)[0] !== "documentType" // documentType 키가 없으면 통과
    );
  };

  const openLink = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const confirmAddGroup = async () => {
    setIsLoading(true);
    if (addGroupName.length > 0) {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/group";
      try {
        const response = await axios.post(endPoint, {
          groupName: addGroupName,
          email: userData.companyEmail,
        });
        if (response.data.status === "그룹 생성 성공") {
          alert("새로운 그룹이 추가되었습니다.");
          groupList.push(response.data.data);
          setAddGroupName("");
          setIsLoading(false);
          setIsVisibleAddGroup(false);
          setCurrentRequestGroupListData(groupList.slice(0, requestGroupItemsPerPage));
          setCurrentInquiryGroupListData(groupList.slice(0, inquiryGroupItemsPerPage));
        } else if (response.data.status === "그룹명 중복") {
          alert("중복된 그룹명입니다. 다른 이름으로 생성해주세요.");
          setIsLoading(false);
        } else {
          alert("그룹 추가에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("그룹 추가 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } else {
      alert("그룹명을 입력해주세요.");
    }
  };

  const confirmChangeGroup = async () => {
    if (changeGroupName.length > 0) {
      const matchingObject = groupList.find((item) => item.groupName === changeGroupName);
      const matchingId = matchingObject ? matchingObject.id : null;

      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/recipient/group";
      try {
        const response = await axios.put(endPoint, {
          email: userData.companyEmail,
          recipientsName: secondTabDocumentItem.name,
          recipientTelNumber: secondTabDocumentItem.telNumber,
          newGroupId: matchingId,
          newGroupName: changeGroupName,
        });
        if (response.data.status === "그룹 변경 성공") {
          alert("그룹이 변경되었습니다.");
          setIsVisibleChangeGroup(false);
          setChangeGroupName("");
          setSecondTabDocumentListArray([]);
          setSecondTabDocumentListName("");
          setCurrentSelectedGroupDocumentListData([]);
          await getGroupList();
          await getRequestList();
          setSecondTabDocumentDetail(false);
          setSecondTabGroupList(true);
          setSecondTabDocumentList(false);
          setIsLoading(false);
        } else {
          alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } else {
      alert("변경할 그룹을 선택해주세요.");
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isVisibleAddGroup && (
        <div className="Signin_CompleteModal_BG">
          <div className="CheckList_InfoModal_CT">
            <div className="CheckList_InfoModal_Text_CT">
              <div className="CheckList_InfoModal_Title_CT">
                <div className="CheckList_InfoModal_Title_Copy">그룹 추가</div>
                <div className="CheckList_InfoModal_Icon_CT">
                  <button className="CheckList_Close_Button" onClick={() => setIsVisibleAddGroup(false)}>
                    <Icon name={"Close"} size={"24"} />
                  </button>
                </div>
              </div>
              <div className="CheckList_InfoModal_Divider" />
              <div className="CheckList_InfoModal_Body_Copy">추가할 그룹의 이름을 입력해주세요.</div>
            </div>
            <div className="ProfileDetail_Subject_TBD" style={{ width: "432px", marginBottom: "0px" }}>
              <div className="Profile_Label_CT">
                <div className="Input_Label_Copy">그룹명</div>
                <div className={addGroupName.length > 0 ? "Required_Dot_Blue_TBD" : "Required_Dot_Red_TBD"} />
              </div>
              <input
                className="Leave_LongInput_BG_TBD"
                value={addGroupName}
                onChange={(e) => setAddGroupName(e.target.value)}
                placeholder="예시) 20XX년 상반기 마케팅팀 경력직 모집"
                name="addGroupName"
                style={{ width: "430px" }}
                required
              />
            </div>
            <button className="Modal_CTA_Button" onClick={confirmAddGroup}>
              <div className="Modal_CTA_Button_Label">확인</div>
            </button>
          </div>
        </div>
      )}
      {isVisibleChangeGroup && (
        <div className="Signin_CompleteModal_BG">
          <div className="CheckList_InfoModal_CT">
            <div className="CheckList_InfoModal_Text_CT">
              <div className="CheckList_InfoModal_Title_CT">
                <div className="CheckList_InfoModal_Title_Copy">그룹 변경</div>
                <div className="CheckList_InfoModal_Icon_CT">
                  <button className="CheckList_Close_Button" onClick={() => setIsVisibleChangeGroup(false)}>
                    <Icon name={"Close"} size={"24"} />
                  </button>
                </div>
              </div>
              <div className="CheckList_InfoModal_Divider" />
              <div className="CheckList_InfoModal_Body_Copy">변경할 그룹을 선택해주세요.</div>
            </div>
            <Select
              options={groupListOptions}
              onChange={(values) => setChangeGroupName(values[0].value)}
              values={groupListOptions.filter((option) => option.value === changeGroupName)}
              style={{
                width: 432,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />

            <button className="Modal_CTA_Button" onClick={confirmChangeGroup}>
              <div className="Modal_CTA_Button_Label">확인</div>
            </button>
          </div>
        </div>
      )}
      {isVisibleResendMessege && (
        <div className="Signin_CompleteModal_BG">
          <div className="CheckList_InfoModal_CT">
            <div className="CheckList_InfoModal_Text_CT" style={{ marginBottom: "0px" }}>
              <div className="CheckList_InfoModal_Title_CT">
                <div className="CheckList_InfoModal_Title_Copy">메시지 재전송</div>
                <div className="CheckList_InfoModal_Icon_CT">
                  <button className="CheckList_Close_Button" onClick={() => setIsVisibleResendMessege(false)}>
                    <Icon name={"Close"} size={"24"} />
                  </button>
                </div>
              </div>
              <div className="CheckList_InfoModal_Divider" />
              <div className="CheckList_InfoModal_Body_Copy">
                {secondTabDocumentItem.Name}님에게 필수 서류 전송 요청 메시지를 다시 전송하시겠습니끼?
              </div>
            </div>
            <button className="Modal_CTA_Button" onClick={secondTabConfirmResendMessege}>
              <div className="Modal_CTA_Button_Label">확인</div>
            </button>
          </div>
        </div>
      )}
      <div className="Screen_CT">
        <div className="Contents_Area_Left_Margin" />
        <div className="Contents_Area_CT">
          <PageTitle icon={"RequiredDocument"} title={"필수 서류 받기"} />
          <div className="Widget_CT_TBD">
            <div className="Widget_Title_CT">
              <div className="Widget_Title_Left_Area">
                {tabList.length === 1 && (
                  <button className="TabMenuListWidget_Title_Tab" onClick={onClickFirstTab}>
                    <div className="Widget_Title_Copy" style={{ color: firstTab ? "#464646" : "#AAAAAA" }}>
                      {tabList[0]}
                    </div>
                  </button>
                )}
                {tabList.length === 2 && (
                  <>
                    <button className="TabMenuListWidget_Title_Tab" onClick={onClickFirstTab}>
                      <div className="Widget_Title_Copy" style={{ color: firstTab ? "#464646" : "#AAAAAA" }}>
                        {tabList[0]}
                      </div>
                    </button>
                    <button className="TabMenuListWidget_Title_Tab" onClick={onClickSecondTab}>
                      <div className="Widget_Title_Copy" style={{ color: secondTab ? "#464646" : "#AAAAAA" }}>
                        {tabList[1]}
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="Widget_Divider" />

            {firstTab && firstTabPage1 && (
              <div className="Widget_Contents_CT">
                <div className="Widget_Contents_Upper">
                  <WidgetNavigation nextButtonLabel={"다음"} onClickNextButton={firstTabMoveToPage2} />
                  <WidgetHeader
                    titleText={"필수 서류를 분류할 그룹을 선택해주세요."}
                    bodyText={"필수 서류 전송 요청을 그룹별로 분류하여 관리할 수 있습니다."}
                    pageNumber={"1 / 4"}
                  />
                  {currentRequestGroupListData.map((listItem, index) => (
                    <React.Fragment key={listItem.id}>
                      <div className="Widget_Checkbox_Card_CT" style={{ marginBottom: "12px" }}>
                        <div className="Widget_Checkbox_Card_Left">
                          <div className="Widget_Checkbox_Card_Title_Copy">{listItem.groupName}</div>
                        </div>
                        <div className="Widget_Checkbox_Card_Right">
                          {listItem.creationDate && (
                            <div className="Widget_Checkbox_Card_Sub_Copy" style={{ marginRight: "16px" }}>
                              {AddDotYYYYMMDD(ConvertISOFormatDate(listItem.creationDate))} 생성
                            </div>
                          )}
                          <button className="Widget_Checkbox_Card_Icon" onClick={() => onSelectGroup(listItem)}>
                            <Icon
                              name={selectedGroup && selectedGroup.id === listItem.id ? "CheckBoxFill" : "CheckBoxBlank"}
                              size={"24"}
                              color={selectedGroup && selectedGroup.id === listItem.id ? "#3769fc" : "#aaaaaa"}
                            />
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="Widget_Contents_Lower">
                  {requestGroupPageCount > 1 ? (
                    <Pagination
                      totalItems={groupList.length}
                      itemsPerPage={requestGroupItemsPerPage}
                      onPageChange={handleRequestGroupListPageChange}
                    />
                  ) : null}
                  <div className="Widget_Divider" style={{ marginBottom: "24px", marginTop: "24px" }} />
                  <button className="Widget_Solid_Button" onClick={() => setIsVisibleAddGroup(true)}>
                    <Icon name={"Add"} size={"24"} color={"#3769fc"} />
                    <div className="Widget_Solid_Button_Label">그룹 추가</div>
                  </button>
                </div>
              </div>
            )}
            {firstTab && firstTabPage2 && (
              <div className="Widget_Contents_CT">
                <div className="Widget_Contents_Upper">
                  <WidgetNavigation
                    previousButtonLabel={"이전"}
                    onClickPreviousButton={firstTabMoveToPage1}
                    nextButtonLabel={"다음"}
                    onClickNextButton={firstTabMoveToPage3}
                  />
                  <WidgetHeader
                    titleText={"대상자의 이름과 전화번호를 입력해주세요."}
                    bodyText={"대상자에게 필수 서류 전송을 안내하는 문자메시지를 전송해 드립니다."}
                    pageNumber={"2 / 4"}
                  />
                  <div className="Widget_Scroll_Contents">
                    {resistEmployees.map((employee, index) => (
                      <div key={index} className="Leave_InputField_CT_TBD" style={{ marginTop: "0px" }}>
                        <div className="ProfileDetail_Subject_TBD">
                          <div className="Profile_Label_CT">
                            <div className="Input_Label_Copy">입사자 {index + 1} 이름</div>
                            <div className={employee.nameDone ? "Required_Dot_Blue_TBD" : "Required_Dot_Red_TBD"} />
                          </div>
                          <input
                            className="Leave_LongInput_BG_TBD"
                            value={employee.name}
                            onChange={(e) => handleInputChange(index, "name", e.target.value)}
                            placeholder="입사자의 이름을 입력해주세요."
                            name="employeeName"
                            required
                          />
                        </div>
                        <div className="ProfileDetail_Subject_TBD">
                          <div className="Profile_Label_CT">
                            <div className="Input_Label_Copy">입사자 {index + 1} 전화번호</div>
                            <div className={employee.telNumberDone ? "Required_Dot_Blue_TBD" : "Required_Dot_Red_TBD"} />
                          </div>
                          <input
                            className="Leave_LongInput_BG_TBD"
                            value={employee.telNumber}
                            onChange={(e) => handleInputChange(index, "telNumber", e.target.value)}
                            placeholder="입사자의 전화번호를 입력해주세요. (숫자만 입력해주세요.)"
                            name="telNumber"
                            required
                          />
                        </div>
                        {resistEmployees.length > 1 && (
                          <button
                            className="Widget_Solid_Button"
                            style={{
                              backgroundColor: "#FFE9E9",
                              marginBottom: index === resistEmployees.length - 1 ? "24px" : "0px",
                              width: "574px",
                            }}
                            type="button"
                            onClick={() => removeEmployee(index)}
                          >
                            <Icon name={"Delete"} size={"20"} color={"rgb(255, 115, 115)"} />
                            <div className="Widget_Solid_Button_Label" style={{ color: "rgb(255, 115, 115)", marginLeft: "4px" }}>
                              삭제
                            </div>
                          </button>
                        )}
                        {index !== resistEmployees.length - 1 && <div className="NewEmployee_Divider" />}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="Widget_Contents_Lower">
                  {/* <div className="Widget_Divider" style={{ marginBottom: "24px", width: "574px" }} /> */}
                  <button className="Widget_Solid_Button" type="button" onClick={addEmployee}>
                    <Icon name={"Add"} size={"20"} color={"#3769fc"} />
                    <div className="Widget_Solid_Button_Label" style={{ marginLeft: "4px" }}>
                      입사자 추가
                    </div>
                  </button>
                </div>
              </div>
            )}
            {firstTab && firstTabPage3 && (
              <div className="Widget_Contents_CT">
                <div className="Widget_Contents_Upper">
                  <WidgetNavigation
                    previousButtonLabel={"이전"}
                    onClickPreviousButton={firstTabMoveToPage2}
                    nextButtonLabel={"다음"}
                    onClickNextButton={firstTabMoveToPage4}
                  />
                  <WidgetHeader
                    titleText={"대상자로 부터 전달 받을 서류를 선택해주세요."}
                    bodyText={
                      "대상자에게 필수로 받아야 하는 서류로, 필요하신 서류를 선택하시면\n대상자에게 해당 서류 전달에 대한 안내 문자 메시지가 전송됩니다."
                    }
                    pageNumber={"3 / 4"}
                  />
                  <div className="Leave_InputField_CT_TBD">
                    <div className="CheckList_Divider_TBD" />
                    <div className="CheckList_Comp_CT_TBD">
                      <CheckListLeft2 label={"경력증명서"} isCheckBox={certificate} setIsCheckBox={setCertificate} />
                    </div>
                    <div className="CheckList_Divider_TBD" />
                    <div className="CheckList_Comp_CT_TBD">
                      <CheckListLeft2 label={"이력서"} isCheckBox={resume} setIsCheckBox={setResume} />
                    </div>
                    <div className="CheckList_Divider_TBD" />
                    <div className="CheckList_Comp_CT_TBD">
                      <CheckListLeft2 label={"주민등록증 이미지"} isCheckBox={identityCardImage} setIsCheckBox={setIdentityCardImage} />
                    </div>
                    <div className="CheckList_Divider_TBD" />
                    <div className="CheckList_Comp_CT_TBD">
                      <CheckListLeft2 label={"졸업증명서"} isCheckBox={diploma} setIsCheckBox={setDiploma} />
                    </div>
                    <div className="CheckList_Divider_TBD" />
                  </div>
                </div>
              </div>
            )}
            {firstTab && firstTabPage4 && (
              <div className="Widget_Contents_CT">
                <div className="Widget_Contents_Upper">
                  <WidgetNavigation
                    previousButtonLabel={"이전"}
                    onClickPreviousButton={firstTabMoveToPage3}
                    nextButtonLabel={"전송"}
                    onClickNextButton={firstTabConfirmSendMessege}
                  />

                  <WidgetHeader
                    titleText={
                      resistEmployees.length > 1
                        ? `${resistEmployees[0].name}님 외 ${resistEmployees.length - 1} 명 에게는\n이렇게 메시지가 전달돼요.`
                        : `${resistEmployees[0].name}님 에게는\n이렇게 메시지가 전달돼요.`
                    }
                    pageNumber={"4 / 4"}
                  />
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                    <div className="Widget_Messege_Label">문자메시지 내용</div>
                    <div className="Widget_Messege_BG">
                      <div className="Widget_Messege_Copy">
                        안녕하세요 {resistEmployees[0].name}님, (주) 지디피스튜디오입니다.
                        <br />
                        아래 문서를 써티드 앱을 통해 전송해주세요. 감사합니다.
                        <br />
                        -(주) 지디피스튜디오 드림-
                        <br />
                        <br />
                        [필수 서류 목록]
                        <br />
                        {getRequiredDocuments()
                          .split("\n")
                          .map((item, index) => (
                            <div key={index}>{item}</div>
                          ))}
                        <br />
                        [다운로드 링크]
                        <br />
                        https://certifie.onelink.me/2vIN/landing01
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {secondTab && (
              <div className="Widget_Contents_CT" style={{ justifyContent: secondTabDocumentDetail ? "flex-start" : "space-between" }}>
                {secondTabGroupList && (
                  <>
                    <div className="Widget_Contents_Upper">
                      <div className="ListWidget_Label_CT">
                        <div className="List_Label_CompanyName">그룹명</div>
                        <div className="List_Label_Date">생성일</div>
                      </div>
                      <div className="List_Label_Divider" />

                      {currentInquiryGroupListData.map((listItem) => (
                        <React.Fragment key={listItem.id}>
                          <button className="ReferSelectList_EmployeeList_Button_BG" onClick={() => secondTabSelectGroup(listItem)}>
                            <div className="ListWidget_Body_CT">
                              <div className="List_Body_CompanyName">{listItem?.groupName ? listItem.groupName : "-"}</div>
                              <div className="List_Body_Date">
                                {listItem?.creationDate ? AddDotYYYYMMDD(ConvertISOFormatDate(listItem.creationDate)) : "-"}
                              </div>
                            </div>
                            <div className="List_Body_Divider" />
                          </button>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="Widget_Contents_Lower">
                      {inquiryGroupPageCount > 1 ? (
                        <Pagination
                          totalItems={groupList.length}
                          itemsPerPage={inquiryGroupItemsPerPage}
                          onPageChange={handleInquiryGroupListPageChange}
                        />
                      ) : null}
                    </div>
                  </>
                )}
                {secondTabDocumentList && (
                  <>
                    <div className="Widget_Contents_Upper">
                      <WidgetNavigation previousButtonLabel={secondTabDocumentListName} onClickPreviousButton={secondTabReturnToGroupList} />
                      {secondTabDocumentListArray.length > 0 ? (
                        <>
                          <div className="ListWidget_Label_CT" style={{ marginTop: "24px", justifyContent: "space-between" }}>
                            <div className="ListWidget_Body_Left_CT">
                              <div className="List_Label_UserName">대상자</div>
                              <div className="List_Label_TelNumber">전화번호</div>
                            </div>
                            <div className="ListWidget_Body_Right_CT">
                              <div className="List_Label_Status">상태</div>
                              <div className="List_Label_Date">수신일</div>
                            </div>
                          </div>
                          <div className="List_Label_Divider" />
                          {currentSelectedGroupDocumentListData.map((listItem) => (
                            <React.Fragment key={listItem.ID}>
                              <button className="ReferSelectList_EmployeeList_Button_BG" onClick={() => secondTabSelectDocument(listItem)}>
                                <div className="ListWidget_Body_CT" style={{ justifyContent: "space-between" }}>
                                  <div className="ListWidget_Body_Left_CT">
                                    <div className="List_Body_UserName">{listItem?.name ? listItem.name : "-"}</div>
                                    <div className="List_Body_TelNumber">{listItem?.telNumber ? AddHyphenTelNumber(listItem.telNumber) : "-"}</div>
                                  </div>
                                  <div className="ListWidget_Body_Right_CT">
                                    <div className="List_Body_Status">{listItem?.recieveTime?.length > 0 ? "수신" : "미수신"}</div>
                                    <div className="List_Body_Date">
                                      {listItem?.recieveTime?.length > 0 ? AddDotYYYYMMDD(ConvertISOFormatDate(listItem.recieveTime)) : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="List_Body_Divider" />
                              </button>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <div className="None_List_Copy" style={{ marginTop: "50%" }}>
                          요청 목록이 없습니다.
                        </div>
                      )}
                    </div>
                    <div className="Widget_Contents_Lower">
                      {selectedGroupDocumentListPageCount > 1 ? (
                        <>
                          <Pagination
                            totalItems={secondTabDocumentListArray.length}
                            itemsPerPage={selectedGroupDocumentListItemsPerPage}
                            onPageChange={handleSelectedGroupDocumentListPageChange}
                          />
                        </>
                      ) : null}
                    </div>
                  </>
                )}
                {secondTabDocumentDetail && (
                  <>
                    <WidgetNavigation previousButtonLabel={"상세 정보"} onClickPreviousButton={secondTabReturnToDocumentList} />
                    <div className="Widget_Infomation_CT">
                      <div className="Widget_Infomation_Line_CT" style={{ justifyContent: "flex-start", marginTop: "24px", marginBottom: "12px" }}>
                        <div className="Widget_Infomation_Content_CT">
                          <div className="Widget_Infomation_Label">그룹명</div>
                          <div className="Widget_Infomation_Vertical_Divider" />
                          <div className="Widget_Infomation_Body">{secondTabDocumentListName}</div>
                        </div>
                      </div>
                      <div className="Widget_Infomation_Line_CT" style={{ marginBottom: "12px" }}>
                        <div className="Widget_Infomation_Content_CT">
                          <div className="Widget_Infomation_Label">대상자</div>
                          <div className="Widget_Infomation_Vertical_Divider" />
                          <div className="Widget_Infomation_Body">{secondTabDocumentItem.name}</div>
                        </div>
                        <div className="Widget_Infomation_Content_CT">
                          <div className="Widget_Infomation_Label">전화번호</div>
                          <div className="Widget_Infomation_Vertical_Divider" />
                          <div className="Widget_Infomation_Body">{AddHyphenTelNumber(secondTabDocumentItem.telNumber)}</div>
                        </div>
                      </div>
                      <div className="Widget_Infomation_Line_CT" style={{ marginBottom: "24px" }}>
                        <div className="Widget_Infomation_Content_CT">
                          <div className="Widget_Infomation_Label">요쳥일</div>
                          <div className="Widget_Infomation_Vertical_Divider" />
                          <div className="Widget_Infomation_Body">{AddDotYYYYMMDD(ConvertISOFormatDate(secondTabDocumentItem.requestTime))}</div>
                        </div>
                        <div className="Widget_Infomation_Content_CT">
                          <div className="Widget_Infomation_Label">수신일</div>
                          <div className="Widget_Infomation_Vertical_Divider" />
                          <div className="Widget_Infomation_Body">
                            {secondTabDocumentItem?.recieveDate?.length > 0 ? AddDotYYYYMMDD(secondTabDocumentItem.recieveDate) : "미수신"}
                          </div>
                        </div>
                      </div>
                      <button className="Widget_Solid_Button_Small" onClick={() => setIsVisibleChangeGroup(true)}>
                        <div className="Widget_Solid_Button_Label_Small">그룹 변경</div>
                      </button>
                    </div>
                    <div className="Widget_Divider" style={{ color: "#464646", marginTop: "24px", marginBottom: "24px" }} />

                    {hasDocumentsArray(secondTabDocumentItem) ? (
                      <div className="Widget_Documents_CT">
                        {hasCertificateDocument && (
                          <>
                            <div className="Widget_Documents_Label">경력증명서</div>
                            {certificateDocuments.map((listItem) => (
                              <React.Fragment key={listItem.ID}>
                                <div className="Widget_Documents_Content_CT">
                                  <div className="Widget_Documents_Content_BG">
                                    <div className="Widget_Documents_Body">
                                      {listItem.companyName}{" "}
                                      <span style={{ color: "#AAAAAA" }}>
                                        ({AddDotYYYYMMDD(listItem.acqDate)} ~ {AddDotYYYYMMDD(GetPreviousDay(listItem.loseDate))})
                                      </span>
                                    </div>
                                  </div>
                                  <button
                                    className="Widget_Documents_Content_Button"
                                    onClick={() => {
                                      openLink(listItem.url);
                                    }}
                                  >
                                    PDF
                                  </button>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                        {hasResumeDocument && (
                          <>
                            <div className="Widget_Documents_Label" style={{ marginTop: "12px" }}>
                              이력서
                            </div>
                            <div className="Widget_Documents_Content_CT">
                              <div className="Widget_Documents_Content_BG">
                                <div className="Widget_Documents_Body">{secondTabDocumentItem.name}님의 이력서</div>
                              </div>
                              <button
                                className="Widget_Documents_Content_Button"
                                onClick={() => {
                                  openLink(resumeDocument.url);
                                }}
                              >
                                PDF
                              </button>
                            </div>
                          </>
                        )}
                        {hasDiplomaDocument && (
                          <>
                            <div className="Widget_Documents_Label" style={{ marginTop: "12px" }}>
                              졸업증명서
                            </div>
                            {diplomaDocuments.map((listItem) => (
                              <React.Fragment key={listItem.ID}>
                                <div className="Widget_Documents_Content_CT">
                                  <div className="Widget_Documents_Content_BG">
                                    <div className="Widget_Documents_Body">
                                      {listItem.educationalInstitutionName}{" "}
                                      <span style={{ color: "#AAAAAA" }}>
                                        ({AddDotYYYYMMDD(listItem.admissionDate)} ~ {AddDotYYYYMMDD(listItem.graduationDate)})
                                      </span>
                                    </div>
                                  </div>
                                  <button
                                    className="Widget_Documents_Content_Button"
                                    onClick={() => {
                                      openLink(listItem.url);
                                    }}
                                  >
                                    PDF
                                  </button>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                        {hasIdentityCardImageDocument && (
                          <>
                            <div className="Widget_Documents_Label" style={{ marginTop: "12px" }}>
                              주민등록증 사본 이미지
                            </div>
                            <div className="Widget_Documents_Content_CT">
                              <div className="Widget_Documents_Content_BG">
                                <div className="Widget_Documents_Body">{secondTabDocumentItem.name}님의 주민등록증 사본 이미지</div>
                              </div>
                              <button
                                className="Widget_Documents_Content_Button"
                                onClick={() => {
                                  openLink(identityCardImageDocument.url);
                                }}
                              >
                                이미지
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="Widget_NoDocuments_CT">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "590px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="Widget_None_Contents_Copy">아직 {secondTabDocumentItem.name} 님이 필수 서류를 전송하지 않았습니다.</div>
                          <button className="Widget_Solid_Button_Small" style={{ marginTop: "24px" }} onClick={() => setIsVisibleResendMessege(true)}>
                            <div className="Widget_Solid_Button_Label_Small">메시지 재전송</div>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="Contents_Area_Right_Margin" />
      </div>
    </>
  );
});

export default WBd1RequestRequiredDocument;
