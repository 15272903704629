import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import axios from "axios";
// import ReferSelectList from "../Components/ReferSelectList";
import TabBar from "../Components/TabBar";
// import { Select } from "react-dropdown-select";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
// import { DocumentSelectOption } from "../Utils/KeyArray";
import WidgetList from "../Components/Organism/WidgetList";

const WB81ReferDocumentFullList = observer(() => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const itemData = location?.state?.item;
  // const [filteredDocument, setFilteredDocument] = useState(itemData || "");
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [filteredDocumentData, setFilteredDocumentData] = useState([]);
  const userData = LoadComapanyData("userData");

  // useEffect(() => {
  //   const filteredData =
  //     filteredDocument && filteredDocument !== "AllDocument"
  //       ? employeeData.filter((employee) => employee.DocumentName === filteredDocument)
  //       : employeeData;

  //   setFilteredDocumentData(filteredData);
  // }, [employeeData, filteredDocument]);

  useEffect(() => {
    if (!userData.companyEmail) return;

    const loadEmployeeData = async () => {
      setIsLoading(true);
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/contrack";
      try {
        const response = await axios.get(endPoint, {
          params: { email: userData.companyEmail },
        });
        if (response.data.status === "문서 조회 성공") {
          const rawEmployeeData = response.data.data;
          rawEmployeeData.sort((a, b) => b.CreationDate.localeCompare(a.CreationDate));
          setEmployeeData(rawEmployeeData);
        } else {
          alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
        }
      } catch (error) {
        console.error("Error: ", error);
        alert("직원 정보 조회 통신을 실패하였습니다. 다시 시도해주세요.");
      } finally {
        setIsLoading(false);
      }
    };

    loadEmployeeData();
  }, [userData.companyEmail]);

  const handleItemClick = (certificateData) => {
    navigate(`/referDocumentDetail/${certificateData.DocumentName}`, {
      state: {
        item: certificateData,
        // fillter: filteredDocument,
        route: "full",
      },
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="FlexContent_Other_V" style={{ marginBottom: "192px" }}>
        <div className="AllContent_Default_C">
          <TabBar
            tabLabel1={"referDocumentFullList"}
            tabLink1={"/referDocumentFullList"}
            tabLabel1Text={"전체"}
            tabLabel1AmplitudeText={"문서 조회 (전체) 스크린 진입"}
            tabLabel2={"referDocumentUnsentList"}
            tabLink2={"/referDocumentUnsentList"}
            tabLabel2Text={"미발송"}
            tabLabel2AmplitudeText={"문서 조회 (미발송) 스크린 진입"}
            tabLabel3={"referDocumentWaitList"}
            tabLink3={"/referDocumentWaitList"}
            tabLabel3Text={"대기"}
            tabLabel3AmplitudeText={"문서 조회 (대기) 스크린 진입"}
            tabLabel4={"referDocumentAgreeList"}
            tabLink4={"/referDocumentAgreeList"}
            tabLabel4Text={"완료"}
            tabLabel4AmplitudeText={"문서 조회 (완료) 스크린 진입"}
          />
          <div className="Wait_CT" style={{ alignItems: "flex-start" }}>
            {/* <div style={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
              <Select
                options={DocumentSelectOption}
                onChange={(selectedOption) => {
                  console.log("선택한 옵션 : ", selectedOption);
                  setFilteredDocument(selectedOption[0]?.value || "AllDocument");
                }}
                values={DocumentSelectOption.filter((option) => option.value === filteredDocument)}
                style={{
                  width: 320,
                  height: 32,
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgb(227, 227, 227)",
                  paddingLeft: 12,
                  paddingRight: 12,
                  fontSize: 14,
                }}
              />
            </div> */}
            {!isLoading && employeeData.length > 0 ? (
              // <ReferSelectList
              //   labelList={["이름", "전화번호", "동의 여부", "문서명", "작성일"]}
              //   handleItemClick={handleItemClick}
              //   listData={filteredDocumentData}
              // />
              <WidgetList
                type={"singleSelect"}
                fieldList={["이름", "전화번호", "동의 여부", "문서명", "작성일"]}
                listDataArray={employeeData}
                onClickListItem={handleItemClick}
                itemsPerPage={15}
                height={"586px"}
              />
            ) : (
              <div className="Wait_NoneItem_Copy">문서 정보가 없습니다.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default WB81ReferDocumentFullList;
