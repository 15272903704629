import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Loading from "../Components/Loading";
import TabBar from "../Components/TabBar";
import Icon from "../Assets/Icons/Icon";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WB32AddEmployeeInfo = observer(() => {
  const [name, setName] = useState("");
  const [nameDone, setNameDone] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [telNumberDone, setTelNumberDone] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [birthdayDone, setBirthdayDone] = useState(false);
  const [email, setEmail] = useState("");
  const [emailDone, setEmailDone] = useState(false);
  const [acqDate, setAcqDate] = useState("");
  const [acqDateDone, setAcqDateDone] = useState(false);
  const [position, setPosition] = useState("");
  const [positionDone, setPositionDone] = useState(false);
  const [department, setDepartment] = useState("");
  const [departmentDone, setDepartmentDone] = useState(false);
  const [jobPosition, setJobPosition] = useState("");
  const [jobPositionDone, setJobPositionDone] = useState(false);
  const [job1, setJob1] = useState("");
  const [job2, setJob2] = useState("");
  const [job3, setJob3] = useState("");
  const [job4, setJob4] = useState("");
  const [job5, setJob5] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [isNewLabelVisible, setIsNewLabelVisible] = useState(false);
  const [inputTexts, setInputTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetAllUseState = () => {
    setName("");
    setNameDone(false);
    setTelNumber("");
    setTelNumberDone(false);
    setBirthday("");
    setBirthdayDone(false);
    setEmail("");
    setEmailDone(false);
    setAcqDate("");
    setAcqDateDone(false);
    setDepartment("");
    setDepartmentDone(false);
    setPosition("");
    setPositionDone(false);
    setJobPosition("");
    setJobPositionDone(false);
    setJob1("");
    setJob2("");
    setJob3("");
    setJob4("");
    setJob5("");
    inputTexts.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        obj[key] = "";
      });
    });
  };

  const handleInputChange = (index, key, value) => {
    const newInputTexts = [...inputTexts];
    newInputTexts[index][key] = value;
    setInputTexts(newInputTexts);
  };

  const onClickNext = async () => {
    let errorMessage = "";

    if (name.length === 0) {
      errorMessage += "- 이름을 입력해주세요.\n";
    }
    if (birthday.length === 0) {
      errorMessage += "- 생년월일을 입력해주세요.\n";
    }
    if (email.length === 0) {
      errorMessage += "- 이메일을 입력해주세요.\n";
    }
    if (acqDate.length === 0) {
      errorMessage += "- 입사일을 입력해주세요.\n";
    }
    if (position.length === 0) {
      errorMessage += "- 포지션을 입력해주세요.\n";
    }
    if (department.length === 0) {
      errorMessage += "- 부서를 입력해주세요.\n";
    }
    if (jobPosition.length === 0) {
      errorMessage += "- 직급/직위를 입력해주세요.\n";
    }

    if (errorMessage) {
      alert(errorMessage); // 하나라도 조건을 충족하지 못한 경우, 누적된 메시지를 출력
    } else {
      setIsLoading(true);
      const data = {
        Name: name,
        Birthday: birthday,
        TelNumber: telNumber,
        Email: email,
        AcqDate: acqDate,
        Position: position,
        Department: department,
        JobPosition: jobPosition,
        Job: [
          ...(job1 ? [{ ID: uuidv4(), Job: job1 }] : []),
          ...(job2 ? [{ ID: uuidv4(), Job: job2 }] : []),
          ...(job3 ? [{ ID: uuidv4(), Job: job3 }] : []),
          ...(job4 ? [{ ID: uuidv4(), Job: job4 }] : []),
          ...(job5 ? [{ ID: uuidv4(), Job: job5 }] : []),
        ],
      };
      const mergedData = inputTexts.reduce((acc, item) => {
        return { ...acc, ...item };
      }, data);

      const userData = LoadComapanyData("userData");

      try {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/employee";
        const response = await axios.post(endPoint, {
          email: userData.companyEmail,
          data: mergedData,
        });
        if (response.data.status === "직원 정보 저장 성공") {
          alert("직원 정보 저장에 성공했습니다.");
          resetAllUseState();
        } else if (response.data.status === "동일인물 존재") {
          alert("동일 인물이 존재합니다.");
          resetAllUseState();
        } else {
          alert("직원 정보 저장을 실패하였습니다. 다시 시도해주세요.");
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("직원 정보 저장 통신을 실패하였습니다. 다시 시도해주세요.");
      }
      setIsLoading(false);
    }
  };

  const onClickNewLabelConfirm = () => {
    const newLabelToAdd = newLabel.trim();

    const labelExists = inputTexts.some((input) => Object.keys(input)[0] === newLabelToAdd);
    if (
      labelExists ||
      newLabel === "이름" ||
      newLabel === "생년월일" ||
      newLabel === "전화번호" ||
      newLabel === "이메일" ||
      newLabel === "입사일" ||
      newLabel === "포지션" ||
      newLabel === "부서" ||
      newLabel === "직급/직위" ||
      newLabel === "직무"
    ) {
      setNewLabel("");
      alert("이미 추가된 힝목입니다.");

      return; // 함수 종료
    }
    setInputTexts([...inputTexts, { [newLabel]: "" }]); // 새로운 인풋 필드 추가
    setNewLabel("");
    onClickNewLabelClose();
  };

  const onClickNewLabelOpen = () => {
    setIsNewLabelVisible(true);
  };

  const onClickNewLabelClose = () => {
    setIsNewLabelVisible(false);
  };

  const handleNewLabelInput = (event) => {
    setNewLabel(event.target.value);
  };

  const onClickNewLabelDelete = (index) => {
    const newInputTexts = [...inputTexts];
    newInputTexts.splice(index, 1); // 해당 인덱스의 요소 삭제
    setInputTexts(newInputTexts);
  };

  useEffect(() => {
    const numberPattern = /^\d+$/;
    const isEmailValid = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    };
    if (name.length > 0) {
      setNameDone(true);
    } else {
      setNameDone(false);
    }

    if (telNumber.length > 0 && numberPattern.test(telNumber)) {
      setTelNumberDone(true);
    } else {
      setTelNumberDone(false);
    }

    if (birthday.length === 8 && numberPattern.test(birthday)) {
      setBirthdayDone(true);
    } else {
      setBirthdayDone(false);
    }

    if (email.length > 0 && isEmailValid(email)) {
      setEmailDone(true);
    } else {
      setEmailDone(false);
    }

    if (acqDate.length === 8 && numberPattern.test(acqDate)) {
      setAcqDateDone(true);
    } else {
      setAcqDateDone(false);
    }

    if (position.length > 0) {
      setPositionDone(true);
    } else {
      setPositionDone(false);
    }

    if (department.length > 0) {
      setDepartmentDone(true);
    } else {
      setDepartmentDone(false);
    }

    if (jobPosition.length > 0) {
      setJobPositionDone(true);
    } else {
      setJobPositionDone(false);
    }
  }, [
    name,
    setNameDone,
    birthday,
    setBirthdayDone,
    telNumber,
    setTelNumberDone,
    email,
    setEmailDone,
    acqDate,
    setAcqDateDone,
    position,
    setPositionDone,
    department,
    setDepartmentDone,
    jobPosition,
    setJobPositionDone,
  ]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isNewLabelVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="EmployeeResist_NewLabel_Copy">
                  새로 추가할 항목의
                  <br />
                  이름을 입력해주세요.
                </div>
                <div className="Email_Sub_ETC_Copy">힝목 이름</div>
                <input
                  className="Resend_Textinput"
                  name="newLabel"
                  id="newLabel"
                  placeholder="예시) 연봉"
                  value={newLabel}
                  style={{ width: "286px" }}
                  onChange={handleNewLabelInput}
                  required
                />
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickNewLabelClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickNewLabelConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="FlexContent_Other_V">
        <div className="AllContent_Default_C">
          <TabBar
            tabLabel1={"addEmployeeFile"}
            tabLink1={"/addEmployeeFile"}
            tabLabel1Text={"파일 등록"}
            tabLabel1AmplitudeText={"직원 등록 (엑셀파일)1 - 엑셀 파일 형식 안내 스크린 진입"}
            tabLabel2={"addEmployeeInfo"}
            tabLink2={"/addEmployeeInfo"}
            tabLabel2Text={"직접 입력"}
            tabLabel2AmplitudeText={"직원 등록 (직접등록)1 - 직원 정보 직접 등록 스크린 진입"}
          />
          <div className="AllContent_Default_V2">
            <div className="Leave_LeftArea_CT">
              <div className="Leave_Main_Copy">
                새로 입사한 직원의 정보를 <br />
                직접 입력할 수 있습니다.
              </div>
              <div className="Leave_Sub_Copy">필수 항목을 모두 입력하신 후 등록 버튼을 클릭해주세요.</div>
              <div className="Leave_InputField_CT">
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">이름</div>
                    <div className={nameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="직원의 이름을 입력해주세요."
                    name="name"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">생년월일</div>
                    <div className={birthdayDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="birthday"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">전화번호</div>
                    <div className={telNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={telNumber}
                    onChange={(e) => setTelNumber(e.target.value)}
                    placeholder="전화번호를 입력해주세요. ( - 없이 입력해주세요.)"
                    name="telNumber"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">이메일</div>
                    <div className={emailDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="이메일 주소를 입력해주세요."
                    name="email"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">입사일</div>
                    <div className={acqDateDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={acqDate}
                    onChange={(e) => setAcqDate(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="acqDate"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">포지션</div>
                    <div className={positionDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    placeholder="예시) 프론트앤드 개발자"
                    name="position"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">부서</div>
                    <div className={departmentDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    placeholder="부서를 입력해주세요."
                    name="department"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">직급/직위</div>
                    <div className={jobPositionDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={jobPosition}
                    onChange={(e) => setJobPosition(e.target.value)}
                    placeholder="직급/직위를 입력해주세요."
                    name="jobPosition"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">직무</div>
                  </div>
                  <input
                    className="Leave_LongInput_BG"
                    value={job1}
                    onChange={(e) => setJob1(e.target.value)}
                    placeholder="직무를 입력해주세요."
                    name="job1"
                  />
                  <input
                    className="Leave_LongInput_BG"
                    value={job2}
                    onChange={(e) => setJob2(e.target.value)}
                    placeholder="직무를 입력해주세요."
                    name="job2"
                    style={{ marginTop: 12 }}
                  />
                  <input
                    className="Leave_LongInput_BG"
                    value={job3}
                    onChange={(e) => setJob3(e.target.value)}
                    placeholder="직무를 입력해주세요."
                    name="job3"
                    style={{ marginTop: 12 }}
                  />
                  <input
                    className="Leave_LongInput_BG"
                    value={job4}
                    onChange={(e) => setJob4(e.target.value)}
                    placeholder="직무를 입력해주세요."
                    name="job4"
                    style={{ marginTop: 12 }}
                  />
                  <input
                    className="Leave_LongInput_BG"
                    value={job5}
                    onChange={(e) => setJob5(e.target.value)}
                    placeholder="직무를 입력해주세요."
                    name="job5"
                    style={{ marginTop: 12 }}
                  />
                </div>
                {inputTexts.map((input, index) => (
                  <div className="ProfileDetail_Subject" key={index}>
                    <div className="EmployeeResist_Label_CT">
                      <div className="Profile_Label_Copy">{Object.keys(input)[0]}</div>
                      <button className="EmployeeResist_Button" type="button" onClick={() => onClickNewLabelDelete(index)}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Icon name={"Delete"} size={24} color={"rgb(170, 170, 170)"} />

                          <div className="EmployeeResist_Delete_Copy">삭제</div>
                        </div>
                      </button>
                    </div>
                    <input
                      className="Leave_LongInput_BG"
                      value={Object.values(input)[0]}
                      onChange={(e) => handleInputChange(index, Object.keys(input)[0], e.target.value)}
                      placeholder=""
                      name="inputText"
                    />
                  </div>
                ))}

                <button
                  className="EmployeeResist_NewLabelButton_BG"
                  type="button"
                  onClick={onClickNewLabelOpen}
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "#94BAFD",
                    borderStyle: "dashed",
                    borderRadius: "8px",
                    borderColor: "#94BAFD",
                    borderWidth: "1px",
                    fontSize: "20px",
                  }}
                >
                  <Icon name={"Add"} size={"32"} color={"#94BAFD"} marginRight={"8px"} />새 항목 추가
                </button>
              </div>
            </div>
            <div className="EmployeeResist_Vertical_Divider" />
            <div className="Leave_RightArea_CT">
              <div className="Leave_IconTextButton_CT">
                <button className="Leave_TextButton_Copy" type="button" onClick={onClickNext} style={{ marginRight: 8 }}>
                  등록
                </button>
                <Icon name={"ArrowForward"} size={24} color={"#3769FC"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default WB32AddEmployeeInfo;
