import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const AnnualSalaryContractInfoModal = ({ setAnnualSalaryContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">연봉계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setAnnualSalaryContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            근로자와 근로계약의 내용 중 임금과 관련된 사항을 분리하여 연단위로 임금을 산정(연봉제) 할 경우 연봉계약서를 작성해야 합니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            ​연봉계약서는 근로계약서와 함께 별도로 작성이 필요하기에 연봉제로 임금을 지급하는 회사에서는 근로자 당 근로계약서 1부, 연봉계약서 1부, 총
            2부를 필수로 작성하세요!
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualSalaryContractInfoModal;
