export const LoadComapanyData = (key) => {
  try {
    const companyData = localStorage.getItem(key);
    if (companyData) {
      return JSON.parse(companyData);
    } else {
      console.log("No companyData found for the key");
      return null;
    }
  } catch (error) {
    console.error("Error loading companyData:", error);
    return null;
  }
};
