const RequiredDocument = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14C12.55 14 13.0208 13.8042 13.4125 13.4125C13.8042 13.0208 14 12.55 14 12C14 11.45 13.8042 10.9792 13.4125 10.5875C13.0208 10.1958 12.55 10 12 10C11.45 10 10.9792 10.1958 10.5875 10.5875C10.1958 10.9792 10 11.45 10 12C10 12.55 10.1958 13.0208 10.5875 13.4125C10.9792 13.8042 11.45 14 12 14ZM8 18H16V17.425C16 17.025 15.8917 16.6583 15.675 16.325C15.4583 15.9917 15.1583 15.7417 14.775 15.575C14.3417 15.3917 13.8958 15.25 13.4375 15.15C12.9792 15.05 12.5 15 12 15C11.5 15 11.0208 15.05 10.5625 15.15C10.1042 15.25 9.65833 15.3917 9.225 15.575C8.84167 15.7417 8.54167 15.9917 8.325 16.325C8.10833 16.6583 8 17.025 8 17.425V18ZM18 22H6C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22ZM18 20V8.85L13.15 4H6V20H18Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default RequiredDocument;
