import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB71ResignerSub6 = observer(
  ({
    workIncomeSrc,
    setWorkIncomeSrc,
    salarySrc,
    setSalarySrc,
    severancePaySrc,
    setSeverancePaySrc,
    sealImageSrc,
    setSealImageSrc,
    sealImageSrcDone,
    setSealImageSrcDone,
    setResigner5,
    setResigner6,
    setResigner7,
    seal,
  }) => {
    const workIncomeInput = useRef();
    const salarySrcInput = useRef();
    const severancePayInput = useRef();
    const sealImageInput = useRef();

    const onCickSealImageUpload = async () => {
      sealImageInput.current.click();
    };

    const onSealUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        // 파일 선택 초기화
        e.target.value = null;
      } else {
        setSealImageSrc(e.target.files[0]);
      }
    };

    const onClickWorkIncomeUpload = async () => {
      workIncomeInput.current.value = null;
      workIncomeInput.current.click();
    };
    const onWorkIncomeUpload = (e) => {
      setWorkIncomeSrc(e.target.files[0]);
    };

    const onClickSalaryUpload = async () => {
      salarySrcInput.current.value = null;
      salarySrcInput.current.click();
    };
    const onSalaryUpload = (e) => {
      setSalarySrc(e.target.files[0]);
    };

    const onClickSeverancePayUpload = async () => {
      severancePayInput.current.value = null;
      severancePayInput.current.click();
    };
    const onSeverancePayUpload = (e) => {
      setSeverancePaySrc(e.target.files[0]);
    };

    const onClickNext = () => {
      if (workIncomeSrc.length === 0 || salarySrc.length === 0 || severancePaySrc.length === 0 || (seal === "" && sealImageSrc.length < 1)) {
        alert("필수 파일을 등록해주세요.");
      } else {
        amplitude.track("퇴사 키트 발급5 - 퇴사자 전달 서류 등록 완료", {
          근로소득원천징수영수증: workIncomeSrc,
          급여명세서: salarySrc,
          퇴직금정산내역: severancePaySrc,
          직인: sealImageSrc ? sealImageSrc : "회원 가입시 직인 등록",
        });
        amplitude.track("퇴사 키트 발급6 - 퇴사자 전달 메시지 미리보기 페이지 진입");
        setResigner6(false);
        setResigner7(true);
      }
    };
    const onClickBack = () => {
      amplitude.track("퇴사 키트 발급5 - 퇴사자 퇴사 예정일 등록 페이지 진입");
      setResigner6(false);
      setResigner5(true);
    };
    useEffect(() => {
      if (sealImageSrc) {
        setSealImageSrcDone(true);
      } else {
        setSealImageSrcDone(false);
      }
    }, [sealImageSrc, setSealImageSrcDone]);

    return (
      <div className="AllContent_Default_V2" style={{ marginBottom: "192px" }}>
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">아래의 파일을 모두 등록해주세요.</div>
          <div className="Leave_Sub_Copy">
            퇴사자에게 필수로 전달해야 하는 파일로, 담당 세무사 혹은 사용하고 계시는 HR 시스템에서 발급받으실 수 있습니다.
          </div>
          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">근로소득 원천징수 영수증</div>
                <div className={workIncomeSrc ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <div className="ProfileDetail_UploadButton_CT">
                <input className="ProfileDetail_ShortInput_BG " value={workIncomeSrc ? workIncomeSrc.name : ""} readOnly />
                <input
                  accept=".pdf"
                  type="file"
                  onChange={(e) => onWorkIncomeUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={workIncomeInput}
                  required
                  max-size="5242880"
                  name="license"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onClickWorkIncomeUpload}>
                  파일 업로드
                </button>
              </div>
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">급여 명세서</div>
                <div className={salarySrc ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <div className="ProfileDetail_UploadButton_CT">
                <input className="ProfileDetail_ShortInput_BG " value={salarySrc ? salarySrc.name : ""} readOnly />
                <input
                  accept=".pdf"
                  type="file"
                  onChange={(e) => onSalaryUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={salarySrcInput}
                  required
                  max-size="5242880"
                  name="license"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onClickSalaryUpload}>
                  파일 업로드
                </button>
              </div>
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">퇴직금 정산내역</div>
                <div className={severancePaySrc ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <div className="ProfileDetail_UploadButton_CT">
                <input className="ProfileDetail_ShortInput_BG" value={severancePaySrc ? severancePaySrc.name : ""} readOnly />
                <input
                  accept=".pdf"
                  type="file"
                  onChange={(e) => onSeverancePayUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={severancePayInput}
                  required
                  max-size="5242880"
                  name="license"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onClickSeverancePayUpload}>
                  파일 업로드
                </button>
              </div>
            </div>
            {seal?.length > 0 ? null : (
              <div className="ProfileDetail_Subject">
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">직인</div>
                  <div className="Profile_Label_Copy" style={{ fontWeight: 400, marginLeft: 4, fontSize: 14 }}>
                    (png 파일)
                  </div>
                  <div className={sealImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>

                <div className="ProfileDetail_UploadButton_CT" style={{ marginBottom: 8 }}>
                  <input className="ProfileDetail_ShortInput_BG " value={sealImageSrc ? sealImageSrc.name : ""} readOnly />
                  <input
                    accept=".png"
                    type="file"
                    onChange={(e) => onSealUpload(e)}
                    className="ProfileDetail_UploadButton_None"
                    ref={sealImageInput}
                    max-size="5242880"
                    name="seal"
                  />
                  <button type="button" className="ProfileDetail_UploadButton" onClick={onCickSealImageUpload}>
                    이미지 업로드
                  </button>
                </div>
                <div className="Signin_Match_Copy" style={{ marginTop: 0 }}>
                  증명서를 발급할 때 사용되는 직인 파일을 업로드 해야합니다.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">3/4</div>
        </div>
      </div>
    );
  }
);

export default WB71ResignerSub6;
