import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const WorkScheduleTable = ({ selectedEmployeeData, documentName, handleSelectedEmployeeDataChange }) => {
  return (
    <>
      <table
        className="Email_PDF_Chart_BG"
        style={{
          borderSpacing: "0px",
          borderCollapse: "collapse",
          marginBottom: "8px",
          marginTop: "4px",
        }}
      >
        <tbody>
          <tr>
            <td className="WorkScheduleTable_Cell_Label">요일</td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableDay || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableDay1")}
                placeholder="필수입력"
                name="WorkScheduleTableDay1"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableDay || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableDay2")}
                placeholder="선택입력"
                name="WorkScheduleTableDay2"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableDay || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableDay3")}
                placeholder="선택입력"
                name="WorkScheduleTableDay3"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableDay || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableDay4")}
                placeholder="선택입력"
                name="WorkScheduleTableDay4"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableDay || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableDay5")}
                placeholder="선택입력"
                name="WorkScheduleTableDay5"
              />
            </td>
          </tr>
          <tr>
            <td className="WorkScheduleTable_Cell_Label">근로시간</td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableWorkingHour || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkingHour1")}
                placeholder="필수입력"
                name="WorkScheduleTableWorkingHour1"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableWorkingHour || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkingHour2")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkingHour2"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableWorkingHour || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkingHour3")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkingHour3"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableWorkingHour || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkingHour4")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkingHour4"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableWorkingHour || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkingHour5")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkingHour5"
              />
            </td>
          </tr>
          <tr>
            <td className="WorkScheduleTable_Cell_Label">출근시각</td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableWorkStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkStartTime1")}
                placeholder="필수입력"
                name="WorkScheduleTableWorkStartTime1"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableWorkStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkStartTime2")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkStartTime2"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableWorkStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkStartTime3")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkStartTime3"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableWorkStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkStartTime4")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkStartTime4"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableWorkStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkStartTime5")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkStartTime5"
              />
            </td>
          </tr>
          <tr>
            <td className="WorkScheduleTable_Cell_Label">퇴근시각</td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableWorkEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkEndTime1")}
                placeholder="필수입력"
                name="WorkScheduleTableWorkEndTime1"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableWorkEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkEndTime2")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkEndTime2"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableWorkEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkEndTime3")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkEndTime3"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableWorkEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkEndTime4")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkEndTime4"
              />
            </td>
            <td className="WorkScheduleTable_Cell" style={{ width: "56px" }}>
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableWorkEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableWorkEndTime5")}
                placeholder="선택입력"
                name="WorkScheduleTableWorkEndTime5"
              />
            </td>
          </tr>
          <tr>
            <td className="WorkScheduleTable_Cell_Label">휴게시간</td>
            <td className="WorkScheduleTable_Cell">
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableRestStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestStartTime1")}
                placeholder="부터"
                name="WorkScheduleTableRestStartTime1"
              />
              ~
              <input
                className="WorkScheduleTable_Required_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[0]?.WorkScheduleTableRestEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestEndTime1")}
                placeholder="까지"
                name="WorkScheduleTableRestEndTime1"
              />
            </td>
            <td className="WorkScheduleTable_Cell">
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableRestStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestStartTime2")}
                placeholder="부터"
                name="WorkScheduleTableRestStartTime2"
              />
              ~
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[1]?.WorkScheduleTableRestEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestEndTime2")}
                placeholder="까지"
                name="WorkScheduleTableRestEndTime2"
              />
            </td>
            <td className="WorkScheduleTable_Cell">
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableRestStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestStartTime3")}
                placeholder="부터"
                name="WorkScheduleTableRestStartTime3"
              />
              ~
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[2]?.WorkScheduleTableRestEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestEndTime3")}
                placeholder="까지"
                name="WorkScheduleTableRestEndTime3"
              />
            </td>
            <td className="WorkScheduleTable_Cell">
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableRestStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestStartTime4")}
                placeholder="부터"
                name="WorkScheduleTableRestStartTime4"
              />
              ~
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[3]?.WorkScheduleTableRestEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestEndTime4")}
                placeholder="까지"
                name="WorkScheduleTableRestEndTime4"
              />
            </td>
            <td className="WorkScheduleTable_Cell">
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableRestStartTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestStartTime5")}
                placeholder="부터"
                name="WorkScheduleTableRestStartTime5"
              />
              ~
              <input
                className="WorkScheduleTable_Input_BG"
                value={selectedEmployeeData?.Documents?.[documentName]?.UserWorkInformation[4]?.WorkScheduleTableRestEndTime || ""}
                onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkScheduleTableRestEndTime5")}
                placeholder="까지"
                name="WorkScheduleTableRestEndTime5"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default WorkScheduleTable;
