import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const ContractInput = ({ label, isInputData, onpress }) => {
  return (
    <div className="ProfileDetail_UploadButton_CT">
      <div className="ContractInput_Label_BG">
        <div className="ContractInput_Label_Copy" style={{ fontSize: "16px" }}>
          {label}
        </div>
      </div>
      {isInputData ? (
        <button type="button" className="ProfileDetail_UploadButton" onClick={onpress}>
          정보 수정
        </button>
      ) : (
        <button type="button" className="ContractInput_Button" onClick={onpress}>
          정보 입력
        </button>
      )}
    </div>
  );
};

export default ContractInput;
