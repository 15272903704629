import React from "react";

const BasicText = ({
  text,
  fontSize,
  fontWeight,
  fontColor,
  lineHeight,
  textAlign,
  width,
  height,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}) => {
  return (
    <div
      style={{
        fontFamily: "Pretendard",
        fontStyle: "normal",
        fontSize: fontSize,
        fontWeight: fontWeight === "regular" ? 400 : fontWeight === "semiBold" ? 600 : 400,
        color: fontColor || "#464646",
        lineHeight: lineHeight || "auto",
        textAlign: textAlign || "left",
        width: width || "auto",
        height: height || "auto",
        marginTop: marginTop || "0px",
        marginBottom: marginBottom || "0px",
        marginLeft: marginLeft || "0px",
        marginRight: marginRight || "0px",
        maxWidth: "592px",
      }}
    >
      {text}
    </div>
  );
};

export default BasicText;
