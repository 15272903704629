import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const StockOptionContractInfoModal = ({ setStockOptionContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">주식매수선택권 부여계약서(스톡옵션 계약서)</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setStockOptionContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            회사 임직원에게 스톡옵션을 부여하는 문서로, 스톡옵션을 장기 인센티브 수단으로 활용할 수 있는 문서입니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockOptionContractInfoModal;
