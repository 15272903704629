import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const ParttimeContractInfoModal = ({ setParttimeContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">단시간(아르바이트) 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setParttimeContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            단시간 근로자 채용시 작성하는 단시간 근로계약서에는 근로계약기간, 근로/휴게시간, 임금의 구성항목, 휴일/휴가, 취업장소, 종사업무 등을
            명시해야 하며, 작성하고 교부하지 않으면 벌금이 적용됩니다.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 과태료 500만원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParttimeContractInfoModal;
