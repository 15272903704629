import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const FulltimeContractInfoModal = ({ setFulltimeContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">정규직 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setFulltimeContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            정규직 근로자 채용 시 작성하는 정규직 근로계약서에는 임금의 구성항목, 근로시간, 휴일, 연차/유급휴가 등을 명시해야 하며, 근로계약서 작성 후
            교부하지 않으면 벌금이 적용됩니다.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 벌금 500만원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FulltimeContractInfoModal;
