const ExpandLess = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54968 14.825C6.39968 14.675 6.32051 14.5 6.31218 14.3C6.30385 14.1 6.37468 13.925 6.52468 13.775L11.4747 8.82501C11.558 8.74167 11.6413 8.68334 11.7247 8.65001C11.808 8.61667 11.8997 8.60001 11.9997 8.60001C12.0997 8.60001 12.1913 8.61667 12.2747 8.65001C12.358 8.68334 12.4413 8.74167 12.5247 8.82501L17.4747 13.75C17.6247 13.8833 17.6997 14.0542 17.6997 14.2625C17.6997 14.4708 17.6247 14.65 17.4747 14.8C17.3247 14.95 17.1455 15.025 16.9372 15.025C16.7288 15.025 16.5497 14.95 16.3997 14.8L11.9997 10.425L7.59968 14.85C7.46635 15 7.29551 15.0708 7.08718 15.0625C6.87885 15.0542 6.69968 14.975 6.54968 14.825Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default ExpandLess;
