import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import CertedLogo from "../Assets/Logo/CertedLogo";

const Footer = () => {
  const landingPageUrl = "https://www.certifie.io";

  const moveToLandingPage = () => {
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };

  return (
    <div className="Footer_CT">
      <div className="Footer_Divider" />
      <div className="Footer_Contents_CT">
        <button className="Footer_Button" onClick={moveToLandingPage}>
          <div className="Footer_Logo">
            <CertedLogo />
          </div>
        </button>
        <div className="Footer_Texts">
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">상호명 : 주식회사 지디피스튜디오</div>
          </div>
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">대표자 : 이유 | 사업자 등록번호 : 146-87-02284</div>
          </div>
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">통신판매업신고번호 : 2023-창원성산-0866</div>
          </div>
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">주소 : 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지 G밸리창업큐브 603 (08590)</div>
          </div>
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">이메일 : help@certifie.io | 전화번호 : 010-6212-0225</div>
          </div>
          <div className="Footer_Text">
            <div className="Footer_Text_Copy">ⓒ 2024 GDP Studio Inc. All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
