import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";

import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loading = () => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="Issue_Seal_Modal_CT">
        <PulseLoader
          color="#94BAFD"
          size={10}
          margin={4}
          loading={true}
          speedMultiplier={1}
        />
      </div>
    </div>
  );
};

export default Loading;
