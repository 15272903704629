const ArrowBack = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.18508 12L10.7178 19.5327C10.9165 19.7314 11.0133 19.9654 11.0082 20.2346C11.003 20.5038 10.9011 20.7378 10.7024 20.9365C10.5037 21.1352 10.2697 21.2346 10.0005 21.2346C9.73124 21.2346 9.49728 21.1352 9.29858 20.9365L1.62743 13.2808C1.44663 13.1 1.31264 12.8974 1.22548 12.6731C1.13831 12.4487 1.09473 12.2244 1.09473 12C1.09473 11.7757 1.13831 11.5513 1.22548 11.3269C1.31266 11.1026 1.44664 10.9 1.62743 10.7193L9.29858 3.0481C9.49728 2.84938 9.73381 2.75258 10.0082 2.7577C10.2825 2.76283 10.5191 2.86476 10.7178 3.06348C10.9165 3.26219 11.0159 3.49616 11.0159 3.76538C11.0159 4.03461 10.9165 4.26858 10.7178 4.4673L3.18508 12Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default ArrowBack;
