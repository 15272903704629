import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { Select } from "react-dropdown-select";
import axios from "axios";
import Loading from "../Components/Loading";
import Icon from "../Assets/Icons/Icon";

const WB01SigninSub5 = observer(
  ({
    termsDone,
    companyIdEmail,
    companyPasswordCheck,
    companyNameDone,
    representativeNameDone,
    businessTypeDone,
    employeesNumber,
    companyName,
    employeesNumberDone,
    isRadio4Check,
    isRadio5Check,
    setSignin4,
    setSignin5,
    representativeName,
    businessType,
    sealImageSrc,
    lisenceSrc,
    isModalVisible,
    setIsModalVisible,
    managerNameDone,
    setManagerNameDone,
    managerTelNumber,
    managerName,
    setManagerTelNumber,
    setManagerName,
    managerTelNumberDone,
    setManagerTelNumberDone,
    cleanedTelNumber,
    setCleanedTelNumber,
    managerPosition,
    setManagerPosition,
    managerPositionDone,
    setManagerPositionDone,
    isVisibleManegerEtc,
    setIsVisibleManegerEtc,
    etcManagerPosition,
    setEtcManagerPosition,
    isLoading,
    setIsLoading,
    cleanedResistNumber,
    etcBusinessType,
  }) => {
    const navigate = useNavigate();
    const positionOptions = [
      {
        value: "대표",
        label: "대표",
      },
      {
        value: "경영진",
        label: "경영진",
      },
      {
        value: "인사 팀장",
        label: "인사 팀장",
      },
      {
        value: "인사 팀원",
        label: "인사 팀원",
      },
      {
        value: "경영지원 팀장",
        label: "경영지원 팀장",
      },
      {
        value: "경영지원 팀원",
        label: "경영지원 팀원",
      },
      {
        value: "기타",
        label: "기타",
      },
    ];

    const onClickBack = () => {
      amplitude.track("회원가입4 - 기업/기관 직원수/업종 정보 등록 페이지 진입");
      setSignin5(false);
      setSignin4(true);
    };

    const termsData = { emailTerm: isRadio4Check, snsTerm: isRadio5Check };

    const handleSigninComplete = async (e) => {
      if (
        termsDone === false ||
        companyIdEmail === "" ||
        companyPasswordCheck === "" ||
        !companyNameDone ||
        !representativeNameDone ||
        !employeesNumberDone ||
        !businessTypeDone ||
        managerName === "" ||
        cleanedTelNumber === "" ||
        managerPosition === "" ||
        cleanedResistNumber === ""
      ) {
        alert("앞서 필수적으로 입력해야할 정보 중 생략된 정보가 있습니다.\n 회원가입을 처음부터 다시 진행해주세요.");
      } else {
        setIsLoading(true);

        amplitude.init("9fa2d56b8be8c76c4e7a38a6bb76da6f", companyIdEmail, {
          defaultTracking: false,
          minIdLength: 1,
        });

        e.preventDefault();
        if (managerPosition.length > 0 && managerName.length > 0 && cleanedTelNumber.length > 0) {
          const data = {
            email: companyIdEmail,
            password: companyPasswordCheck,
            terms: termsData,
            companyName: companyName,
            representativeName: representativeName,
            employee: employeesNumber[0].label,
            sectors: etcBusinessType.length > 0 ? etcBusinessType : businessType[0].label,
            managerName: managerName,
            managerTelNumber: cleanedTelNumber,
            managerPosition: etcManagerPosition.length > 0 ? etcManagerPosition : managerPosition[0].label,
            companyRegistrationNumber: cleanedResistNumber,
          };
          const formData = new FormData();

          formData.append("data", JSON.stringify(data));
          if (sealImageSrc) {
            formData.append("seal", sealImageSrc);
          }
          formData.append("businessRegistration", lisenceSrc);

          try {
            const resistEndPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/member/signup";
            await axios.post(resistEndPoint, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              transformRequest: [
                function () {
                  return formData;
                },
              ],
            });
            amplitude.track("회원가입5 - 담당자 정보 등록 완료", {
              담당자명: managerName,
              담당자번호: cleanedTelNumber,
              담당자포지션: etcManagerPosition.length > 0 ? etcManagerPosition : managerPosition[0].label,
            });
            amplitude.track("회원가입5 - 회원가입 완료 모달 팝업");

            setIsLoading(false);
            setIsModalVisible(true);
          } catch (error) {
            console.log("Resist Company Error : ", error);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          alert("담당자님의 정보를 입력해주세요.");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    };

    const onClickSigninCompolete = async () => {
      amplitude.track("홈 화면(증명서 관리 화면) 진입");
      setSignin5(false);
      navigate("/");
    };

    useEffect(() => {
      const numberPattern = /^\d+$/;
      if (managerPosition.length > 0) {
        setManagerPositionDone(true);
      } else {
        setManagerPositionDone(false);
      }
      if (managerName.length > 0) {
        setManagerNameDone(true);
      } else {
        setManagerNameDone(false);
      }
      if (managerTelNumber.length > 0) {
        if (numberPattern.test(managerTelNumber)) {
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(managerTelNumber);
          setCleanedTelNumber(cleanedNumber);
          setManagerTelNumberDone(true);
        } else {
          alert("숫자만 입력해주세요.");
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(managerTelNumber);
          setManagerTelNumber(cleanedNumber);
          setManagerTelNumberDone(false);
        }
      } else {
        setManagerTelNumberDone(false);
      }

      if (managerPosition.length > 0 && managerPosition[0].label !== "기타") {
        setManagerPositionDone(true);
      } else if (managerPosition.length > 0 && managerPosition[0].label === "기타" && etcManagerPosition.length > 0) {
        setManagerPositionDone(true);
      } else {
        setManagerPositionDone(false);
      }

      if (managerPosition[0]?.label && managerPosition[0]?.label === "기타") {
        setIsVisibleManegerEtc(true);
      } else {
        setIsVisibleManegerEtc(false);
      }
    }, [
      managerPosition,
      managerName,
      managerTelNumber,
      etcManagerPosition,
      setManagerPositionDone,
      setManagerNameDone,
      setCleanedTelNumber,
      setManagerTelNumberDone,
      setManagerTelNumber,
      setIsVisibleManegerEtc,
    ]);

    return (
      <>
        {isLoading ? <Loading /> : null}
        {isModalVisible && (
          <div className="Signin_CompleteModal_BG">
            <div className="Signin_CompleteModal_CT">
              <img width={"260px"} src={require("../Assets/Images/SigninImage.png")} alt="SigninImg" />
              <div className="Signin_CompleteModal_Copy">회원가입이 완료되었습니다!</div>

              <div className="Signin_CompleteModal_Button_CT">
                <button className="Signin_CompleteModal_Button" onClick={onClickSigninCompolete}>
                  <div className="Signin_CompleteModal_Button_Copy">확인</div>
                </button>
              </div>
            </div>
          </div>
        )}

        <form className="Signin_Content_V" onSubmit={handleSigninComplete} encType="multipart/form-data">
          <div className="Leave_LeftArea_CT">
            <div className="Leave_Main_Copy">담당자님의 정보를 입력해주세요.</div>
            <div className="Leave_Sub_Copy">담당자 정보는 해당 사업장의 관리자 확인용으로, 그 외의 목적으로 일체 사용되지 않습니다.</div>
            <div className="Leave_InputField_CT">
              {/* 담당자 이름 */}
              <div className="ProfileDetail_Subject ">
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">이름</div>
                  <div className={managerNameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>

                <input
                  className="Signin_LongInput_BG"
                  value={managerName}
                  onChange={(e) => setManagerName(e.target.value)}
                  placeholder="담당자님의 이름을 입력해주세요."
                  name="managerName"
                  required
                />
              </div>

              {/* 담당자 전화번호 */}
              <div className="ProfileDetail_Subject ">
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">전화번호</div>
                  <div className={managerTelNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="Signin_LongInput_BG"
                  value={managerTelNumber}
                  onChange={(e) => setManagerTelNumber(e.target.value)}
                  placeholder="담당자님의 전화번호를 입력해주세요. (숫자만 입력해주세요.)"
                  name="managerTelNumber"
                  required
                />
              </div>
              {/* 담당자 포지션 */}
              <div className="ProfileDetail_Subject " style={{ marginBottom: isVisibleManegerEtc ? 8 : null }}>
                <div className="Profile_Label_CT">
                  <div className="Profile_Label_Copy">포지션</div>
                  <div className={managerPositionDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <Select
                  options={positionOptions}
                  onChange={(values) => setManagerPosition(values)}
                  style={{
                    width: 480,
                    height: 44,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#AAAAAA",
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                />
              </div>
              {isVisibleManegerEtc ? (
                <input
                  className="Signin_LongInput_BG"
                  value={etcManagerPosition}
                  onChange={(e) => setEtcManagerPosition(e.target.value)}
                  placeholder="포지션을 직접 입력해주세요."
                  name="etcManagerPosition"
                  style={{
                    borderColor: "rgb(170, 170, 170)",
                    marginBottom: 24,
                  }}
                  required
                />
              ) : null}
            </div>
          </div>
          <div className="Leave_Vertical_Divider" />
          <div className="Leave_RightArea_CT">
            <div className="Leave_Navigation_CT">
              <div className="Leave_IconTextButton_CT">
                <Icon name={"ArrowBack"} size={24} color={"#3769FC"} />
                <button className="Leave_TextButton_Copy" type="button" onClick={onClickBack}>
                  이전
                </button>
              </div>
              <div className="Leave_IconTextButton_CT">
                <button className="Leave_TextButton_Copy" type="submit">
                  가입 완료
                </button>
                <Icon name={"ArrowForward"} size={24} color={"#3769FC"} />
              </div>
            </div>
            <div className="Leave_PageCount_Copy">5/5</div>
          </div>
        </form>
      </>
    );
  }
);

export default WB01SigninSub5;
