import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const DetailList = ({ state, label, bodyText, detailText, secondBodyText, isExpandMore, setIsExpandMore }) => {
  const iconHandler = !isExpandMore ? "expandMore" : "expandLess";

  const toggleHandler = () => {
    setIsExpandMore(!isExpandMore);
  };

  return (
    <div className="CertificateCard_Content">
      <div className="CertificateCard_Label_Copy">{label}</div>

      <div className="CertificateCard_Body_CT">
        <div className="CertificateCard_BodyLeft">
          <div className="CertificateCard_Body_Copy">{bodyText}</div>
          {detailText ? <div className="CertificateCard_DetailBody_Copy">({detailText})</div> : null}
        </div>
        {state === "extendable" ? (
          <div className="CertificateCard_Icon_CT" onClick={toggleHandler}>
            {iconHandler === "expandMore" ? <Icon name={"ExpandMore"} /> : <Icon name={"ExpandLess"} />}
          </div>
        ) : null}
      </div>

      {isExpandMore ? (
        <div className="CertificateCard_SecondText_CT">
          {secondBodyText.split("\n").map((text, index) => (
            <div key={index} className="CertificateCard_SecondBody_Copy">
              {text}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DetailList;
