import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import * as amplitude from "@amplitude/analytics-browser";

const WB71ResignerSub8 = observer(({ setResigner1, setResigner8, resignerData }) => {
  const onClickConfirm = () => {
    amplitude.track("퇴사 키트 발급1 - 퇴사 키트 발급 완료후 안내 페이지1 진입");
    setResigner8(false);
    setResigner1(true);
  };

  return (
    <>
      <div className="Leave_Guide_Copy">
        {resignerData.Name} 님께 안내메시지와 함께
        <br />
        필수 서류 발급 안내 소식을 전달했어요!
      </div>
      <div className="Leave_Image_CT">
        <img src={require("../Assets/Images/PaperPlane.png")} alt="PaperPlane" width="33%" height="auto" />
      </div>
      <div className="Leave_Sub_Copy" style={{ marginTop: 72 }}>
        조회 탭에서 발급퇴사 키트 목록을
        <br />
        언제든지 조회 할 수 있습니다.
      </div>
      <div className="Leave_TextButton_CT" style={{ justifyContent: "center" }}>
        <div className="Leave_IconTextButton_CT">
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickConfirm}>
            확인
          </button>
          <Icon name={"ChevronRightBlue"} size={"32"} />
        </div>
      </div>
    </>
  );
});

export default WB71ResignerSub8;
