export const GetPreviousDay = (dateStr) => {
  if (dateStr === "" || dateStr === null || dateStr === undefined) return "";

  const year = parseInt(dateStr.slice(0, 4), 10);
  const month = parseInt(dateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
  const day = parseInt(dateStr.slice(6, 8), 10);

  // 로컬 시간으로 날짜 생성
  const date = new Date(year, month, day);
  date.setDate(date.getDate() - 1); // 하루 전으로 설정

  const yearStr = date.getFullYear().toString().padStart(4, "0");
  const monthStr = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const dayStr = date.getDate().toString().padStart(2, "0");

  return `${yearStr}${monthStr}${dayStr}`;
};
