import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const ConfidentialityAgreementInfoModal = ({ setConfidentialityAgreementInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">보안유지서약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setConfidentialityAgreementInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            기업의 생존은 핵심 기술, 영업 노하우에 달려있습니다. 핵심 기술, 영업 노하우를 비밀로 규정하고 업무를 수행하는 사람들에게 알게 되는 비밀을
            지키는 의무를 부과해야 합니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            이 때 가장 확실한 방법이 <span style={{ fontWeight: 600 }}>보안유지서약서</span> 입니다.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 분쟁 시 법적 대응이 어려울 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfidentialityAgreementInfoModal;
