export const DocumentSelectOption = [
  { value: "AllDocument", label: "전체" },
  { value: "OfferLetter", label: "입사확약서" },
  { value: "ConfidentialityAgreement", label: "보안유지서약서" },
  { value: "SalaryContract", label: "연봉계약서" },
  { value: "PrivacyConsent", label: "개인정보 제공 및 수집 이용 동의서" },
  { value: "FulltimeContract", label: "정규직 근로계약서" },
  { value: "TemporaryContract", label: "계약직(기간제) 근로계약서" },
  { value: "ParttimeContract", label: "단시간(아르바이트) 근로계약서" },
  { value: "DailyContract", label: "일용직 근로계약서" },
  { value: "JuvenileContract", label: "청소년(연소자) 근로계약서" },
  { value: "ParentalConsentForm", label: "친권자(후견인) 동의서" },
  { value: "StockOptionContract", label: "주식매수선택권 부여계약서(스톡옵션 계약서)" },
  { value: "StockholdersAgreement", label: "주주간계약서" },
  { value: "PartnershipAgreement", label: "주주간계약서 (동업계약서)" },
];

export const HideMemberDataKeys = [
  "AcqDate",
  "Email",
  "CompanyName",
  "Position",
  "Birthday",
  "TelNumber",
  "Name",
  "Department",
  "JobPosition",
  "ID",
  "Job",
  "Address",
  "Gender",
  "주소",
  "EmployeeStatus",
  "EntityType",
  "PK",
  "SK",
  "OfferLetter",
  "SalaryContract",
  "ConfidentialityAgreement",
  "DailyContract",
  "FulltimeContract",
  "JuvenileContract",
  "ParentalAuthorityConsent",
  "PartnershipAgreement",
  "ParttimeContract",
  "PrivacyConsent",
  "StockOptionContract",
  "TemporaryContract",
  "PartnershipAgreement",
  "PartnershipAgreementSignInfo",
  "StockholdersAgreement",
  "StockholdersAgreementSignInfo",
  "ParentalConsentForm",
  "EarnedIncomeWithholdingTaxReceipt",
  "PayStub",
  "SeverancePaySettlementDetails",
  "CareerCertificatePDF",
  "LoseDate",
];
