import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
// import * as amplitude from "@amplitude/analytics-browser";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import { useQuery } from "@tanstack/react-query";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const selector = "#payment-widget";
const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";
const customerKey = nanoid();

const PaymentProgress = observer(() => {
  const usePaymentWidget = (clientKey, customerKey) => {
    return useQuery({
      queryKey: ["payment-widget", clientKey, customerKey],
      queryFn: () => {
        // ------  결제위젯 초기화 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
        return loadPaymentWidget(clientKey, customerKey);
      },
    });
  };

  const { data: paymentWidget } = usePaymentWidget(clientKey, customerKey);
  const paymentMethodsWidgetRef = useRef(null);
  // const [price, setPrice] = useState(50_000);
  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false);
  // const navigate = useNavigate();
  const screenWidth = window.innerWidth;

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(selector, { value: 1_000_000 }, { variantKey: "DEFAULT" });

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    //  ------  결제 UI 렌더링 완료 이벤트 ------
    paymentMethodsWidget.on("ready", () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      isPaymentMethodsWidgetReady(true);
    });
  }, [paymentWidget]);

  // useEffect(() => {
  //   const paymentMethodsWidget = paymentMethodsWidgetRef.current;

  //   if (paymentMethodsWidget == null) {
  //     return;
  //   }

  //   // ------ 금액 업데이트 ------
  //   // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
  //   paymentMethodsWidget.updateAmount(price);
  // }, [price]);

  const userData = LoadComapanyData("userData");

  return (
    <>
      <div className="FlexContent_Login" style={{ backgroundColor: "rgb(242, 244, 246)" }}>
        <div className="AllContent">
          {/* <div className="PaymentIntro_Title_Copy">
            써티드 1년 이용권을 결제해주세요.
          </div> */}

          <div className="box_section">
            <div id="payment-widget" style={{ width: "540px" }} />
            <div id="agreement" />
            <div style={{ paddingLeft: "24px" }}>
              {/* <label
                  htmlFor="coupon-box"
                  className="checkable__label typography--regular"
                >
                  <input
                    id="coupon-box"
                    className="checkable__input"
                    type="checkbox"
                    aria-checked="true"
                    disabled={!paymentMethodsWidgetReady}
                    onChange={(event) => {
                      setPrice(
                        event.target.checked ? price - 5_000 : price + 5_000
                      );
                    }}
                  />
                  <span className="checkable__label-text">
                    5,000원 쿠폰 적용
                  </span>
                </label> */}
            </div>

            <button
              className="button"
              style={{ marginTop: "30px" }}
              disabled={!paymentMethodsWidgetReady}
              onClick={async () => {
                // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                try {
                  // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                  // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
                  await paymentWidget?.requestPayment({
                    orderId: nanoid(),
                    orderName: "써티드 기업회원 연간 이용료",
                    customerName: userData.companyName,
                    customerEmail: userData.companyEmail,
                    // customerMobilePhone: "01012341234",
                    successUrl: `${window.location.origin}/paymentConfirm`,
                    failUrl: `${window.location.origin}/paymentFail`,
                  });
                } catch (error) {
                  // 에러 처리하기
                  console.error(error);
                }
              }}
            >
              결제하기
            </button>
          </div>
        </div>
        {screenWidth > 1023 && <Footer />}
      </div>
    </>
  );
});

export default PaymentProgress;
