import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { Select } from "react-dropdown-select";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddHyphen13DigitNumber, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";

const StockOptionContract = observer(
  ({
    setStockOptionContractInfo,
    inputCompanyName,
    setInputCompanyName,
    companyAddress,
    setCompanyAddress,
    companyRepresentName,
    setCompanyRepresentName,
    corporationRegistrationNumber,
    setCorporationRegistrationNumber,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const issueStockTypeOptions = [
      {
        value: "common",
        label: "보통주식",
      },
      {
        value: "etc",
        label: "종류주식",
      },
    ];

    const issueStockMethodNumberOptions = [
      {
        value: "1",
        label: "한 가지",
      },
      {
        value: "2",
        label: "두 가지 이상",
      },
    ];

    const exerciseTermTypeOptions = [
      {
        value: "default",
        label: "기본",
      },
      {
        value: "listed",
        label: "상장일 기준",
      },
      {
        value: "outcome",
        label: "누적매출 기준",
      },
    ];

    const checkStockOptionContractData = () => {
      const basicChecks =
        inputCompanyName.length > 0 &&
        companyAddress.length > 0 &&
        corporationRegistrationNumber.length === 13 &&
        companyRepresentName.length > 0 &&
        (selectedEmployeeData?.Address?.length > 0 || selectedEmployeeData?.Documents?.StockOptionContract?.Address?.length > 0) &&
        (selectedEmployeeData?.ResidentRegistrationNumber?.length === 13 ||
          selectedEmployeeData?.Documents?.StockOptionContract?.ResidentRegistrationNumber?.length === 13) &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate?.length === 8 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate?.length === 8 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate?.length === 8;

      if (!basicChecks) {
        return false;
      }

      if (selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "common") {
        return selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount?.length > 0;
      } else if (selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "etc") {
        return (
          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle?.length > 0 &&
          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount?.length > 0
        );
      }

      if (selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "1") {
        return selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article?.length > 0;
      } else if (selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "2") {
        return selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article?.length > 0;
      }

      if (selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "listed") {
        return (
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8 &&
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0
        );
      } else if (selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome") {
        return (
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8 &&
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0 &&
          selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales?.length > 0
        );
      } else if (selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "default") {
        return (
          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate?.length === 8 &&
          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate?.length === 8
        );
      }

      return false;
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 회사명을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 주주총회 결의일을 입력해주세요." : "- 주주총회 결의일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0) {
        errorMessage += errorMessage ? "\n- [제1조]의 교부 주식 종류를 선택해주세요." : "- [제1조]의 교부 주식 종류를 선택해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "common" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount?.length > 0
      ) {
        errorMessage += errorMessage ? "\n- [제1조]의 보통주식 수를 입력해주세요." : "- [제1조]의 보통주식 수를 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "etc" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle?.length > 0
      ) {
        errorMessage += errorMessage ? "\n- [제1조]의 종류주식 근거 정관을 입력해주세요." : "- [제1조]의 종류주식 근거 정관을 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "etc" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount?.length > 0
      ) {
        errorMessage += errorMessage ? "\n- [제1조]의 종류주식 수를 입력해주세요." : "- [제1조]의 종류주식 수를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제2조]의 스톡옵션 부여 근거 정관을 입력해주세요."
          : "- [제2조]의 스톡옵션 부여 근거 정관을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제2조]의 스톡옵션 부여 방법 개수를 선택해주세요."
          : "- [제2조]의 스톡옵션 부여 방법 개수를 선택해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "1" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article?.length > 0
      ) {
        errorMessage += errorMessage
          ? "\n- [제2조]의 부여방법이 한 가지인 경우 근거 정관 입력해주세요."
          : "- [제2조]의 부여방법이 한 가지인 경우 근거 정관 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "2" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article?.length > 0
      ) {
        errorMessage += errorMessage
          ? "\n- [제2조]의 부여방법이 두 가지 이상인 경우 근거 정관 입력해주세요."
          : "- [제2조]의 부여방법이 두 가지 이상인 경우 근거 정관 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- [제3조]의 스톡옵션 부여일을 입력해주세요." : "- [제3조]의 스톡옵션 부여일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice?.length > 0) {
        errorMessage += errorMessage ? "\n- [제4조]의 스톡옵션 행사가격을 입력해주세요." : "- [제4조]의 스톡옵션 행사가격을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제6조 - ①]의 스톡옵션 행사기간 옵션을 선택해주세요."
          : "- [제6조 - ①]의 스톡옵션 행사기간 옵션을 선택해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "default" &&
        selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate?.length !== 8
      ) {
        errorMessage += errorMessage
          ? "\n- [제6조 - ①]의 스톡옵션 행사 시작일을 입력해주세요."
          : "- [제6조 - ①]의 스톡옵션 행사 시작일을 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "default" &&
        selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate?.length !== 8
      ) {
        errorMessage += errorMessage
          ? "\n- [제6조 - ①]의 스톡옵션 행사 종료일을 입력해주세요."
          : "- [제6조 - ①]의 스톡옵션 행사 종료일을 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        (selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "listed" ||
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome") &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length !== 8
      ) {
        errorMessage += errorMessage
          ? "\n- [제6조 - ①]의 스톡옵션 행사 기준일을 입력해주세요."
          : "- [제6조 - ①]의 스톡옵션 행사 기준일을 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        (selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "listed" ||
          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome") &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0
      ) {
        errorMessage += errorMessage ? "\n- [제6조 - ①]의 스톡옵션 행사 기간을 입력해주세요." : "- [제6조 - ①]의 스톡옵션 행사 기간을 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0 &&
        selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome" &&
        !selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales?.length > 0
      ) {
        errorMessage += errorMessage ? "\n- [제6조 - ①]의 누적 매출액을 입력해주세요." : "- [제6조 - ①]의 누적 매출액을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0) {
        errorMessage += errorMessage ? "\n- [제6조 - ②]의 재임/재직 기간을 입력해주세요." : "- [제6조 - ②]의 재임/재직 기간을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제7조 - ①]의 스톡옵션 연간 행사 횟수를 입력해주세요."
          : "- [제7조 - ①]의 스톡옵션 연간 행사 횟수를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제7조 - ②]의 스톡옵션 행사가격 납입처를 입력해주세요."
          : "- [제7조 - ②]의 스톡옵션 행사가격 납입처를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm?.length > 0) {
        errorMessage += errorMessage ? "\n- [제7조 - ③]의 스톡옵션 납입 기한을 입력해주세요." : "- [제7조 - ③]의 스톡옵션 납입 기한을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제7조 - ③]의 신주/자사주식 교부 기한을 입력해주세요."
          : "- [제7조 - ③]의 신주/자사주식 교부 기한을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm?.length > 0) {
        errorMessage += errorMessage ? "\n- [제7조 - ③]의 현금 지급 기한을 입력해주세요." : "- [제7조 - ③]의 현금 지급 기한을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle?.length > 0) {
        errorMessage += errorMessage ? "\n- [제10조]의 사내 인사 징계 규정을 입력해주세요." : "- [제10조]의 사내 인사 징계 규정을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제11조 - ①]의 스톡옵션 부여 이후 합병 기한을 입력해주세요."
          : "- [제11조 - ①]의 스톡옵션 부여 이후 합병 기한을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm?.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제11조 - ②]의 스톡옵션 부여 이후 분할 기한을 입력해주세요."
          : "- [제11조 - ②]의 스톡옵션 부여 이후 분할 기한을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- [갑] 주소를 입력해주세요." : "- [갑] 주소를 입력해주세요.";
      }
      if (!inputCompanyName.length > 0) {
        errorMessage += errorMessage ? "\n- 회사명을 입력해주세요." : "- 회사명을 입력해주세요.";
      }
      if (corporationRegistrationNumber.length !== 13) {
        errorMessage += errorMessage ? "\n- 법인등록번호를 입력해주세요." : "- 법인등록번호를 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage ? "\n- [을] 주소를 입력해주세요." : "- [을] 주소를 입력해주세요.";
      }
      if (selectedEmployeeData?.ResidentRegistrationNumber?.length !== 13) {
        errorMessage += errorMessage ? "\n- [을] 주민등록번호를 입력해주세요." : "- [을] 주민등록번호를 입력해주세요.";
      }
      return errorMessage;
    };

    const splitDateString = (originalString) => {
      const startDateYear = originalString.slice(0, 4);
      const startDateMonth = originalString.slice(4, 6);
      const startDateDay = originalString.slice(6, 8);

      return `${startDateYear}년 ${startDateMonth}월 ${startDateDay}일`;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (nestedField === "IssueStockType") {
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.CommonStockAmount");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.EtcStockAmountArticle");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.EtcStockAmount");
          }
          if (nestedField === "IssueStockMethodNumber") {
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.IssueStockMethod1Article");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.IssueStockMethod2Article");
          }
          if (nestedField === "ExerciseTermType") {
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.StockOptionExerciseStartDate");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.StockOptionExerciseEndDate");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.ExerciseStandardDate");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.ExerciseLimitTerm");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.CumulativeSales");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.ExerciseStandardDate");
            deleteKeyAtPath(selectedEmployeeData, "Documents.StockOptionContract.ExerciseLimitTerm");
          }

          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.StockOptionContract) {
            updatedData.Documents.StockOptionContract = {};
          }
          updatedData.Documents.StockOptionContract[nestedField] = value;
        } else {
          updatedData[field] = value;
        }
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));
        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyAddress");
    };

    const onChangeStateCorporationRegistrationNumberData = (value) => {
      setCorporationRegistrationNumber(value);
      handleSelectedEmployeeDataChange("Documents", value, "CorporationRegistrationNumber");
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeStateResidentRegistrationNumberData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ResidentRegistrationNumber");
      handleSelectedEmployeeDataChange("ResidentRegistrationNumber", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", companyAddress, "CompanyAddress");
      handleSelectedEmployeeDataChange("Documents", corporationRegistrationNumber, "CorporationRegistrationNumber");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
    };

    const onClickConfirmInput = async () => {
      if (checkStockOptionContractData()) {
        setIsLoading(true);

        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);

        if (selectedEmployeeDataCopy.Documents.StockOptionContract.IssueStockType === "common") {
          selectedEmployeeDataCopy.Documents.StockOptionContract.CommonStockAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy.Documents.StockOptionContract.CommonStockAmount
          );
        }
        if (selectedEmployeeDataCopy.Documents.StockOptionContract.IssueStockType === "etc") {
          selectedEmployeeDataCopy.Documents.StockOptionContract.EtcStockAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy.Documents.StockOptionContract.EtcStockAmount
          );
        }
        selectedEmployeeDataCopy.Documents.StockOptionContract.StockOptionExercisePrice = AddCommaAmountNumber(
          selectedEmployeeDataCopy.Documents.StockOptionContract.StockOptionExercisePrice
        );
        if (selectedEmployeeDataCopy.Documents.StockOptionContract.ExerciseTermType === "outcome") {
          selectedEmployeeDataCopy.Documents.StockOptionContract.CumulativeSales = AddCommaAmountNumber(
            selectedEmployeeDataCopy.Documents.StockOptionContract.CumulativeSales
          );
        }
        selectedEmployeeDataCopy.Documents.StockOptionContract.CorporationRegistrationNumber = AddHyphen13DigitNumber(
          selectedEmployeeDataCopy.Documents.StockOptionContract.CorporationRegistrationNumber
        );
        selectedEmployeeDataCopy.Documents.StockOptionContract.ResidentRegistrationNumber = AddHyphen13DigitNumber(
          selectedEmployeeDataCopy.Documents.StockOptionContract.ResidentRegistrationNumber
        );

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/pdf/general/stockoption-contract";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            telNumber: selectedEmployeeDataCopy?.TelNumber,
            name: selectedEmployeeData?.Name,
            data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.StockOptionContract),
            generalDocument: true,
          });
          if (response.data.message === "주식매수선택권 부여 계약서 생성 완료") {
            handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
            setTimeout(async () => {
              setIsLoading(false);
              setStockOptionContractInfo(false);
              alert("주식매수선택권 부여계약서(스톡옵션 계약서) 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setStockOptionContractInfo(false);
            alert("주식매수선택권 부여계약서(스톡옵션 계약서) 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("StockOptionContract ERROR : ", error);
          setIsLoading(false);
          setStockOptionContractInfo(false);
          alert("주식매수선택권 부여계약서(스톡옵션 계약서) 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        setIsLoading(false);
        alert(`${getErrorMessage()}`);
      }
    };

    const deleteKeyAtPath = (obj, path) => {
      if (!obj || typeof obj !== "object" || !path) {
        return;
      }

      const keys = path.split(".");
      const lastKey = keys.pop();

      let currentObj = obj;
      for (const key of keys) {
        if (!currentObj[key] || typeof currentObj[key] !== "object") {
          return;
        }
        currentObj = currentObj[key];
      }

      delete currentObj[lastKey];
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">주식매수선택권 부여계약서(스톡옵션 계약서)</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setStockOptionContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  {inputCompanyName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{inputCompanyName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  (이하 “갑” 이라 한다)와 <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData?.Name || "______ "}</span>
                  (이하 “을”이라 한다)는{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {splitDateString(selectedEmployeeData.Documents.StockOptionContract.ShareholderMeetingResolutionDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일자
                    </span>
                  )}{" "}
                  주주총회 결의에 의하여 “갑”이 “을”에게 주식매수선택권(이하 “스톡옵션”이라 한다)을 부여함에 있어 필요한 사항을 다음 각조와 같이
                  약정한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 조 (교부할 주식의 종류와 수)
                </div>
                {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType &&
                selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "common" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    “을”의 스톡옵션 행사에 대해 “갑”이 교부할 주식은 “갑”이 발행한 보통주식{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {AddCommaAmountNumber(selectedEmployeeData.Documents.StockOptionContract.CommonStockAmount)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ______
                      </span>
                    )}{" "}
                    주로 한다.
                  </div>
                ) : selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "etc" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    “을”의 스톡옵션 행사에 대해 “갑”이 교부할 주식은 “갑”이 발행한 정관{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.EtcStockAmountArticle}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ________
                      </span>
                    )}{" "}
                    의 종류주식{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {AddCommaAmountNumber(selectedEmployeeData.Documents.StockOptionContract.EtcStockAmount)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ______
                      </span>
                    )}{" "}
                    주로 한다.
                  </div>
                ) : (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    <span style={{ color: "rgb(216, 44, 44)" }}>교부 주식의 종류를 선택하세요.</span>
                  </div>
                )}

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (스톡옵션 부여방법)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  “갑”은 정관{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.IssueStockArticle}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  에 따라 “을”에게 스톡옵션을 부여한다.
                </div>
                {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber &&
                selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "1" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    정관{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {selectedEmployeeData.Documents.StockOptionContract.IssueStockMethod1Article}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ________
                      </span>
                    )}{" "}
                    에 따라 유상증자에 의한 신주발행방식으로 한다.
                  </div>
                ) : selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "2" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    “갑”은 정관{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ________
                      </span>
                    )}{" "}
                    에 따라 신주발행, 시가차액보상 방식 중 하나로 “을”에게 스톡옵션을 부여한다. 최종 부여방식은 “을”이 스톡옵션을 행사하는 시점에서
                    이사회의 결정에 따른다.
                  </div>
                ) : (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    <span style={{ color: "rgb(216, 44, 44)" }}>정관에 규정된 스톡옵션 부여 방법의 개수를 선택헤주세요.</span>
                  </div>
                )}

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (부여일)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  스톡옵션의 부여일은{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {splitDateString(selectedEmployeeData.Documents.StockOptionContract.IssueStockDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}
                  로 한다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (행사가격)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  “을”이 부여받는 스톡옵션 행사가격은{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddCommaAmountNumber(selectedEmployeeData.Documents.StockOptionContract.StockOptionExercisePrice)}원
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______ 원
                    </span>
                  )}
                  으로 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (행사가격과 부여할 주식수의 조정)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 스톡옵션 부여일 이후 스톡옵션의 행사전에 “갑”의 자본 또는 주식발행사항에 변동이 있는 경우에는 제4조의 행사가격을 다음 각호와 같이
                  조정한다.
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  1. 준비금을 자본전입(무상증자)하는 경우 : 행사가격을 다음과 같은 산식으로 조정한다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginTop: "8px",
                    fontStyle: "italic",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "rgb(170, 170, 170)",
                    padding: "12px",
                    width: "394px",
                    fontWeight: "600",
                  }}
                >
                  조정후 행사가액 = 조정전행사가액 × (기발행주식수 + 신발행주식수 ×<br />
                  1주당발행가액 / 시가 ) / (기발행주식수 + 신발행주식수)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "8px" }}>
                  2. 주식분할을 하는 경우 : 행사가격은 액면가의 분할비율과 동등한 비율로 감소하고 교부할 주식의 수는 액면가의 분할비율의 역수로
                  증가한다.
                  <br />
                  3. 주식병합을 하는 경우 : 행사가격은 액면가의 병합비율과 동등한 비율로 증가하고 교부할 주식의 수는 액면가의 병합비율의 역수로
                  감소한다.
                  <br />
                  4. 자본감소, 이익소각, 상환주식을 상환항 발행주식총수가 감소하는 경우 : 교부할 주식의 수는 발행주식총수의 감소비율과 같은 비율로
                  감소하고 행사가격은 다음 산식으로 조정한다. 다만 조정후 행사가액은 벤처기업육성에 관한 특별조치법 시행령 제11조의3 제2항의 규정을
                  준용하여 산정한 가액이상이어야 한다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginTop: "8px",
                    fontStyle: "italic",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "rgb(170, 170, 170)",
                    padding: "12px",
                    width: "394px",
                    fontWeight: "600",
                  }}
                >
                  조정후 행사가액 = 조정전행사가액 × (기발행주식수 - 신발행주식수 ×<br />
                  1주당 주주환급가액 / 시가 ) / (기발행주식수 + 신발행주식수)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "8px" }}>
                  ② 제1항에 의한 조정은 제1항의 각호의 사유가 발생한 때에 별도의 절차없이 이루어지는 것으로 하며, 이 경우 “갑”은 “을”에게 지체없이 그
                  내용을 통지해야 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (행사기간)
                </div>

                {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
                selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "listed" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    ① 스톡옵션은 상장일과{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {splitDateString(selectedEmployeeData.Documents.StockOptionContract.ExerciseStandardDate)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}{" "}
                    중 늦은 시점으로부터{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.ExerciseLimitTerm}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        ____
                      </span>
                    )}{" "}
                    이내에 행사해야 하며, 이 기간중 행사하지 아니한 스톡옵션은 부여하지 않은 것으로 본다.
                    <br /> ② “을”은 제3조의 부여일로부터{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.EmployeeTenure}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        ____
                      </span>
                    )}{" "}
                    이상 재임 또는 재직해야 하며, 행사시 회사에 재임중이어야 한다. 다만, “을”이 사망하거나 정년으로 인한 퇴임 또는 퇴직 기타 본인의
                    귀책사유가 아닌 사유로 퇴임 또는 퇴직하는 경우에는 그 행사기간 동안 스톡옵션을 행사할 수 있다.
                    <br />※ 제2항 재임 또는 재직기간은 최소 2년 이상으로 해야함
                  </div>
                ) : selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome" ? (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    ① 스톡옵션은 스톡옵션은 누적 매출액{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {AddCommaAmountNumber(selectedEmployeeData.Documents.StockOptionContract.CumulativeSales)}원
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        _______ 원
                      </span>
                    )}{" "}
                    시점 또는{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {splitDateString(selectedEmployeeData.Documents.StockOptionContract.ExerciseStandardDate)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}{" "}
                    중 늦은 시점으로부터{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.ExerciseLimitTerm} </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        ____
                      </span>
                    )}{" "}
                    이내에 행사해야 하며, 이 기간중 행사하지 아니한 스톡옵션은 부여하지 않은 것으로 본다. <br /> ② “을”은 제3조의 부여일로부터{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.EmployeeTenure}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        ____
                      </span>
                    )}{" "}
                    이상 재임 또는 재직해야 하며, 행사시 회사에 재임중이어야 한다. 다만, “을”이 사망하거나 정년으로 인한 퇴임 또는 퇴직 기타 본인의
                    귀책사유가 아닌 사유로 퇴임 또는 퇴직하는 경우에는 그 행사기간 동안 스톡옵션을 행사할 수 있다.
                    <br />※ 제2항 재임 또는 재직기간은 최소 2년 이상으로 해야함
                  </div>
                ) : (
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    ① 스톡옵션은{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate?.length === 8 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {splitDateString(selectedEmployeeData.Documents.StockOptionContract.StockOptionExerciseStartDate)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}{" "}
                    이후{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate?.length === 8 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {splitDateString(selectedEmployeeData.Documents.StockOptionContract.StockOptionExerciseEndDate)}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}{" "}
                    이내에 행사해야 하며, 이 기간중 행사하지 아니한 스톡옵션은 부여하지 않은 것으로 본다. <br /> ② “을”은 제3조의 부여일로부터{" "}
                    {selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0 ? (
                      <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.EmployeeTenure}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        ____
                      </span>
                    )}{" "}
                    이상 재임 또는 재직해야 하며, 행사시 회사에 재임중이어야 한다. 다만, “을”이 사망하거나 정년으로 인한 퇴임 또는 퇴직 기타 본인의
                    귀책사유가 아닌 사유로 퇴임 또는 퇴직하는 경우에는 그 행사기간 동안 스톡옵션을 행사할 수 있다.
                    <br />※ 제2항 재임 또는 재직기간은 최소 2년 이상으로 해야함
                  </div>
                )}

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 7 조 (행사방법 및 절차)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① “을”은 제6조의 기간내에 제1조가 정한 주식수 또는 제5조에 의해 조정된 주식수의 전부 또는 일부에 관해 스톡옵션을 행사하거나 분할하여
                  행사할 수 있다. 다만, “을”의 연간 행사횟수는{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.ExerciseYearNumber}회</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      __ 회
                    </span>
                  )}
                  로 한다.
                  <br />② “을”은 스톡옵션을 행사하고자 할 경우에는 회사에서 정한 행사신청서 양식에 따라 스톡옵션을 행사하고자 하는 주식의 종류,
                  주식수, 행사일을 기재하고 기명날인 또는 서명을 하여 “갑”에게 제출(2통)하고, 행사가격을{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.PaymentDestination}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  에 납입해야 한다. 다만 ‘갑“이 스톡옵션의 행사가격과 시가와의 차액을 현금으로 지급하거나 자기주식으로 교부하는 경우에는 ”을“은
                  행사신청서만 제출한다.
                  <br />③ “을”은 신청서 접수일로부터{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.PaymentTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  이내에 행사가격을 납입해야 하며, “갑”은 “을”이 행사가격 납입을 완료하면{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.StockIssueTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  이내에 “을”이 청구서에 기재한 계좌로 신주 또는 자시주식을 교부해야 한다. 다만, “을”에게 스톡옵션 행사가격과 시가와의 차액을 현금으로
                  지급하는 경우에는{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.DifferencePaymentTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  이내에 지급한다.
                  <br />④ “을”은 행사시 조세특례제한법에 따라 근로소득세 분할납부 및 근로소득세 또는 양도소득세를 선택할 수 있으며, 이때 “갑”은
                  조세특례제한법에서 정한 절차에 따라 절차를 진행한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 8 조 (스톡옵션 행사의 효력)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  “을”은 제7조 제1항의 납입을 한 때로부터 “갑”의 주주가 된다. 단, 납입한 날이 주주명부의 폐쇄기간중인 경우 스톡옵션의 행사로 주주가 된
                  자는 그 기간중의 주주총회에서 의결권을 행사할 수 없다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 9 조 (양도 및 담보의 제한)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  “을”은 스톡옵션을 양도하거나 담보로 제공해서는 아니된다. 다만, “을”이 사망한 때에는 그 상속인이 스톡옵션을 부여받은 것으로 본다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 10 조 (스톡옵션 부여의 취소)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① “갑”은 다음의 경우 정관이 정하는 바에 따라 이사회의 결의에 의해 스톡옵션 부여를 취소할 수 있다. 이 경우 “갑”은 지체없이 “을”에게
                  스톡옵션의 취소를 통보해야 한다.
                  <br />
                  1. “을”이 스톡옵션을 부여받은 후 2년이내에 임의로 퇴임하거나 퇴직한 경우
                  <br />
                  2. “을”이 고의 또는 과실로 법령 또는 정관에 위반한 행위를 하거나 그 임무를 게을리 하여 “갑”에게 손해를 입힌 경우. 다만 이사 또는
                  감사인 “을”이 제3자에 대하여 상법401조(제3자에 대한 책임)의 책임을 지게 된 경우에도 같다.
                  <br />
                  3. “갑”의 파산 또는 해산 등으로 “갑”이 스톡옵션 행사에 응할 수 없는 경우
                  <br />
                  4. “을”이 상법 제397조(경업금지), 제397조의2(회사의 기회 및 자산의 유용 금지), 제398조(이사 등과 회사 간의 거래) 및
                  제411조(겸임금지)를 위반한 경우
                  <br />
                  5. 주주총회에서 상법 제385조(해임)가 정하는 정당한 사유(기타법령의 규정에 의해 감독기관으로부터 “을”의 해임을 권고 받은 경우를
                  포함한다)로 “을” (이사 또는 감사인 경우에 한한다)을 해임하는 경우
                  <br />
                  6. “을”(종업원인 경우에 한한다)이 “갑”의 인사규정{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {selectedEmployeeData.Documents.StockOptionContract.InHouseDisciplinaryActionArticle}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  에 위반하여 징계 또는 상법 제17조에서 규정하는 경업 또는 겸직을 한 경우
                  <br />
                  7. “을”이 스톡옵션을 타인에게 양도하거나 담보로 제공한 경우
                  <br />
                  8. 주식매수선택권이 압류된 경우
                  <br />
                  9. 주식매수선택권 행사를 위해 “갑”의 미공개 정보를 이용하거나 시세조종 등 불공정 거래를 한 경우
                  <br />② 제1항의 사유의 존부 또는 이사회의 취소의 효력을 다투는 소가 제기되고 이 소송에서 “을”이 승소한 판결이 확정되는 경우에는
                  스톡옵션 부여를 취소한 이사회 결의는 취소시에 소급하여 효력을 상실한다.
                  <br />③ 상법 제385조(해임)에 의해 “을”의 해임을 청구하는 소가 제기되거나, “을”을 이사 또는 감사로 선임한 주주총회의 결의의 효력을
                  다투는 소가 제기된 경우에는 당해 소에 대한 법원의 확정판결이 있을 때까지 “을”은 스톡옵션을 행사할 수 없으며, 당해 소에서 “을”을
                  해임하는 판결이 확정된 경우에는 스톡옵션의 부여를 취소하며, “을”을 선임한 주주총회결의를 취소하는 판결(무효, 부존재판결을
                  포함한다)이 확정되는 경우에는 선택권을 부여하지 않은 것으로 본다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 11 조 (합병, 분할로 인한 승계)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① “갑”이 다른 회사에 흡수합병되는 경우에는 합병계약에 의해 다른 회사가 “을”에 대한 주식교부의무를 승계하지 않는 경우에는 “을”은
                  합병결의 후 2주 내에 스톡옵션을 행사해야한다. 단, 합병이 “을”에 대한 스톡옵션 부여일 이후{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.MergerTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  내에 이루어지는 경우에는 “갑”은 다른 회사가 “을”에 대한 의무를 승계할 것을 합병계약의 내용으로 해야한다.
                  <br />② “갑”이 분할(물적분할을 제외한다)로 인하여 회사를 신설하거나 “갑”의 일부가 다른 회사와 합병하는 경우에는 분할계획 또는
                  분할합병계약에 의해 다른 회사가 스톡옵션 보유자에 대한 의무를 승계하지 않을 경우에는 “을”은 분할계획서 또는 분할합병계약서를
                  승인하는 주주총회의 결의일로부터 2주간 스톡옵션을 행사하여야 한다. 단, 분할이 “을”에 대한 스톡옵션 부여일 이후{" "}
                  {selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm &&
                  selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.StockOptionContract.PartitionTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  내에 이루어지는 경우에는 “갑”은 다른회사가 “을”에 대한 의무를 승계할 것을 분할계획 또는 분할합병계약의 내용으로 해야한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 12 조 (준용)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  이 계약에서 정하지 아니한 사항은 이 계약 체결일 현재 시행중인 관련 법규 및 “갑”의 정관과 당사자간의 합의에 따른다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 13 조 (재판관할)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  이 계약과 관련하여 당사자간에 분쟁이 발생하는 경우, “갑”의 주사무소 소재지 법원을 관할법원으로 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 14 조 (서명날인 및 보관)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  이 계약서는 2부 작성하여 “갑”과 “을”이 서명날인한 후 각 1부씩 보관한다.
                </div>

                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate &&
                    selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate?.length === 8 ? (
                      <span>{splitDateString(selectedEmployeeData.Documents.StockOptionContract.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "32px" }}>
                  갑
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주소 : {companyAddress || ""}
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  회사명 : {inputCompanyName || ""}
                </div>

                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  법인등록번호 : {AddHyphen13DigitNumber(corporationRegistrationNumber) || ""}
                </div>
                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    대표자 : {companyRepresentName || ""}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "24px" }}>
                  을
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주소 : {selectedEmployeeData?.Address || selectedEmployeeData?.Documents?.StockOptionContract?.Address || ""}
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주민등록번호 :{" "}
                  {AddHyphen13DigitNumber(selectedEmployeeData?.ResidentRegistrationNumber) ||
                    AddHyphen13DigitNumber(selectedEmployeeData?.Documents?.StockOptionContract?.ResidentRegistrationNumber) ||
                    ""}
                </div>
                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    성명 : {selectedEmployeeData?.Name || selectedEmployeeData?.Documents?.StockOptionContract?.Name || ""}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday)}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">회사명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="주식회사명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">주주총회 결의일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.ShareholderMeetingResolutionDate || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ShareholderMeetingResolutionDate")}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ShareholderMeetingResolutionDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제1조] 교부 주식 종류</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <Select
                  options={issueStockTypeOptions}
                  // onChange={(values) => setIssueStockType(values)}
                  onChange={(selectedOption) => handleSelectedEmployeeDataChange("Documents", selectedOption[0].value, "IssueStockType")}
                  values={issueStockTypeOptions.filter(
                    (option) => option.value === selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType
                  )}
                  style={{
                    width: 420,
                    height: 44,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgb(227, 227, 227)",
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                />
              </div>
              {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType &&
              selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "common" ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">[제1조] 보통주식 수</div>
                    <div
                      className={
                        selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount &&
                        selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount?.length > 0
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.StockOptionContract?.CommonStockAmount || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "CommonStockAmount")}
                    placeholder="숫자만 입력해주세요."
                    name="CommonStockAmount"
                    required
                  />
                </div>
              ) : null}
              {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType &&
              selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockType === "etc" ? (
                <>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제1조] 종류주식 근거 정관</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle?.length > 0
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmountArticle || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EtcStockAmountArticle")}
                      placeholder="예시) 제 ⃝조 ⃝항"
                      name="EtcStockAmountArticle"
                      required
                    />
                  </div>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제1조] 종류주식 수</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount?.length > 0
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.EtcStockAmount || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EtcStockAmount")}
                      placeholder="숫자만 입력해주세요."
                      name="EtcStockAmount"
                      required
                    />
                  </div>
                </>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제2조] 스톡옵션 부여 근거 정관</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockArticle || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "IssueStockArticle")}
                  placeholder="예시) 제 ⃝조 ⃝항"
                  name="IssueStockArticle"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제2조] 스톡옵션 부여 방법 개수</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <Select
                  options={issueStockMethodNumberOptions}
                  // onChange={(values) => setIssueStockMethodNumber(values)}
                  onChange={(selectedOption) => handleSelectedEmployeeDataChange("Documents", selectedOption[0].value, "IssueStockMethodNumber")}
                  values={issueStockMethodNumberOptions.filter(
                    (option) => option.value === selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber
                  )}
                  style={{
                    width: 420,
                    height: 44,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgb(227, 227, 227)",
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                />
              </div>
              {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber &&
              selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "1" ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">[제2조] 부여방법이 한 가지인 경우 근거 정관</div>
                    <div
                      className={
                        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article &&
                        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article?.length > 0
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod1Article || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "IssueStockMethod1Article")}
                    placeholder="예시) 제 ⃝조 ⃝항"
                    name="IssueStockMethod1Article"
                    required
                  />
                </div>
              ) : null}
              {selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber &&
              selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethodNumber === "2" ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">[제2조] 부여방법이 두 가지 이상인 경우 근거 정관</div>
                    <div
                      className={
                        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article &&
                        selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article?.length > 0
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockMethod2Article || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "IssueStockMethod2Article")}
                    placeholder="예시) 제 ⃝조 ⃝항"
                    name="IssueStockMethod2Article"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제3조] 스톡옵션 부여일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.IssueStockDate || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "IssueStockDate")}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="IssueStockDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제4조] 스톡옵션 행사가격</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExercisePrice || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "StockOptionExercisePrice")}
                  placeholder="숫자만 입력해주세요."
                  name="StockOptionExercisePrice"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사기간 옵션</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <Select
                  options={exerciseTermTypeOptions}
                  // onChange={(values) => setExerciseTermType(values)}
                  onChange={(selectedOption) => handleSelectedEmployeeDataChange("Documents", selectedOption[0].value, "ExerciseTermType")}
                  values={exerciseTermTypeOptions.filter(
                    (option) => option.value === selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType
                  )}
                  style={{
                    width: 420,
                    height: 44,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "rgb(227, 227, 227)",
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                />
              </div>
              {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
              selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "default" ? (
                <>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 시작일</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate?.length === 8
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseStartDate || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "StockOptionExerciseStartDate")}
                      placeholder="YYYYMMDD 형식으로 입력해주세요."
                      name="StockOptionExerciseStartDate"
                      required
                    />
                  </div>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 종료일</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate?.length === 8
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.StockOptionExerciseEndDate || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "StockOptionExerciseEndDate")}
                      placeholder="YYYYMMDD 형식으로 입력해주세요."
                      name="StockOptionExerciseEndDate"
                      required
                    />
                  </div>
                </>
              ) : null}
              {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
              selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "listed" ? (
                <>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 기준일</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ExerciseStandardDate")}
                      placeholder="YYYYMMDD 형식으로 입력해주세요."
                      name="ExerciseStandardDate"
                      required
                    />
                  </div>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 기간</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ExerciseLimitTerm")}
                      placeholder="예시) 3년"
                      name="ExerciseLimitTerm"
                      required
                    />
                  </div>
                </>
              ) : null}
              {selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType &&
              selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseTermType === "outcome" ? (
                <>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 누적 매출액</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales?.length > 0
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.CumulativeSales || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "CumulativeSales")}
                      placeholder="숫자만 입력해주세요."
                      name="CumulativeSales"
                      required
                    />
                  </div>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 기준일</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate?.length === 8
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseStandardDate || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ExerciseStandardDate")}
                      placeholder="YYYYMMDD 형식으로 입력해주세요."
                      name="ExerciseStandardDate"
                      required
                    />
                  </div>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">[제6조 - ①] 스톡옵션 행사 기간</div>
                      <div
                        className={
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm &&
                          selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm?.length > 0
                            ? "Required_Dot_Blue"
                            : "Required_Dot_Red"
                        }
                      />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseLimitTerm || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ExerciseLimitTerm")}
                      placeholder="예시) 3년"
                      name="ExerciseLimitTerm"
                      required
                    />
                  </div>
                </>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제6조 - ②] 재임/재직 기간</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.EmployeeTenure || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeTenure")}
                  placeholder="예시) 2년"
                  name="EmployeeTenure"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제7조 - ①] 스톡옵션 연간 행사 횟수</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.ExerciseYearNumber || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ExerciseYearNumber")}
                  placeholder="숫자만 입력해주세요."
                  name="ExerciseYearNumber"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제7조 - ②] 스톡옵션 행사가격 납입처</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.PaymentDestination || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "PaymentDestination")}
                  placeholder="예시) ⃝⃝은행 ⃝⃝지점"
                  name="PaymentDestination"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제7조 - ③] 스톡옵션 납입 기한</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.PaymentTerm || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "PaymentTerm")}
                  placeholder="예시) 7일"
                  name="PaymentTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제7조 - ③] 신주/자사주식 교부 기한</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.StockIssueTerm || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "StockIssueTerm")}
                  placeholder="예시) 7일"
                  name="StockIssueTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제7조 - ③] 현금 지급 기한</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.DifferencePaymentTerm || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "DifferencePaymentTerm")}
                  placeholder="예시) 7일"
                  name="DifferencePaymentTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제10조] 사내 인사 징계 규정</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.InHouseDisciplinaryActionArticle || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "InHouseDisciplinaryActionArticle")}
                  placeholder="예시) 제 ⃝조 ⃝항"
                  name="InHouseDisciplinaryActionArticle"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제11조 - ①] 스톡옵션 부여 이후 합병 기한</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.MergerTerm || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "MergerTerm")}
                  placeholder="예시) 3년"
                  name="MergerTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제11조 - ②] 스톡옵션 부여 이후 분할 기한</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.PartitionTerm || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "PartitionTerm")}
                  placeholder="예시) 3년"
                  name="PartitionTerm"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate &&
                      selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[갑] 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="회사 주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[갑] 법인등록번호</div>
                  <div className={corporationRegistrationNumber.length === 13 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={corporationRegistrationNumber}
                  onChange={(e) => onChangeStateCorporationRegistrationNumberData(e.target.value)}
                  placeholder="법인등록번호를 입력해주세요."
                  name="CorporationRegistrationNumber"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[갑] 대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[을] 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.StockOptionContract?.Address &&
                        selectedEmployeeData?.Documents?.StockOptionContract?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.StockOptionContract?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="EmployeeAddress"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[을] 주민등록번호</div>
                  <div
                    className={
                      (selectedEmployeeData?.ResidentRegistrationNumber && selectedEmployeeData?.ResidentRegistrationNumber?.length === 13) ||
                      (selectedEmployeeData?.Documents?.StockOptionContract?.ResidentRegistrationNumber &&
                        selectedEmployeeData?.Documents?.StockOptionContract?.ResidentRegistrationNumber?.length === 13)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={
                    selectedEmployeeData?.Documents?.StockOptionContract?.ResidentRegistrationNumber ||
                    selectedEmployeeData?.ResidentRegistrationNumber ||
                    ""
                  }
                  onChange={(e) => onChangeStateResidentRegistrationNumberData(e.target.value)}
                  placeholder="주민등록번호를 입력해주세요."
                  name="EmployeeResidentRegistrationNumber"
                  required
                />
              </div>

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default StockOptionContract;
