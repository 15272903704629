import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React from "react";
import Icon from "../../Assets/Icons/Icon";

const CheckBox = ({ isCheck, setIsCheck, marginRight }) => {
  const onPressCheckBox = () => {
    setIsCheck(!isCheck);
  };
  return (
    <>
      <button type="button" className="TermList_CheckBox_CT" onClick={onPressCheckBox} style={{ marginRight: marginRight ? marginRight : "0px" }}>
        {isCheck === true ? <Icon name={"CheckBoxFill"} size={24} /> : <Icon name={"CheckBoxBlank"} size={24} />}
      </button>
    </>
  );
};

export default CheckBox;
