const PaginationSingleArrowRight = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5998 11.9998L8.6998 8.0998C8.51647 7.91647 8.4248 7.68314 8.4248 7.3998C8.4248 7.11647 8.51647 6.88314 8.6998 6.6998C8.88314 6.51647 9.11647 6.4248 9.3998 6.4248C9.68314 6.4248 9.91647 6.51647 10.0998 6.6998L14.6998 11.2998C14.7998 11.3998 14.8706 11.5081 14.9123 11.6248C14.954 11.7415 14.9748 11.8665 14.9748 11.9998C14.9748 12.1331 14.954 12.2581 14.9123 12.3748C14.8706 12.4915 14.7998 12.5998 14.6998 12.6998L10.0998 17.2998C9.91647 17.4831 9.68314 17.5748 9.3998 17.5748C9.11647 17.5748 8.88314 17.4831 8.6998 17.2998C8.51647 17.1165 8.4248 16.8831 8.4248 16.5998C8.4248 16.3165 8.51647 16.0831 8.6998 15.8998L12.5998 11.9998Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default PaginationSingleArrowRight;
