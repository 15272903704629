import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const PrivacyConsentInfoModal = ({ setPrivacyConsentInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">개인정보활용동의서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setPrivacyConsentInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            회사가 급여지급, 4대보험 신고 등 인사관리의 목적으로 근로자의 개인정보를 활용하기 위해서는 개인정보활용동의서가 필요합니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            근로감독 시 근로자별 근로계약서와 개인정보활용동의서 구비 여부는 함께 준비해야 할 사항이므로 반드시 근로계약 체결 시 개인정보활용동의서도
            같이 서명날인 받으시기 바랍니다.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 과태료 3,000 - 5,000만원 이하
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyConsentInfoModal;
