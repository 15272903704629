import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Loading from "../Components/Loading";
import TabBar from "../Components/TabBar";
// import ReferSelectList from "../Components/ReferSelectList";
import WidgetList from "../Components/Organism/WidgetList";

const WB72ResignerList = observer(() => {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userData = LoadComapanyData("userData");
    const loadList = async () => {
      setIsLoading(true);
      try {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/dev/resigner/list";

        const response = await axios.get(endPoint, {
          params: { email: userData.companyEmail },
        });
        if (response.data.status === "퇴사자 조회 성공") {
          setListData(response.data.data);
          setIsLoading(false);
        } else if (response.data.status === "퇴사자 미존재") {
          setListData([]);
          setIsLoading(false);
        } else {
          alert("퇴사 키트 발급 목록 조회에 실패하였습니다.");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        alert("퇴사 키트 발급 목록 조회 통신에 실패하였습니다.");
        console.log("Resigner List Error : ", error);
      }
    };
    loadList();
  }, []);

  const navigate = useNavigate();

  const handleItemClick = (certificateData) => {
    navigate(`/resignerDetail/${certificateData.ID}`, {
      state: {
        item: certificateData,
      },
    });
  };

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
        <TabBar
          tabLabel1={"resigner"}
          tabLink1={"/resigner"}
          tabLabel1Text={"등록"}
          tabLabel1AmplitudeText={"퇴사 키트 발급1 - 안내 페이지1 진입"}
          tabLabel2={"resignerList"}
          tabLink2={"/resignerList"}
          tabLabel2Text={"조회"}
          tabLabel2AmplitudeText={"퇴사 키트 조회 스크린 진입"}
        />

        <div className="Wait_CT">
          {isLoading ? (
            <div className="Wait_Lists_CT">
              <Loading />
            </div>
          ) : !isLoading && listData.length === 0 ? (
            <div className="Wait_NoneItem_Copy">퇴사 키트 발급 목록이 없습니다.</div>
          ) : !isLoading && listData.length > 0 ? (
            <>
              {/* <ReferSelectList labelList={["이름", "전화번호", "부서", "재직기간", "발급일"]} handleItemClick={handleItemClick} listData={listData} /> */}
              <WidgetList
                type={"singleSelect"}
                fieldList={["이름", "전화번호", "부서", "재직기간", "발급일"]}
                onClickListItem={handleItemClick}
                listDataArray={listData}
                itemsPerPage={10}
                height={"586px"}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
});

export default WB72ResignerList;
