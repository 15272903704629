import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import TermList from "../Components/TermList";
import NavigationPanel from "../Components/NavigationPanel";

const WB01SigninSub1 = observer(
  ({
    isRadioAllCheck,
    setIsRadioAllCheck,
    isRadio1Check,
    setIsRadio1Check,
    isRadio2Check,
    setIsRadio2Check,
    isRadio3Check,
    setIsRadio3Check,
    isRadio4Check,
    setIsRadio4Check,
    isRadio5Check,
    setIsRadio5Check,
    termsDone,
    setTermsDone,
    setSignin1,
    setSignin2,
  }) => {
    const navigate = useNavigate();

    const checkAllHandler = () => {
      if (!isRadioAllCheck) {
        setIsRadio1Check(true);
        setIsRadio2Check(true);
        setIsRadio3Check(true);
        setIsRadio4Check(true);
        setIsRadio5Check(true);
      } else {
        setIsRadio1Check(false);
        setIsRadio2Check(false);
        setIsRadio3Check(false);
        setIsRadio4Check(false);
        setIsRadio5Check(false);
      }
    };

    useEffect(() => {
      if (isRadio1Check && isRadio2Check && isRadio3Check) {
        setTermsDone(true);
      } else {
        setTermsDone(false);
      }

      if (!isRadio1Check || !isRadio2Check || !isRadio3Check || !isRadio4Check || !isRadio5Check) {
        setIsRadioAllCheck(false);
      }
    }, [isRadio1Check, isRadio2Check, isRadio3Check, isRadio4Check, isRadio5Check, setIsRadioAllCheck, setTermsDone]);

    const privacyPolicy = "https://certifie-privacy-policy.vercel.app";
    const termsOfUse = "https://certifie-terms-of-use.vercel.app";

    const onClickNext = () => {
      if (termsDone === true) {
        amplitude.track("회원가입1 - 약관동의 완료", {
          EMAIL약관동의: isRadio4Check,
          SMS약관동의: isRadio5Check,
        });
        amplitude.track("회원가입2 - 이메일/비밀번호 등록 페이지 진입");
        setSignin1(false);
        setSignin2(true);
      } else {
        alert("약관에 동의 해주세요.");
      }
    };

    const onClickBack = () => {
      amplitude.track("로그인 - 로그인 페이지 진입");
      navigate("/");
    };

    return (
      <>
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">회원가입 약관에 동의해주세요.</div>
          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">약관</div>
                <div className={termsDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <div className="TermLists_BG">
                <TermList
                  bodyText={"필수동의 항목 및 광고성 정보 수신(선택)에 모두 동의합니다."}
                  twoLine
                  isCheckBox={isRadioAllCheck}
                  setIsCheckBox={setIsRadioAllCheck}
                  checkAll
                  checkAllHandler={checkAllHandler}
                />
                <div className="TermLists_Line" />
                <TermList required bodyText={"만 15세 이상입니다."} isCheckBox={isRadio1Check} setIsCheckBox={setIsRadio1Check} />
                <TermList
                  required
                  bodyText={"서비스 이용약관에 동의합니다."}
                  detail
                  detailLink={termsOfUse}
                  isCheckBox={isRadio2Check}
                  setIsCheckBox={setIsRadio2Check}
                />
                <TermList
                  required
                  bodyText={"개인정보 수집 및 이용에 동의합니다."}
                  detail
                  detailLink={privacyPolicy}
                  isCheckBox={isRadio3Check}
                  setIsCheckBox={setIsRadio3Check}
                />
                <div className="TermLists_Line2" />
                <TermList
                  optional
                  bodyText={"광고성 정보 이메일 수신에 동의합니다."}
                  detail
                  detailLink={privacyPolicy}
                  isCheckBox={isRadio4Check}
                  setIsCheckBox={setIsRadio4Check}
                />
                <TermList
                  optional
                  bodyText={"광고성 정보 SMS 수신에 동의합니다."}
                  detail
                  detailLink={privacyPolicy}
                  isCheckBox={isRadio5Check}
                  setIsCheckBox={setIsRadio5Check}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">1/5</div>
        </div>
      </>
    );
  }
);

export default WB01SigninSub1;
