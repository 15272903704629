import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";
import DetailTitle from "../Components/DetailTitle";

const WB93ReferEmployeeModify = observer(() => {
  const location = useLocation();
  const itemData = location.state.item;
  const navigate = useNavigate();
  const [name, setName] = useState(itemData.Name || "");
  const [nameDone, setNameDone] = useState(false);
  const originalName = itemData.Name;
  const [telNumber, setTelNumber] = useState(itemData.TelNumber || "");
  const [telNumberDone, setTelNumberDone] = useState(false);
  const originalTelNumber = itemData.TelNumber;
  const [birthday, setBirthday] = useState(itemData.Birthday || "");
  const [birthdayDone, setBirthdayDone] = useState(false);
  const [email, setEmail] = useState(itemData.Email || "");
  const [emailDone, setEmailDone] = useState(false);
  const [acqDate, setAcqDate] = useState(itemData.AcqDate || "");
  const [acqDateDone, setAcqDateDone] = useState(false);
  const [position, setPosition] = useState(itemData.Position || "");
  const [positionDone, setPositionDone] = useState(false);
  const [department, setDepartment] = useState(itemData.Department || "");
  const [departmentDone, setDepartmentDone] = useState(false);
  const [jobPosition, setJobPosition] = useState(itemData.JobPosition || "");
  const [jobPositionDone, setJobPositionDone] = useState(false);
  const jobArray = itemData?.Job || [];
  const [job1, setJob1] = useState(jobArray[0]?.Job || "");
  const [job2, setJob2] = useState(jobArray[1]?.Job || "");
  const [job3, setJob3] = useState(jobArray[2]?.Job || "");
  const [job4, setJob4] = useState(jobArray[3]?.Job || "");
  const [job5, setJob5] = useState(jobArray[4]?.Job || "");
  const [newLabel, setNewLabel] = useState("");
  const [isNewLabelVisible, setIsNewLabelVisible] = useState(false);
  //   const [inputTexts, setInputTexts] = useState([]);
  const [extraLabels, setExtraLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkFormCompletion = () => {
    const messages = [];

    if (!nameDone) messages.push("- 이름을 입력해주세요.");
    if (!telNumberDone) messages.push("- 전화번호를 입력해주세요.");
    if (!birthdayDone) messages.push("- 생년월일을 입력해주세요.");
    if (!emailDone) messages.push("- 이메일을 입력해주세요.");
    if (!acqDateDone) messages.push("- 입사일을 입력해주세요.");
    if (!positionDone) messages.push("- 포지션을 입력해주세요.");
    if (!departmentDone) messages.push("- 부서를 입력해주세요.");
    if (!jobPositionDone) messages.push("- 직급/직위를 입력해주세요.");

    if (messages.length > 0) {
      alert(messages.join("\n"));
      return false;
    }

    return true;
  };

  const handleButtonClick = () => {
    if (checkFormCompletion()) {
      onClickModify();
    }
  };

  const resetAllUseState = () => {
    setName("");
    setNameDone(false);
    setTelNumber("");
    setTelNumberDone(false);
    setBirthday("");
    setBirthdayDone(false);
    setEmail("");
    setEmailDone(false);
    setAcqDate("");
    setAcqDateDone(false);
    setDepartment("");
    setDepartmentDone(false);
    setPosition("");
    setPositionDone(false);
    setJobPosition("");
    setJobPositionDone(false);
    setJob1("");
    setJob2("");
    setJob3("");
    setJob4("");
    setJob5("");
    extraLabels.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        obj[key] = "";
      });
    });
  };

  const handleInputChange = (index, key, value) => {
    const newExtraLabels = [...extraLabels];
    newExtraLabels[index][key] = value;
    setExtraLabels(newExtraLabels);
  };

  const updateData = (itemData, mergedData) => {
    const updatedData = { ...itemData };
    for (const key in mergedData) {
      if (mergedData.hasOwnProperty(key)) {
        updatedData[key] = mergedData[key];
      }
    }

    return updatedData;
  };

  const getChangedValues = (original, updated) => {
    const changedValues = {};
    for (const key in updated) {
      if (key === "Job") {
        const originalJob = original[key] || [];
        const updatedJob = updated[key] || [];
        if (originalJob.length !== updatedJob.length || !originalJob.every((obj, index) => obj.Job === updatedJob[index].Job)) {
          changedValues[key] = updatedJob;
        }
      } else if (JSON.stringify(original[key]) !== JSON.stringify(updated[key])) {
        changedValues[key] = updated[key];
      }
    }
    return changedValues;
  };

  const onClickModify = async () => {
    setIsLoading(true);
    const originalData = { ...itemData };
    const data = {
      Name: name,
      Birthday: birthday,
      TelNumber: telNumber,
      Email: email,
      AcqDate: acqDate,
      Position: position,
      Department: department,
      JobPosition: jobPosition,
      Job: [
        ...(job1 ? [{ ID: uuidv4(), Job: job1 }] : []),
        ...(job2 ? [{ ID: uuidv4(), Job: job2 }] : []),
        ...(job3 ? [{ ID: uuidv4(), Job: job3 }] : []),
        ...(job4 ? [{ ID: uuidv4(), Job: job4 }] : []),
        ...(job5 ? [{ ID: uuidv4(), Job: job5 }] : []),
      ],
    };
    const mergedData = extraLabels.reduce((acc, item) => {
      return { ...acc, ...item };
    }, data);
    const changedData = getChangedValues(originalData, mergedData);
    const userData = LoadComapanyData("userData");
    try {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/employee";
      const response = await axios.put(endPoint, {
        email: userData.companyEmail,
        name: originalName,
        telNumber: originalTelNumber,
        updateInfo: changedData,
      });
      if (response.data.status === "사용자 정보 수정 성공") {
        alert("직원 정보 수정에 성공했습니다.");
        resetAllUseState();
        setIsLoading(false);
        navigate(`/referEmployeeDetail/${itemData.ID}`, {
          state: {
            item: updateData(itemData, mergedData),
            from: "Modify",
          },
        });
      } else {
        alert("직원 정보 저장을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Employee Data Modify Error : ", error);
      alert("직원 정보 저장 통신을 실패하였습니다. 다시 시도해주세요.");
      setIsLoading(false);
    }
  };

  const onClickNewLabelConfirm = () => {
    const newLabelToAdd = newLabel.trim();

    const labelExists = extraLabels.some((input) => Object.keys(input)[0] === newLabelToAdd);
    if (
      labelExists ||
      newLabel === "이름" ||
      newLabel === "생년월일" ||
      newLabel === "전화번호" ||
      newLabel === "이메일" ||
      newLabel === "입사일" ||
      newLabel === "포지션" ||
      newLabel === "부서" ||
      newLabel === "직급/직위" ||
      newLabel === "직무"
    ) {
      setNewLabel("");
      alert("이미 추가된 힝목입니다.");

      return;
    }
    setExtraLabels([...extraLabels, { [newLabel]: "" }]);
    setNewLabel("");
    onClickNewLabelClose();
  };

  const onClickNewLabelOpen = () => {
    setIsNewLabelVisible(true);
  };

  const onClickNewLabelClose = () => {
    setIsNewLabelVisible(false);
  };

  const handleNewLabelInput = (event) => {
    setNewLabel(event.target.value);
  };

  const onClickNewLabelDelete = (index) => {
    const newExtraLabels = [...extraLabels];
    newExtraLabels.splice(index, 1);
    setExtraLabels(newExtraLabels);
  };

  useEffect(() => {
    const numberPattern = /^\d+$/;
    const isEmailValid = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    };
    if (name.length > 0) {
      setNameDone(true);
    } else {
      setNameDone(false);
    }

    if (telNumber.length > 0 && numberPattern.test(telNumber)) {
      setTelNumberDone(true);
    } else {
      setTelNumberDone(false);
    }

    if (birthday.length === 8 && numberPattern.test(birthday)) {
      setBirthdayDone(true);
    } else {
      setBirthdayDone(false);
    }

    if (email.length > 0 && isEmailValid(email)) {
      setEmailDone(true);
    } else {
      setEmailDone(false);
    }

    if (acqDate.length === 8 && numberPattern.test(acqDate)) {
      setAcqDateDone(true);
    } else {
      setAcqDateDone(false);
    }

    if (position.length > 0) {
      setPositionDone(true);
    } else {
      setPositionDone(false);
    }

    if (department.length > 0) {
      setDepartmentDone(true);
    } else {
      setDepartmentDone(false);
    }

    if (jobPosition.length > 0) {
      setJobPositionDone(true);
    } else {
      setJobPositionDone(false);
    }
  }, [
    name,
    setNameDone,
    birthday,
    setBirthdayDone,
    telNumber,
    setTelNumberDone,
    email,
    setEmailDone,
    acqDate,
    setAcqDateDone,
    position,
    setPositionDone,
    department,
    setDepartmentDone,
    jobPosition,
    setJobPositionDone,
  ]);

  useEffect(() => {
    const keysToExclude = [
      "AcqDate",
      "Birthday",
      "Department",
      "Email",
      "Job",
      "Position",
      "JobPosition",
      "Name",
      "TelNumber",
      "PK",
      "SK",
      "CompanyName",
      "EntityType",
      "EmployeeStatus",
      "ID",
      "OfferLetter",
      "ConfidentialityAgreement",
      "FulltimeContract",
      "TemporaryContract",
      "ParttimeContract",
      "DailyContract",
      "JuvenileContract",
      "SalaryContract",
      "PrivacyConsent",
      "ParentalConsentForm",
      "StockOptionContract",
      "StockholdersAgreement",
      "PartnershipAgreement",
      "PartnershipAgreementSignInfo",
      "StockholdersAgreementSignInfo",
    ];

    const filteredInputTexts = Object.keys(itemData)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => ({ [key]: itemData[key] }));

    setExtraLabels(filteredInputTexts);
  }, [itemData]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isNewLabelVisible && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="EmployeeResist_NewLabel_Copy">
                  새로 추가할 항목의
                  <br />
                  이름을 입력해주세요.
                </div>
                <div className="Email_Sub_ETC_Copy">힝목 이름</div>
                <input
                  className="Resend_Textinput"
                  name="newLabel"
                  id="newLabel"
                  placeholder="예시) 연봉"
                  value={newLabel}
                  style={{ width: "286px" }}
                  onChange={handleNewLabelInput}
                  required
                />
              </div>
              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickNewLabelClose}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    취소
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickNewLabelConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="FlexContent_Other_V">
        <DetailTitle title={"직원 정보 수정"} />
        <div className="AllContent_Default_C" style={{ alignItems: "flex-start" }}>
          <div className="Detail_Modify_Title_BG">
            <div className="Detail_Title_Contents">
              <div className="Detail_Title_LeftContents">
                <div className="Detail_Title_ContentBox">
                  <div className="EmployeeDetail_Title_Content_Copy">{itemData.Name} 님의 상세 정보</div>
                </div>
              </div>
              <div className="Detail_Title_RightContents">
                <button type="button" className="TitleBG_Button" onClick={handleButtonClick}>
                  수정 완료
                </button>
              </div>
            </div>
          </div>

          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">이름</div>
                <div className={nameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="직원의 이름을 입력해주세요."
                name="name"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">생년월일</div>
                <div className={birthdayDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                placeholder="YYYYMMDD 형식으로 입력해주세요."
                name="birthday"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">전화번호</div>
                <div className={telNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={telNumber}
                onChange={(e) => setTelNumber(e.target.value)}
                placeholder="전화번호를 입력해주세요. ( - 없이 입력해주세요.)"
                name="telNumber"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">이메일</div>
                <div className={emailDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="이메일 주소를 입력해주세요."
                name="email"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">입사일</div>
                <div className={acqDateDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={acqDate}
                onChange={(e) => setAcqDate(e.target.value)}
                placeholder="YYYYMMDD 형식으로 입력해주세요."
                name="acqDate"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">포지션</div>
                <div className={positionDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                placeholder="예시) 프론트앤드 개발자"
                name="position"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">부서</div>
                <div className={departmentDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                placeholder="부서를 입력해주세요."
                name="department"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">직급/직위</div>
                <div className={jobPositionDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Leave_LongInput_BG"
                value={jobPosition}
                onChange={(e) => setJobPosition(e.target.value)}
                placeholder="직급/직위를 입력해주세요."
                name="jobPosition"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">직무</div>
              </div>
              <input
                className="Leave_LongInput_BG"
                value={job1}
                onChange={(e) => setJob1(e.target.value)}
                placeholder="직무를 입력해주세요."
                name="job1"
              />
              <input
                className="Leave_LongInput_BG"
                value={job2}
                onChange={(e) => setJob2(e.target.value)}
                placeholder="직무를 입력해주세요."
                name="job2"
                style={{ marginTop: 12 }}
              />
              <input
                className="Leave_LongInput_BG"
                value={job3}
                onChange={(e) => setJob3(e.target.value)}
                placeholder="직무를 입력해주세요."
                name="job3"
                style={{ marginTop: 12 }}
              />
              <input
                className="Leave_LongInput_BG"
                value={job4}
                onChange={(e) => setJob4(e.target.value)}
                placeholder="직무를 입력해주세요."
                name="job4"
                style={{ marginTop: 12 }}
              />
              <input
                className="Leave_LongInput_BG"
                value={job5}
                onChange={(e) => setJob5(e.target.value)}
                placeholder="직무를 입력해주세요."
                name="job5"
                style={{ marginTop: 12 }}
              />
            </div>
            {extraLabels.map((input, index) => (
              <div className="ProfileDetail_Subject" key={index}>
                <div className="EmployeeResist_Label_CT">
                  <div className="Profile_Label_Copy">{Object.keys(input)[0]}</div>
                  <button className="EmployeeResist_Button" type="button" onClick={() => onClickNewLabelDelete(index)}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Icon name={"Delete"} />

                      <div className="EmployeeResist_Delete_Copy">삭제</div>
                    </div>
                  </button>
                </div>
                <input
                  className="Leave_LongInput_BG"
                  value={Object.values(input)[0]}
                  onChange={(e) => handleInputChange(index, Object.keys(input)[0], e.target.value)}
                  placeholder=""
                  name="inputText"
                />
              </div>
            ))}
            <button
              className="EmployeeResist_NewLabelButton_BG"
              type="button"
              onClick={onClickNewLabelOpen}
              style={{
                backgroundColor: "rgb(231, 239, 255)",
                color: "rgb(55, 105, 252)",
                fontSize: "20px",
              }}
            >
              새 레이블 추가
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default WB93ReferEmployeeModify;
