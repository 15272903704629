import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { MergeData } from "../Utils/MergeData";
import { ConvertKeysToLowercase, ConvertArrayKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphen13DigitNumber, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";

const PartnershipAgreement = observer(
  ({
    setPartnershipAgreementInfo,
    inputCompanyName,
    setInputCompanyName,
    corporationRegistrationNumber,
    setCorporationRegistrationNumber,
    companyAddress,
    setCompanyAddress,
    companyRepresentName,
    setCompanyRepresentName,
    selectedEmployees,
    setSelectedEmployees,
    partnershipAgreementData,
    setPartnershipAgreementData,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        corporationRegistrationNumber.length === 13 &&
        companyAddress.length > 0 &&
        companyRepresentName.length > 0 &&
        partnershipAgreementData.CompanyIssueStockAmount.length > 0 &&
        partnershipAgreementData.CompanyIssueStockPrice.length > 0 &&
        partnershipAgreementData.WorkDutyTenure.length > 0 &&
        partnershipAgreementData.RivalBusinessWorkProhibitionTerm.length > 0 &&
        partnershipAgreementData.RivalBusinessShareProhibitionTerm.length > 0 &&
        partnershipAgreementData.StockholderAgreementProportion.length > 0 &&
        partnershipAgreementData.InvestmentVoteAmount.length > 0 &&
        partnershipAgreementData.SecrecyAgreementTerm.length > 0 &&
        partnershipAgreementData.InHouseDisciplinaryActionArticle.length > 0 &&
        partnershipAgreementData.InHouseDisciplinaryActionPenalty.length > 0 &&
        partnershipAgreementData.MinimumStockAmount.length > 0 &&
        partnershipAgreementData.RemainEffectArticle.length > 0 &&
        partnershipAgreementData.ContractDate.length === 8 &&
        selectedEmployees.every((employee) => employee?.Address?.length > 0) &&
        selectedEmployees.every((employee) => employee?.StockholderJob?.length > 0) &&
        selectedEmployees.every((employee) => employee?.PossessionStockType?.length > 0) &&
        selectedEmployees.every((employee) => employee?.PossessionStockAmount?.length > 0) &&
        selectedEmployees.every((employee) => employee?.PossessionStockProportion?.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";

      if (!inputCompanyName.length > 0) {
        errorMessage += "- 회사명을 입력해주세요.";
      }
      if (!corporationRegistrationNumber.length === 13) {
        errorMessage += errorMessage ? "\n- 법인등록번호를 입력해주세요." : "- 법인등록번호를 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 회사 주소를 입력해주세요." : "- 회사 주소를 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표 이사명을 입력해주세요." : "- 대표 이사명을 입력해주세요.";
      }
      if (!partnershipAgreementData?.CompanyIssueStockAmount.length > 0) {
        errorMessage += errorMessage ? "\n- [제1조 - ①] 총 발행주식 수 입력해주세요." : "- [제1조 - ①] 총 발행주식 수 입력해주세요.";
      }
      if (!partnershipAgreementData?.CompanyIssueStockPrice.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제1조 - ①] 발행주식 1 주당 액면가를 입력해주세요."
          : "- [제1조 - ①] 발행주식 1 주당 액면가를 입력해주세요.";
      }
      if (!partnershipAgreementData?.StockholderAgreementProportion.length > 0) {
        errorMessage += errorMessage ? "\n- [제2조 - ②] 주주 합의 기준 비율을 입력해주세요." : "- [제2조 - ②] 주주 합의 기준 비율을 입력해주세요.";
      }
      if (!partnershipAgreementData?.InvestmentVoteAmount.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제2조 - ②] 주주총회 또는 이사회 결의 기준 투자금액을 입력해주세요."
          : "- [제2조 - ②] 주주총회 또는 이사회 결의 기준 투자금액을 입력해주세요.";
      }
      if (!partnershipAgreementData?.WorkDutyTenure.length > 0) {
        errorMessage += errorMessage ? "\n- [제3조 - ①] 근로 의무 기간을 입력해주세요." : "- [제3조 - ①] 근로 의무 기간을 입력해주세요.";
      }
      if (!partnershipAgreementData?.RivalBusinessWorkProhibitionTerm.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제4조 - ①] 경쟁 사업 종사 금지기한을 입력해주세요."
          : "- [제4조 - ①] 경쟁 사업 종사 금지기한을 입력해주세요.";
      }
      if (!partnershipAgreementData?.RivalBusinessShareProhibitionTerm.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제4조 - ②] 경쟁 사업 지분 취득 금지기한을 입력해주세요."
          : "- [제4조 - ②] 경쟁 사업 지분 취득 금지기한을 입력해주세요.";
      }
      if (!partnershipAgreementData?.SecrecyAgreementTerm.length > 0) {
        errorMessage += errorMessage ? "\n- [제8조 - ②] 기밀 유지기한을 입력해주세요." : "- [제8조 - ②] 기밀 유지기한을 입력해주세요.";
      }
      if (!partnershipAgreementData?.InHouseDisciplinaryActionArticle.length > 0) {
        errorMessage += errorMessage ? "\n- [제10조] 위약벌에 대한 사내규정을 입력해주세요." : "- [제10조] 위약벌에 대한 사내규정을 입력해주세요.";
      }
      if (!partnershipAgreementData?.InHouseDisciplinaryActionPenalty.length > 0) {
        errorMessage += errorMessage ? "\n- [제10조] 위약벌에 대한 벌금을 입력해주세요." : "- [제10조] 위약벌에 대한 벌금을 입력해주세요.";
      }
      if (!partnershipAgreementData?.MinimumStockAmount.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제11조] 계약종료의 기준이 되는 주식 보유수를 입력해주세요."
          : "- [제11조] 계약종료의 기준이 되는 주식 보유수를 입력해주세요.";
      }
      if (!partnershipAgreementData?.RemainEffectArticle.length > 0) {
        errorMessage += errorMessage
          ? "\n- [제11조] 계약종료와 관계없이 지속되는 조항을 입력해주세요."
          : "- [제11조] 계약종료와 관계없이 지속되는 조항을 입력해주세요.";
      }
      if (!partnershipAgreementData?.JurisdictionCourt.length > 0) {
        errorMessage += errorMessage ? "\n- 분쟁조절 관할 법원을 입력해주세요." : "- 분쟁조절 관할 법원을 입력해주세요.";
      }
      if (!partnershipAgreementData?.ContractDate.length === 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }

      selectedEmployees.forEach((employee, index) => {
        if (!employee?.PossessionStockType?.length > 0) {
          errorMessage += errorMessage
            ? `\n- 주주 ${index + 1}의 보유 주식 종류를 입력해주세요.`
            : `- 주주 ${index + 1}의 보유 주식 종류를 입력해주세요.`;
        }
      });
      selectedEmployees.forEach((employee, index) => {
        if (!employee?.PossessionStockAmount?.length > 0) {
          errorMessage += errorMessage
            ? `\n- 주주 ${index + 1}의 보유 주식 수를 입력해주세요.`
            : `- 주주 ${index + 1}의 보유 주식 수를 입력해주세요.`;
        }
      });
      selectedEmployees.forEach((employee, index) => {
        if (!employee?.PossessionStockProportion?.length > 0) {
          errorMessage += errorMessage
            ? `\n- 주주 ${index + 1}의 보유 주식 비율을 입력해주세요.`
            : `- 주주 ${index + 1}의 보유 주식 비율을 입력해주세요.`;
        }
      });
      selectedEmployees.forEach((employee, index) => {
        if (!employee?.StockholderJob?.length > 0) {
          errorMessage += errorMessage
            ? `\n- 주주 ${index + 1}의 역할 또는 업무 내용을 입력해주세요.`
            : `- 주주 ${index + 1}의 역할 또는 업무 내용을 입력해주세요.`;
        }
      });

      selectedEmployees.forEach((employee, index) => {
        if (!employee?.Address?.length > 0) {
          errorMessage += errorMessage ? `\n- 주주 ${index + 1}의 주소를 입력해주세요.` : `- 주주 ${index + 1}의 주소를 입력해주세요.`;
        }
      });

      return errorMessage;
    };

    const stockholderNamesString = selectedEmployees.map((employee) => employee.Name).join(", ");

    const handleSelectedEmployeeDataChange = (index, field, value) => {
      const updatedEmployees = [...selectedEmployees];
      updatedEmployees[index][field] = value;
      setSelectedEmployees(updatedEmployees);
    };

    const handleContractDataChange = (field, value) => {
      setPartnershipAgreementData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    };

    const onChangeCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleContractDataChange("InputCompanyName", value);
    };

    const onChangeCorporationRegistrationNumberData = (value) => {
      setCorporationRegistrationNumber(value);
      handleContractDataChange("CorporationRegistrationNumber", value);
    };

    const onChangeCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleContractDataChange("CompanyAddress", value);
    };

    const onChangeCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleContractDataChange("CompanyRepresentName", value);
    };

    const onChangeContractDateData = (value) => {
      handleContractDataChange("ContractDate", value);
      handleContractDataChange("InputCompanyName", inputCompanyName);
      handleContractDataChange("CorporationRegistrationNumber", corporationRegistrationNumber);
      handleContractDataChange("CompanyAddress", companyAddress);
      handleContractDataChange("CompanyRepresentName", companyRepresentName);
    };

    const filterKeys = (arr, keysToKeep) => {
      return arr.map((obj) => {
        const filteredObj = {};
        keysToKeep.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      });
    };

    const keysToKeep = [
      "Name",
      "Birthday",
      "Address",
      "TelNumber",
      "PossessionStockType",
      "PossessionStockAmount",
      "PossessionStockProportion",
      "StockholderJob",
    ];
    const filteredEmployees = filterKeys(selectedEmployees, keysToKeep);

    const applyFunctionsToMemberData = (memberData) => {
      return memberData.map((member) => {
        let updatedMember = { ...member };
        if (updatedMember.birthday) {
          updatedMember.birthday = AddDotYYYYMMDD(updatedMember.birthday);
        }
        if (updatedMember.possessionStockAmount) {
          updatedMember.possessionStockAmount = AddCommaAmountNumber(updatedMember.possessionStockAmount);
        }
        if (updatedMember.telNumber) {
          updatedMember.telNumber = AddHyphenTelNumber(updatedMember.telNumber);
        }
        return updatedMember;
      });
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);

        const lowCaseContractData = ConvertKeysToLowercase(partnershipAgreementData);
        const lowCaseEmployeeData = ConvertArrayKeysToLowercase(filteredEmployees);
        const bodyRequestData = MergeData(lowCaseContractData, lowCaseEmployeeData);
        const bodyRequestDataCopy = { ...bodyRequestData };

        bodyRequestDataCopy.companyIssueStockAmount = AddCommaAmountNumber(bodyRequestDataCopy?.companyIssueStockAmount);
        bodyRequestDataCopy.companyIssueStockPrice = AddCommaAmountNumber(bodyRequestDataCopy?.companyIssueStockPrice);
        bodyRequestDataCopy.corporationRegistrationNumber = AddHyphen13DigitNumber(bodyRequestDataCopy?.corporationRegistrationNumber);
        bodyRequestDataCopy.inHouseDisciplinaryActionPenalty = AddCommaAmountNumber(bodyRequestDataCopy?.inHouseDisciplinaryActionPenalty);
        bodyRequestDataCopy.investmentVoteAmount = AddCommaAmountNumber(bodyRequestDataCopy?.investmentVoteAmount);
        bodyRequestDataCopy.minimumStockAmount = AddCommaAmountNumber(bodyRequestDataCopy?.minimumStockAmount);
        bodyRequestDataCopy.memberData = applyFunctionsToMemberData(bodyRequestDataCopy?.memberData);

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/pdf/general/shareholders-partnership-contracts";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            data: bodyRequestDataCopy,
          });
          if (response.data.message === "주주간계약서(동업계약서) 부여 계약서 생성 완료") {
            handleContractDataChange("Url", response.data.url);
            setTimeout(async () => {
              setIsLoading(false);
              setPartnershipAgreementInfo(false);
              alert("주주간계약서(동업계약서) 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setPartnershipAgreementInfo(false);
            alert("주주간계약서(동업계약서) 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("PartnershipAgreement ERROR : ", error);
          setIsLoading(false);
          setPartnershipAgreementInfo(false);
          alert("주주간계약서(동업계약서) 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">주주간계약서 (동업계약서)</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setPartnershipAgreementInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  본 주주간 계약서 (이하 “본계약”)는 주주 <span style={{ color: "rgb(55, 105, 252)" }}>{stockholderNamesString || "________"}</span>{" "}
                  (이하 개별적으로 또는 총칭하여 “주주”라 한다.)는 주주들 사이에 회사 운영 관련 사항, 회사 및 주주들의 권리와 의무를 정하고자{" "}
                  {partnershipAgreementData?.ContractDate && partnershipAgreementData?.ContractDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddUnitYYYYMMDD(partnershipAgreementData.ContractDate)}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __일
                    </span>
                  )}
                  (이하 “체결일”)로 체결되었다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 조 (회사와 주주의 지위)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 회사는 대한민국 법률에 따라 설립된 주식회사로서, 본 계약 체결일 현재 총 발행주식 수는 보통주식{" "}
                  {partnershipAgreementData?.CompanyIssueStockAmount && partnershipAgreementData?.CompanyIssueStockAmount?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(partnershipAgreementData.CompanyIssueStockAmount)}주</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____주
                    </span>
                  )}{" "}
                  (1주의 액면 금액{" "}
                  {partnershipAgreementData?.CompanyIssueStockPrice && partnershipAgreementData?.CompanyIssueStockPrice?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(partnershipAgreementData.CompanyIssueStockPrice)}원</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______원
                    </span>
                  )}
                  )이다.
                  <br />② 회사가 발행하는 총 주식은 각 주주(동업자)에게 다음과 같이 배분한다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginBottom: "0px",
                    marginTop: "4px",
                    color: "rgb(55, 105, 252)",
                  }}
                >
                  ※ 아래 표에 정보를 직접 입력해 주세요. <span style={{ color: "red" }}>붉은 글자</span>는 필수 입력값 입니다.
                </div>
                <div className="Document_Box_BG">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="Document_Box_Label_Copy"
                        style={{
                          width: "60px",
                          marginRight: "12px",
                        }}
                      >
                        이름
                      </div>
                      <div className="Document_Box_Label_Copy" style={{ width: "148px", marginRight: "12px" }}>
                        주식 종류
                      </div>
                      <div className="Document_Box_Label_Copy" style={{ width: "84px", marginRight: "12px" }}>
                        주식 수
                      </div>
                      <div
                        className="Document_Box_Label_Copy"
                        style={{
                          width: "64px",
                        }}
                      >
                        비율(%)
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgb(170, 170, 170)",
                        marginTop: "8px",
                      }}
                    />
                  </div>
                  {selectedEmployees.map((employee, index) => (
                    <div
                      key={employee.ID}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "4px",
                          marginBottom: "4px",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                        }}
                      >
                        <div
                          className="ContractInput_Body_Copy"
                          style={{
                            width: "60px",
                            marginRight: "12px",
                          }}
                        >
                          {employee.Name}
                        </div>

                        <input
                          className="PartnershipAgreement_TableInput"
                          value={employee?.PossessionStockType || ""}
                          onChange={(e) => handleSelectedEmployeeDataChange(index, "PossessionStockType", e.target.value)}
                          placeholder="예시) 보통주식 / 종류주식"
                          name="PossessionStockType"
                          style={{ width: "148px", marginRight: "12px" }}
                          required
                        />

                        <input
                          className="PartnershipAgreement_TableInput"
                          value={employee?.PossessionStockAmount || ""}
                          onChange={(e) => handleSelectedEmployeeDataChange(index, "PossessionStockAmount", e.target.value)}
                          placeholder="숫자만 입력"
                          name="PossessionStockAmount"
                          style={{ width: "84px", marginRight: "12px" }}
                          required
                        />

                        <input
                          className="PartnershipAgreement_TableInput"
                          value={employee?.PossessionStockProportion || ""}
                          onChange={(e) => handleSelectedEmployeeDataChange(index, "PossessionStockProportion", e.target.value)}
                          placeholder="숫자만 입력"
                          name="PossessionStockProportion"
                          style={{ width: "64px" }}
                          required
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "rgb(227, 227, 227)",
                        }}
                      />
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "8px",
                    }}
                  >
                    <div
                      className="Document_Box_Label_Copy"
                      style={{
                        width: "318px",
                        marginRight: "12px",
                      }}
                    >
                      총합
                    </div>

                    <div
                      className="Document_Box_Label_Copy"
                      style={{
                        width: "64px",
                      }}
                    >
                      100%
                    </div>
                  </div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (업무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 각 주주(동업자)가 담당하는 역할과 업무는 다음과 같고 각기 담당한 회사의 업무를 성실하게 수행하여야 한다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginBottom: "0px",
                    marginTop: "4px",
                    color: "rgb(55, 105, 252)",
                  }}
                >
                  ※ 아래 표에 정보를 직접 입력해 주세요. <span style={{ color: "red" }}>붉은 글자</span>는 필수 입력값 입니다.
                </div>
                <div className="Document_Box_BG" style={{ marginBottom: "8px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="Document_Box_Label_Copy"
                        style={{
                          width: "60px",
                          marginRight: "12px",
                        }}
                      >
                        이름
                      </div>
                      <div className="Document_Box_Label_Copy" style={{ width: "322px" }}>
                        역할 또는 업무 내용
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgb(170, 170, 170)",
                        marginTop: "8px",
                      }}
                    />
                  </div>
                  {selectedEmployees.map((employee, index) => (
                    <div
                      key={employee.ID}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "4px",
                          marginBottom: "4px",
                          paddingBottom: "4px",
                          paddingTop: "4px",
                        }}
                      >
                        <div
                          className="ContractInput_Body_Copy"
                          style={{
                            width: "60px",
                            marginRight: "12px",
                          }}
                        >
                          {employee.Name}
                        </div>

                        <input
                          className="PartnershipAgreement_TableInput"
                          value={employee?.StockholderJob || ""}
                          onChange={(e) => handleSelectedEmployeeDataChange(index, "StockholderJob", e.target.value)}
                          placeholder="역할 또는 업무 내용을 입력해주세요."
                          name="StockholderJob"
                          style={{ width: "322px" }}
                          required
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "rgb(227, 227, 227)",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="ContractInput_Body_Copy">
                  ② 주주들은 다음 각 호의 사항에 관하여는 주주들{" "}
                  {partnershipAgreementData?.StockholderAgreementProportion &&
                  partnershipAgreementData?.StockholderAgreementProportion?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.StockholderAgreementProportion}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}{" "}
                  의 합의로 결정하고, 주주총회 또는 이사회 결의가 필요한 경우 주주들의 합의에 따라 의결권을 행사한다.
                  <br />
                  1. 정관의 변경
                  <br />
                  2. 주된 사업의 변경, 신규 사업의 진출
                  <br />
                  3. 주식 및 주식 관련 사채의 발행, 주식매수 선택권 부여등 자본금의 증감을 가져올 수 있는 행위
                  <br />
                  4. 배당의 결정
                  <br />
                  5. 사업의 중단 또는 포기, 사업양수도, 합병, 분할, 분할합병, 주식의 포괄적 교환 또는 이전, 영업의 양도, 영업의 양수, 위탁경영, 기타
                  회사조직의 근본적인 변경
                  <br />
                  6. 회사와 주요 경영진 및 이들의 특수관계인과의 거래
                  <br />
                  7. 회사가{" "}
                  {partnershipAgreementData?.InvestmentVoteAmount && partnershipAgreementData?.InvestmentVoteAmount?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(partnershipAgreementData.InvestmentVoteAmount)}원</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________원
                    </span>
                  )}{" "}
                  을 초과하여 투자하는 행위
                  <br />③ 회사 및 주주들은 다음 각 호의 사항에 관하여는 다른 주주에게 사전에 통지하고 이를 협의하여야 한다.
                  <br />
                  1. 이사회 및 주주총회 결의 사항
                  <br />
                  2. 연간 사업계획 및 예산의 결정
                  <br />
                  3. 기타 회사의 운영에 중대한 영향을 미치는 사항
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (계속 근로 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 본 계약 체결일로부터 사유를 불문하고{" "}
                  {partnershipAgreementData?.WorkDutyTenure && partnershipAgreementData?.WorkDutyTenure?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.WorkDutyTenure}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  간 다른 주주들 전원의 사전 서면동의 없이 회사에서 퇴사하여서는 안된다. 단, 해당 주주에게 책임없는 사유로 인한 비자발적 퇴사는 예외로
                  한다.
                  <br />② 전항에도 불구하고 주주가{" "}
                  {partnershipAgreementData?.WorkDutyTenure && partnershipAgreementData?.WorkDutyTenure?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.WorkDutyTenure}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  이내에 회사에서 퇴사하는 경우, 다른 주주는 퇴사하는 주주가 보유하고있는 주식 전부 또는 일부를 퇴사일 당시의 각 지분율에 따라
                  퇴사하는 주주로부터 액면가로 매수할 수 있는 권리를 가진다.
                  <br />③ 본 조에 의한 권리행사는 퇴사자가 다른 주주 및 회사에 대하여 부담하는 위약금등 손해배상 의무에 영향을 미치지 아니한다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (경업 금지 및 신회사 설립 금지)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 회사에 재직중인 기간 및 회사를 퇴사한 날로부터{" "}
                  {partnershipAgreementData?.RivalBusinessWorkProhibitionTerm &&
                  partnershipAgreementData?.RivalBusinessWorkProhibitionTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.RivalBusinessWorkProhibitionTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  간 회사가 영위하는 사업과 동종의 사업 또는 회사에 직접 또는 간접으로 중대한 영향을 미치는 사업(이하“경쟁사업”)에 종사할 수 없다.
                  <br />② 주주는 회사에 재직중인 기간 및 회사를 퇴사한 날로부터{" "}
                  {partnershipAgreementData?.RivalBusinessShareProhibitionTerm &&
                  partnershipAgreementData?.RivalBusinessShareProhibitionTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.RivalBusinessShareProhibitionTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  간 경쟁 사업을 영위할 목적으로 신회사를 설립하거나 경쟁 사업을 영위하는 회사(이하“경쟁회사”)의 지분을 취득하여서는 아니된다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (주식의 처분제한)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 다른 주주들 전원의 사전 서면동의가 없는 이상 자신이 보유한 회사의 주식을 처분할 수 없다.
                  <br />② 주주는 전항에 의하여 다른 주주의 사전 서면동의를 요청하는 경우, 보유주식을 처분하고자 한다는 취지, 처분 상대방에 대한 정보,
                  처분 주식수, 주당 처분가 격, 처분 예정일, 기타 처분의 주요 조건을 명시한 서면을 다른 주주에게 제공하여야한다.
                  <br />③ 주주는 제1항에 따라 다른 주주들 전원의 사전 서면동의를 얻은 경우에도 제6조 및 제7조의 제한하에서만 주식을 처분할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (우선매수권)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주식을 매도하고자 하는 주주(이하 “양도인”)가 보유 주식의 전부 또는 일부를 제3자(다른 주주를 포함하고, 이하 “양 수예 정자”라
                  한다)에게 양도하고자 하는 경우, 다른 주주(이하 “우선 매수권자”)는 양수 예정자에 우선하여 동주식을 매수할 권리(이하“우선
                  매수권”)가있다.
                  <br />② 양도인은 양수 예정자에게 주식을 양도하고자 하는 경우, 양도인이 보유한 주식을 양도하고자 한다는 취지, 양 수예 정자에 대한
                  정보, 양도 주식 수, 주당 양도 가격, 양도예정일, 기타 양도의 주요 조건(이하 “주요 양도 조건”)을 명시하여 양도예정일로부터 30일 전에
                  우선 매수권자에게 서면으로 통지하여야 한다.
                  <br />③ 우선 매수권 자는 양도인으로부터 제2항에 따라 통지를 받은 날로부터 30일 이내에 양도 주식에 대하여 양수할 의사가 있음을 통지할
                  수 있고, 이 경우 양도인은 처분 대상 주식을 우선 매수권자에게 주 요양도 조건과 동일한 조건으로 매도하여야 한다.
                  <br />④ 우선 매수권자가 제2항의 통지를 수령한 후 30일 이내에 위 주식에 대한 우선 매수권을 행사하지 아니하는 경우, 양도인은 제2항에
                  따라 양도 통지된 주식 중 우선 매수권자가 우선 매수권을 행사하지 아니한 주식을 다음의 조건으로 양수 예정자에게 매각할 수 있다.
                  <br />
                  1. 양도 주식 수는 제2항에서 통지된 주식 수와 일치하여야 한다.
                  <br />
                  2. 주당 가격은 제2항에서 통지된 주당 양도 가격을 하회하여서는 아니 된다.
                  <br />
                  3. 양도의 기타 조건은 제2항에 의하여 통지된 주요 양도 조건보다 양수 예정자에게 유리하여서는 아니 된다. 단, 양도예정일 이외의 일시에
                  양도하는 것은 주요 양도 조건보다 유리한 것으로 해석되지 아니한다.
                  <br />⑤ 제4항에 의하여 30일 이내에 양수 예정자에게 주식이 매각되지 아니할 경우, 양도인은 제2항 내지 제4항에 규정된 절차를 다시
                  이행하여야 한다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 7 조 (공동매도권)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 양도인이 제5조 및 제6조에 따라 주식을 양도하고자 하는 경우,다른 주주(이하 “공동매도권자”)는 제6조의 우선매수권을 행사하는 대신
                  양도인과 동일한 조건으로 양도에 참여할 수 있는 권리(이하 “공동매도권”)가 있다.
                  <br />
                  ② 공동매도권 자는 양도인으로부터 제6조 제2항의 통지를 받은 날로부터 30일 이내(이하 “공동매도 통지 기간”)에 자신이 양도하고자 하는
                  주식의 종류와 수를 양도인에게 서면으로 통지함으로써, 주요 양도 조건과 동일한 조건으로 자신이 보유하고 있는 회사 주식을 양수
                  예정자에게 양도할 수 있다. 이 경우 양도인은 자신이 보유한 주식과 함께 공동매도권자가 양도를 희망하는 주식이 제7조 제2항에 의하여
                  통지된 주요 양도 조건과 동일한 조건으로 양수 예정자에게 양도되도록 하여야 한다.
                  <br />③ 공동매도 통지 기간이 종료한 날로부터 30일 이내에 양수 예정자에게 주식이 매각되지 아니할 경우, 양도인은 제2항에 규정된 절차를
                  다시 이행하여야 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 8 조 (비밀 유지 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 회사에서의 근무 등 본 계약에 의한 업무 수행 과정에서 회사 및 다른 주주로부터 취득한 일체의 비밀(사업 계획 및 파트너십 등
                  사업상 비밀, 기술 및 노하우 관련 비밀, 인사, 조직, 재무 전산 등 관리 비밀, 거래처 등 영업 비밀 등을 포함하며 이에 국한되지
                  아니한다)을 타인에게 누설 또는 공개하거나, 자신 또는 제3자의 이익을 위하여 스스로 이용하여서는 아니 된다.
                  <br />② 본 조의 기밀유지 의무는 주주가 회사에 재직 중인 기간 및 퇴사한 날로부터{" "}
                  {partnershipAgreementData?.SecrecyAgreementTerm && partnershipAgreementData?.SecrecyAgreementTerm?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.SecrecyAgreementTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  간 유효하다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 9 조 (손해 배상)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주가 본 계약을 위반하여 다른 주주들에게 손해가 발생한 경우, 손해를 입은 주주는 책임 있는 주주에 대하여 손해배상을 청구할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 10 조 (위약벌)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주가{" "}
                  {partnershipAgreementData?.InHouseDisciplinaryActionArticle &&
                  partnershipAgreementData?.InHouseDisciplinaryActionArticle?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.InHouseDisciplinaryActionArticle}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  를 위반한 경우, 제10조의 손해배상과 별도로, 위약벌로{" "}
                  {partnershipAgreementData?.InHouseDisciplinaryActionPenalty &&
                  partnershipAgreementData?.InHouseDisciplinaryActionPenalty?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddCommaAmountNumber(partnershipAgreementData.InHouseDisciplinaryActionPenalty)}원
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________ 원
                    </span>
                  )}
                  을 다른 주주에게 지급한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 11 조 (본 계약의 종료)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주들 전원의 서면합의가 있거나 주주가 본 계약에 의하여 보유 주식 전부 또는 일부를 제3자에게 양도하여 보유하고 있는 회사 주식의
                  수가{" "}
                  {partnershipAgreementData?.MinimumStockAmount && partnershipAgreementData?.MinimumStockAmount?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(partnershipAgreementData.MinimumStockAmount)}주</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 주
                    </span>
                  )}{" "}
                  미만이 되는 경우, 본 계약은 별도의 의사표시 없이 해당 주주에 대하여 종료되는 것으로 본다.
                  <br />② 제1항에도 불구하고{" "}
                  {partnershipAgreementData?.RemainEffectArticle && partnershipAgreementData?.RemainEffectArticle?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.RemainEffectArticle}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  규정은 본 계약의 종료와 관계없이 계속하여 효력을 유지하고 주주를 구속한다.
                  <br />③ 제1항에도 불구하고 본 계약의 종료 시점에 이미 발생한 주주들의 권리 및 의무(손해배상의무 등을 포함한다)는 본 계약의 종료에
                  의하여 영향을 받지 아니한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 12 조 (권리 및 의무의 양도 제한)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 다른 주주의 사전 서면동의 없이는 본 계약에 따른 권리 또는 의무를 제3자에게 양도 또는 이전할 수 없다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 13 조 (기타)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 본 계약은 본 계약에 정한 사항에 관하여 주주들 사이의 완전한 합의를 구성한다.
                  <br />② 본 계약에서 달리 정하지 않는 한, 본 계약의 체결, 이행과 관련하여 발생하는 비용, 세금 등은 해당 비용이 발생하거나 법령에 의해
                  등 세금이 부과되는 주주가 부담한다.
                  <br />③ 본 계약은 대한민국 법을 준거법으로 하며, 이에 따라 해석된다.
                  <br />④ 협의에 의하여 본 계약에 관련된 분쟁을 해결할 수 없는 경우에는 그 분쟁은{" "}
                  {partnershipAgreementData?.JurisdictionCourt && partnershipAgreementData?.JurisdictionCourt?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{partnershipAgreementData.JurisdictionCourt}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  을 관할법원으로 하여 해결한다.
                </div>

                <div className="ContractInput_Date_Copy_CT" style={{ marginBottom: "24px" }}>
                  {partnershipAgreementData?.ContractDate && partnershipAgreementData?.ContractDate?.length === 8 ? (
                    <div className="ContractInput_Date_Copy">{AddUnitYYYYMMDD(partnershipAgreementData.ContractDate)}</div>
                  ) : (
                    <div className="ContractInput_Date_Copy">
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    </div>
                  )}
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "32px" }}>
                  회사
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  상호명 : {inputCompanyName}
                </div>

                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  법인등록번호 : {AddHyphen13DigitNumber(corporationRegistrationNumber)}
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주소 : {companyAddress}
                </div>
                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    대표자명 : {companyRepresentName}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
                {selectedEmployees.map((employee, index) => (
                  <div key={employee.ID}>
                    <div className="ContractInput_Label_Copy" style={{ marginTop: "32px" }}>
                      주주 {index + 1}
                    </div>
                    <div
                      className="ContractInput_SubBody_CT"
                      style={{
                        minWidth: "240px",
                        maxWidth: "420px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                        이름 : {employee.Name}
                      </div>
                      <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                        (서명 또는 인)
                      </div>
                    </div>
                    <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                      생년월일 : {AddDotYYYYMMDD(employee.Birthday)}
                    </div>
                    <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                      주소 : {employee.Address}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              {selectedEmployees.map((employee, index) => (
                <div className="Contract_UserData_CT" key={employee.ID}>
                  <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                    <div className="Contract_Label_Copy"> 주주 {index + 1}</div> | {employee.Name}
                  </div>
                  <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                    <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(employee.Birthday)}
                  </div>
                  <div className="Contract_Body_Copy">
                    <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(employee.TelNumber)}
                  </div>
                  <div className="Contract_Divider"> </div>
                </div>
              ))}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">체결일</div>
                  <div
                    className={
                      partnershipAgreementData?.ContractDate && partnershipAgreementData?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제1조 - ①] 회사 발행 주식수</div>
                  <div
                    className={
                      partnershipAgreementData?.CompanyIssueStockAmount && partnershipAgreementData?.CompanyIssueStockAmount?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.CompanyIssueStockAmount || ""}
                  onChange={(e) => handleContractDataChange("CompanyIssueStockAmount", e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="CompanyIssueStockAmount"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제1조 - ①] 회사 발행 주식 액면가</div>
                  <div
                    className={
                      partnershipAgreementData?.CompanyIssueStockPrice && partnershipAgreementData?.CompanyIssueStockPrice?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.CompanyIssueStockPrice || ""}
                  onChange={(e) => handleContractDataChange("CompanyIssueStockPrice", e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="CompanyIssueStockPrice"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제2조 - ②] 주주간 합의 기준</div>
                  <div
                    className={
                      partnershipAgreementData?.StockholderAgreementProportion && partnershipAgreementData?.StockholderAgreementProportion?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.StockholderAgreementProportion || ""}
                  onChange={(e) => handleContractDataChange("StockholderAgreementProportion", e.target.value)}
                  placeholder="예시) 전원"
                  name="StockholderAgreementProportion"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제2조 - ② - 7] 투자금 기준</div>
                  <div
                    className={
                      partnershipAgreementData?.InvestmentVoteAmount && partnershipAgreementData?.InvestmentVoteAmount?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.InvestmentVoteAmount || ""}
                  onChange={(e) => handleContractDataChange("InvestmentVoteAmount", e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="InvestmentVoteAmount"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제3조 - ①, ②] 의무 근로 기간</div>
                  <div
                    className={
                      partnershipAgreementData?.WorkDutyTenure && partnershipAgreementData?.WorkDutyTenure?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.WorkDutyTenure || ""}
                  onChange={(e) => handleContractDataChange("WorkDutyTenure", e.target.value)}
                  placeholder="예시) 2년"
                  name="WorkDutyTenure"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제4조 - ①] 경쟁 사업 종사 금지 기간</div>
                  <div
                    className={
                      partnershipAgreementData?.RivalBusinessWorkProhibitionTerm &&
                      partnershipAgreementData?.RivalBusinessWorkProhibitionTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.RivalBusinessWorkProhibitionTerm || ""}
                  onChange={(e) => handleContractDataChange("RivalBusinessWorkProhibitionTerm", e.target.value)}
                  placeholder="예시) 3년"
                  name="RivalBusinessWorkProhibitionTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제4조 - ②] 경쟁 사업 지분 취득 금지 기간</div>
                  <div
                    className={
                      partnershipAgreementData?.RivalBusinessShareProhibitionTerm &&
                      partnershipAgreementData?.RivalBusinessShareProhibitionTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.RivalBusinessShareProhibitionTerm || ""}
                  onChange={(e) => handleContractDataChange("RivalBusinessShareProhibitionTerm", e.target.value)}
                  placeholder="예시) 3년"
                  name="RivalBusinessShareProhibitionTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제8조 - ②] 비밀 유지 기간</div>
                  <div
                    className={
                      partnershipAgreementData?.SecrecyAgreementTerm && partnershipAgreementData?.SecrecyAgreementTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.SecrecyAgreementTerm || ""}
                  onChange={(e) => handleContractDataChange("SecrecyAgreementTerm", e.target.value)}
                  placeholder="예시) 3년"
                  name="SecrecyAgreementTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제10조] 위약벌에 대한 규정</div>
                  <div
                    className={
                      partnershipAgreementData?.InHouseDisciplinaryActionArticle &&
                      partnershipAgreementData?.InHouseDisciplinaryActionArticle?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.InHouseDisciplinaryActionArticle || ""}
                  onChange={(e) => handleContractDataChange("InHouseDisciplinaryActionArticle", e.target.value)}
                  placeholder="예시) 제 ⃝조 ⃝항"
                  name="InHouseDisciplinaryActionArticle"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제10조] 위약벌에 대한 벌금</div>
                  <div
                    className={
                      partnershipAgreementData?.InHouseDisciplinaryActionPenalty &&
                      partnershipAgreementData?.InHouseDisciplinaryActionPenalty?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.InHouseDisciplinaryActionPenalty || ""}
                  onChange={(e) => handleContractDataChange("InHouseDisciplinaryActionPenalty", e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="InHouseDisciplinaryActionPenalty"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제11조 - ①] 계약종료 주식보유수 기준</div>
                  <div
                    className={
                      partnershipAgreementData?.MinimumStockAmount && partnershipAgreementData?.MinimumStockAmount?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.MinimumStockAmount || ""}
                  onChange={(e) => handleContractDataChange("MinimumStockAmount", e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="MinimumStockAmount"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제11조 - ②] 효력 유지 조항</div>
                  <div
                    className={
                      partnershipAgreementData?.RemainEffectArticle && partnershipAgreementData?.RemainEffectArticle?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.RemainEffectArticle || ""}
                  onChange={(e) => handleContractDataChange("RemainEffectArticle", e.target.value)}
                  placeholder="예시) 제 ⃝조 ⃝항"
                  name="RemainEffectArticle"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제13조 - ④] 분쟁조절 관할 법원</div>
                  <div
                    className={
                      partnershipAgreementData?.JurisdictionCourt && partnershipAgreementData?.JurisdictionCourt?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={partnershipAgreementData?.JurisdictionCourt || ""}
                  onChange={(e) => handleContractDataChange("JurisdictionCourt", e.target.value)}
                  placeholder="예시) 서울중앙지방법원"
                  name="JurisdictionCourt"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">회사명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeCompanyNameData(e.target.value)}
                  placeholder="회사명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeCompanyRepresentNameData(e.target.value)}
                  placeholder="회사 대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">법인등록번호</div>
                  <div className={corporationRegistrationNumber.length === 13 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={corporationRegistrationNumber}
                  onChange={(e) => onChangeCorporationRegistrationNumberData(e.target.value)}
                  placeholder="숫자만 입력해주세요."
                  name="CorporationRegistrationNumber"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">회사 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeCompanyAddressData(e.target.value)}
                  placeholder="회사 주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>

              {selectedEmployees.map((employee, index) => (
                <div key={employee.ID}>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">주주 {index + 1} 주소</div>
                      <div className={employee?.Address?.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={employee?.Address || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange(index, "Address", e.target.value)}
                      placeholder="주소를 입력해주세요."
                      name="StockholderAddress"
                      required
                    />
                  </div>
                </div>
              ))}

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PartnershipAgreement;
