const OpenPreview = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 21C4.1 21 3.75 20.85 3.45 20.55C3.15 20.25 3 19.9 3 19.5V4.5C3 4.1 3.15 3.75 3.45 3.45C3.75 3.15 4.1 3 4.5 3H10.725C10.9375 3 11.1156 3.07229 11.2594 3.21687C11.4031 3.36147 11.475 3.54064 11.475 3.75437C11.475 3.96812 11.4031 4.14583 11.2594 4.2875C11.1156 4.42917 10.9375 4.5 10.725 4.5H4.5V19.5H19.5V13.275C19.5 13.0625 19.5723 12.8844 19.7169 12.7406C19.8615 12.5969 20.0406 12.525 20.2544 12.525C20.4681 12.525 20.6458 12.5969 20.7875 12.7406C20.9292 12.8844 21 13.0625 21 13.275V19.5C21 19.9 20.85 20.25 20.55 20.55C20.25 20.85 19.9 21 19.5 21H4.5ZM19.5 5.575L10.075 15C9.925 15.15 9.75 15.2208 9.55 15.2125C9.35 15.2042 9.175 15.125 9.025 14.975C8.875 14.825 8.8 14.65 8.8 14.45C8.8 14.25 8.875 14.075 9.025 13.925L18.45 4.5H13.725C13.5125 4.5 13.3344 4.42771 13.1906 4.28313C13.0469 4.13853 12.975 3.95936 12.975 3.74563C12.975 3.53188 13.0469 3.35417 13.1906 3.2125C13.3344 3.07083 13.5125 3 13.725 3H21V10.275C21 10.4875 20.9277 10.6656 20.7831 10.8094C20.6385 10.9531 20.4594 11.025 20.2456 11.025C20.0319 11.025 19.8542 10.9531 19.7125 10.8094C19.5708 10.6656 19.5 10.4875 19.5 10.275V5.575Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default OpenPreview;
