const Read = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0028 15.75C13.1841 15.75 14.1873 15.3365 15.0123 14.5096C15.8373 13.6826 16.2498 12.6784 16.2498 11.4971C16.2498 10.3157 15.8363 9.3125 15.0094 8.4875C14.1824 7.6625 13.1782 7.25 11.9969 7.25C10.8155 7.25 9.8123 7.66348 8.9873 8.49045C8.1623 9.3174 7.7498 10.3216 7.7498 11.5029C7.7498 12.6843 8.16329 13.6875 8.99025 14.5125C9.81721 15.3375 10.8214 15.75 12.0028 15.75ZM11.9939 14.3C11.2145 14.3 10.554 14.0272 10.0123 13.4816C9.47064 12.936 9.19981 12.2735 9.19981 11.4941C9.19981 10.7147 9.4726 10.0542 10.0182 9.5125C10.5638 8.97083 11.2263 8.7 12.0057 8.7C12.7851 8.7 13.4456 8.97279 13.9873 9.51837C14.529 10.064 14.7998 10.7265 14.7998 11.5059C14.7998 12.2853 14.527 12.9458 13.9814 13.4875C13.4358 14.0292 12.7733 14.3 11.9939 14.3ZM11.9998 19C9.69981 19 7.60397 18.375 5.7123 17.125C3.82064 15.875 2.35935 14.2244 1.32843 12.1731C1.27601 12.091 1.2373 11.9902 1.2123 11.8706C1.1873 11.7511 1.1748 11.6275 1.1748 11.5C1.1748 11.3725 1.1873 11.2489 1.2123 11.1294C1.2373 11.0098 1.27601 10.909 1.32843 10.8269C2.35935 8.77564 3.82064 7.125 5.7123 5.875C7.60397 4.625 9.69981 4 11.9998 4C14.2998 4 16.3956 4.625 18.2873 5.875C20.179 7.125 21.6403 8.77564 22.6712 10.8269C22.7236 10.909 22.7623 11.0098 22.7873 11.1294C22.8123 11.2489 22.8248 11.3725 22.8248 11.5C22.8248 11.6275 22.8123 11.7511 22.7873 11.8706C22.7623 11.9902 22.7236 12.091 22.6712 12.1731C21.6403 14.2244 20.179 15.875 18.2873 17.125C16.3956 18.375 14.2998 19 11.9998 19ZM11.9956 17.5C14.0151 17.5 15.8706 16.9542 17.5623 15.8625C19.254 14.7708 20.5415 13.3167 21.4248 11.5C20.5415 9.68333 19.2554 8.22917 17.5665 7.1375C15.8777 6.04583 14.0235 5.5 12.004 5.5C9.98455 5.5 8.12897 6.04583 6.4373 7.1375C4.74564 8.22917 3.4498 9.68333 2.5498 11.5C3.4498 13.3167 4.74423 14.7708 6.43308 15.8625C8.12195 16.9542 9.97611 17.5 11.9956 17.5Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default Read;
