import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import InputField from "../Components/InputField";
import { debounce } from "lodash";
import NavigationPanel from "../Components/NavigationPanel";

const WB01SigninSub2 = observer(
  ({
    companyIdEmail,
    setCompanyIdEmail,
    companyIdEmailNum,
    setCompanyIdEmailNum,
    companyRandomNum,
    setCompanyRandomNum,
    companyIdEmailNumDone,
    setCompanyIdEmailNumDone,
    isSendIdEmailNum,
    setIsSendIdEmailNum,
    remainingTime,
    setRemainingTime,
    companyPassword,
    setCompanyPassword,
    companyPasswordCheck,
    setCompanyPasswordCheck,
    passwordValid,
    setPasswordValid,
    passwordMatch,
    setPasswordMatch,
    readPassword,
    setReadPassword,
    readPasswordCheck,
    setReadPasswordCheck,
    clickEmailValid,
    setClickEmailValid,
    setSignin1,
    setSignin2,
    setSignin3,
  }) => {
    const isEmailValid = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    };

    const onSendIdEmailNum = async () => {
      if (isEmailValid(companyIdEmail)) {
        setIsSendIdEmailNum(true);
        setClickEmailValid(false);
        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/member/email";

          const response = await axios.get(endPoint, {
            params: { email: companyIdEmail },
          });
          amplitude.track("회원가입2 - 이메일 인증 번호 전송");
          setCompanyRandomNum(response.data.code);
        } catch (error) {
          console.log("Send email number error", error);
        }
      }
    };

    const debouncedOnSendIdEmailNum = debounce(onSendIdEmailNum, 1000);

    const onResendIdEmailNum = () => {
      if (isSendIdEmailNum) {
        setClickEmailValid(false);
        debouncedOnSendIdEmailNum();
        setRemainingTime(180);
      }
    };
    const debouncedOnResendIdEmailNum = debounce(onResendIdEmailNum, 1000);
    const onCheckIdEmailNum = () => {
      setClickEmailValid(true);
      if (companyRandomNum === companyIdEmailNum) {
        setCompanyIdEmailNumDone(true);
      } else {
        setCompanyIdEmailNumDone(false);
      }
    };

    const onPressReadPassword = () => {
      setReadPassword(!readPassword);
    };

    const onPressReadPasswordCheck = () => {
      setReadPasswordCheck(!readPasswordCheck);
    };

    useEffect(() => {
      const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?^()&])[A-Za-z\d@$!%*^()#?&]{8,}$/;
      const isPasswordValid = passwordPattern.test(companyPassword);
      setPasswordValid(isPasswordValid);
      if (isPasswordValid && companyPassword === companyPasswordCheck) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }, [companyPassword, companyPasswordCheck, setPasswordMatch, setPasswordValid]);

    useEffect(() => {
      let timerInterval;

      if (!companyIdEmailNumDone && isSendIdEmailNum) {
        timerInterval = setInterval(() => {
          if (remainingTime > 0) {
            setRemainingTime((prevTime) => prevTime - 1);
          }
        }, 1000); // 1초마다 감소
      } else {
        clearInterval(timerInterval);
      }
      return () => clearInterval(timerInterval);
    }, [remainingTime, companyIdEmailNumDone, isSendIdEmailNum, setRemainingTime]);

    const onClickBack = () => {
      amplitude.track("회원가입1 - 약관동의 페이지 진입");
      setSignin2(false);
      setSignin1(true);
    };

    const onClickNext = () => {
      if (passwordMatch === true && companyIdEmailNumDone === true) {
        amplitude.track("회원가입2 - 이메일/비밀번호 등록 완료", {
          이메일: companyIdEmail,
          비밀번호: companyPasswordCheck,
        });
        amplitude.track("회원가입3 - 기업/기관 필수 정보 등록 페이지 진입");
        setSignin2(false);
        setSignin3(true);
      } else if (passwordMatch === true && companyIdEmailNumDone !== true) {
        alert("이메일을 설정해주세요.");
      } else if (passwordMatch !== true && companyIdEmailNumDone === true) {
        alert("비밀번호를 설정해주세요.");
      } else {
        alert("이메일, 비밀번호를 설정해주세요.");
      }
    };

    return (
      <>
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">
            아이디로 사용될 이메일 주소와
            <br />
            비밀번호를 입력해주세요.
          </div>
          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">이메일</div>
                <div className={companyIdEmailNumDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <div className="Signin_InputField_CT">
                <input
                  className="ProfileDetail_ShortInput_BG"
                  value={companyIdEmail}
                  onChange={(e) => setCompanyIdEmail(e.target.value)}
                  placeholder="담당자 이메일을 입력해주세요."
                  readOnly={remainingTime > 0 && companyIdEmailNumDone === true}
                  name="email"
                  required
                />
                {!isSendIdEmailNum ? (
                  <button type="button" className="ProfileDetail_UploadButton" onClick={!companyIdEmailNumDone ? debouncedOnSendIdEmailNum : null}>
                    인증번호 전송
                  </button>
                ) : (
                  <button type="button" className="ProfileDetail_UploadButton" onClick={!companyIdEmailNumDone ? debouncedOnResendIdEmailNum : null}>
                    인증번호 재전송
                  </button>
                )}
              </div>

              {isSendIdEmailNum ? (
                <div className="ProfileDetail_AddressDetail_CT">
                  <div className="Signin_IdEmail_Num">
                    <div className="Signin_InputField_CT">
                      <input
                        className="ProfileDetail_ShortInput_BG"
                        value={companyIdEmailNum}
                        onChange={(e) => setCompanyIdEmailNum(e.target.value)}
                        placeholder="인증번호를 입력해주세요."
                        readOnly={remainingTime > 0 && companyIdEmailNumDone === true}
                        required
                      />
                      <button type="button" className="ProfileDetail_UploadButton" onClick={!companyIdEmailNumDone ? onCheckIdEmailNum : null}>
                        확인
                      </button>
                    </div>
                    {remainingTime > 0 && companyIdEmailNumDone === true ? (
                      <div className="Signin_Match_Copy">인증이 완료되었습니다.</div>
                    ) : remainingTime > 0 && !companyIdEmailNumDone && clickEmailValid === false ? (
                      <div className="Signin_Error_Copy">
                        {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초
                      </div>
                    ) : remainingTime > 0 && !companyIdEmailNumDone && clickEmailValid === true ? (
                      <div className="Signin_Error_Copy">
                        {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초 | 인증번호가 일치하지 않습니다.
                      </div>
                    ) : (
                      <div className="Signin_Error_Copy">인증시간이 만료되었습니다.</div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">비밀번호</div>
                <div className={passwordMatch ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <div className="Signin_Password_Contents">
                <div className="Signin_LongInput_BG_None">
                  <InputField
                    type={readPassword ? "text" : "password"}
                    value={companyPassword}
                    onChangeFunction={(e) => setCompanyPassword(e.target.value)}
                    onPressPassword={onPressReadPassword}
                    className={"ProfileDetail_LongInput_None"}
                    placeholder={"비밀번호를 입력해주세요."}
                    required
                    password
                    autoComplete={"new-password"}
                    isRead={readPassword}
                  />
                </div>
              </div>
              {!passwordValid && (
                <div className="Signin_PasswordInvalid_Copy">최소 8자 이상 / 영문 대문자, 소문자, 숫자, 특수문자 중 3가지 이상 포함</div>
              )}
              <div className="ProfileDetail_AddressDetail_CT">
                <div className="Signin_LongInput_BG_None">
                  <InputField
                    type={readPassword ? "text" : "password"}
                    value={companyPasswordCheck}
                    onChangeFunction={(e) => setCompanyPasswordCheck(e.target.value)}
                    onPressPassword={onPressReadPasswordCheck}
                    className={"ProfileDetail_LongInput_None"}
                    placeholder={"비밀번호를 한번 더 입력해주세요."}
                    autoComplete={"new-password"}
                    name="password"
                    required
                  />
                </div>
              </div>
              {passwordMatch ? (
                <div className="Signin_Match_Copy">비밀번호가 일치합니다.</div>
              ) : (companyPasswordCheck.length > 0) & !passwordMatch ? (
                <div className="Signin_Error_Copy">비밀번호가 불일치합니다.</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">2/5</div>
        </div>
      </>
    );
  }
);

export default WB01SigninSub2;
