import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const InputField = ({
  value,
  onChangeFunction,
  onPressSearch,
  onPressPassword,
  isRead,
  search,
  clear,
  password,
  placeholder,
  className,
  type,
  required,
  autoComplete,
}) => {
  const handleClear = () => {
    clear();
  };

  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChangeFunction}
        required={required}
        autoComplete={autoComplete}
      />
      {search ? (
        <div className="Icon_CT" onClick={value.length > 0 ? handleClear : onPressSearch}>
          {value.length > 0 ? <Icon name={"Close"} /> : <Icon name={"Search"} />}
        </div>
      ) : null}
      {password ? (
        <div className="Icon_CT" onClick={onPressPassword}>
          {isRead ? <Icon name={"ReadNone"} /> : <Icon name={"Read"} />}
        </div>
      ) : null}
    </>
  );
};

export default InputField;
