const CertedLogo = () => {
  return (
    <svg viewBox="0 0 486 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M438.629 113.343C419.496 113.343 404.529 98.2839 404.529 70.535C404.529 42.0981 420.113 27.8796 438.629 27.8796C452.67 27.8796 459.382 36.1355 462.391 42.8625H463.162V1.27734H485.998V111.967H463.471V98.6661H462.391C459.228 105.546 452.284 113.343 438.629 113.343ZM427.982 70.3821C427.905 85.0592 434.154 95.2262 445.727 95.3026C457.145 95.2262 463.625 85.365 463.625 70.3821C463.625 55.4757 457.222 45.9202 445.727 45.9202C434.077 45.9202 427.905 55.9343 427.982 70.3821Z"
        fill="#3769FC"
      />
      <path
        d="M358.002 113.649C332.312 113.573 316.574 97.1375 316.651 70.841C316.574 45.2325 332.543 27.8799 357.076 27.8799C379.064 27.8799 396.422 41.6397 396.422 69.9237V76.3449H339.178C339.178 88.8816 346.661 96.6024 358.465 96.6789C366.257 96.6024 371.812 93.2389 374.049 88.2701H395.805C392.565 103.635 378.678 113.573 358.002 113.649ZM339.178 62.1265H374.821C374.743 52.036 367.723 44.7739 357.539 44.8503C347.047 44.7739 339.641 52.5711 339.178 62.1265Z"
        fill="#3769FC"
      />
      <path
        d="M308.869 28.9495V45.9199H293.131V86.4349C293.054 93.0854 296.371 94.6907 301 94.8436C303.314 94.8436 307.326 94.6143 310.104 94.385V112.273C307.635 112.655 303.546 113.114 297.914 113.037C281.404 113.114 270.141 105.775 270.295 89.6455V45.9199H258.723V28.9495H270.295V9.07422H293.131V28.9495H308.869Z"
        fill="#3769FC"
      />
      <path
        d="M201.01 111.967V28.9497H223.074V43.321H224C227.009 33.154 234.801 27.7266 244.213 27.7266C246.527 27.7266 249.459 28.0323 251.465 28.491V48.672C249.305 47.984 244.907 47.4489 241.744 47.4489C231.406 47.4489 223.769 54.5582 223.846 64.7251V111.967H201.01Z"
        fill="#3769FC"
      />
      <path
        d="M152.241 113.649C126.55 113.573 110.812 97.1375 110.889 70.841C110.812 45.2325 126.782 27.8799 151.315 27.8799C173.302 27.8799 190.66 41.6397 190.66 69.9237V76.3449H133.416C133.416 88.8816 140.9 96.6024 152.703 96.6789C160.495 96.6024 166.05 93.2389 168.287 88.2701H190.043C186.803 103.635 172.916 113.573 152.241 113.649ZM133.416 62.1265H169.059C168.982 52.036 161.961 44.7739 151.778 44.8503C141.285 44.7739 133.879 52.5711 133.416 62.1265Z"
        fill="#3769FC"
      />
      <path
        d="M103.006 74.4488H68.0346V111.842L103.006 74.4488Z"
        fill="#7EE5C3"
      />
      <path
        d="M77.0311 40.1452H100.543C97.4489 14.6331 77.9591 0 52.1274 0C22.5834 0 0 20.7621 0 57C0 93.0847 22.1967 114 52.1274 114C57.8944 114 63.2073 113.237 68.0346 111.842C68.0346 110.057 68.0346 107.746 68.0346 103.439V95.5615C68.0346 91.675 68.0346 95.0363 68.0346 90.6247C68.0346 89.8381 68.0346 89.2592 68.0346 89.0722C63.8505 92.008 58.5018 93.5846 52.4368 93.621C35.3446 93.5444 23.6662 80.9032 23.6662 57C23.6662 33.6331 35.1899 20.4556 52.5915 20.379C65.662 20.4556 75.1749 27.8871 77.0311 40.1452Z"
        fill="#3769FC"
      />
    </svg>
  );
};

export default CertedLogo;
