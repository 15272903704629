import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB61NewEmployeeSub4 = observer(
  ({ sealImageSrc, setSealImageSrc, sealImageSrcDone, setSealImageSrcDone, setNewEmployee3, setNewEmployee4, setNewEmployee5, seal }) => {
    const sealImageInput = useRef();

    const onCickSealImageUpload = async () => {
      sealImageInput.current.click();
    };

    const onSealUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        e.target.value = null;
      } else {
        setSealImageSrc(e.target.files[0]);
      }
    };

    const onClickNext = () => {
      if (seal === "" && sealImageSrc.length < 1) {
        alert("필수 파일을 등록해주세요.");
      } else {
        amplitude.track("입사 키트 발급4 - 기업/기관 직인 등록 완료", {
          직인: sealImageSrc ? sealImageSrc : "회원 가입시 직인 등록",
        });
        amplitude.track("입사 키트 발급5 - 입사 서류 선택 페이지 진입");
        setNewEmployee4(false);
        setNewEmployee5(true);
      }
    };
    const onClickBack = () => {
      amplitude.track("입사 키트 발급3 - 입사자 이름/전화번호 등록 페이지 진입");
      setNewEmployee4(false);
      setNewEmployee3(true);
    };
    useEffect(() => {
      if (sealImageSrc) {
        setSealImageSrcDone(true);
      } else {
        setSealImageSrcDone(false);
      }
    }, [sealImageSrc, setSealImageSrcDone]);

    return (
      <div className="AllContent_Default_V2">
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">기업/기관의 직인 파일을 모두 등록해주세요.</div>
          <div className="Leave_Sub_Copy">동의서 및 계약서에 사용될 직인 파일을 업로드해주세요.</div>
          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">직인</div>
                <div className="Profile_Label_Copy" style={{ fontWeight: 400, marginLeft: 4, fontSize: 14 }}>
                  (png 파일)
                </div>
                <div className={sealImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <div className="ProfileDetail_UploadButton_CT" style={{ marginBottom: 8 }}>
                <input className="ProfileDetail_ShortInput_BG " value={sealImageSrc ? sealImageSrc.name : ""} readOnly />
                <input
                  accept=".png"
                  type="file"
                  onChange={(e) => onSealUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={sealImageInput}
                  max-size="5242880"
                  name="seal"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onCickSealImageUpload}>
                  이미지 업로드
                </button>
              </div>
              <div className="Signin_Match_Copy" style={{ marginTop: 0 }}>
                증명서를 발급할 때 사용되는 직인 파일을 업로드 해야합니다.
              </div>
            </div>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">2/3</div>
        </div>
      </div>
    );
  }
);

export default WB61NewEmployeeSub4;
