import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";

const ConfidentialityAgreement = observer(
  ({
    setConfidentialityAgreementInfo,
    inputCompanyName,
    companyRepresentName,
    setInputCompanyName,
    setCompanyRepresentName,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        companyRepresentName.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address?.length > 0 &&
        selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate?.length === 8
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 상호명을 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.ConfidentialityAgreement) {
            updatedData.Documents.ConfidentialityAgreement = {};
          }
          updatedData.Documents.ConfidentialityAgreement[nestedField] = value;
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.TelNumber, "TelNumber");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);
        const selectedEmployeeDataCopy = { ...selectedEmployeeData };
        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.ConfidentialityAgreement.TelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.ConfidentialityAgreement?.TelNumber
        );
        selectedEmployeeDataCopy.Documents.ConfidentialityAgreement.Birthday = AddDotYYYYMMDD(
          selectedEmployeeDataCopy?.Documents?.ConfidentialityAgreement?.Birthday
        );

        setTimeout(async () => {
          try {
            const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/pdf/confidentiality-agreement";
            const response = await axios.post(endPoint, {
              email: companyEmail,
              telNumber: selectedEmployeeDataCopy?.TelNumber,
              name: selectedEmployeeData?.Name,
              data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.ConfidentialityAgreement),
              ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
            });
            if (response.data.message === "보안유지서약서 생성 완료") {
              handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
              setTimeout(async () => {
                setIsLoading(false);
                setConfidentialityAgreementInfo(false);
                alert("보안유지 서약서 작성을 완료했습니다.");
              }, 100);
            } else {
              setIsLoading(false);
              setConfidentialityAgreementInfo(false);
              alert("보안유지 서약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
            }
          } catch (error) {
            setIsLoading(false);
            setConfidentialityAgreementInfo(false);
            alert("보안유지 서약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
            console.log("ConfidentialityAgreement ERROR : ", error);
          }
        }, 100);
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">보안유지 서약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setConfidentialityAgreementInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <div className="ContractInput_Body_Copy">
                  {inputCompanyName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{inputCompanyName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  대표이사{" "}
                  {companyRepresentName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{companyRepresentName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  귀증
                  <br />
                  {selectedEmployeeData?.Name?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Name}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  (이하 '본인') 은(는){" "}
                  {inputCompanyName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{inputCompanyName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  (이하 '회사')에 입사합에 있어 다음과 같이 서약합니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 항
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본인은 업무상 지득한 영업비밀이 회사에 귀속됨을 확인하며, 계약기간 및 퇴사 후 이를 누설 또는 공개하거나 부정한 이익을 얻을 목적으로
                  이를 사용하지 않을 것임을 서약합니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 항
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  제1항에 따라 본인이 비밀로 유지하여야 할 회사의 영업비밀이란 다음 각호의 사항을 포함하나, 이에 국한되지 않음을 인정합니다.
                </div>
                <div className="ContractInput_SubBody_CT">
                  <div className="ContractInput_Dot_BG" />
                  <div className="ContractInput_SubBody_Copy">
                    회사를 위해 또는 회사에 의해 개발되었거나, 제3자가 회사에게 사용권을 부여한 모든 소프트웨어와 기술 또는 그러한 소프트웨어와 기술에
                    관한 문서들, 여기서 ‘소프트웨어’라 함은 그러한 소프트웨어를 담고 있는 제품을 포함하며, 또한 프로그램의 문자요소(source code,
                    object code 등등), 시청각 요소(메뉴, 화면, 구조 등), 기타 인간이나 기계가 인지할 수 있는 프로그램 형식, 프로그램 또는 정보가
                    기록되거나 표현되어 있는 도형·도표·디자인·도면·설계명세서·모델·데이터 그리고 고장 보고와 고객 정보 등을 포함한다.
                  </div>
                </div>
                <div className="ContractInput_SubBody_CT">
                  <div className="ContractInput_Dot_BG" />
                  <div className="ContractInput_SubBody_Copy">
                    회사의 영업 계획, 제품개발 계획, 경쟁력 분석, 사업 및 재정계획, 기타 비공식적인 재무정보 및 그에 관한 자료
                  </div>
                </div>
                <div className="ContractInput_SubBody_CT">
                  <div className="ContractInput_Dot_BG" />
                  <div className="ContractInput_SubBody_Copy">
                    회사의 계약서·주문서·승인서 양식과 제안·입찰·작업현황·가격제안·견적·구매주문 등에 관한 정보와 자료
                  </div>
                </div>
                <div className="ContractInput_SubBody_CT">
                  <div className="ContractInput_Dot_BG" />
                  <div className="ContractInput_SubBody_Copy">
                    회사의 제품 개발과 관련되거나 또는 회사의 구매, 회계, 거래와 라이선스에 관련된 모든 정보와 자료
                  </div>
                </div>
                <div className="ContractInput_SubBody_CT">
                  <div className="ContractInput_Dot_BG" />
                  <div className="ContractInput_SubBody_Copy">
                    회사가 소유하거나 개발했는지 여부와 상관없이 회사가 비밀로 유지하여야 할 법적인 책임이 있거나 회사가 비밀사항으로 지정하거나
                    비밀사항으로 취급하고 있는 모든 정보와 자료
                  </div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 항
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본인은 근무 중 본인이 개발 또는 생산하였는지의 여부를 불문하고 회사의 문서, 소프트웨어, 디스크, 디스켓, 테이프, 데이터, 노트, 서신
                  및 그 사본 또는 복제본, 기타 지적 자산의 소유권이 회사에 있음을 확인합니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 항
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본인은 위 내용을 충분히 숙지하였고 이를 성실히 준수하겠으며, 만약 위 내용을 위반하였을 경우에는 부정경쟁방지 및 영업비밀보호에 관한
                  법률 및 기타 관련법령에 따라 회사에 대하여 손해배상책임을 포함한 어떠한 민·형사상의 책임을 이행할 것을 서약합니다.
                </div>

                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.ConfidentialityAgreement.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __일
                      </span>
                    )}
                  </div>
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "24px" }}>
                  위 서약인
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주소 : {selectedEmployeeData?.Address || ""}
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  생년월일 : {AddDotYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    성명 : {selectedEmployeeData?.Name || ""}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="기업/기관명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Birthday &&
                          selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>

                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Address &&
                        selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ConfidentialityAgreement?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="NewEmployeeAddress"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate &&
                      selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ConfidentialityAgreement?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ConfidentialityAgreement;
