import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const JuvenileContractInfoModal = ({ setJuvenileContractInfo }) => {
  return (
    <div className="Signin_CompleteModal_BG">
      <div className="CheckList_InfoModal_CT">
        <div className="CheckList_InfoModal_Text_CT">
          <div className="CheckList_InfoModal_Title_CT">
            <div className="CheckList_InfoModal_Title_Copy">청소년(연소자) 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setJuvenileContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="CheckList_InfoModal_Divider"> </div>
          <div className="CheckList_InfoModal_Body_Copy">
            청소년(연소자)의 경우 <span style={{ fontWeight: 600 }}>1일 소정근로시간 7시간, 1주 35시간</span>을 초과하여 근로를 제공할 수 없으므로
            근로계약서 작성 시 유의해야 합니다.
          </div>
          <div className="CheckList_InfoModal_Body_Copy">
            또한 청소년과 근로계약을 체결할 경우, 친권자 또는 후견인의 동의서가 필요하므로 근로계약서 아래에 첨부되어 있는
            <span style={{ fontWeight: 600 }}>친권자(후견인)동의서</span>를 연소근로자 근로계약 체결 시 반드시 함께 작성해야 하며, 근로계약서 작성 후
            교부하는 것도 필수입니다.
          </div>
          <div className="CheckList_InfoModal_Warning_CT ">
            <Icon name={"WarningIcon"} color={"#FF7373"} />
            <div
              className="CheckList_InfoModal_Body_Copy"
              style={{
                lineHeight: "100%",
                marginTop: "0px",
                marginLeft: "4px",
              }}
            >
              미작성 시 과태료 500만원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JuvenileContractInfoModal;
