import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Loading from "../Components/Loading";
import DetailTitle from "../Components/DetailTitle";
import ReferSelectList from "../Components/ReferSelectList";
import InvestorCompanyShareholderReport from "../IssueDocument/InvestorCompanyShareholderReport";
import axios from "axios";

const WBc1InvestorCompanyShareholderReportList = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyProfileData = location.state.companyProfileData;
  // const companyData = location.state.company;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleReport, setIsVisibleReport] = useState(false);
  const [investorCompanyShareholderReportList, setInvestorCompanyShareholderReportList] = useState();

  const [reportName, setReportName] = useState("");
  const [reportYearMonth, setReportYearMonth] = useState("");

  const [newMembershipRegistration, setNewMembershipRegistration] = useState("");
  const [newPaidMember, setNewPaidMember] = useState("");
  const [accumulatedPaidMember, setAccumulatedPaidMember] = useState("");
  const [breakawayCustomer, setBreakawayCustomer] = useState("");
  const [monthlyRecurringRevenue, setMonthlyRecurringRevenue] = useState("");
  const [churnMonthlyRecurringRevenue, setChurnMonthlyRecurringRevenue] = useState("");
  const [netMonthlyRecurringRevenue, setNetMonthlyRecurringRevenue] = useState("");

  const [totalBalance, setTotalBalance] = useState("");
  const [investmentFund, setInvestmentFund] = useState("");
  const [supportFund, setSupportFund] = useState("");

  const [totalIncome, setTotalIncome] = useState("");
  const [incomeData, setIncomeData] = useState([]);

  const [totalSpending, setTotalSpending] = useState("");
  const [fixedCost, setFixedCost] = useState("");
  const [variableCost, setVariableCost] = useState("");

  const [followUpInvestments, setFollowUpInvestments] = useState([]);
  const [subsidies, setSubsidies] = useState([]);

  const [keyMonthlyPlan, setKeyMonthlyPlan] = useState("");
  const [sharedIssue, setSharedIssue] = useState("");

  useEffect(() => {
    const getInvestorCompanyShareholderReportList = async () => {
      try {
        setIsLoading(true);
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/contrack/shareholder-report";
        const response = await axios.get(endPoint, {
          params: { email: companyProfileData.Email },
        });
        setIsLoading(false);
        if (response.data.status === "주주보고서 조회 성공") {
          setInvestorCompanyShareholderReportList(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("Get Shareholder Report Error : ", error);
        setIsLoading(false);
        alert("주주보고서 목록 조회 통신에 실패하였습니다.\n로그아웃 후 다시 한번 시도해주세요.");
      }
    };
    if (!investorCompanyShareholderReportList) {
      getInvestorCompanyShareholderReportList();
    }
  }, [companyProfileData, investorCompanyShareholderReportList]);

  const handleItemClick = (certificateData) => {
    navigate(`/investorCompanyShareholderReportDetail/${certificateData.ID}`, {
      state: {
        item: certificateData,
      },
    });
  };

  const onClickIssueReport = () => {
    setIsVisibleReport(true);
  };

  const sortedReports =
    Array.isArray(investorCompanyShareholderReportList) && investorCompanyShareholderReportList.length > 0
      ? investorCompanyShareholderReportList
          .map((report) => ({
            ...report, // 기존 객체의 나머지 속성들을 유지
            ReportYearMonth: parseInt(report.ReportYearMonth, 10), // ReportYearMonth 값을 숫자로 변환
          }))
          .sort((a, b) => b.ReportYearMonth - a.ReportYearMonth)
      : [];
  console.log("sortedReports", sortedReports);
  return (
    <>
      {isVisibleReport && (
        <InvestorCompanyShareholderReport
          setIsVisibleReport={setIsVisibleReport}
          reportYearMonth={reportYearMonth}
          setReportYearMonth={setReportYearMonth}
          reportName={reportName}
          setReportName={setReportName}
          newMembershipRegistration={newMembershipRegistration}
          setNewMembershipRegistration={setNewMembershipRegistration}
          newPaidMember={newPaidMember}
          setNewPaidMember={setNewPaidMember}
          accumulatedPaidMember={accumulatedPaidMember}
          setAccumulatedPaidMember={setAccumulatedPaidMember}
          breakawayCustomer={breakawayCustomer}
          setBreakawayCustomer={setBreakawayCustomer}
          monthlyRecurringRevenue={monthlyRecurringRevenue}
          setMonthlyRecurringRevenue={setMonthlyRecurringRevenue}
          churnMonthlyRecurringRevenue={churnMonthlyRecurringRevenue}
          setChurnMonthlyRecurringRevenue={setChurnMonthlyRecurringRevenue}
          netMonthlyRecurringRevenue={netMonthlyRecurringRevenue}
          setNetMonthlyRecurringRevenue={setNetMonthlyRecurringRevenue}
          totalBalance={totalBalance}
          setTotalBalance={setTotalBalance}
          investmentFund={investmentFund}
          setInvestmentFund={setInvestmentFund}
          supportFund={supportFund}
          setSupportFund={setSupportFund}
          totalIncome={totalIncome}
          setTotalIncome={setTotalIncome}
          incomeData={incomeData}
          setIncomeData={setIncomeData}
          totalSpending={totalSpending}
          setTotalSpending={setTotalSpending}
          fixedCost={fixedCost}
          setFixedCost={setFixedCost}
          variableCost={variableCost}
          setVariableCost={setVariableCost}
          followUpInvestments={followUpInvestments}
          setFollowUpInvestments={setFollowUpInvestments}
          subsidies={subsidies}
          setSubsidies={setSubsidies}
          keyMonthlyPlan={keyMonthlyPlan}
          setKeyMonthlyPlan={setKeyMonthlyPlan}
          sharedIssue={sharedIssue}
          setSharedIssue={setSharedIssue}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="FlexContent_Other_V">
        <DetailTitle title={`${companyProfileData.CompanyName} 주주보고서`} />
        <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
          {companyProfileData && (
            <>
              <div className="Detail_Title_BG">
                <div className="Detail_Title_Contents">
                  <div className="Detail_Title_LeftContents"></div>
                  <div className="Detail_Title_RightContents">
                    <button
                      type="button"
                      className="TitleBG_Button"
                      style={{
                        width: "120px",
                        color: "#FFFFFF",
                        backgroundColor: "rgb(55, 105, 252)",
                      }}
                      onClick={onClickIssueReport}
                    >
                      주주보고서 작성
                    </button>
                  </div>
                </div>
              </div>
              <div className="DetailTitle_Line" style={{ marginTop: "0px" }} />
              <div className="ResignerDetail_Contents_CT">
                {isLoading ? (
                  <div className="Wait_Lists_CT">
                    <Loading />
                  </div>
                ) : !isLoading && investorCompanyShareholderReportList?.length === 0 ? (
                  <div className="Wait_NoneItem_Copy">주주보고서가 없습니다.</div>
                ) : !isLoading && investorCompanyShareholderReportList?.length > 0 ? (
                  <ReferSelectList
                    labelList={["주주보고서 명", "주주보고서 시기", "작성일"]}
                    handleItemClick={handleItemClick}
                    listData={sortedReports}
                  />
                ) : (
                  <div className="Wait_NoneItem_Copy">주주보고서가 없습니다.</div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default WBc1InvestorCompanyShareholderReportList;
