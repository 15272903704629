const ArrowForward200 = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7002 41.6497C14.4669 41.4164 14.3502 41.1247 14.3502 40.7747C14.3502 40.4247 14.4669 40.133 14.7002 39.8997L30.6502 23.9497L14.7002 7.99971C14.4669 7.76638 14.3419 7.48305 14.3252 7.14971C14.3085 6.81638 14.4335 6.51638 14.7002 6.24971C14.9669 6.01638 15.2752 5.89138 15.6252 5.87471C15.9752 5.85805 16.2669 5.96638 16.5002 6.19971L33.2502 22.9497C33.4169 23.1164 33.5335 23.283 33.6002 23.4497C33.6669 23.6164 33.7002 23.783 33.7002 23.9497C33.7002 24.1497 33.6669 24.3247 33.6002 24.4747C33.5335 24.6247 33.4169 24.7664 33.2502 24.8997L16.5002 41.6997C16.2669 41.933 15.9835 42.0497 15.6502 42.0497C15.3169 42.0497 15.0002 41.9164 14.7002 41.6497V41.6497Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ArrowForward200;
