import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DetailTitle from "../Components/DetailTitle";
import DetailList from "../Components/DetailList";
import { observer } from "mobx-react";
import { AddDotYYYYMMDD } from "../Utils/ConvertNumberFormat";
import PDFInputField from "../Components/PDFInputField";
import DetailHead from "../Components/DetailHead";

const WB73ResignerDetail = observer(() => {
  const location = useLocation();
  const itemData = location.state.item;
  const [isExpandMore, setIsExpandMore] = useState(false);

  const earnedIncomeWithholdingTaxReceiptURL = itemData.Documents.find((doc) => doc.DocumentName === "EarnedIncomeWithholdingTaxReceipt").Url;
  const payStubURL = itemData.Documents.find((doc) => doc.DocumentName === "PayStub").Url;
  const severancePaySettlementDetailsURL = itemData.Documents.find((doc) => doc.DocumentName === "SeverancePaySettlementDetails").Url;
  const careerCertificateURL = itemData.Documents.find((doc) => doc.DocumentName === "CareerCertificatePDF").Url;

  function calculateYearsAndMonthsWorked(startDate, endDate) {
    const startDateObj = new Date(startDate.substring(0, 4) + "-" + startDate.substring(4, 6) + "-" + startDate.substring(6));
    const endDateObj = new Date(endDate.substring(0, 4) + "-" + endDate.substring(4, 6) + "-" + endDate.substring(6));
    const totalMonthsWorked = (endDateObj - startDateObj) / (1000 * 60 * 60 * 24 * 30.44);
    let years = Math.floor(totalMonthsWorked / 12);
    let months = Math.round(totalMonthsWorked % 12);
    if (months >= 12) {
      years++;
      months = 0;
    }
    return { years, months };
  }
  const { years, months } = calculateYearsAndMonthsWorked(itemData.AcqDate, itemData.LoseDate);
  const secondBodyText = [itemData?.Job[1]?.Job, itemData?.Job[2]?.Job, itemData?.Job[3]?.Job, itemData?.Job[4]?.Job].filter((job) => job).join("\n");

  return (
    <div className="FlexContent_Other_V">
      <DetailTitle title={"상세 정보"} />
      <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
        {itemData && (
          <>
            <DetailHead itemData={itemData} labelList={["이름", "생년월일", "재직기간", "발급일"]} />
            <div className="ResignerDetail_Contents_CT">
              <div className="CertificateCard_BG_ResignerDetail">
                <DetailList label={"포지션"} bodyText={itemData.Position} />
                {months > 0 ? (
                  <DetailList
                    label={"재직기간"}
                    bodyText={years > 0 ? `${years}년 ${months}개월` : `${months}개월`}
                    detailText={
                      itemData.LoseDate.length > 0
                        ? `${AddDotYYYYMMDD(itemData.AcqDate)} ~ ${AddDotYYYYMMDD(itemData.LoseDate)}`
                        : `${AddDotYYYYMMDD(itemData.AcqDate)} ~ 현재`
                    }
                  />
                ) : (
                  <DetailList
                    label={"재직기간"}
                    bodyText={`${years}년`}
                    detailText={
                      itemData.LoseDate.length > 0
                        ? `${AddDotYYYYMMDD(itemData.AcqDate)} ~ ${AddDotYYYYMMDD(itemData.LoseDate)}`
                        : `${AddDotYYYYMMDD(itemData.AcqDate)} ~ 현재`
                    }
                  />
                )}
                <DetailList label={"부서"} bodyText={itemData.Department} />
                <DetailList label={"직위/직급"} bodyText={itemData.JobPosition} />
                {itemData.Job.length > 0 ? (
                  <>
                    {secondBodyText && (
                      <>
                        <DetailList
                          label={"직무/업무"}
                          bodyText={itemData.Job[0].Job}
                          state={"extendable"}
                          isExpandMore={isExpandMore}
                          setIsExpandMore={setIsExpandMore}
                          secondBodyText={secondBodyText}
                        />
                      </>
                    )}
                    {!secondBodyText && (
                      <>
                        <DetailList label={"직무/업무"} bodyText={itemData.Job[0].Job} />
                      </>
                    )}
                  </>
                ) : null}
              </div>
              <div className="ResignerDetail_Vertical_Divider"></div>
              <div className="ResignerDetail_Contents_Right_CT">
                <div className="Profile_Label_CT" style={{ marginBottom: "12px" }}>
                  <div className="Profile_Label_Copy">발급된 문서</div>
                </div>

                {earnedIncomeWithholdingTaxReceiptURL && (
                  <PDFInputField name={"근로소득 원천징수 영수증"} link={earnedIncomeWithholdingTaxReceiptURL} />
                )}
                {payStubURL && <PDFInputField name={"급여명세서"} link={payStubURL} />}
                {severancePaySettlementDetailsURL && <PDFInputField name={"퇴직금 정산 내역서"} link={severancePaySettlementDetailsURL} />}
                {careerCertificateURL && <PDFInputField name={"경력증명서"} link={careerCertificateURL} />}
                {!payStubURL && !severancePaySettlementDetailsURL && !careerCertificateURL && !earnedIncomeWithholdingTaxReceiptURL ? (
                  <div className="Wait_NoneItem_Copy">발급된 문서가 없습니다.</div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default WB73ResignerDetail;
