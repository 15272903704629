import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React from "react";
import Icon from "../../Assets/Icons/Icon";
import BasicText from "../Atom/BasicText";

const WidgetNavigation = ({ previousButtonLabel, onClickPreviousButton, nextButtonLabel, onClickNextButton }) => {
  return (
    <div className="WidgetNavigation_Container">
      <div className="WidgetNavigation_ButtonContainer" style={{ justifyContent: previousButtonLabel ? "space-between" : "flex-end" }}>
        {previousButtonLabel && (
          <button className="WidgetNavigation_Button" onClick={onClickPreviousButton}>
            <Icon name={"ArrowBack"} size={"16"} color={"#464646"} />
            <BasicText text={previousButtonLabel} fontSize={"16px"} fontWeight={"semiBold"} />
          </button>
        )}
        {nextButtonLabel && (
          <button className="WidgetNavigation_Button" onClick={onClickNextButton}>
            <BasicText text={nextButtonLabel} fontSize={"16px"} fontWeight={"semiBold"} marginRight={"4px"} />
            <Icon name={"ArrowForward"} size={"16"} color={"#464646"} />
          </button>
        )}
      </div>
      <div className="WidgetNavigation_Divider" />
    </div>
  );
};

export default WidgetNavigation;
