const updateUserSalaryInformation = (updatedData, documentName, nestedField, value) => {
  const match = nestedField.match(/SalaryCompositionTable(?:Title|PaymentAmount|PaymentStandard|Note)(\d*)/);
  const removeLastCharacter = (str) => str.slice(0, -1);
  if (match) {
    const index = parseInt(match[1], 10) - 1;
    while (updatedData.Documents[documentName].SalaryInformation.length <= index) {
      updatedData.Documents[documentName].SalaryInformation.push({});
    }
    updatedData.Documents[documentName].SalaryInformation[index][removeLastCharacter(nestedField)] = value;
  } else {
    updatedData.Documents[documentName][nestedField] = value;
  }
};

const updateUserWorkInformation = (updatedData, documentName, nestedField, value) => {
  const match = nestedField.match(/UserWorkInformation(?:Title|PaymentAmount|PaymentStandard|Note)(\d*)/);
  const removeLastCharacter = (str) => str.slice(0, -1);
  if (match) {
    const index = parseInt(match[1], 10) - 1;
    while (updatedData.Documents[documentName].UserWorkInformation.length <= index) {
      updatedData.Documents[documentName].UserWorkInformation.push({});
    }
    updatedData.Documents[documentName].UserWorkInformation[index][removeLastCharacter(nestedField)] = value;
  } else {
    updatedData.Documents[documentName][nestedField] = value;
  }
};

const ensureNestedStructure = (updatedData, documentName, tableData) => {
  if (!updatedData.Documents) {
    updatedData.Documents = {};
  }
  if (!updatedData.Documents[documentName]) {
    updatedData.Documents[documentName] = {};
  }
  if (!updatedData.Documents[documentName][tableData]) {
    updatedData.Documents[documentName][tableData] = [];
  }
};

const ensureAndSetField = (updatedData, documentName, nestedField, value) => {
  if (!updatedData.Documents) {
    updatedData.Documents = {};
  }
  if (!updatedData.Documents[documentName]) {
    updatedData.Documents[documentName] = {};
  }
  updatedData.Documents[documentName][nestedField] = value;
};

export const RemoveEmptyObjects = (obj) => {
  const isObject = (value) => value && typeof value === "object" && !Array.isArray(value);
  if (Array.isArray(obj)) {
    return obj.filter((item) => {
      if (isObject(item)) {
        return Object.values(RemoveEmptyObjects(item)).some((value) => value !== "" && value !== null && value !== undefined);
      }
      return item !== "" && item !== null && item !== undefined;
    });
  }
  if (isObject(obj)) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = RemoveEmptyObjects(obj[key]);
        if (value !== "" && value !== null && value !== undefined) {
          newObj[key] = value;
        }
      }
    }
    return newObj;
  }
  return obj;
};

export const HandleDocumentData = (field, value, nestedField, documentName, setSelectedEmployeeData, setSelectedEmployees) => {
  setSelectedEmployeeData((prevData) => {
    const updatedData = { ...prevData };
    if (nestedField) {
      ensureAndSetField(updatedData, documentName, nestedField, value);
    } else {
      updatedData[field] = value;
    }
    setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));
    return updatedData;
  });
};

export const HandleSalaryDocumentData = (field, value, nestedField, documentName, setSelectedEmployeeData, setSelectedEmployees) => {
  setSelectedEmployeeData((prevData) => {
    const updatedData = { ...prevData };
    if (nestedField) {
      ensureNestedStructure(updatedData, documentName, "SalaryInformation");
      updateUserSalaryInformation(updatedData, documentName, nestedField, value);
    } else {
      updatedData[field] = value;
    }
    setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));
    return updatedData;
  });
};

export const HandleUserWorkDocumentData = (field, value, nestedField, documentName, setSelectedEmployeeData, setSelectedEmployees) => {
  setSelectedEmployeeData((prevData) => {
    const updatedData = { ...prevData };
    if (nestedField) {
      ensureNestedStructure(updatedData, documentName, "UserWorkInformation");
      updateUserWorkInformation(updatedData, documentName, nestedField, value);
    } else {
      updatedData[field] = value;
    }
    setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));
    return updatedData;
  });
};
