import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import InitialLogo from "../Assets/Logo/InitialLogo";
import Icon from "../Assets/Icons/Icon";
import GNBMenuBar from "./GNBMenuBar";
import CertedLogo from "../Assets/Logo/CertedLogo";
import CertedForCompanyLogo from "../Assets/Logo/CertedForCompanyLogo";

const GNBVertical = observer(() => {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();
  const landingPageUrl = "https://www.certifie.io";
  const handleCertifie = () => {
    amplitude.track("써티드 랜딩 페이지 진입");
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };
  const moveToLandingPage = () => {
    amplitude.track("써티드 랜딩 페이지 진입");
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/issueDocument")) {
      setCurrentPage("issueDocument");
    } else if (path.startsWith("/consentDocument")) {
      setCurrentPage("consentDocument");
    } else if (path.startsWith("/resigner")) {
      setCurrentPage("resigner");
    } else if (path.startsWith("/newEmployee")) {
      setCurrentPage("newEmployee");
    } else if (path.startsWith("/companyProfile")) {
      setCurrentPage("companyProfile");
    } else if (path.startsWith("/addEmployee")) {
      setCurrentPage("addEmployeeFile");
    } else if (path.startsWith("/referEmployee")) {
      setCurrentPage("referEmployeeList");
    } else if (path.startsWith("/home")) {
      setCurrentPage("home");
    } else if (path.startsWith("/notification")) {
      setCurrentPage("notification");
    } else if (path.startsWith("/referDocument")) {
      setCurrentPage("referDocumentFullList");
    } else if (path.startsWith("/requestRequiredDocument")) {
      setCurrentPage("requestRequiredDocument");
    } else if (path.startsWith("/investorCompanyShareholderReport")) {
      setCurrentPage("companyProfile");
    } else {
      setCurrentPage("home");
    }
  }, [location]);

  const onClickIssueDocument = () => {
    if (currentPage !== "issueDocument") {
      amplitude.track("문서 발급1 - 직원 선택 페이지 진입");
      navigate("/issueDocument");
    }
  };

  const onClickConsentDocument = () => {
    if (currentPage !== "consentDocument") {
      amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
      navigate("/consentDocument");
    }
  };

  const onClickResigner = () => {
    if (currentPage !== "resigner") {
      amplitude.track("퇴사 키트 발급1 - 안내 페이지1 진입");
      navigate("/resigner");
    }
  };

  const onClickHome = () => {
    if (currentPage !== "home") {
      amplitude.track("홈 스크린 진입");
      navigate("/home");
    }
  };

  const onClickNotification = () => {
    if (currentPage !== "notification") {
      amplitude.track("알림 스크린 진입");
      navigate("/notification");
    }
  };

  const onClickEmployee = () => {
    if (currentPage !== "addEmployeeFile") {
      amplitude.track("직원 등록 (엑셀파일)1 - 엑셀 파일 형식 안내 스크린 진입");
      navigate("/addEmployeeFile");
    }
  };

  const onClickNewEmployee = () => {
    if (currentPage !== "newEmployee") {
      amplitude.track("입사 키트 발급1 - 안내 페이지1 진입");
      navigate("/newEmployee");
    }
  };

  const onClickreferDocumentFullList = () => {
    if (currentPage !== "referDocumentFullList") {
      amplitude.track("문서 조회 (전체) 스크린 진입");
      navigate("/referDocumentFullList");
    }
  };

  const onClickNewProfile = () => {
    if (currentPage !== "companyProfile") {
      amplitude.track("기업/기관 정보 메뉴 진입");
      navigate("/companyProfile");
    }
  };

  const onClickReferEmployeeList = () => {
    if (currentPage !== "referEmployeeList") {
      amplitude.track("직원 조회 메뉴 진입");
      navigate("/referEmployeeList");
    }
  };

  const onClickRequestRequiredDocument = () => {
    if (currentPage !== "requestRequiredDocument") {
      amplitude.track("필수 서류 받기 메뉴 진입");
      navigate("/requestRequiredDocument");
    }
  };

  // const onClickRequestRequiredDocument = () => {
  //   alert("준비 중 입니다.");
  // };

  const onClickLogout = () => {
    localStorage.removeItem("userData");
    amplitude.track("로그아웃");
    navigate("/");
  };

  return (
    <div className="GNB_BG_V">
      <div className="GNB_Upper_V">
        <div className="GNB_LogoCT_V">
          <button className="GNB_Logout_Button" onClick={handleCertifie}>
            <div className="GNB_Logo_V">{screenWidth >= 768 ? <CertedForCompanyLogo /> : <InitialLogo />}</div>
          </button>
        </div>
        <div className="GNB_MenuBar_CT" style={{ marginTop: 24 }}>
          <GNBMenuBar label={"홈"} currentPage={currentPage} currentPageName={"home"} onClick={onClickHome} />
          <GNBMenuBar label={"알림"} currentPage={currentPage} currentPageName={"notification"} onClick={onClickNotification} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <GNBMenuBar label={"직원 등록"} currentPage={currentPage} currentPageName={"addEmployeeFile"} onClick={onClickEmployee} />
          <GNBMenuBar label={"문서 작성"} currentPage={currentPage} currentPageName={"issueDocument"} onClick={onClickIssueDocument} />
          <GNBMenuBar label={"동의 받기"} currentPage={currentPage} currentPageName={"consentDocument"} onClick={onClickConsentDocument} />
          <GNBMenuBar
            label={"필수 서류 받기"}
            currentPage={currentPage}
            currentPageName={"requestRequiredDocument"}
            onClick={onClickRequestRequiredDocument}
          />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <GNBMenuBar label={"입사 키트"} currentPage={currentPage} currentPageName={"newEmployee"} onClick={onClickNewEmployee} />
          <GNBMenuBar label={"퇴사 키트"} currentPage={currentPage} currentPageName={"resigner"} onClick={onClickResigner} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <GNBMenuBar
            label={"문서 조회"}
            currentPage={currentPage}
            currentPageName={"referDocumentFullList"}
            onClick={onClickreferDocumentFullList}
          />
          <GNBMenuBar label={"직원 조회"} currentPage={currentPage} currentPageName={"referEmployeeList"} onClick={onClickReferEmployeeList} />
          <GNBMenuBar label={"회사 정보"} currentPage={currentPage} currentPageName={"companyProfile"} onClick={onClickNewProfile} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <button className="GNB_MenuBar_BG_Off" type="button" onClick={onClickLogout}>
            <div className="GNB_MenuBar_Contents">
              <Icon name={"Logout"} />
              <div className="GNB_MenuBar_Label_Off">로그아웃</div>
            </div>
          </button>
        </div>
      </div>
      <div className="GNB_Lower_V">
        <div className="Footer_Contents_CT_V">
          <button className="Footer_Button" onClick={moveToLandingPage}>
            <div className="Footer_Logo">
              <CertedLogo />
            </div>
          </button>
          <div className="Footer_Texts_V">
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">상호명 | 주식회사 지디피스튜디오</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">대표자 | 이유</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">사업자 등록번호 | 146-87-02284</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">통신판매업신고번호 | 2023-창원성산-0866</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">주소 | 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지 G밸리창업큐브 603 (08590)</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">이메일 | help@certifie.io</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">전화번호 | 010-6212-0225</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">ⓒ 2024 GDP Studio Inc. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GNBVertical;
