import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const WorkTimeTable = ({ type }) => {
  return (
    <table
      className="Email_PDF_Chart_BG"
      style={{
        borderSpacing: "0px",
        borderCollapse: "collapse",
        marginBottom: "8px",
        marginTop: "12px",
      }}
    >
      <tbody>
        <tr>
          <td className="ContractInput_Cell_Label" style={{ width: "72px" }}>
            소정근로일
          </td>
          <td className="ContractInput_Cell_Full_Body" style={{ textAlign: "center" }}>
            {type === "teen" ? "월요일 ~ 목요일 (주 4일)" : "월요일 ~ 금요일 (주 5일)"}
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label" style={{ width: "72px" }}>
            출퇴근시간
          </td>
          <td className="ContractInput_Cell_Full_Body" style={{ textAlign: "center" }}>
            {type === "teen" ? "09:00 ~ 17:00" : "09:00 ~ 18:00"}
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label" style={{ width: "72px" }}>
            휴게시간
          </td>
          <td className="ContractInput_Cell_Full_Body" style={{ textAlign: "center" }}>
            12:00 ~ 13:00
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default WorkTimeTable;
