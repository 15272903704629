const Block = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="auto"
      viewBox="0 -960 960 960"
      width="auto"
    >
      <path
        fill="#E3E3E3"
        d="M480-71.87q-84.674 0-159.109-32.217T191.261-191.5q-55.196-55.196-87.294-129.75T71.869-480.478q0-84.674 32.098-158.989 32.098-74.316 87.294-129.392 55.195-55.076 129.63-87.174Q395.326-888.131 480-888.131t159.109 32.098q74.435 32.098 129.63 87.174 55.196 55.076 87.294 129.392 32.098 74.315 32.098 158.989T856.033-321.25q-32.098 74.554-87.294 129.75-55.195 55.196-129.63 87.413Q564.674-71.869 480-71.869Zm0-91q52.087 0 100.652-16.304t89.13-48.109L226.804-670.022q-31.326 41.044-47.63 89.37-16.304 48.326-16.304 100.174 0 132.804 92.282 225.206Q347.435-162.87 480-162.87Zm253.435-128.065q31.087-41.043 47.391-89.369t16.304-100.174q0-132.565-92.282-224.609Q612.565-797.13 480-797.13q-51.848 0-100.054 16.065-48.207 16.065-89.25 47.152l442.739 442.978Z"
      />
    </svg>
  );
};

export default Block;
