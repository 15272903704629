import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React from "react";
import Icon from "../../Assets/Icons/Icon";
import BasicText from "../Atom/BasicText";
import BasicButton from "../Atom/BasicButton";

const InputField = ({
  property,
  value,
  onValueChange,
  placeholder,
  marginBottom,
  readOnly,
  buttonWidth,
  icon,
  onClickButton,
  buttonLabel,
  buttonProperty,
}) => {
  return (
    <div
      className="InputField_Container"
      style={{
        marginBottom: marginBottom,
      }}
    >
      <input
        type="text"
        className="InputField_Input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        readOnly={readOnly ? true : false}
      />
      {property === "button" && buttonWidth === "onlyIcon" && (
        <button
          type="button"
          className="InputField_Button"
          style={{
            width: "36px",
          }}
          onClick={onClickButton}
        >
          <Icon name={icon} size={"20px"} color={"#3769fc"} />
        </button>
      )}
      {property === "button" && buttonWidth === "small" && (
        // <button
        //   type="button"
        //   className="InputField_Button"
        //   style={{
        //     width: "72px",
        //   }}
        //   onClick={onClickButton}
        // >
        //   {icon && <Icon name={icon} size={"20px"} color={"#3769fc"} />}
        //   <BasicText text={buttonLabel} fontSize={"14px"} fontWeight={"semiBold"} fontColor={"#3769fc"} marginLeft={icon ? "4px" : "0px"} />
        // </button>
        <BasicButton
          property={buttonProperty ? buttonProperty : "solid"}
          label={buttonLabel}
          buttonWidth={"72px"}
          buttonHeight={"small"}
          onClickButton={onClickButton}
          marginLeft={"12px"}
          icon={icon ? icon : null}
        />
      )}
      {property === "button" && buttonWidth === "medium" && (
        <button
          type="button"
          className="InputField_Button"
          style={{
            width: "96px",
          }}
          onClick={onClickButton}
        >
          {icon && <Icon name={icon} size={"20px"} color={"#3769fc"} />}
          <BasicText text={buttonLabel} fontSize={"14px"} fontWeight={"semiBold"} fontColor={"#3769fc"} marginLeft={icon ? "4px" : "0px"} />
        </button>
      )}
      {property === "button" && buttonWidth === "large" && (
        <button
          type="button"
          className="InputField_Button"
          style={{
            width: "144px",
          }}
          onClick={onClickButton}
        >
          {icon && <Icon name={icon} size={"20px"} color={"#3769fc"} />}
          <BasicText text={buttonLabel} fontSize={"14px"} fontWeight={"semiBold"} fontColor={"#3769fc"} marginLeft={icon ? "4px" : "0px"} />
        </button>
      )}
      {property === "button" && buttonWidth === "iconButton" && (
        <button
          type="button"
          className="InputField_Button"
          style={{
            width: "24px",
            backgroundColor: "transparent",
          }}
          onClick={onClickButton}
        >
          {icon && <Icon name={icon} size={"24px"} color={"#AAAAAA"} />}
        </button>
      )}
    </div>
  );
};

export default InputField;
