import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const CheckListLeft2 = ({ label, isCheckBox, setIsCheckBox, onpressInfo }) => {
  const onPressCheckBox = () => {
    setIsCheckBox(!isCheckBox);
  };
  return (
    <div className="CheckList_CT_TBD" style={{ justifyContent: "flex-start" }}>
      <button type="button" className="TermList_CheckBox_CT" onClick={onPressCheckBox} style={{ marginRight: "8px" }}>
        {isCheckBox === true ? <Icon name={"CheckBoxFill"} size={"24"} /> : <Icon name={"CheckBoxBlank"} size={"24"} />}
      </button>
      <div className="CheckList_Label_CT">
        <div className="CheckList_Label_Copy_TBD">{label}</div>
        {onpressInfo && (
          <button type="button" className="TermList_CheckBox_CT" onClick={onpressInfo}>
            <Icon name={"InfoIcon"} size={"16"} color={"#3769FC"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CheckListLeft2;
