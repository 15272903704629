const Search = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.35 20.425L13.325 14.4C12.825 14.8333 12.242 15.1708 11.576 15.4125C10.91 15.6542 10.2014 15.775 9.45 15.775C7.6473 15.775 6.12163 15.15 4.87298 13.9C3.62433 12.65 3 11.1417 3 9.37501C3 7.60834 3.625 6.10001 4.875 4.85001C6.125 3.60001 7.6375 2.97501 9.4125 2.97501C11.1875 2.97501 12.6958 3.60001 13.9375 4.85001C15.1792 6.10001 15.8 7.60959 15.8 9.37876C15.8 10.0929 15.6833 10.7833 15.45 11.45C15.2167 12.1167 14.8667 12.7417 14.4 13.325L20.475 19.35C20.625 19.4907 20.7 19.6634 20.7 19.868C20.7 20.0727 20.6174 20.2576 20.4522 20.4228C20.3007 20.5743 20.1148 20.65 19.8945 20.65C19.6741 20.65 19.4926 20.575 19.35 20.425ZM9.425 14.275C10.7792 14.275 11.9302 13.7958 12.8781 12.8375C13.826 11.8792 14.3 10.725 14.3 9.37501C14.3 8.02501 13.826 6.87084 12.8781 5.91251C11.9302 4.95417 10.7792 4.47501 9.425 4.47501C8.05695 4.47501 6.8941 4.95417 5.93645 5.91251C4.97882 6.87084 4.5 8.02501 4.5 9.37501C4.5 10.725 4.97882 11.8792 5.93645 12.8375C6.8941 13.7958 8.05695 14.275 9.425 14.275Z"
        fill={color || "#AAAAAA"}
      />
    </svg>
  );
};

export default Search;
