import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB31AddEmployeeFileSub1 = observer(({ setEmployee1, setEmployee2 }) => {
  const onClickNext = () => {
    amplitude.track("직원 등록 (엑셀파일)2 - 엑셀 파일 업로드 스크린 진입");
    setEmployee1(false);
    setEmployee2(true);
  };

  return (
    <>
      <div className="AllContent_Default_V2">
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">
            엑셀 파일을 등록하시기 전에 <br />
            형식을 확인해주세요.
          </div>
          <div className="Leave_Sub_Copy">아래 이미지의 형식으로 엑셀 파일의 형식을 수정해주세요.</div>

          <div className="EmployeeResist_Example_CT">
            <div className="EmployeeResist_Text_CT">
              <div
                className="EmployeeResist_Example_Copy"
                style={{
                  fontWeight: "600",
                  marginBottom: "8px",
                  color: "rgb(3,166,0)",
                }}
              >
                알맞은 형식
              </div>
              <div className="EmployeeResist_Example_Copy">
                통합된 셀이 없고, 각 행에 한 명의 정보가 입력 되어있는 형식
                <br />
                (sheet는 1 페이지만 적용됩니다.)
              </div>
            </div>

            <img className="EmployeeResist_Example_Image" src={require("../Assets/Images/exampleImage1.png")} alt="exampleImg1" />
          </div>
          <div className="EmployeeResist_Example_CT">
            <div className="EmployeeResist_Text_CT">
              <div
                className="EmployeeResist_Example_Copy"
                style={{
                  fontWeight: "600",
                  marginBottom: "8px",
                  color: "rgb(255,115,115)",
                }}
              >
                수정이 필요한 형식 예시
              </div>
              <div className="EmployeeResist_Example_Copy">
                항목의 위계가 한단계 이상인 형식
                <br />
                (ex : "개인정보" 항목 하위에 "이름", "전화번호", "이메일" 항목이 포함)
              </div>
            </div>

            <img className="EmployeeResist_Example_Image" src={require("../Assets/Images/exampleImage2.png")} alt="exampleImg1" />
          </div>
          <div className="EmployeeResist_Example_CT">
            <div className="EmployeeResist_Text_CT">
              <div className="EmployeeResist_Example_Copy">
                행에 항목명이 사용된 형식
                <br />
                (ex : 부서("개발팀", "총무팀") 레이블이 행 기준으로 분류)
              </div>
            </div>

            <img className="EmployeeResist_Example_Image" src={require("../Assets/Images/exampleImage3.png")} alt="exampleImg1" />
          </div>
        </div>
        <div className="EmployeeResist_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel nextButtonText={"다음"} onClickNext={onClickNext} />
        </div>
      </div>
    </>
  );
});

export default WB31AddEmployeeFileSub1;
