import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import DetailTitle from "../Components/DetailTitle";
import { observer } from "mobx-react";
// import NotificationList from "../Components/NotificationList";
// import { v4 as uuidv4 } from "uuid";

const WBb1Notification = observer(() => {
  // const testDataArray = [
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  //   { bodyText: "김서진님이 정규직 근로계약서 외 88건의 문서에 동의하였습니다.", date: "2024.08.18", ID: uuidv4(), show: false },
  // ];

  // const [testArray, setTestArray] = useState(testDataArray);

  // const handleItemClick = (selectedList) => {
  //   setTestArray((prevArray) => prevArray.map((item) => (item.ID === selectedList.ID ? { ...item, show: true } : item)));
  // };

  // const allListDelete = () => {
  //   setTestArray([]);
  // };

  // const readListDelete = () => {
  //   setTestArray((prevArray) => prevArray.filter((item) => !item.show));
  // };

  return (
    <>
      <div className="FlexContent_Other_V">
        <DetailTitle title={"알림"} notUseArrowBack />
        <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
          {/* <NotificationList handleItemClick={handleItemClick} allListDelete={allListDelete} readListDelete={readListDelete} list={testArray} /> */}
          <div className="Wait_Notification_Copy">준비중 입니다.</div>
        </div>
      </div>
    </>
  );
});

export default WBb1Notification;
