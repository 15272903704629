import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import TabBar from "../Components/TabBar";
import WB31AddEmployeeFileSub1 from "../SubPages/WB31AddEmployeeFileSub1";
import WB31AddEmployeeFileSub2 from "../SubPages/WB31AddEmployeeFileSub2";
import WB31AddEmployeeFileSub3 from "../SubPages/WB31AddEmployeeFileSub3";

const WB31AddEmployeeFile = observer(() => {
  const excelDataInput = useRef();

  const [excelData, setExcelData] = useState();
  const [excelDataSrc, setExcelDataSrc] = useState("");
  const [mappedData, setMappedData] = useState();
  const [employee1, setEmployee1] = useState(true);
  const [employee2, setEmployee2] = useState(false);
  const [employee3, setEmployee3] = useState(false);

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C">
        <TabBar
          tabLabel1={"addEmployeeFile"}
          tabLink1={"/addEmployeeFile"}
          tabLabel1Text={"파일 등록"}
          tabLabel1AmplitudeText={"직원 등록 (엑셀파일)1 - 엑셀 파일 형식 안내 스크린 진입"}
          tabLabel2={"addEmployeeInfo"}
          tabLink2={"/addEmployeeInfo"}
          tabLabel2Text={"직접 입력"}
          tabLabel2AmplitudeText={"직원 등록 (직접등록)1 - 직원 정보 직접 등록 스크린 진입"}
        />
        {employee1 ? <WB31AddEmployeeFileSub1 setEmployee1={setEmployee1} setEmployee2={setEmployee2} /> : null}
        {employee2 ? (
          <WB31AddEmployeeFileSub2
            setEmployee1={setEmployee1}
            setEmployee2={setEmployee2}
            setEmployee3={setEmployee3}
            excelData={excelData}
            setExcelData={setExcelData}
            excelDataSrc={excelDataSrc}
            setExcelDataSrc={setExcelDataSrc}
            excelDataInput={excelDataInput}
            setMappedData={setMappedData}
          />
        ) : null}
        {employee3 ? (
          <WB31AddEmployeeFileSub3 setEmployee1={setEmployee1} setEmployee2={setEmployee2} setEmployee3={setEmployee3} mappedData={mappedData} />
        ) : null}
      </div>
    </div>
  );
});

export default WB31AddEmployeeFile;
