import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const ContractSignTable = ({ inputCompanyName, companyRepresentName, employeeName }) => {
  return (
    <table
      className="Email_PDF_Chart_BG"
      style={{
        borderSpacing: "0px",
        borderCollapse: "collapse",
        marginBottom: "24px",
      }}
    >
      <tbody>
        <tr>
          <td className="ContractInput_Cell_Label" colSpan="4">
            기업/기관
          </td>
          <td className="ContractInput_Cell_Label" colSpan="4">
            입사 예정자
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
            기업명
          </td>
          <td className="ContractInput_Cell_Label" colSpan="3" style={{ backgroundColor: "white" }}>
            {inputCompanyName}
          </td>
          <td className="ContractInput_Cell_Label" rowSpan="2" style={{ backgroundColor: "white" }}>
            성명
          </td>
          <td className="ContractInput_Cell_Label" colSpan="3" rowSpan="2" style={{ backgroundColor: "white" }}>
            {employeeName}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              (인)
            </div>
          </td>
        </tr>
        <tr>
          <td className="ContractInput_Cell_Label" style={{ backgroundColor: "white" }}>
            대표
          </td>
          <td className="ContractInput_Cell_Label" colSpan="3" style={{ backgroundColor: "white" }}>
            {companyRepresentName}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              (인)
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ContractSignTable;
