import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";
import ContractDataTable from "../ChartTable/ContractDataTable";
import ContractSignTable from "../ChartTable/ContractSignTable";

const OfferLetter = observer(
  ({
    setOfferLetterInfo,
    inputCompanyName,
    companyRepresentName,
    companyAddress,
    setInputCompanyName,
    setCompanyRepresentName,
    setCompanyAddress,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      const isComplete =
        inputCompanyName.length > 0 &&
        companyRepresentName.length > 0 &&
        companyAddress.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address?.length > 0 &&
        selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate?.length === 8 &&
        selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate?.length === 8 &&
        selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary?.length > 0 &&
        selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty?.length > 0 &&
        selectedEmployeeData?.Documents?.OfferLetter?.ContractDate?.length === 8;
      return isComplete;
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 상호명을 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 기업/기관 주소를 입력해주세요." : "- 기업/기관 주소를 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 입사 예정일을 입력해주세요." : "- 입사 예정일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 입사 예정일 조정 범위를 입력해주세요." : "- 입사 예정일 조정 범위를 입력해주세요.";
      }

      if (!selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary?.length > 0) {
        errorMessage += errorMessage ? "\n- 연봉을 입력해주세요." : "- 연봉을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty?.length > 0) {
        errorMessage += errorMessage ? "\n- 위약벌을 입력해주세요." : "- 위약벌을 입력해주세요.";
      }

      if (selectedEmployeeData?.Documents?.OfferLetter?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.OfferLetter) {
            updatedData.Documents.OfferLetter = {};
          }
          updatedData.Documents.OfferLetter[nestedField] = value;
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyAddress");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", companyAddress, "CompanyAddress");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.TelNumber, "TelNumber");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);

        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.OfferLetter.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.Documents?.OfferLetter?.TelNumber);
        selectedEmployeeDataCopy.Documents.OfferLetter.Birthday = AddDotYYYYMMDD(selectedEmployeeDataCopy?.Documents?.OfferLetter?.Birthday);
        selectedEmployeeDataCopy.Documents.OfferLetter.AnnualSalary = AddCommaAmountNumber(
          selectedEmployeeDataCopy?.Documents?.OfferLetter?.AnnualSalary
        );
        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/pdf/offer-letter";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            telNumber: selectedEmployeeDataCopy?.TelNumber,
            name: selectedEmployeeData?.Name,
            data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.OfferLetter),
            ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
          });
          if (response.data.message === "입사확약서 생성 완료") {
            handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
            setTimeout(async () => {
              setIsLoading(false);
              setOfferLetterInfo(false);
              alert("입사 확약서 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setOfferLetterInfo(false);
            alert("입사 확약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          setIsLoading(false);
          setOfferLetterInfo(false);
          alert("입사 확약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          console.log("OfferLetter ERROR : ", error);
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };
    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">입사확약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setOfferLetterInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <ContractDataTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  companyAddress={companyAddress}
                  employeeName={selectedEmployeeData.Name}
                  employeeBirthday={selectedEmployeeData.Birthday}
                  employeeAddress={selectedEmployeeData.Address}
                  employeeTelNumber={selectedEmployeeData.TelNumber}
                />

                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  본 확약서는 회사와 입사 예정자의 근로계약 체결에 앞서 상호 합의한 내용을 확인하고 약속하기 위한 목적으로 작성되었습니다. 두 당사자는
                  아래 조건을 구체적으로 이행하기 위해 입사 이후 근로계약을 별도로 체결하며, 계약 시점 이후 본 확약서의 효력은 상실됩니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  1. 입사예정일
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  입사 예정자는{" "}
                  {selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate &&
                  selectedEmployeeData.Documents.OfferLetter.EmployeeContractStartDate.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.OfferLetter.EmployeeContractStartDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____년 __월 __일
                    </span>
                  )}{" "}
                  (이하 “입사 예정일”라 함)에 회사에 입사하며 회사는 입사 예정일에 입사 예정자를 채용합니다. 단,{" "}
                  {selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate &&
                  selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.OfferLetter.AdjustJoinDate)}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____년 __월 __일
                    </span>
                  )}
                  을 초과하지 않는 범위에서 일방 당사자의 요구에 의해 입사 시기를 조정할 수 있습니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  2. 처우
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  연봉 : 금{" "}
                  {selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary &&
                  selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddCommaAmountNumber(selectedEmployeeData.Documents.OfferLetter.AnnualSalary)}원
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      __________ 원
                    </span>
                  )}
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  기타 :{" "}
                  {selectedEmployeeData?.Documents?.OfferLetter?.EtcTreatment &&
                  selectedEmployeeData?.Documents?.OfferLetter?.EtcTreatment.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.OfferLetter.EtcTreatment}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(170, 170, 170)",
                      }}
                    >
                      기타 처우 사항 입력
                    </span>
                  )}
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  3. 수습기간
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  회사는 근로자의 업무수행능력, 업무 적격성 등을 판단하기 위하여 3개월 간의 수습기간을 둡니다. 단, 수습기간 중 급여는 차감되지
                  않습니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  4. 위약벌
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 확약서 내용을 이행하지 않을 경우 상대방은 큰 피해가 예상됩니다. 두 당사자는 본 확약서 내용을 성실히 수행할 것을 약속하고 만약 본
                  확약서 내용을 이행하지 않은 당사자는{" "}
                  {selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty &&
                  selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddCommaAmountNumber(selectedEmployeeData.Documents.OfferLetter.ContractPenalty)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      __________
                    </span>
                  )}{" "}
                  을/를 위약벌로 지급합니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  5. 확약 내용 변경
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 확약서 내용은 당사자간 서면, 메신저 등 전자적 의사표시에 의한 합의에 의해 변경될 수 있습니다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  6. 국가지원사업
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  입사와 관련해서 회사, 입사 예정자 각각 또는 쌍방에 도움이 될 수 있는 국가지원사업이 있을 경우 적극적으로 협조합니다.
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.OfferLetter?.ContractDate &&
                    selectedEmployeeData.Documents.OfferLetter.ContractDate.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.OfferLetter.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <ContractSignTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  employeeName={selectedEmployeeData?.Name}
                />
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">상호명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="상호명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.OfferLetter?.Birthday &&
                          selectedEmployeeData?.Documents?.OfferLetter?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.OfferLetter?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.OfferLetter?.Address && selectedEmployeeData?.Documents?.OfferLetter?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="NewEmployeeAddress"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">입사 예정일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate &&
                      selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.EmployeeContractStartDate || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeContractStartDate")}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="EmployeeContractStartDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">입사 예정일 조정 범위</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate &&
                      selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.AdjustJoinDate || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "AdjustJoinDate")}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="adjustJoinDate"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">연봉</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary &&
                      selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.AnnualSalary || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "AnnualSalary")}
                  placeholder="숫자만 입력해주세요."
                  name="annualSalary"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기타 처우</div>
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.EtcTreatment || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EtcTreatment")}
                  placeholder="예시) 성과에 따른 인센티브 지급"
                  name="etcTreatment"
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">위약벌</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty &&
                      selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.ContractPenalty || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "ContractPenalty")}
                  placeholder="예시) 월 급여"
                  name="contractPenalty"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.OfferLetter?.ContractDate &&
                      selectedEmployeeData?.Documents?.OfferLetter?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.OfferLetter?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default OfferLetter;
