import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB71ResignerSub5 = observer(({ setResigner4, setResigner5, setResigner6, loseDate, setLoseDate }) => {
  const isValidLoseDate = (loseDate) => {
    const regex = /^\d{8}$/;
    return regex.test(loseDate);
  };

  const onClickBack = () => {
    amplitude.track("퇴사 키트 발급4 - 퇴사자 정보 확인 페이지 진입");
    setResigner5(false);
    setResigner4(true);
  };

  const onClickNext = () => {
    if (isValidLoseDate(loseDate)) {
      amplitude.track("퇴사 키트 발급6 - 퇴사자 서류 등록 페이지 진입", {
        퇴사예정일: loseDate,
      });
      setResigner5(false);
      setResigner6(true);
    } else {
      alert("퇴사 예정일을 입력해주세요.");
    }
  };

  return (
    <div className="AllContent_Default_V2" style={{ marginBottom: "192px" }}>
      <div className="Leave_LeftArea_CT">
        <div className="Leave_Main_Copy">퇴사 예정일을 입력해주세요.</div>
        <div className="Leave_Sub_Copy">
          퇴사일은 귀사의 건강보험자격이 상실되는 날의 하루 전 입니다.
          <br />
          퇴사 키트를 받급받은 직원은 퇴사 예정일에 자동으로 퇴사 처리가 됩니다.
          <br />
          <span style={{ color: "#03A600" }}>예시) 건강보험자격상실일 : 2024년 6월 13일 -{">"} 퇴사일 : 2024년 6월 12일</span>
        </div>
        <div className="Leave_InputField_CT">
          <div className="ProfileDetail_Subject">
            <div className="Profile_Label_CT">
              <div className="Profile_Label_Copy">퇴사 예정일</div>
              <div className={isValidLoseDate(loseDate) ? "Required_Dot_Blue" : "Required_Dot_Red"} />
            </div>
            <input
              className="Leave_LongInput_BG"
              value={loseDate}
              onChange={(e) => setLoseDate(e.target.value)}
              placeholder="YYYYMMDD 형식으로 입력해주세요."
              name="loseDate"
              required
            />
          </div>
        </div>
      </div>
      <div className="Leave_Vertical_Divider" />
      <div className="Leave_RightArea_CT">
        <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
        <div className="Leave_PageCount_Copy">2/4</div>
      </div>
    </div>
  );
});

export default WB71ResignerSub5;
