import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { AddDotYYYYMMDD, AddHyphenTelNumber, ConvertKSTFormatDate } from "../Utils/ConvertNumberFormat";
import { ExtractValuesToString } from "../Utils/ExtractValuesToString";

const DetailHead = ({ itemData, labelList }) => {
  const checkIfLabelExists = (label) => {
    return labelList.includes(label);
  };

  const isNameIncluded = checkIfLabelExists("이름");
  const isReportYearMonth = checkIfLabelExists("주주보고서 시기");
  const isBirthdayIncluded = checkIfLabelExists("생년월일");
  const isTelNumberIncluded = checkIfLabelExists("전화번호");
  const isSignStatusIncluded = checkIfLabelExists("동의 여부");
  const isWorkPeriodIncluded = checkIfLabelExists("재직기간");
  const isCreateDateIncluded = checkIfLabelExists("작성일");
  const isIssueDateIncluded = checkIfLabelExists("발급일");
  const isReportDateIncluded = checkIfLabelExists("주주보고서 작성일");

  const formatReportYearMonthDateString = (date) => {
    const dateString = typeof date === "number" ? date.toString() : date;
    if (dateString.length === 6) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      return `${year}년 ${month}월`;
    }
    return date;
  };

  return (
    <>
      <div className="Detail_Title_BG">
        <div className="Detail_Title_Contents">
          <div className="Detail_Title_LeftContents">
            {isNameIncluded && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">이름</div>
                <div className="Detail_Title_Content_Copy">
                  {ExtractValuesToString(itemData?.MemberData, "name") || ExtractValuesToString(itemData?.MemberData, "Name") || itemData.Name}
                </div>
              </div>
            )}
            {isReportYearMonth && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">주주보고서 시기</div>
                <div className="Detail_Title_Content_Copy">{formatReportYearMonthDateString(itemData?.ReportYearMonth) || ""}</div>
              </div>
            )}
            {isBirthdayIncluded && itemData?.Birthday?.length > 0 && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">생년월일</div>
                <div className="Detail_Title_Content_Copy">{AddDotYYYYMMDD(itemData.Birthday)}</div>
              </div>
            )}
            {isTelNumberIncluded && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">전화번호</div>
                <div className="Detail_Title_Content_Copy">
                  {AddHyphenTelNumber(
                    ExtractValuesToString(itemData.MemberData, "telNumber") ||
                      ExtractValuesToString(itemData.MemberData, "TelNumber") ||
                      itemData.TelNumber
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="Detail_Title_RightContents">
            {isSignStatusIncluded && itemData?.DocumentStatus?.length > 0 && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">동의 여부</div>
                <div className="Detail_Title_Content_Copy">
                  {itemData.DocumentStatus === "작성"
                    ? "미발송"
                    : itemData.DocumentStatus === "서명대기"
                    ? "대기"
                    : itemData.DocumentStatus === "서명완료"
                    ? "완료"
                    : "미표시"}
                </div>
              </div>
            )}
            {isWorkPeriodIncluded && itemData?.AcqDate?.length > 0 && itemData?.LoseDate?.length > 0 && (
              <div className="Detail_Title_ContentBox">
                <div className="Detail_Title_Label_Copy">재직기간</div>
                <div className="Detail_Title_Content_Copy">
                  {AddDotYYYYMMDD(itemData.AcqDate)} ~ {AddDotYYYYMMDD(itemData.LoseDate)}
                </div>
              </div>
            )}
            {isCreateDateIncluded && itemData?.CreationDate?.length > 0 && (
              <div className="Detail_Title_ContentBox_Last">
                <div className="Detail_Title_Label_Copy">작성일</div>
                <div className="Detail_Title_Content_Copy">{AddDotYYYYMMDD(ConvertKSTFormatDate(itemData?.CreationDate)) || ""}</div>
              </div>
            )}
            {isReportDateIncluded && itemData?.CreationDate?.length > 0 && (
              <div className="Detail_Title_ContentBox_Last">
                <div className="Detail_Title_Label_Copy">작성일</div>
                <div className="Detail_Title_Content_Copy">{AddDotYYYYMMDD(ConvertKSTFormatDate(itemData?.CreationDate)) || ""}</div>
              </div>
            )}
            {isIssueDateIncluded && itemData?.createDate?.length > 0 && (
              <div className="Detail_Title_ContentBox_Last">
                <div className="Detail_Title_Label_Copy">발급일</div>
                <div className="Detail_Title_Content_Copy">{ConvertKSTFormatDate(itemData.createDate)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="DetailTitle_Line" style={{ marginTop: "0px" }} />
    </>
  );
};

export default DetailHead;
