import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import Icon from "../Assets/Icons/Icon";

const WB71ResignerSub2 = observer(({ setResigner2, setResigner3 }) => {
  const onClickNext = () => {
    amplitude.track("퇴사 키트 발급3 - 퇴사자 이름/전화번호 등록 페이지 진입");
    setResigner2(false);
    setResigner3(true);
  };
  return (
    <>
      <div className="Leave_Guide_Copy">
        이제 복잡하고 어려운 퇴사 절차를 퇴사 키트로 한 번에 해결하세요!
        <br />
        퇴사자의 재직 정보만 입력하면 필수 서류 작성 부터
        <br />
        전달 및 저장까지 퇴사 키트가 모두 챙겨드립니다.
      </div>
      <div className="Leave_Image_CT">
        <img src={require("../Assets/Images/FolderFile.png")} alt="FolderFile" width="33%" height="auto" />
      </div>
      <div className="Leave_TextButton_CT" style={{ justifyContent: "center" }}>
        <div className="Leave_IconTextButton_CT">
          <button className="Leave_TextButton_Copy" type="button" onClick={onClickNext}>
            시작하기
          </button>
          <Icon name={"ChevronRightBlue"} size={"32"} />
        </div>
      </div>
    </>
  );
});

export default WB71ResignerSub2;
