import "../../GlobalStyle.css";
import "../../PCStyle.css";
import "../../TabletStyle.css";
import "../../MobileStyle.css";
import React from "react";

const WidgetHeader = ({ titleText, bodyText, pageNumber }) => {
  const renderTextWithLineBreaks = (text) =>
    text.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  return (
    <div className="Widget_Header_CT">
      <div className="Widget_Header_Text_CT">
        <div className="Widget_Head_Copy">{renderTextWithLineBreaks(titleText)}</div>
        {bodyText && <div className="Widget_Sub_Copy">{renderTextWithLineBreaks(bodyText)}</div>}
      </div>
      <div className="Widget_Header_Number_CT">
        <div className="Widget_Number_Copy">{pageNumber}</div>
      </div>
    </div>
  );
};

export default WidgetHeader;
