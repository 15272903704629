import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const TabBar = ({
  tabLabel1,
  tabLink1,
  tabLabel1Text,
  tabLabel1AmplitudeText,
  tabLabel2,
  tabLink2,
  tabLabel2Text,
  tabLabel2AmplitudeText,
  tabLabel3,
  tabLink3,
  tabLabel3Text,
  tabLabel3AmplitudeText,
  tabLabel4,
  tabLink4,
  tabLabel4Text,
  tabLabel4AmplitudeText,
  tabLabel5,
  tabLink5,
  tabLabel5Text,
  tabLabel5AmplitudeText,
}) => {
  const [currentPage, setCurrentPage] = useState(tabLabel1);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    if (path === tabLink1) {
      setCurrentPage(tabLabel1);
    } else if (path === tabLink2) {
      setCurrentPage(tabLabel2);
    } else if (path === tabLink3) {
      setCurrentPage(tabLabel3);
    } else if (path === tabLink4) {
      setCurrentPage(tabLabel4);
    } else if (path === tabLink5) {
      setCurrentPage(tabLabel5);
    } else {
      setCurrentPage(tabLabel1);
    }
  }, [location, tabLabel1, tabLabel2, tabLabel3, tabLabel4, tabLabel5, tabLink1, tabLink2, tabLink3, tabLink4, tabLink5]);

  const moveToTabLink1 = () => {
    if (tabLabel1AmplitudeText.length > 0) {
      amplitude.track(tabLabel1AmplitudeText);
    }
    navigate(tabLink1);
  };

  const moveToTabLink2 = () => {
    if (tabLabel2AmplitudeText.length > 0) {
      amplitude.track(tabLabel2AmplitudeText);
    }
    navigate(tabLink2);
  };

  const moveToTabLink3 = () => {
    if (tabLabel3AmplitudeText.length > 0) {
      amplitude.track(tabLabel3AmplitudeText);
    }
    navigate(tabLink3);
  };

  const moveToTabLink4 = () => {
    if (tabLabel4AmplitudeText.length > 0) {
      amplitude.track(tabLabel4AmplitudeText);
    }
    navigate(tabLink4);
  };

  const moveToTabLink5 = () => {
    if (tabLabel5AmplitudeText.length > 0) {
      amplitude.track(tabLabel5AmplitudeText);
    }
    navigate(tabLink5);
  };

  return (
    <div className="TabBar_CT">
      <div className="TabBar_Labels_CT">
        <div className="TabBar_Label_CT">
          <button className="TabBar_Label_Underline" onClick={moveToTabLink1}>
            <div className={`TabBar_Label_Copy_${currentPage === tabLabel1 ? "A" : "B"}`}>{tabLabel1Text}</div>
          </button>
        </div>
        <div className="TabBar_Label_CT">
          <button className="TabBar_Label_Underline" onClick={moveToTabLink2}>
            <div className={`TabBar_Label_Copy_${currentPage === tabLabel2 ? "A" : "B"}`}>{tabLabel2Text}</div>
          </button>
        </div>
        {tabLabel3 && tabLink3 ? (
          <div className="TabBar_Label_CT">
            <button className="TabBar_Label_Underline" onClick={moveToTabLink3}>
              <div className={`TabBar_Label_Copy_${currentPage === tabLabel3 ? "A" : "B"}`}>{tabLabel3Text}</div>
            </button>
          </div>
        ) : null}
        {tabLabel4 && tabLink4 ? (
          <div className="TabBar_Label_CT">
            <button className="TabBar_Label_Underline" onClick={moveToTabLink4}>
              <div className={`TabBar_Label_Copy_${currentPage === tabLabel4 ? "A" : "B"}`}>{tabLabel4Text}</div>
            </button>
          </div>
        ) : null}
        {tabLabel5 && tabLink5 ? (
          <div className="TabBar_Label_CT">
            <button className="TabBar_Label_Underline" onClick={moveToTabLink5}>
              <div className={`TabBar_Label_Copy_${currentPage === tabLabel5 ? "A" : "B"}`}>{tabLabel5Text}</div>
            </button>
          </div>
        ) : null}
      </div>
      <div className="TabBar_Line" />
    </div>
  );
};

export default TabBar;
