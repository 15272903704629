import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { AddDotYYYYMMDD, ConvertKSTFormatDate } from "../Utils/ConvertNumberFormat";
import { DocumentNameToKorean } from "../Utils/ConvertStringFormat";
import CheckBox from "./Atom/CheckBox";

const ReferCheckList = observer(({ selectedList, setSelectedList, mappedList, handleCheckBoxChange, labelList, showDetailData }) => {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  const [isCheckAll, setIsCheckAll] = useState(false);
  useEffect(() => {
    if (isCheckAll && selectedList.length < mappedList.length) {
      setIsCheckAll(false);
    } else if (isCheckAll && selectedList.length === mappedList.length) {
      setIsCheckAll(true);
    }
  }, [selectedList, isCheckAll, mappedList.length]);

  const [currentListData, setCurrentListData] = useState([]);

  useEffect(() => {
    if (currentListData.length === 0 && mappedList.length > 0) {
      setCurrentListData(mappedList.slice(0, itemsPerPage));
    }
  }, [currentListData.length, mappedList]);

  const checkIfLabelExists = (label) => {
    return labelList.includes(label);
  };

  const itemsPerPage = 10;

  const isNameIncluded = checkIfLabelExists("이름");
  const isRecipientIncluded = checkIfLabelExists("수령인");
  const isBirthdayIncluded = checkIfLabelExists("생년월일");
  const isDepartmentIncluded = checkIfLabelExists("부서");
  const isDocumentNameIncluded = checkIfLabelExists("문서명");
  const isAcqDateIncluded = checkIfLabelExists("입사일");
  const isIssueDateIncluded = checkIfLabelExists("작성일");

  const handleItemClick = (certificateData) => {
    navigate(`/consentDocumentDetail/${certificateData.ID}`, {
      state: {
        item: certificateData,
      },
    });
  };

  const handlePageChange = (page) => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentListData(mappedList.slice(start, end));
  };

  return (
    <div className="ReferCheckList_CT">
      <div className="ReferCheckList_Label_CT">
        <div className="ReferCheckList_CheckBox_CT">
          <CheckBox
            size={"32"}
            isCheck={isCheckAll}
            setIsCheck={(value) => {
              setIsCheckAll(value);
              setSelectedList(value ? mappedList.map((item) => item) : []);
            }}
          />
        </div>
        {isNameIncluded && <div className="ReferCheckList_Label_Name_Copy">이름</div>}
        {isRecipientIncluded && <div className="ReferCheckList_Label_Name_Copy">수령인</div>}
        {isDocumentNameIncluded && <div className="ReferCheckList_Label_Department_Copy">문서명</div>}
        {isBirthdayIncluded && <div className="ReferCheckList_Label_YYYYMMDD_Copy">생년월일</div>}
        {isDepartmentIncluded && <div className="ReferCheckList_Label_Department_Copy">부서</div>}
        {isAcqDateIncluded && (
          <div className="ReferCheckList_Label_YYYYMMDD_Copy" style={{ marginRight: "0px" }}>
            입사일
          </div>
        )}
        {isIssueDateIncluded && (
          <div className="ReferCheckList_Label_IssueDate_Copy" style={{ marginRight: "0px" }}>
            작성일
          </div>
        )}
      </div>
      <div className="Document_EmployeeList_Divider" style={{ backgroundColor: "rgb(170, 170, 170)" }} />
      <div className="ReferCheckList_BodyList_CT">
        {mappedList.map((certificateData) => (
          <React.Fragment key={certificateData.ID}>
            <div style={{ display: "flex", flexDirection: "column" }} key={certificateData.ID}>
              <div className="ReferCheckList_Body_CT" key={certificateData.ID}>
                <div className="ReferCheckList_CheckBox_CT" key={certificateData.ID}>
                  <CheckBox isCheck={selectedList.includes(certificateData)} setIsCheck={() => handleCheckBoxChange(certificateData)} size={"32"} />
                </div>
                {isNameIncluded && (
                  <div className="ReferSelectList_Body_Name_Copy">
                    {certificateData.MemberData ? (
                      <>
                        {certificateData.MemberData[0].Name || certificateData.MemberData[0].name}
                        <br />외 {certificateData.MemberData.length - 1} 명
                      </>
                    ) : (
                      certificateData.Name
                    )}
                  </div>
                )}
                {isRecipientIncluded && (
                  <div className="ReferSelectList_Body_Name_Copy">
                    {certificateData.MemberData ? (
                      <>
                        {certificateData.MemberData[0].Name || certificateData.MemberData[0].name}
                        <br />외 {certificateData.MemberData.length - 1} 명
                      </>
                    ) : (
                      certificateData.Name
                    )}
                  </div>
                )}
                {screenWidth > 768 && (
                  <>
                    {isBirthdayIncluded && certificateData.Birthday ? (
                      <div className="ReferCheckList_Body_YYYYMMDD_Copy">{AddDotYYYYMMDD(certificateData.Birthday)}</div>
                    ) : isBirthdayIncluded && !certificateData.Birthday ? (
                      <div className="ReferCheckList_Body_YYYYMMDD_Copy" style={{ color: "rgb(170, 170, 170)" }}>
                        정보 없음
                      </div>
                    ) : null}
                    {isDepartmentIncluded && certificateData.Department ? (
                      <div className="ReferCheckList_Body_Department_Copy">{certificateData.Department}</div>
                    ) : isDepartmentIncluded && !certificateData.Department ? (
                      <div className="ReferCheckList_Body_Department_Copy" style={{ color: "rgb(170, 170, 170)" }}>
                        정보 없음
                      </div>
                    ) : null}
                    {isDocumentNameIncluded && certificateData.DocumentName ? (
                      <div className="ReferCheckList_Body_Department_Copy">{DocumentNameToKorean(certificateData.DocumentName)}</div>
                    ) : isDocumentNameIncluded && !certificateData.DocumentName ? (
                      <div className="ReferCheckList_Body_Department_Copy" style={{ color: "rgb(170, 170, 170)" }}>
                        정보 없음
                      </div>
                    ) : null}
                    {isAcqDateIncluded && certificateData.AcqDate ? (
                      <div className="ReferCheckList_Body_YYYYMMDD_Copy" style={{ marginRight: "0px" }}>
                        {AddDotYYYYMMDD(certificateData.AcqDate)}
                      </div>
                    ) : isAcqDateIncluded && !certificateData.AcqDate ? (
                      <div
                        className="ReferCheckList_Body_YYYYMMDD_Copy"
                        style={{
                          marginRight: "0px",
                          color: "rgb(170, 170, 170)",
                        }}
                      >
                        정보 없음
                      </div>
                    ) : null}
                    {isIssueDateIncluded && certificateData.CreationDate ? (
                      <div className="ReferCheckList_Body_YYYYMMDD_Copy" style={{ marginRight: "0px" }}>
                        {ConvertKSTFormatDate(certificateData.CreationDate)}
                      </div>
                    ) : isIssueDateIncluded && !certificateData.CreationDate ? (
                      <div
                        className="ReferCheckList_Body_YYYYMMDD_Copy"
                        style={{
                          marginRight: "0px",
                          color: "rgb(170, 170, 170)",
                        }}
                      >
                        정보 없음
                      </div>
                    ) : null}

                    {showDetailData ? (
                      <button
                        className="ReferCheckList_Body_DetailData_Button"
                        style={{
                          color: "rgb(55, 105, 252)",
                        }}
                        onClick={() => handleItemClick(certificateData)}
                      >
                        상세 정보
                      </button>
                    ) : null}
                  </>
                )}
              </div>
              <div className="Document_EmployeeList_Divider" />
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="Wait_Pagination">
        <Pagination totalItems={mappedList.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
      </div>
    </div>
  );
});

export default ReferCheckList;
