import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { v4 as uuidv4 } from "uuid";
import Icon from "../Assets/Icons/Icon";
import * as amplitude from "@amplitude/analytics-browser";
import RefundPolicy from "../Components/RefundPolicy";

const WB51ConsentDocumentSub2 = observer(({ setDocument1, setDocument2, setSelectedDocuments }) => {
  const [isVisibleTerms, setIsVisibleTerms] = useState(false);

  const testCustomerKey = uuidv4();
  const onClickTerms = async () => {
    setIsVisibleTerms(true);
  };

  const onClickUseTermsLink = async () => {
    window.open("https://certed.co.kr/terms-of-service/ ", "_blank", "noopener noreferrer");
  };

  const onClickLandingPage = async () => {
    window.open("https://certed.co.kr", "_blank", "noopener noreferrer");
  };

  const handlePayment = () => {
    amplitude.track("동의 받기 - 결제 진행");
    setDocument2(false);
    const successUrl = `${window.location.origin}/paymentConfirm`;
    const failUrl = `${window.location.origin}/paymentFail`;
    loadTossPayments(process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY).then((tossPayments) => {
      tossPayments
        .requestBillingAuth("카드", {
          // amount: 1000000,
          // orderId: nanoid(),
          // orderName: "써티드 기업회원 연간 이용료",
          // customerEmail: companyEmail,
          // customerName: companyName,
          customerKey: testCustomerKey,
          successUrl: successUrl,
          failUrl: failUrl,
        })
        // .then((data) => {
        //   console.log("data : ", data);
        // })

        .catch(function (error) {
          console.log("Payments error", error);
          if (error.code === "USER_CANCEL") {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
            setDocument1(false);
            setDocument2(true);
          } else if (error.code === "INVALID_CARD_COMPANY") {
            alert("카드 정보가 유효하지 않습니다.");
          }
        });
    });
  };

  const onClickBack = () => {
    amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
    setSelectedDocuments([]);
    setDocument2(false);
    setDocument1(true);
  };

  return (
    <>
      {isVisibleTerms ? (
        <div className="Signin_CompleteModal_BG">
          <div className="Payment_Terms_Modal_CT">
            <div className="Payment_Terms_Close_CT">
              <div className="CheckList_InfoModal_Title_Copy">환불 규정</div>
              <div className="CheckList_InfoModal_Icon_CT">
                <button className="CheckList_Close_Button" onClick={() => setIsVisibleTerms(false)}>
                  <Icon name={"Close"} size={24} />
                </button>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgb(170,170,170)",
                marginBottom: "16px",
              }}
            ></div>
            <RefundPolicy />
            <button className="Login_SigninButton" onClick={onClickUseTermsLink} style={{ marginTop: "0px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                  이용약관 전문
                </div>
                <Icon name={"ChevronRightBlue"} size={24} />
              </div>
            </button>
          </div>
        </div>
      ) : null}
      <div className="AllContent_Default_V2">
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">
            문서 동의 서비스를 이용하시려면
            <br />
            결제를 진행해주세요.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div className="PaymentIntro_Info_BG">
              <div className="Read_Item_Name_Copy" style={{ fontWeight: 600, color: "rgb(55, 105, 252)" }}>
                연간 구독권
              </div>
              <div
                className="Leave_Guide_Copy"
                style={{
                  color: "rgb(70, 70, 70)",
                  lineHeight: "100%",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              >
                1,000,000원
              </div>
              <div className="PaymentIntro_Info_Divider" />
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">직원 인사정보 관리</div>
                <div className="PaymentIntro_Info_Body_Copy">엑셀파일 업로드 한번으로 끝내는 직원관리</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">증명서 생성</div>
                <div className="PaymentIntro_Info_Body_Copy">등록된 직원정보로 빠르게 생성하는 제증명</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">입사자 처리</div>
                <div className="PaymentIntro_Info_Body_Copy">처음부터 꼼꼼하게 각종 서류 동의</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">퇴사자 처리</div>
                <div className="PaymentIntro_Info_Body_Copy">마지막까지 깔끔하게 퇴사 서류 발급</div>
              </div>
              <div className="PaymentIntro_Info_CT" style={{ marginBottom: "0px" }}>
                <div className="PaymentIntro_Info_Label_Copy">앱과 연계</div>
                <div className="PaymentIntro_Info_Body_Copy">웹에서 발급하고 앱에서 확인하는 서류</div>
              </div>
              <button className="Login_SigninButton" onClick={onClickLandingPage} style={{ marginTop: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                    더 알아보기
                  </div>
                  <Icon name={"ChevronRightBlue"} size={24} />
                </div>
              </button>
              <div className="Login_Button_One_CT" style={{ display: "flex", flexDirection: "column" }}>
                <button className="LoginButton_Copy_CT" onClick={handlePayment}>
                  <div className="LoginButton_One_BG" style={{ width: "280px" }}>
                    <div className="LoginButton_Copy">결제 진행</div>
                  </div>
                </button>
              </div>
            </div>
            <button className="Login_SigninButton" onClick={onClickTerms} style={{ marginTop: "0px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                  환불 규정
                </div>
                <Icon name={"ChevronRightBlue"} size={24} />
              </div>
            </button>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT" style={{ alignItems: "flex-end" }}>
          <div className="Leave_Navigation_CT">
            <div className="Leave_IconTextButton_CT">
              <Icon name={"ArrowBack"} size={24} color={"#3769FC"} />
              <button className="Leave_TextButton_Copy" type="button" onClick={onClickBack}>
                이전
              </button>
            </div>
          </div>
          <div className="Leave_PageCount_Copy">1/3</div>
        </div>
      </div>
    </>
  );
});

export default WB51ConsentDocumentSub2;
