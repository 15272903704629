import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import OfferLetterInfoModal from "../Modal/OfferLetterInfoModal";
import ConfidentialityAgreementInfoModal from "../Modal/ConfidentialityAgreementInfoModal";
import FulltimeContractInfoModal from "../Modal/FulltimeContractInfoModal";
import TemporaryContractInfoModal from "../Modal/TemporaryContractInfoModal";
import ParttimeContractInfoModal from "../Modal/ParttimeContractInfoModal";
import DailyContractInfoModal from "../Modal/DailyContractInfoModal";
import JuvenileContractInfoModal from "../Modal/JuvenileContractInfoModal";
import ParentalAuthorityConsentInfoModal from "../Modal/ParentalAuthorityConsentInfoModal";
import AnnualSalaryContractInfoModal from "../Modal/AnnualSalaryContractInfoModal";
import PrivacyConsentInfoModal from "../Modal/PrivacyConsentInfoModal";
import CheckListLeft from "../Components/CheckListLeft";
import NavigationPanel from "../Components/NavigationPanel";

const WB61NewEmployeeSub5 = observer(
  ({
    seal,
    setNewEmployee3,
    setNewEmployee4,
    setNewEmployee5,
    setNewEmployee6,
    offerLetter,
    setOfferLetter,
    offerLetterInfo,
    setOfferLetterInfo,
    confidentialityAgreement,
    setConfidentialityAgreement,
    confidentialityAgreementInfo,
    setConfidentialityAgreementInfo,
    fulltimeContract,
    setFulltimeContract,
    fulltimeContractInfo,
    setFulltimeContractInfo,
    temporaryContract,
    setTemporaryContract,
    temporaryContractInfo,
    setTemporaryContractInfo,
    parttimeContract,
    setParttimeContract,
    parttimeContractInfo,
    setParttimeContractInfo,
    dailyContract,
    setDailyContract,
    dailyContractInfo,
    setDailyContractInfo,
    juvenileContract,
    setJuvenileContract,
    juvenileContractInfo,
    setJuvenileContractInfo,
    annualSalaryContract,
    setAnnualSalaryContract,
    annualSalaryContractInfo,
    setAnnualSalaryContractInfo,
    privacyConsent,
    setPrivacyConsent,
    privacyConsentInfo,
    setPrivacyConsentInfo,
    parentalAuthorityConsentInfo,
    setParentalAuthorityConsentInfo,
  }) => {
    const onClickNext = () => {
      if (
        !offerLetter &&
        !confidentialityAgreement &&
        !fulltimeContract &&
        !temporaryContract &&
        !parttimeContract &&
        !dailyContract &&
        !juvenileContract &&
        !annualSalaryContract &&
        !privacyConsent
      ) {
        alert("서류를 선택해주세요.");
      } else {
        amplitude.track("입사 키트 발급5 - 입사 서류 선택 완료", {
          입사확약서: offerLetter,
          보안유지서약서: confidentialityAgreement,
          정규직근로계약서: fulltimeContract,
          계약직근로계약서: temporaryContract,
          아르바이트근로계약서: parttimeContract,
          일용직근로계약서: dailyContract,
          청소년근로계약서: juvenileContract,
          연봉계약서: annualSalaryContract,
          개인정보활용동의서: privacyConsent,
        });
        amplitude.track("입사 키트 발급6 - 입사 서류 정보 입력 페이지 진입");
        setNewEmployee5(false);
        setNewEmployee6(true);
      }
    };
    const onClickBack = () => {
      if (seal) {
        amplitude.track("입사 키트 발급3 - 입사자 이름/전화번호 등록 페이지 진입");
        setNewEmployee5(false);
        setNewEmployee3(true);
      } else {
        amplitude.track("입사 키트 발급4 - 기업/기관 직인 등록 페이지 진입");
        setNewEmployee5(false);
        setNewEmployee4(true);
      }
    };

    return (
      <>
        {offerLetterInfo ? <OfferLetterInfoModal setOfferLetterInfo={setOfferLetterInfo} /> : null}
        {confidentialityAgreementInfo ? (
          <ConfidentialityAgreementInfoModal setConfidentialityAgreementInfo={setConfidentialityAgreementInfo} />
        ) : null}
        {fulltimeContractInfo ? <FulltimeContractInfoModal setFulltimeContractInfo={setFulltimeContractInfo} /> : null}
        {temporaryContractInfo ? <TemporaryContractInfoModal setTemporaryContractInfo={setTemporaryContractInfo} /> : null}
        {parttimeContractInfo ? <ParttimeContractInfoModal setParttimeContractInfo={setParttimeContractInfo} /> : null}
        {dailyContractInfo ? <DailyContractInfoModal setDailyContractInfo={setDailyContractInfo} /> : null}
        {juvenileContractInfo ? <JuvenileContractInfoModal setJuvenileContractInfo={setJuvenileContractInfo} /> : null}
        {parentalAuthorityConsentInfo ? (
          <ParentalAuthorityConsentInfoModal setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo} />
        ) : null}
        {annualSalaryContractInfo ? <AnnualSalaryContractInfoModal setAnnualSalaryContractInfo={setAnnualSalaryContractInfo} /> : null}
        {privacyConsentInfo ? <PrivacyConsentInfoModal setPrivacyConsentInfo={setPrivacyConsentInfo} /> : null}
        <div className="AllContent_Default_V2" style={{ marginBottom: "64px" }}>
          <div className="Leave_LeftArea_CT">
            <div className="Leave_Main_Copy">
              입사자로 부터 서명을 받을
              <br />
              동의서 및 계약서를 선택해주세요.
            </div>
            <div className="Leave_Sub_Copy">
              입사자에게 교부 및 동의를 받아야 하는 파일로,
              <br />각 문서의 상세 정보를 확인해주세요.
            </div>
            <div className="Leave_InputField_CT">
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"입사확약서"}
                  isCheckBox={offerLetter}
                  setIsCheckBox={setOfferLetter}
                  onpressInfo={() => setOfferLetterInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"보안유지서약서"}
                  isCheckBox={confidentialityAgreement}
                  setIsCheckBox={setConfidentialityAgreement}
                  onpressInfo={() => setConfidentialityAgreementInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"연봉계약서"}
                  isCheckBox={annualSalaryContract}
                  setIsCheckBox={setAnnualSalaryContract}
                  onpressInfo={() => setAnnualSalaryContractInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"개인정보 제공 및 수집 이용 동의서"}
                  isCheckBox={privacyConsent}
                  setIsCheckBox={setPrivacyConsent}
                  onpressInfo={() => setPrivacyConsentInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"정규직 근로계약서"}
                  isCheckBox={fulltimeContract}
                  setIsCheckBox={setFulltimeContract}
                  onpressInfo={() => setFulltimeContractInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"계약직(기간제) 근로계약서"}
                  isCheckBox={temporaryContract}
                  setIsCheckBox={setTemporaryContract}
                  onpressInfo={() => setTemporaryContractInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"단시간(아르바이트) 근로계약서"}
                  isCheckBox={parttimeContract}
                  setIsCheckBox={setParttimeContract}
                  onpressInfo={() => setParttimeContractInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"일용직 근로계약서"}
                  isCheckBox={dailyContract}
                  setIsCheckBox={setDailyContract}
                  onpressInfo={() => setDailyContractInfo(true)}
                />
              </div>
              <div className="CheckList_Divider" />
              <div className="CheckList_Comp_CT">
                <CheckListLeft
                  label={"청소년(연소자) 근로계약서"}
                  isCheckBox={juvenileContract}
                  setIsCheckBox={setJuvenileContract}
                  onpressInfo={() => setJuvenileContractInfo(true)}
                />
              </div>
              {juvenileContract ? (
                <>
                  <div className="CheckList_Divider" />
                  <div className="CheckList_Comp_CT">
                    <CheckListLeft
                      label={"친권자(후견인) 동의서"}
                      isCheckBox={juvenileContract}
                      setIsCheckBox={setJuvenileContract}
                      onpressInfo={() => setParentalAuthorityConsentInfo(true)}
                    />
                  </div>
                </>
              ) : null}
              <div className="CheckList_Divider" />
            </div>
          </div>
          <div className="Leave_Vertical_Divider" />
          <div className="Leave_RightArea_CT">
            <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
            <div className="Leave_PageCount_Copy">2/3</div>
          </div>
        </div>
      </>
    );
  }
);

export default WB61NewEmployeeSub5;
